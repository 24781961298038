<ngx-spinner bdcolor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" style="color: white" type="ball-spin">Loading...</ngx-spinner>

<mat-card-content>
    <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="card border-grey border-lighten-3  m-0">
                
                <div class="card-content">
                    <div style="text-align: center;">
                        <h4>Slot details</h4>
                    </div>
                    <div class="card-body">
                        
                        <form class="form form-horizontal" [formGroup]="personaldetailsForm" (ngSubmit)="onSubmit()">
                            <a class="label-control" style="margin-top:5px;margin-bottom: 10px; " (click)="addTimeSlots()">Add Slots</a>
                            <div class="row" *ngIf="!showdata" >
                                <p>No available slots</p>
                            </div>

                            <fieldset class="form-group position-relative col-md-12" >
                               <!-- <div class="row">
                                    <div class="col-12 " style="margin-left: 70%; margin-bottom: 5px;">

                                        <fieldset class="col-md-12" >
                                            <button class="btn btn-primary" type="button" (click)="addTimeSlots()"><i
                                                class="feather ft-plus"></i></button>
                                        </fieldset>
                                    </div>
                                </div>
                                -->
                                <div class="" formArrayName="timeSlotDTOList" *ngIf="showdata">
                                    <label class="label-control" style="margin-top:5px;margin-bottom: 10px; ">Doctor Available Slots</label>
                                    
                                    
                                    <div class="" *ngFor="let appointmentTimeSlotsForm of getTimeSlotFormrGoup.controls; let j = index;">
                                        <div [formGroupName]="j" class="row">
                                            <fieldset class="form-group position-relative col-md-2">Time Slots</fieldset>
                                            
                                            <fieldset class="form-group position-relative col-md-4">
                                                <select class="form-control" formControlName="startTime" [ngClass]="{ 'is-invalid': submitted }">
                                                    <option value="">From</option>
                                                    <option *ngFor="let obj of timeSlots" [value]="obj.key">{{obj.name}}</option>
                                                </select>
                                            </fieldset>
                                            <fieldset class="form-group position-relative col-md-4">
                                                <select class="form-control" formControlName="endTime" [ngClass]="{ 'is-invalid': submitted  }">
                                                    <option value="">To</option>
                                                    <option *ngFor="let obj of timeSlots" [value]="obj.key">{{obj.name}}</option>
                                                </select>
                                            </fieldset>
                                            
                                            <fieldset class="col-md-1" *ngIf="j==0">
                                                <button class="btn btn-danger" type="button" (click)="removeTimeSlots(j)"><i
                                                    class="feather ft-x"></i></button>
                                            </fieldset>
                                            <fieldset class="col-md-1" *ngIf="j!=0">
                                                <button class="btn btn-danger" type="button" (click)="removeTimeSlots(j)"><i
                                                    class="feather ft-x"></i></button>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <button [disabled]="personaldetailsForm.invalid" type="submit" class="btn btn-block" [ngClass]="personaldetailsForm.invalid ? 'btn-outline-secondary' : 'btn-outline-info'">
                                 Update
                            </button>
                            <button  class="btn btn-block btn-outline-danger" (click)="goBack()">
                                Cancel
                            </button>
                        </form>
                        <hr style="border:0px;height: 1px; background:#333;width: 100%;  background-image:linear-gradient(to right, #ccc, rgb(228, 216, 216), #ccc);">
                        <!--
                        <form class="form-horizontal" [formGroup]="blockdetailsForm" (ngSubmit)="onSubmit1()">
                                
                                <fieldset class="form-group position-relative col-md-12" >
                                    <div class="" formArrayName="timeSlotDTOList1" *ngIf="showdata">
                                        <label class="label-control" style="margin-top:5px;margin-bottom: 10px; ">Block Slots</label>
                                        <div class="" *ngFor="let appointmentTimeSlotsForm of getTimeSlotFormrGoup1.controls; let j = index;">
                                            <div [formGroupName]="j" class="row">
                                                <fieldset class="form-group position-relative col-md-2">Time Slots</fieldset>
    
                                                <fieldset class="form-group position-relative col-md-4">
                                                    <select class="form-control" formControlName="startTime">
                                                        <option value="">From</option>
                                                        <option *ngFor="let obj of timeSlots" [value]="obj.key">{{obj.name}}</option>
                                                    </select>
                                                </fieldset>
                                                <fieldset class="form-group position-relative col-md-4">
                                                    <select class="form-control" formControlName="endTime" >
                                                        <option value="">To</option>
                                                        <option *ngFor="let obj of timeSlots" [value]="obj.key">{{obj.name}}</option>
                                                    </select>
                                                </fieldset>
                                                <fieldset class="col-md-1" *ngIf="j==0">
                                                    <button class="btn btn-primary" type="button" (click)="addTimeSlots1()"><i
                                                        class="feather ft-plus"></i></button>
                                                </fieldset>
                                                <fieldset class="col-md-1" *ngIf="j!=0">
                                                    <button class="btn btn-danger" type="button" (click)="removeTimeSlots1(j)"><i
                                                        class="feather ft-x"></i></button>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                        </form>
                        -->
                        
                    </div>
                    
                  
                </div>
            </div>
    </div>
    
</mat-card-content>