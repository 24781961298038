import { Inject, Optional } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validator, Validators } from '@angular/forms';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ApiService } from '../_services/api-service';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'callend',
  templateUrl: './callend.component.html',
  styleUrls: ['./callend.component.scss'],
  providers: [ApiService]
})
export class CallEndComponent implements OnInit {
 
  constructor(private router: Router,private apiService:ApiService,private authService: AuthenticationService
  )
   {
    
  }

  ngOnInit() {
}


}
