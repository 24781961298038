import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/_services/api-service';
import { AppointmentConsultComponent } from '../appointment-consultation/appointment-consultation.component';

@Component({
  selector: 'app-appointment-history',
  templateUrl: './appointment-history.component.html',
  styleUrls: ['./appointment-history.component.css']
})
export class AppointmentHistoryComponent implements OnInit {

  rows: any = [];
  dataTableMessage: any = { emptyMessage: 'Loading...' };
  temp2: any;
  date: Date;
 
  constructor(private apiservice: ApiService,private matDailog: MatDialog) { }

  ngOnInit(){
    this.date=new Date();
    this.getMyAppointmentsHistory();
  }

  getMyAppointmentsHistory() {
    const datepipe: DatePipe = new DatePipe('en-US');
    let latest_date =datepipe.transform(this.date, 'yyyy-MM-dd hh:mm:ss');
    var user = JSON.parse(localStorage.getItem('currentUser'));
    this.apiservice.getDoctorAppointmentsHistory(user['username']).pipe(map(response => {
      response.map(item => {
        //item.date = this.datepipe.transform(item.startTime, 'yyyy-MM-dd');
        return item;
      });
      return response;
    })).subscribe(res => {
      let row = [];
      res.forEach(element => {
        if(element.cancelledBy != "Doctor")
          row.push(element);
      });
      this.rows = row;
      this.rows=this.rows.map(row=>{
        console.log("debug "+JSON.stringify(row));
        var scheduleDate = new Date(row['startTime']);
        
        var num:number=+row['duration']
        var validTime = new Date(scheduleDate.getTime() + (num+15) * 60000);
        var toolTip="Meet";
        if(validTime > this.date){
        toolTip="join";
        }else {
        toolTip="Meeting over";
        }
        return {...row ,
        toolTip: toolTip,
        showSuccess: validTime > this.date ?true:false,
         }
      })
      // this.rows = res;
      this.temp2 = this.rows;
      this.dataTableMessage = { emptyMessage: 'No data to display' };
    }, error => {

    });
  }


  startConsultation(row) {
    // this.router.navigate(['/appointment-consult'], { state: row });
    const dialogRef = this.matDailog.open(AppointmentConsultComponent, {
      width: '95%',
      height: '95%',
      maxWidth: '120vw',
      disableClose: true,
      hasBackdrop: true,
      data: { pageValue: row }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

}
