import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import * as constants from './constants'
import { JwtService } from './jwt.service';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { RequestMeeting } from '../_models/RequestMeetingObject';
@Injectable()
export class ApiService {
    constructor(
        private http: HttpClient, private jwtToken: JwtService) {
    }

    loginService(mobileNO) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "app/login/" + mobileNO;
        return this.http.post(url, { headers: headers, responseType: 'text', observe: 'response' })
    }


    executeRegistrationService(registrationForm) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "app/registration"
        return this.http.post(url, registrationForm, { headers: headers, responseType: 'text', observe: 'response' })
    }
    searchHospitals(latitude: any, longitude: any, category: String) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "app/getNearByHospitals/" + latitude + "/" + longitude + "/" + category, { headers: headers });
    }

    // static String Get_LatestPatient_Vitals = "robotapi/getPatientDetails/";

    getPatientDetails(meetingId: any, username: any) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "api/v1/getPatientDetailsById/" + meetingId + "/" + username)

    }

    getPatientDetails1(meetingId: any) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "robotapi/getPatientDetails/" + meetingId)

    }

    getReportdetails(reportno: any, mobileno: any): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        console.log("url--" + constants.API_REST_URL + "api/v1/getAllVitalsDetails/" + mobileno)
        return this.http.get(constants.API_REST_URL + "api/v1/getAllVitalsDetails/" + mobileno)

    }
    savePrescription(data): Observable<any> {
        return this.http.post(constants.API_REST_URL + 'api/v1/editPrescription', data);
    }

    saveMeetingandGetId(personId: any, doctorId: any) {
        return this.http.get(constants.API_REST_URL + "api/v1/saveMeetingId/" + personId + "/" + doctorId)

    }

    getPrescriptionList(mobileno: any) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "api/v1/getUserProfileAndPrescriptions/" + mobileno)

    }

    getReportAttachment(recordno: any) {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
        return this.http.get(
            constants.API_REST_URL + "api/v1/getReportAttachmentByRecordNO/" + recordno, { headers, responseType: 'text' });
    }


    getReportsList(mobileno: any) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "api/v1/getVitalsDetailsReport/" + mobileno)

    }

    getECGdata(personId: any) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "api/v1/getUserECGResult/" + personId)
    }


    getPrescriptionDetails(prescriptionId: any) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "robotapi/getPrescription/" + prescriptionId)
    }

    endMeeting(meetingId: any, personId: any) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "api/v1/meetingEnd/" + meetingId + "/" + personId)
    }

    getPrescriptionPdfdata(meetingId: any, personId: any) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "api/v1/downloadPdf/" + meetingId + "/" + personId)
    }

    saveMeetingId(meetingDTO) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "api/v1/saveMeetingDetails/"
        return this.http.post(url, meetingDTO, { headers: headers, responseType: 'text', observe: 'response' })
    }

    updateAllergisandHistory(meetingDTO) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "api/v1/updateMedicalHistoryAndAllergies/"
        return this.http.post(url, meetingDTO, { headers: headers, responseType: 'text', observe: 'response' })
    }

    cancelAppointment(appointmentDTO) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "api/v1/cancelAppointment/"
        return this.http.post(url, appointmentDTO, { headers: headers, responseType: 'text', observe: 'response' })
    }

    rescheduleAppointment(appointmentDTO) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "api/v1/rescheduleAppointment/"
        return this.http.post(url, appointmentDTO, { headers: headers, responseType: 'text', observe: 'response' })
    }

    cancelAppointmentRequest(followupdeskDTO) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "api/v1/saveFollowups/"
        return this.http.post(url, followupdeskDTO, { headers: headers, responseType: 'text', observe: 'response' })
    }

    bookDoctorAppointment(AppointmentDTO) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "api/v1/bookAppointment/"
        return this.http.post(url, AppointmentDTO, { headers: headers, responseType: 'text', observe: 'response' })
    }
    editBlockedSlots(AppointmentDTO) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "api/v1/bookAppointment/"
        return this.http.post(url, AppointmentDTO, { headers: headers, responseType: 'text', observe: 'response' })
    }

    scheduleMeeting(obj: RequestMeeting) {
        return this.http.post(constants.API_REST_URL + "api/v1/create", obj, { responseType: "text" }).pipe(catchError(this.handleError));
    }

    getDoctorFreeSlots(docId, dateVal): Observable<any> {
        return this.http.get(constants.API_REST_URL + "api/v1/getDoctorFreeSlots/" + docId + "?date=" + dateVal)
    }

    getAllDoctors() {
        return this.http.get(constants.API_REST_URL + "api/v1/getAllDoctors/")
    }

    getAppointments(mobile) {
        let url = constants.API_REST_URL + "api/v1/getCustomerAppointments/" + mobile;
        return this.http.get(url);
    }

    uploadReports(reportsDTO: any) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        return this.http.post(constants.API_REST_URL + "api/v1/savePatientReportUpload/", reportsDTO, { headers: headers, responseType: 'text', observe: 'response' })
    }

    getAllCustomers() {
        return this.http.get(constants.API_REST_URL + "api/v1/getCustomerDetails/")
    }

    handleError(error) {
        return throwError(error.message || "Server Error")
    }

    acceptAppointment(appointmentDTO) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "api/v1/acceptAppointment/"
        return this.http.post(url, appointmentDTO, { headers: headers, responseType: 'text', observe: 'response' })
    }

    editPrescription(prescriptionForm) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken()).set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "robotapi/editPrescription"
        return this.http.post(url, prescriptionForm, { headers: headers, responseType: 'text', observe: 'response' })
        // .map(response => response.json)
        // return this.http.post<any>(url, params);
    }

    registration(regDTO: any) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "api/v1/saveRegistration/"
        return this.http.post(url, regDTO, { headers: headers, responseType: 'text', observe: 'response' })
    }

    updateUserDetails(regDTO: any) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "api/v1/updateUser/"
        return this.http.post(url, regDTO, { headers: headers, responseType: 'text', observe: 'response' })
    }

    updateUserSlotDetails(dataDTO: any, id: any, date: any) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "api/v1/updateAvailableSlotsDay/" + id + "?date=" + date
        return this.http.post(url, dataDTO, { headers: headers, responseType: 'text', observe: 'response' })
    }

    getUserDetails(username: any) {
        let url = constants.API_REST_URL + "api/v1/getRegistration/" + username;
        return this.http.get(url)
    }

    getDoctorAvailableSlotDetails(id: any, date: any) {
        let url = constants.API_REST_URL + "api/v1/getAvailableDocTimeSlots/" + id + "?date=" + date;
        return this.http.get(url)
    }

    updateCheckInCheckOut(checkin: any) {
        var user = JSON.parse(localStorage.getItem('currentUser'));
        let url = constants.API_REST_URL + "api/v1/updateCheckInCheckOut/" + checkin + "/" + user['username'];
        return this.http.get(url)
    }

    getBloodBanks(latitude: any, longitude: any) {
        // let latitude = 17.4288723
        // let longitude =78.3750101
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "app/getNearByBBs/" + latitude + "/" + longitude, { headers: headers });
    }
    nearbyAmbulances(latitude: any, longitude: any) {
        // let latitude = 17.4288723
        // let longitude =78.3750101
        console.log("token At nearBy Ambulances", this.jwtToken.getToken())
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "app/nearbyAmbulances/" + latitude + "/" + longitude, { headers: headers });

    }
    top3NearByAmbulances(latitude: any, longitude: any) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "app/getTop3NearByAmblances/" + latitude + "/" + longitude, { headers: headers });
    }
    loadEmergencies() {
        console.log(this.jwtToken.getToken())
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "app/userIncidents/", { headers: headers });
    }
    createIncident(incidentcreationForm, token) {
        let headers = new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json;');
        let url = constants.EMRI_URL + "app/createIncident"
        return this.http.post(url, incidentcreationForm, { headers: headers, responseType: 'text', observe: 'response' })
        // .map(response => response.json)
        // return this.http.post<any>(url, params);
    }


    saveBeneficiaryVitals(incidentId, vitalsdata, token) {
        let headers = new HttpHeaders().set('Authorization', token)
        return this.http.get(constants.EMRI_URL + "pilot/saveBeneficiaryVitals/" + incidentId + "/" + vitalsdata, { headers: headers });
    }
    getFeedbackQuestions() {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "app/getFeedBackQuestions", { headers: headers });
    }
    getAllEmergencies(token) {
        let headers = new HttpHeaders().set('Authorization', token)
        return this.http.get(constants.EMRI_URL + "app/getAllEmergencies", { headers: headers });
    }
    getMedicalComplaints() {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "api/questions/get/getAllComplaints", { headers: headers });
    }
    getChiefComplaints(token) {
        let headers = new HttpHeaders().set('Authorization', token)
        return this.http.get(constants.EMRI_URL + "api/questions/getAllCheifComplaints", { headers: headers });
    }

    getSubChiefComplaints(id: any) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "api/questions/getComplaintsByCheifComplaintId?cheifComplaintId=" + id, { headers: headers });
    }

    saveFeedback(feedbackVO) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        let url = constants.API_REST_URL + "app/saveFeedBack";
        return this.http.post(url, feedbackVO, { headers: headers });

    }
    upload(uploadsVO, token) {
        let headers = new HttpHeaders().set('Authorization', token)
        let url = constants.EMRI_URL + "app/upload";
        return this.http.post(url, uploadsVO, { headers: headers });
    }

    getDirections(sLat, sLng, dLat, dLng) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        let url = constants.API_REST_URL + "app/getDirections/" + sLat + "/" + sLng + "/" + dLat + "/" + dLng;
        return this.http.get(url, { headers: headers });
    }
    getAmbulanceLocation(ambulanceId) {
        // let url=constants.API_REST_URL + "app/ambulanceLocation/"+ambulanceId;
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "app/ambulanceLocation/" + ambulanceId, { headers: headers });
    }
    dummyHospitals() {
        let latitude = 17.4288723
        let longitude = 78.3750101
        let category = "Government"
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "app/getNearByHospitals/" + latitude + "/" + longitude + "/" + category, { headers: headers });
    }
    getIncidentDetails(id: any) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "api/loadIncident/" + id, { headers: headers });
    }
    getCaseHistoryByContactNumber(contactNumber: any, token) {
        let headers = new HttpHeaders().set('Authorization', token)
        return this.http.get(constants.EMRI_URL + "pilot/getAppCaseHistoryByContactNumber?contactNumber=" + contactNumber, { headers: headers });
    }
    authenticateLogin(loginCredentials: any) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "app/authenticate"
        return this.http.post(url, loginCredentials, { headers: headers, responseType: 'text', observe: 'response' })
    }
    refreshToken(token) {
        let headers = new HttpHeaders().set('Authorization', 'application/json;')
        return this.http.get(constants.API_REST_URL + "api/isTokenValid/" + token, { headers: headers });
    }

    updateStatus(status: any) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "api/v1/updateStatus/" + status;
        return this.http.post(url, status, { headers: headers, responseType: 'text', observe: 'response' })
    }

    sendNotificationBackToServer(notification: any) {
        // let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "api/v1/saveMeetingDetails/";
        return this.http.post(url, notification)
    }

    sendSMStoUser(message, mobileNumber) {
        let url = constants.API_REST_URL + "api/v1/sendSMS/" + message + "/" + mobileNumber;
        return this.http.get(url);
    }

    vitalRecordsByRecordNo(recordNo) {
        let url = constants.API_REST_URL + "api/v1/getAllVitalsDetailsByRecordNo/" + recordNo;
        return this.http.get(url);
    }

    getDrugNames(param: any) {
        var uu = "https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?terms=" + param + "&ef=STRENGTHS_AND_FORMS";
        return this.http.get(uu);

    }

    getDocterConsultations(username: any) {
        let url = constants.API_REST_URL + "api/v1/getConsultedPatientDetails/" + username;
        return this.http.get(url);
    }

    getConsultationdetails(personId: any, prescriptionId: any, meetingId: any) {
        let url = constants.API_REST_URL + "api/v1/getPrescriptionVitalsDetails/" + personId + "/" + prescriptionId + "/" + meetingId;
        return this.http.get(url);
    }

    getPatientConsultations(doctorId: any) {
        let url = constants.API_REST_URL + "api/v1/getConsultedPatients/" + doctorId;
        return this.http.get(url);
    }

    getDoctorAppointments(doctorId: any): Observable<any> {
        let url = constants.API_REST_URL + "api/v1/getDoctorAppointments/" + doctorId;
        return this.http.get(url);
    }

    getDoctorAppointmentsHistory(doctorId: any): Observable<any> {
        let url = constants.API_REST_URL + "api/v1/getDoctorAppointmentsHistory/" + doctorId;
        return this.http.get(url);
    }
    getDoctorBlockSlots(doctorId) {
        let url = constants.API_REST_URL + "api/v1/getMyBlockedRequests/" + doctorId;
        return this.http.get(url);
    }
    getDoctorBlockSlotsPromise(doctorId) {
        let url = constants.API_REST_URL + "api/v1/getMyBlockedRequests/" + doctorId;
        return this.http.get(url).toPromise();
    }
    // getDoctorAvailabiity(doctorId: any): Observable<any> {
    //     let url = constants.API_REST_URL + "api/v1/getDoctorAvailabiltyForSlots/" + doctorId;
    //     return this.http.get(url);
    // }

    saveDoctorAvailabiity(data: any): Observable<any> {
        return this.http.post(constants.API_REST_URL + 'api/v1/saveDoctorAvailabiity', data);
    }

    getTodayDoctorAppointments(doctorId: any): Observable<any> {
        let url = constants.API_REST_URL + "api/v1/getTodayDoctorAppointments/" + doctorId;
        return this.http.get(url);
    }

    getMyAppointmentRequests(doctorId: any): Observable<any> {
        let url = constants.API_REST_URL + "api/v1/getMyAppointmentRequests/" + doctorId;
        return this.http.get(url);
    }

    deleteAppointment(doctorId: any) {
        let url = constants.API_REST_URL + "api/v1/deleteAppointment/" + doctorId;
        return this.http.get(url);
    }

    validateMobile(mobile: any) {
        return this.http.get(constants.API_REST_URL + "api/v1/getMemberIdByPhone/mobile?mobile=" + mobile)
    }

    getAllQuestions(personId: string) {
        return this.http.get(constants.API_REST_URL + "api/v1/getQuestions/" + personId)
    }

    getPrescriptionHistory(id): Observable<any> {
        return this.http.get(constants.API_REST_URL + "api/v1/getUserPrescriptions/" + id);
    }

    publishPrescription(data): Observable<any> {
        return this.http.post(constants.API_REST_URL + 'api/v1/publishPrescription', data);
    }

    getPatientVitals(mobile) {
        let url = constants.API_REST_URL + "api/v1/getAllVitalsReport/" + mobile;
        return this.http.get(url);
    }

    getAllVitals(mobile) {
        let url = constants.API_REST_URL + "api/v1/getAllVitalsDetails/" + mobile;
        return this.http.get(url);
    }

    getPatientPrescriptions(mobile) {
        let url = constants.API_REST_URL + "api/v1/getUserProfileAndPrescriptions/" + mobile;
        return this.http.get(url);
    }

    getDoctorsList() {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "api/v1/getAvailableDoctors")
    }

    getPrescriptionpdfDetails(meetingId: any, personId: any) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "robotapi/getPrescription/" + meetingId + "/" + personId)

    }

    getReportDetails(reportNo: any) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "api/v1/getAllVitalsDetailsByRecordNo/" + reportNo)
    }

    sendSms(smsParametersDTO: any) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "api/v1/sendObjSMS/"
        return this.http.post(url, smsParametersDTO, { headers: headers, responseType: 'text', observe: 'response' })
    }

    updatePrescriptionStatus(prescriptionId: string, status: boolean) {
        return this.http.get(constants.API_REST_URL + "api/v1/updatePrescriptionStatus/" + prescriptionId + "/" + status)
    }

    getConditions(param: any) {
        var url = "https://clinicaltables.nlm.nih.gov/api/conditions/v3/search?terms=" + param;
        return this.http.get(url);
    }

    getGraphsJson() {
        var url = "https://canvasjs.com/data/docs/ethusd2018.json";
        return this.http.get(url);
    }

    // getCD10(param: any) {
    //     var url = "https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=name&df=name&terms=" + param;
    //   return  this.http.get(url);
    // }

    getCD10(param): Observable<any> {
        var url = "https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=name&df=name&terms=" + param;

        return this.http.get<any>(url)
            .pipe(

                map(response => response[3])
            );
    }

    getICD11(term: string) {
        if (term === '') {
            return of([]);
        }
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "api/v1/searchICD/" + term).pipe(
            map((response: any) => {
                let list = [];
                for (var ent of response.destinationEntities) {
                    list.push(ent.title)
                }
                return list;
            }));
    }

    getDrugsInteractions(rxcuis: any) {
        return this.http.get(constants.RXNAV_URL + rxcuis);
    }

    getDrugsList() {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "api/v1/getMedicineList")
    }

    getDrugsbyName(medicine: any) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "api/v1/getMedicine/" + medicine)
    }

    getDrugsbyGenericName(generic: any) {
        let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        return this.http.get(constants.API_REST_URL + "api/v1/getMedicinesByGeneric/" + generic)
    }

    suggestPrescription(reqBody: any) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "api/v1/suggestPrescription"
        return this.http.post(url, reqBody, { headers: headers, responseType: 'json', observe: 'body' })
    }

    suggestComlaints(reqBody: any) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = constants.API_REST_URL + "api/v1/suggestComlaints"
        return this.http.post(url, reqBody, { headers: headers, responseType: 'json', observe: 'body' })
    }

    suggestLifeStyleModifications(doctorId: any,suggestionselected:string) {

        let url = constants.API_REST_URL + "api/v1/getLifeStyleModificationSuggestions/" + doctorId+"/"+suggestionselected;
        return this.http.get(url)
    }

    getAllAreaOfPractice(): any[] {
        return ["Accupunture"
            , "Addiction Medicine"
            , "ADHD & Autism"
            , "Adolescent Medicine"
            , "Adult Cardiac Electrophysiology"
            , "Adult Echocardiography"
            , "Aerospace Medicine"
            , "Aesthetic Medicine"
            , "Allergology"
            , "Allergy and Immunology"
            , "Alternative Medicine"
            , "Anatomical Pathology"
            , "Anatomy"
            , "Andrology"
            , "Anesthesiology"
            , "Anesthesiology - Critical Care Medicine"
            , "Anesthesiology - Hospice and Palliative Medicine"
            , "Anesthesiology - Pain Medicine"
            , "Anesthesiology - Pediatric Anesthesiology"
            , "Anesthesiology - Sleep Medicine"
            , "Asthma Expert"
            , "Audiology"
            , "Audiovestibular Medicine"
            , "Aviation Medicine"
            , "Ayurvedic Medicine"
            , "Bariatrics"
            , "Bio-Chemistry"
            , "Bio-Physics"
            , "Breast Surgery"
            , "Cardiology"
            , "Cardiology - Cardiac Electrophysiology"
            , "Cardiovascular And Pulmonary Physiotherapy"
            , "Cardiovascular medicine"
            , "Child and Adolescent Psychiatry and Psychotherapy"
            , "Clinical Biochemical Genetics"
            , "Clinical Cytogenetics"
            , "Clinical Genetics"
            , "Clinical Haematology"
            , "Clinical Informatics"
            , "Clinical Molecular Genetics"
            , "Clinical Neurophysiology"
            , "Clinical Pharmacology"
            , "Clinical pharmacology and Therapeutics"
            , "Clinical Psychology"
            , "Clinician Educator"
            , "Clinician Investigator Program"
            , "Colon and Rectal Surgery"
            , "Community Child Health"
            , "Community Medicine"
            , "Cosmetology"
            , "Critical Care"
            , "Cytopathology"
            , "Dentistry"
            , "Dentistry - Community Dentistry"
            , "Dentistry - Conservative Dentistry"
            , "Dentistry - Cosmetic"
            , "Dentistry - Endodontics"
            , "Dentistry - Oral Medicine"
            , "Dentistry - Oral Pathology"
            , "Dentistry - Oral Surgery"
            , "Dentistry - Orthodontics"
            , "Dentistry - Pediatric"
            , "Dentistry - Pedodontics"
            , "Dentistry - Periodontics"
            , "Dentistry - Prosthodontics"
            , "Dentofacial Orthopedic"
            , "Dermatology"
            , "Dermatology & Venerology"
            , "Dermatology - Dermatopathology"
            , "Dermatology - Pediatric Dermatology"
            , "Dermatology, Venerology & Leprosy"
            , "Dermatopathology"
            , "Dermatosurgeon"
            , "Developmental Pediatrics"
            , "Diabetology"
            , "Diagnostic Radiology"
            , "Diet & Nutrition"
            , "Embroyology"
            , "Emergency Medicine"
            , "Emergency Medicine - Emergency Medical Services2"
            , "Emergency Medicine - Hospice and Palliative Medicine"
            , "Emergency Medicine - Internal Medicine-Critical Care Medicine"
            , "Emergency Medicine - Medical Toxicology"
            , "Emergency Medicine - Pediatrics"
            , "Emergency Medicine - Sports Medicine"
            , "Emergency Medicine - Undersea and Hyperbaric Medicine"
            , "Endocrinology"
            , "Endocrinology and Diabetes Mellitus"
            , "Endocrinology and Metabolism"
            , "ENT"
            , "ENT - Head & Neck Surgery"
            , "ENT - Head & Neck Surgery - Pediatric"
            , "Environmental Health"
            , "Family Medicine"
            , "Family Medicine - Adolescent Medicine"
            , "Family Medicine - Geriatric Medicine"
            , "Family Medicine - Hospice and Palliative Medicine"
            , "Family Medicine - Sleep Medicine"
            , "Family Medicine - Sports Medicine"
            , "Fertility Medicine"
            , "Forensic Medicine"
            , "Gastroenterology"
            , "General Physician"
            , "General Practice"
            , "Genito-urinary Medicine"
            , "Geriatrics"
            , "GI Surgery"
            , "Gynecologic Reproductive Endocrinology and Infertility"
            , "Gynecology"
            , "Gynecology - Oncology"
            , "Hair Restoration"
            , "Head Pain"
            , "Health Administration"
            , "Hearing & Speech"
            , "Heart Failure/Transplant"
            , "HIV Medicine"
            , "Holistic Medicine"
            , "HomeoPathy"
            , "Hospital Administration"
            , "Hypnotherapy"
            , "Implantology"
            , "Infertility"
            , "Intensive Care Medicine"
            , "Internal Medicine"
            , "Internal Medicine - Adolescent Medicine"
            , "Internal Medicine - Adult Congenital Heart Disease3"
            , "Internal Medicine - Advanced Heart Failure and Transplant"
            , "Internal Medicine - Allergy"
            , "Internal Medicine - Allergy & Immunology"
            , "Internal Medicine - Angiology"
            , "Internal Medicine - Cardiology"
            , "Internal Medicine - Cardiovascular Disease"
            , "Internal Medicine - Clinical Cardiac Electrophysiology"
            , "Internal Medicine - Critical Care Medicine"
            , "Internal Medicine - Diabetology"
            , "Internal Medicine - Endocrinology, Diabetes and Metabolism"
            , "Internal Medicine - Gastroenterology"
            , "Internal Medicine - Geriatric Medicine"
            , "Internal Medicine - Hematology"
            , "Internal Medicine - Hematology & Oncology"
            , "Internal Medicine - Hepatology"
            , "Internal Medicine - Hospice and Palliative Medicine"
            , "Internal Medicine - Immunology"
            , "Internal Medicine - Infectious Disease"
            , "Internal Medicine - Interventional Cardiology"
            , "Internal Medicine - Medical Oncology"
            , "Internal Medicine - Nephrology"
            , "Internal Medicine - Nephrology & Dialysis"
            , "Internal Medicine - Obstretic Medicine"
            , "Internal Medicine - Pulmonary Disease"
            , "Internal Medicine - Pulmonology"
            , "Internal Medicine - Rheumatology"
            , "Internal Medicine - Sleep Medicine"
            , "Internal Medicine - Sports Medicine"
            , "Internal Medicine - Transplant Hepatology"
            , "Interventional Cardiology"
            , "Interventional Radiology and Diagnostic Radiology"
            , "IVF Specialist"
            , "Lab Medicine"
            , "Laparoscopy"
            , "Laproscopic Surgery"
            , "Laryngology"
            , "Laser & Skin"
            , "Marital Therapy"
            , "Maternal-Fetal Medicine"
            , "Medical Administration"
            , "Medical Biochemical Genetics"
            , "Medical Biochemistry"
            , "Medical Gastroenterology"
            , "Medical Genetics"
            , "Medical Microbiology"
            , "Medical Oncology"
            , "Medical Physics"
            , "Medical Toxicology"
            , "Microbiology"
            , "Molecular Genetic Pathology"
            , "Natural Cure"
            , "Naturopathy"
            , "Neonatal-Perinatal Medicine"
            , "Neonatology"
            , "Nephrology"
            , "Neuro Rehablitation"
            , "Neuro-Psychiatry"
            , "Neuro-Psychology"
            , "Neurological Surgery"
            , "Neurology"
            , "Neurology - Brain Injury Medicine"
            , "Neurology - Child Neurology"
            , "Neurology - Clinical Neurophysiology"
            , "Neurology - Epilepsy"
            , "Neurology - Hospice and Palliative Medicine"
            , "Neurology - Neurodevelopmental Disabilities"
            , "Neurology - Neuromuscular Medicine"
            , "Neurology - Pain Medicine"
            , "Neurology - Radiology"
            , "Neurology - Sleep Medicine"
            , "Neurology - Vascular Neurology"
            , "Neuropathology"
            , "Nuclear Medicine"
            , "Nuclear Radiology"
            , "Nutrition"
            , "Nutritionist - Sports"
            , "Nutritionist - Sports Medicine"
            , "Obesity & Weight Management"
            , "Obstetrics and Gynecology"
            , "Obstetrics and Gynecology - Critical Care Medicine"
            , "Obstetrics and Gynecology - Female Pelvic Medicine and"
            , "Obstetrics and Gynecology - Gynecologic Oncology"
            , "Obstetrics and Gynecology - Hospice and Palliative Medicine"
            , "Obstetrics and Gynecology - Maternal and Fetal Medicine"
            , "Obstetrics and Gynecology - Obstetrics and Gynaecological Ultrasound"
            , "Obstetrics and Gynecology - Reconstructive Surgery"
            , "Obstetrics and Gynecology - Reproductive Endocrinology/Infertility"
            , "Obstetrics and Gynecology - Urogynecology"
            , "Occupational and Environmental Medicine"
            , "Occupational Medicine"
            , "Occupational Therapist"
            , "Oncology"
            , "Ophthalmology"
            , "Ophthalmology - LASIK Surgery"
            , "Ophthalmology - Pediatric"
            , "Ophthalmology - Retinal Surgery"
            , "Opticians"
            , "Optometry"
            , "Oral and Maxillofacial Surgery"
            , "Oral Medicine and Radiology1"
            , "Orthopaedic"
            , "Orthopaedic Surgery"
            , "Orthopaedic Surgery - Foot & Ankle"
            , "Orthopaedic Surgery - Orthopaedic Sports Medicine"
            , "Orthopaedic Surgery - Pediatric"
            , "Orthopaedic Surgery - Reconstruction"
            , "Orthopaedic Surgery - Spine"
            , "Orthopaedic Surgery - Surgery of the Hand"
            , "Otolaryngology"
            , "Otolaryngology - Neurotology"
            , "Otolaryngology - Pediatric Otolaryngology"
            , "Otolaryngology - Plastic Surgery Within the Head and Neck"
            , "Otolaryngology - Sleep Medicine"
            , "Otorhinolaryngology"
            , "Pain Management"
            , "Pain Medicine"
            , "Palliative Care"
            , "PathoClinical Informatics"
            , "Pathology"
            , "Pathology - Anatomic"
            , "Pathology - Anatomic/Pathology-Clinical"
            , "Pathology - Blood Banking/Transfusion Medicine"
            , "Pathology - Chemical"
            , "Pathology - Clinical Cytogenetics & Molecular Genetics"
            , "Pathology - Clinical Informatics"
            , "Pathology - Forensic"
            , "Pathology - Hematology"
            , "Pathology - Histopathology"
            , "Pathology - Immunology"
            , "Pathology - Medical Microbiology"
            , "Pathology - Medical Microbiology and Virology"
            , "Pathology - Microbiology"
            , "Pathology - Molecular Genetic"
            , "Pathology - Pediatric"
            , "Pediatric Radiology"
            , "Pediatric Urology"
            , "Pediatrics"
            , "Pediatrics - Adolescent Medicine"
            , "Pediatrics - Allergy"
            , "Pediatrics - Allergy & Asthma"
            , "Pediatrics - Cardiology"
            , "Pediatrics - Child Abuse Pediatrics"
            , "Pediatrics - Clinical Genetics"
            , "Pediatrics - Clinical Pharmacology"
            , "Pediatrics - Community Child Health"
            , "Pediatrics - Critical Care Medicine"
            , "Pediatrics - Developmental-Behavioral Pediatrics"
            , "Pediatrics - Emergency Medicine"
            , "Pediatrics - Endocrinology"
            , "Pediatrics - Gastroenterology"
            , "Pediatrics - Gastroenterology and Hepatology"
            , "Pediatrics - General Medicine"
            , "Pediatrics - General Paediatrics"
            , "Pediatrics - Geriatric Medicine"
            , "Pediatrics - Haematology"
            , "Pediatrics - Hematology-Oncology"
            , "Pediatrics - Hospice and Palliative Medicine"
            , "Pediatrics - Immunology and Allergy"
            , "Pediatrics - Infectious Diseases"
            , "Pediatrics - Intensive Care Medicine"
            , "Pediatrics - Medical Oncology"
            , "Pediatrics - Medical Toxicology"
            , "Pediatrics - Neonatal-Perinatal Medicine"
            , "Pediatrics - Nephrology"
            , "Pediatrics - Nephrology & Dialysis"
            , "Pediatrics - Neurodevelopmental Disabilities"
            , "Pediatrics - Neurology"
            , "Pediatrics - Nuclear Medicine"
            , "Pediatrics - Palliative Medicine"
            , "Pediatrics - Pulmonology"
            , "Pediatrics - Respiratory and Sleep Medicine"
            , "Pediatrics - Rheumatology"
            , "Pediatrics - Sleep Medicine"
            , "Pediatrics - Sports Medicine"
            , "Pediatrics - Transplant Hepatology"
            , "Pharmaceutical Medicine"
            , "Pharmacology"
            , "Phlebology"
            , "Physical Medicine and Rehabilitation"
            , "Physical Medicine and Rehabilitation - Brain Injury Medicine"
            , "Physical Medicine and Rehabilitation - Hospice and Palliative Medicine"
            , "Physical Medicine and Rehabilitation - Neuromuscular Medicine"
            , "Physical Medicine and Rehabilitation - Pain Medicine"
            , "Physical Medicine and Rehabilitation - Pediatric Rehabilitation Medicine"
            , "Physical Medicine and Rehabilitation - Spinal Cord Injury Medicine"
            , "Physical Medicine and Rehabilitation - Sports Medicine"
            , "Physician Assistant"
            , "Physiology"
            , "Physiotherapy"
            , "Physiotherapy - Geriatric"
            , "Physiotherapy - Pediatric"
            , "Physiotherapy - Sports and Musculoskeletal"
            , "Plastic Reconstructive and Aesthetic Surgery"
            , "Plastic Surgery"
            , "Plastic Surgery - Hand"
            , "Plastic Surgery - Head and Neck"
            , "Podiatry"
            , "Preventive medicine"
            , "Proctology"
            , "Psychiatry"
            , "Psychiatry - Addiction"
            , "Psychiatry - Brain Injury Medicine"
            , "Psychiatry - Child and Adolescent"
            , "Psychiatry - Clinical Neurophysiology"
            , "Psychiatry - Forensic"
            , "Psychiatry - General"
            , "Psychiatry - Geriatric"
            , "Psychiatry - Hospice and Palliative Medicine"
            , "Psychiatry - Learning disability"
            , "Psychiatry - Old age psychiatry"
            , "Psychiatry - Pain Medicine"
            , "Psychiatry - Psychosomatic Medicine"
            , "Psychiatry - Psychotherapy"
            , "Psychiatry - Sleep Medicine"
            , "Psychology"
            , "Public Health and General Preventive Medicine"
            , "Public Health Medicine"
            , "Pulmonary Medicine"
            , "Pulmonology"
            , "Radio Diagnosis"
            , "Radiology"
            , "Radiology - Diagnostic Radiology"
            , "Radiology - Diagnostic Ultrasound"
            , "Radiology - Medical Ultrasonography"
            , "Radiology - Neuroradiology"
            , "Radiology - Nuclear Medicine"
            , "Radiology - Radiation Oncology"
            , "Radiotherapy"
            , "Rehabilitation Medicine"
            , "Renal Medicine"
            , "Respiratory medicine"
            , "Respirology"
            , "Rheumatology"
            , "Rhinology"
            , "Sexology"
            , "Sexual Health Medicine"
            , "Siddha"
            , "Social & Preventive Medicine"
            , "Spa Therapy"
            , "Spine Pain Management"
            , "Sport & Exercise Medicine"
            , "Sugery - Botox"
            , "Surgery"
            , "Surgery - Cardiac"
            , "Surgery - Colon & Rectal"
            , "Surgery - Coloproctology"
            , "Surgery - Congenital Cardiac"
            , "Surgery - Coronary"
            , "Surgery - Critical Care"
            , "Surgery - Ear"
            , "Surgery - Endocrine"
            , "Surgery - ENT"
            , "Surgery - Gastro-Intestinal"
            , "Surgery - GastroIntestinal"
            , "Surgery - Hand"
            , "Surgery - Head & Neck"
            , "Surgery - Hepato-Pancreatico-Biliary"
            , "Surgery - Hospice and Palliative Medicine"
            , "Surgery - Neonatal"
            , "Surgery - Nose"
            , "Surgery - Oncology"
            , "Surgery - Oral & Maxillofacial"
            , "Surgery - Pediatric"
            , "Surgery - Plastics"
            , "Surgery - Thoracic"
            , "Surgery - Thoracic and Cardiac"
            , "Surgery - Transplant"
            , "Surgery - Trauma"
            , "Surgery - Urology"
            , "Surgery - Vascular"
            , "Surgical Foundations"
            , "Surgical Gasteroenterology"
            , "Toxicology"
            , "Transfusion Medicine"
            , "Travel Medicine"
            , "Trichology"
            , "Tuberclosis & Respiratory Diseases"
            , "Unani"
            , "Undersea and Hyperbaric Medicine"
            , "Urgent Care"
            , "Urology"
            , "Urology - Female Pelvic Medicine and"
            , "Urology - Oncology"
            , "Urology - Pediatric Urology"
            , "Urology - Reconstructive Surgery1"
            , "Vascular and Interventional Radiology"
            , "Venerology"
            , "Veterinary"
            , "Wellness"
            , "Wilderness Medicine"
            , "Yoga and Naturopathy"]
    }

    getCountries(): string[] {
        return ["Afghanistan"
            , "Albania"
            , "Algeria"
            , "American Samoa"
            , "Andorra"
            , "Angola"
            , "Anguilla"
            , "Antarctica"
            , "Antigua and Barbuda"
            , "Argentina"
            , "Armenia"
            , "Aruba"
            , "Australia"
            , "Austria"
            , "Azerbaijan"
            , "Bahamas"
            , "Bahrain"
            , "Bangladesh"
            , "Barbados"
            , "Belgium"
            , "Belize"
            , "Benin"
            , "Bermuda"
            , "Bhutan"
            , "Bolivia"
            , "Bosnia and Herzegovina"
            , "Botswana"
            , "Brazil"
            , "British Indian Ocean Territory"
            , "British Virgin Islands"
            , "Brunei"
            , "Bulgaria"
            , "Burkina Faso"
            , "Burundi"
            , "Cambodia"
            , "Cameroon"
            , "Canada"
            , "Cape Verde"
            , "Cayman Islands"
            , "Central African Republic"
            , "Chad"
            , "Chile"
            , "China"
            , "Christmas Island"
            , "Cocos (Keeling) Islands"
            , "Colombia"
            , "Comoros"
            , "Cook Islands"
            , "Costa Rica"
            , "Croatia"
            , "Cuba"
            , "Cyprus"
            , "Czech Republic"
            , "Denmark"
            , "Djibouti"
            , "Dominica"
            , "Dominican Republic"
            , "Ecuador"
            , "Egypt"
            , "El Salvador"
            , "Equatorial Guinea"
            , "Eritrea"
            , "Estonia"
            , "Ethiopia"
            , "Falkland Islands"
            , "Faroe Islands"
            , "Fiji"
            , "Finland"
            , "France"
            , "French Polynesia"
            , "Gabon"
            , "Gambia"
            , "Gaza Strip"
            , "Georgia"
            , "Germany"
            , "Ghana"
            , "Gibraltar"
            , "Greece"
            , "Greenland"
            , "Grenada"
            , "Guam"
            , "Guatemala"
            , "Guinea"
            , "Guinea-Bissau"
            , "Guyana"
            , "Haiti"
            , "Holy See (Vatican City)"
            , "Honduras"
            , "Hong Kong"
            , "Hungary"
            , "Iceland"
            , "India"
            , "Indonesia"
            , "Ireland"
            , "Isle of Man"
            , "Israel"
            , "Italy"
            , "Ivory Coast"
            , "Jamaica"
            , "Japan"
            , "Jersey"
            , "Jordan"
            , "Kazakhstan"
            , "Kenya"
            , "Kiribati"
            , "Kosovo"
            , "Kuwait"
            , "Kyrgyzstan"
            , "Laos"
            , "Latvia"
            , "Lebanon"
            , "Lesotho"
            , "Libya"
            , "Liechtenstein"
            , "Lithuania"
            , "Luxembourg"
            , "Macau"
            , "Macedonia"
            , "Madagascar"
            , "Malawi"
            , "Malaysia"
            , "Maldives"
            , "Mali"
            , "Malta"
            , "Marshall Islands"
            , "Mauritania"
            , "Mauritius"
            , "Mayotte"
            , "Mexico"
            , "Micronesia"
            , "Moldova"
            , "Monaco"
            , "Mongolia"
            , "Montenegro"
            , "Montserrat"
            , "Morocco"
            , "Mozambique"
            , "Namibia"
            , "Nauru"
            , "Nepal"
            , "Netherlands"
            , "Netherlands Antilles"
            , "New Caledonia"
            , "New Zealand"
            , "Nicaragua"
            , "Niger"
            , "Nigeria"
            , "Niue"
            , "Norfolk Island"
            , "Northern Mariana Islands"
            , "Norway"
            , "Oman"
            , "Pakistan"
            , "Palau"
            , "Panama"
            , "Papua New Guinea"
            , "Paraguay"
            , "Peru"
            , "Philippines"
            , "Pitcairn Islands"
            , "Poland"
            , "Portugal"
            , "Puerto Rico"
            , "Qatar"
            , "Romania"
            , "Russia"
            , "Rwanda"
            , "Saint Barthelemy"
            , "Saint Helena"
            , "Saint Kitts and Nevis"
            , "Saint Lucia"
            , "Saint Martin"
            , "Saint Pierre and Miquelon"
            , "Saint Vincent and the Grenadines"
            , "Samoa"
            , "San Marino"
            , "Sao Tome and Principe"
            , "Saudi Arabia"
            , "Senegal"
            , "Serbia"
            , "Seychelles"
            , "Singapore"
            , "Slovakia"
            , "Slovenia"
            , "Solomon Islands"
            , "Somalia"
            , "South Africa"
            , "South Korea"
            , "Spain"
            , "Sri Lanka"
            , "Suriname"
            , "Svalbard"
            , "Swaziland"
            , "Sweden"
            , "Switzerland"
            , "Taiwan"
            , "Tajikistan"
            , "Tanzania"
            , "Thailand"
            , "Timor-Leste"
            , "Togo"
            , "Tokelau"
            , "Tonga"
            , "Trinidad and Tobago"
            , "Tunisia"
            , "Turkey"
            , "Turkmenistan"
            , "Turks and Caicos Islands"
            , "Tuvalu"
            , "Uganda"
            , "Ukraine"
            , "United Arab Emirates"
            , "United Kingdom"
            , "United States"
            , "Uruguay"
            , "Uzbekistan"
            , "Virgin Islands"
            , "Vanuatu"
            , "Venezuela"
            , "Vietnam"
            , "Wallis and Futuna"
            , "West Bank"
            , "Western Sahara"
            , "Yemen"
            , "Zambia"];
    }


    getIssue(id) {
        let url = constants.CDSS_URL + "issues/" + id + "/info?token=" + constants.CDSS_Token;
        return this.http.get(url);
    }

    getSymptons() {
        let url = constants.CDSS_URL + "symptoms?token=" + constants.CDSS_Token;
        return this.http.get(url);
    }

    getDiagnosis(symptons, gender, age) {
        let url = constants.CDSS_URL + "diagnosis?symptoms=[" + symptons + "]&gender=" + gender + "&year_of_birth=" + age + "&token=" + constants.CDSS_Token;
        return this.http.get(url);
    }

    getIssues() {
        let url = constants.CDSS_URL + "issues?token=" + constants.CDSS_Token;
        return this.http.get(url);
    }
    // getWaitingTime(appointmentId) {
    //     let url = constants.API_REST_URL + "api/v1/getWaitingTime/" + appointmentId;
    //     return this.http.get(url)
    // }
}
