import { Component, Input, OnInit } from '@angular/core';
import { AgoraClient, ClientEvent, NgxAgoraService, Stream, StreamEvent } from 'ngx-agora';
import { AuthenticationService } from '../_services/authentication.service';
import { ApiService } from '../_services/api-service';
import { ConfirmationDialogService } from '../conform-dialog/confirmation-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { PrescriptionpdfComponent } from '../prescriptionpdf';
import { CallTransferDialogComponent } from '../calltransfer/calltransfer.component';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'agora',
  templateUrl: './agora.component.html',
  styleUrls: ['./agora.component.scss']
})
export class AgoraComponent implements OnInit {
  title = 'jojo_doctor_web';
  localCallId = 'agora_local';
  remoteCalls: string[] = [];

  private client: AgoraClient;
  private localStream: Stream;
  private uid: number;
  audioMute = false;
  videoMute = false;
  transfer = false;
  @Input() parentData: string = "jojopoc"

  channelName: string;
  record: boolean;
  recording: string = "Start Recording";

  constructor(private ngxAgoraService: NgxAgoraService, private userService: UserService,
    private authService: AuthenticationService, private matDailog: MatDialog, private apiService: ApiService, private confirmationDialogService: ConfirmationDialogService) {
    this.uid = Math.floor(Math.random() * 100);

    let notifications = JSON.parse(localStorage.getItem('notifications'));
    this.channelName = notifications != null ? notifications.agoraMeetingId : "";
    // this.localStream.muteVideo();
    console.log("agoraChannelName", this.channelName);
  }

  ngOnInit() {
    this.authService.componentAgoraCalled.subscribe(
      () => {

        this.leaveAndClearOnLogout();

      }
    );
    if (this.channelName != "") {
      // this.client = this.ngxAgoraService.createClient({ mode: 'rtc', codec: 'h264',areaCode: ['GLOBAL']});
      this.assignClientHandlers();

      this.localStream = this.ngxAgoraService.createStream({ streamID: this.uid, audio: true, video: true, screen: false });
      this.localStream.setVideoProfile('720p_2');
      this.assignLocalStreamHandlers();
      // Join and publish methods added in this step
      this.initLocalStream(() => this.join(uid => this.publish(), error => console.error(error)));
      this.ngxAgoraService.audioDevices
      this.setEffectVolume();
      this.getEffectsVolume()
      // stop stream
      // if(this.localStream.isPlaying()) {
      //   this.localStream.stop()
      // }
      console.log("agorachannelName", this.channelName);
    }
  }

  /**
   * Attempts to connect to an online chat room where users can host and receive A/V streams.
   */
  join(onSuccess?: (uid: number | string) => void, onFailure?: (error: Error) => void): void {
    this.client.join(null, this.channelName, this.uid, onSuccess, onFailure);
  }

  /**
   * Attempts to upload the created local A/V stream to a joined chat room.
   */
  publish(): void {
    this.client.publish(this.localStream, err => console.log('Publish local stream error: ' + err));
  }

  private assignClientHandlers(): void {
    this.client.on(ClientEvent.LocalStreamPublished, evt => {
      console.log('Publish local stream successfully');
    });

    this.client.on(ClientEvent.Error, error => {
      console.log('Got error msg:', error.reason);
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.client.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      }
    });

    this.client.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      this.client.subscribe(stream, { audio: true, video: true }, err => {
        console.log('Subscribe stream failed', err);
      });
    });

    this.client.on(ClientEvent.RemoteStreamSubscribed, evt => {
      const stream = evt.stream as Stream;
      const id = this.getRemoteId(stream);
      // if (!this.remoteCalls.length) {
      this.remoteCalls.push(id);
      setTimeout(() => stream.play(id), 1000);
      // }
      this.transfer = this.stringToBoolean(localStorage.getItem("transfer"));
      console.log("remotes--", this.remoteCalls.length + localStorage.getItem("transfer"));
      if (this.transfer && this.remoteCalls.length > 1) {
        this.localStream.stop();
        this.localStream.close();
        this.clearDataOnCallEnd();
      }
    });

    this.client.on(ClientEvent.RemoteStreamRemoved, evt => {
      const stream = evt.stream as Stream;
      if (stream && !this.videoMute) {
        stream.stop();

        //this.remoteCalls = [];
        //   stream.pauseAllEffects(function(err){
        //     if (err){
        //         console.error("Failed to pause effects, reason: ", err);
        //     }else{
        //         console.log("Effects are paused successfully");
        //     }
        // });
        console.log(`Remote stream is removed ${stream.getId()}`);
      }
    });

    this.client.on(ClientEvent.PeerLeave, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = this.remoteCalls.filter(call => call !== `${this.getRemoteId(stream)}`);
        console.log("remotes1--", this.remoteCalls.length);
        if (this.remoteCalls.length == 0)
          this.leave();
        console.log(`${evt.uid} left from this channel`);
      }
    });

    this.client.on(ClientEvent.RemoteAudioMuted, evt => {
      var peeruid = evt.uid;
      console.log("mute audio:" + peeruid);
    });

    this.client.on(ClientEvent.RemoteAudioUnmuted, evt => {
      var peeruid = evt.uid;
      console.log("unmute audio:" + peeruid);
    });

    this.client.on(ClientEvent.RemoveVideoMuted, evt => {
      var peeruid = evt.uid;
      console.log("mute video:" + peeruid);
    });

    this.client.on(ClientEvent.RemoteVideoUnmuted, evt => {
      var peeruid = evt.uid;
      console.log("unmute video:" + peeruid);
    });

  }

  private assignLocalStreamHandlers(): void {
    this.localStream.on(StreamEvent.MediaAccessAllowed, () => {
      console.log('accessAllowed');
    });

    // The user has denied access to the camera and mic.
    this.localStream.on(StreamEvent.MediaAccessDenied, () => {
      console.log('accessDenied');
    });
  }

  private initLocalStream(onSuccess?: () => any): void {
    this.localStream.init(
      () => {
        // The user has granted access to the camera and mic.
        this.localStream.play(this.localCallId);
        if (onSuccess) {
          onSuccess();
          this.unmuteVideo();
          this.unmuteAudio();
        }
      },
      err => console.error('getUserMedia failed', err)
    );
  }

  private getRemoteId(stream: Stream): string {
    return `agora_remote-${stream.getId()}`;
  }

  private stringToBoolean(stringValue: string): boolean | undefined {
    try {
      return JSON.parse(stringValue);
    }
    catch (e) {
      return undefined;
    }
  }

  startOrStop() {
    this.record = !this.record;
    if (this.record) {
      this.recording = "Stop Recording";
    }
    else {
      this.recording = "Start Recording";
    }
  }

  openConfirmationDialog() {
    if (localStorage.getItem("presstatus") == "nondraft") {
      this.confirmationDialogService.confirm('Please confirm..', 'Prescription is not created yet. If you wish to end call without prescription, fill up the reason below ?', "End Call")
        .then((res) => {
          console.log("res--" + JSON.stringify(res));
          if (res.res) {
            this.leave();
          } else {

          }
        })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }
    else if (localStorage.getItem("presstatus") == "draft") {
      this.confirmationDialogService.confirm('Please confirm..', 'Please publish the prescription.If you wish to end call without publish the prescription, fill up the reason below ?', "End Call")
        .then((res) => {
          console.log("res--" + JSON.stringify(res));
          if (res.res) {
            this.leave();
          } else {
            //this.review();
          }
        })
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }
    else
      this.leave();
  }

  review() {
    const dialogRef = this.matDailog.open(PrescriptionpdfComponent, {
      width: '90%',
      disableClose: true,
      hasBackdrop: false,
      data: { pageValue: status }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      localStorage.setItem("presstatus", "published");

    });

  }

  calltransfer() {
    const dialogRef = this.matDailog.open(CallTransferDialogComponent, {
      width: '70%',
      disableClose: true,
      hasBackdrop: true,
      data: { pageValue: "" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result != null && result.data != null) {
        var data = result.data;

        localStorage.setItem("transfer", "true");
        let notifications = JSON.parse(localStorage.getItem('notifications'));
        let userVitals = localStorage.getItem("userVitals");
        var meetingDTO = {
          "deviceId": "",
          "agoraMeetingId": this.channelName,
          "chiefCompliant": localStorage.getItem("chiefComplaint"),
          "personId": this.channelName.split("_")[1],
          "androidId": "",
          "id": notifications.meetingId,
          "callTransferMeetingId": notifications.callTransferMeetingId,
          "callTransferReason": data['reason'],
          "doctorId": data['doctor'],
          "vitalData": userVitals,
          "callTransferDoctor": JSON.parse(localStorage.getItem('currentUser')).username,
          "prescriptionId": localStorage.getItem("prescriptionId")
        };

        console.log("params--" + JSON.stringify(meetingDTO));
        this.apiService.saveMeetingId(meetingDTO).subscribe((resData: any) => {
          var data = JSON.parse(resData['body']);
          console.log("res--" + JSON.stringify(data));

        }, error => {
          var data = error;
        });

      }
    });

  }

  leave() {
    this.ngxAgoraService.client.leave(() => {
      console.log("Leavel channel successfully");
      this.clearDataOnCallEnd();
    }, (err) => {
      console.log("Leave channel failed");
    });

  }

  private unmuteAudio() {
    if (this.localStream.isAudioOn()) {
      this.localStream.muteAudio();
      this.audioMute = true;
    } else {
      this.localStream.unmuteAudio();
      this.audioMute = false;
    }
  }

  private unmuteVideo() {
    if (this.localStream.isVideoOn()) {
      this.localStream.muteVideo();
      this.videoMute = true;
    } else {
      this.localStream.unmuteVideo();
      this.videoMute = false;
    }
  }

  private setEffectVolume() {
    this.localStream.setEffectsVolume(100, function (err) {
      if (err) {
        console.error("Failed to set effects volume, reason: ", err);
      } else {
        console.log("Effects volume is set successfully");
      }
    });
  }

  private setVolumeOfEffect() {
    // When the audio effect 1 is loaded
    this.localStream.setVolumeOfEffect(1, 50, function (err) {
      if (err) {
        console.error("Failed to set volume of Effect, reason: ", err);
      } else {
        console.log("Effect volume is set to", 50);
      }
    });
  }

  private getEffectsVolume() {
    var volumes = this.localStream.getEffectsVolume();
    volumes.forEach(function ({ soundId, volume }) {
      console.log("SoundId", soundId, "Volume", volume);
    });
  }

  private pauseAudio() {
    this.localStream.pauseAllEffects(function (err) {
      if (err) {
        console.error("Failed to pause effects, reason: ", err);
      } else {
        console.log("Effects are paused successfully");
      }
    });
  }

  private clearDataOnCallEnd() {
    this.authService.updateStatus("available").subscribe((resData: any) => {
      console.log("res--" + JSON.stringify(resData));
      localStorage.removeItem("localPrescription")
      localStorage.removeItem('notifications')
      window.location.reload();
      // window.close();
      // this.userService.callWindowMethod();

      localStorage.setItem("transfer", "false");

    }, error => {
      var data = error;
    });
  }

  leaveAndClearOnLogout() {
    if (this.channelName != "") {
      this.ngxAgoraService.client.leave(() => {
        console.log("Leavel channel successfully");
        localStorage.removeItem("localPrescription")
        localStorage.removeItem('notifications')
        window.location.reload();
        localStorage.setItem("transfer", "false");
      }, (err) => {
        console.log("Leave channel failed");
      });
    }
    else {
      window.location.reload();
    }

  }
}
