// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl: 'http://localhost:4200/',
  apiUrl: 'https://devdoc.elro.ai/',

  agora: {
    appId: '28821ab80aaa476bba1c0b5098a46a47'
  },
  firebase: {
    apiKey: "AIzaSyBUgxwB0XFfUMXFFpUylH5iESOYNAJRn54",
    authDomain: "doctorapp-65796.firebaseapp.com",
    databaseURL: "https://doctorapp-65796.firebaseio.com",
    projectId: "doctorapp-65796",
    storageBucket: "doctorapp-65796.appspot.com",
    messagingSenderId: "760379808186",
    appId: "1:760379808186:web:95186b63a9484786cf6583",
    measurementId: "G-ZD3Q085XCC"
  },
  googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU',
  VIDEOSDK_API_KEY: 'f0be971a-bd36-43b4-b852-1bda5322bb70',
  //VIDEOSDK_API_KEY: 'f882a11c-219b-4ec1-b4c8-20208d417d53',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
