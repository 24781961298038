import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../_services/api-service';


declare var require: any;
const dayjs = require("dayjs");
@Component({
  templateUrl: './blockschedule.component.html',
  styleUrls: ['./blockschedule.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockScheduleComponent implements OnInit {
  submitted: boolean = false;
  blockForm: FormGroup;
  datetimerange:any;
  selectedDate: boolean = false;
  dateerror:boolean=true;
  doctorname: any;
  doctorId: any;
  isEdit:boolean;
  editId:any;
  cancellationReason:any;
  saved:boolean=false;
  startDate:Date=new Date();
  constructor( private formBuilder: FormBuilder,private spinner: NgxSpinnerService, private apiService: ApiService,
    private snackbar: MatSnackBar,
    private dialogRef: MatDialogRef<BlockScheduleComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
    this.isEdit=data['isEdit'];
    this.editId=data['editId'];
    this.startDate=data['start'];
    console.log(this.startDate);
  }

  ngOnInit(){
    this.doctorname = JSON.parse(localStorage.getItem('currentUser')).username;
    this.getDoctors();

    this.blockForm = this.formBuilder.group({
      reason: ["", [Validators.required]],
    })
    
  }

  bookSlot() {
    this.submitted = true;
    this.cancellationReason=this.blockForm.controls['reason'].value;
    this.scheduleAppointment(this.datetimerange);
  }

  goBack() {
    this.dialogRef.close();
  }

  daterangepickerOptions = {
    startDate: dayjs(),
    endDate: Date.now() ,
    format: "DD.MM.YYYY HH:mm",
    minDate: dayjs().subtract(1,"days")
      .format("DD.MM.YYYY HH:mm"),
    maxDate: dayjs()
      .add(120, "months")
      .format("DD.MM.YYYY HH:mm"),
      inactiveBeforeStart: true,
    placeholder:"Select Date and Time",
    autoApply: false,
    hideControls:false,
    showRanges: false,
    preDefinedRanges: [
      {
        name: "Today",
        value: {
          start: dayjs().add(5,"minutes"),
          end: dayjs().add(30,"minutes")
        }
      },
      {
        name: "Tomorrow",
        value: {
          start: dayjs().add(1, "days"),
          end: dayjs().add(1, "days").add(30,"minutes")
        }
      },
      {
        name: "This week",
        value: {
          start: dayjs().add(5,"minutes"),
          end: dayjs().add(7, "days")
        }
      }
    ],
    singleCalendar: false,
    displayFormat: "DD.MM.YYYY HH:mm",
    position: "left",
    disabled: false,
    noDefaultRangeSelected: true,
    timePicker: {
      minuteInterval: 15,
      twentyFourHourFormat: false
    },
    disableBeforeStart: true,
    alwaysOpen: true
  };

  rangeSelected(data) {
    const datepipe: DatePipe = new DatePipe('en-US')
    this.datetimerange=data;
    let startdate= new Date(data.start).getTime();
    let enddate= new Date(data.end).getTime();
    let currentdate = Date.now();
    if(startdate>currentdate && startdate<enddate)
    {
      this.selectedDate=true;
      this.dateerror=false;
      this.saved=false;
    }
    else
    {
      this.saved=true;
      this.selectedDate=false;
      this.dateerror=true;
    }
  }


  scheduleAppointment(meetingData: any) {
    console.log(this.doctorId);
    const datepipe: DatePipe = new DatePipe('en-US')
    let formattedDate = datepipe.transform(new Date(meetingData.end), 'short')
    let appointmentDTO = {
      "customerName": "Blocked till "+formattedDate,
      "doctorId": this.doctorId,
      "doctorName": this.doctorname,
      "topic": "Blocked till "+formattedDate,
      "startTime": datepipe.transform(new Date(meetingData.start), 'YYYY-MM-dd HH:mm:ss'),
      "endTime": datepipe.transform(new Date(meetingData.end), 'YYYY-MM-dd HH:mm:ss'),
      "status":"BLOCKED",
      "duration": "0",
      "cancellationReason": this.cancellationReason
    };
    this.spinner.show();
    if (!this.isEdit){
      this.apiService.bookDoctorAppointment(appointmentDTO).subscribe((data: any) => {
        this.spinner.hide();
        this.snackbar.open('Your schedule Blocked successfully', "", { duration: 2000 });
        this.dialogRef.close({ event: 'refresh' });
        
      }, error => {
        this.spinner.hide();
      });
    }
    else if(this.isEdit){
      appointmentDTO['id']=this.editId;
      this.apiService.editBlockedSlots(appointmentDTO).subscribe((data: any) => {
        this.spinner.hide();
        this.snackbar.open('Your schedule updated successfully', "", { duration: 2000 });
      }, error => {
        this.spinner.hide();
      });  
      this.dialogRef.close({ event: 'refresh' });
    }
  }
  doctorsList = [];
  getDoctors() {
    this.apiService.getAllDoctors().subscribe((data: any) => {
      this.doctorsList = data;
      console.log("list--", this.doctorsList);
      this.doctorsList.forEach(element => {
        if (element.username == this.doctorname)
          this.doctorId = element.id;
      });
    }, error => {
      console.log(error);
    });
  }
  
  get pdf() {
    return this.blockForm.controls;
  }

}
