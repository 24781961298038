import { Inject, Input, Optional } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validator, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../_services/api-service';
import { PrescriptionService } from '../_services/prescription.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { report } from 'process';
import { SinglePatientviewComponent } from '../single-patientview/single-patientview.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'reportsupload-dialog',
  templateUrl: './reportsupload.component.html',
  styleUrls: ['./reportsupload.component.scss'],
  providers: [ApiService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe]
})

export class ReportsUploadDialogComponent implements OnInit {
  personId: string;
  reportsForm: FormGroup;
  @Input() max: any;
  tomorrow = new Date();
  fileToUpload: File = null;
  report: string = "";
  mobileno: string;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<SinglePatientviewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private spinner: NgxSpinnerService, private snackbr: MatSnackBar,
    private prescriptionService: PrescriptionService, private apiService: ApiService, private datepipe: DatePipe,

  ) {
    this.personId = data.pageValue;
    this.mobileno = data['mobileno'];
    this.tomorrow.setDate(this.tomorrow.getDate() + 0);
  }

  ngOnInit() {
    this.reportsForm = this.formBuilder.group({
      reportName: ['', Validators.required],
      measureTime: ['', Validators.required],
    });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    if (this.fileToUpload != null) {
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      reader.onload = () => {
        console.log("signaturedata--" + reader.result);
        this.report = reader.result.toString();
      };
    }
    else
      this.report = "";
  }

  get pdf() {
    return this.reportsForm.controls;
  }


  onSubmit() {
    this.submitted = true;
    if (this.reportsForm.invalid) {
      return;
    }

    if (this.report == "") {
      this.snackbr.open("Please upload the report.", "", { duration: 2000 });
      return;
    }
    //debugger;
    this.reportsForm.value.measureTime = this.datepipe.transform(this.reportsForm.value.measureTime, "yyyy-MM-dd");
    this.reportsForm.value.mobileNumber = this.mobileno;
    console.log("mobile num 2:", this.reportsForm.value.mobileNumber)
    this.reportsForm.value.reportAttachment = this.report;
    this.spinner.show();
    this.apiService.uploadReports(this.reportsForm.value).subscribe((resData: any) => {
      //debugger
      console.log("result", resData);
      this.spinner.hide();
      this.snackbr.open('Report has been uploaded successfully.', "", { duration: 2000 });
      this.dialogRef.close({ event: 'refresh' });
    }, error => {
      this.spinner.hide();
      var data = error;
    });
  }

  onCancel() {
    this.dialogRef.close({ event: 'close' });
    console.log("timeafer", this.reportsForm.value.measureTime)
  }

  onCancelClick(data: any) {
    console.log("cancel" + data);
  }


}
