<ngx-spinner bdcolor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" style="color: white" type="ball-spin">Loading...</ngx-spinner>

<mat-card-content>
<div class="container">
    <form [formGroup]="scheduleForm">
        <div style="text-align: center;">
            <h4>{{title}} Appointment</h4>
        </div>

        <div class="form-group ">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Chief Complaint</mat-label>
                <input autocomplete="off" matInput [class.is-invalid]="pdf.topic.invalid && pdf.topic.touched" [ngClass]="{ 'is-invalid': submitted && pdf.topic.errors }" formControlName="topic">
                <div *ngIf="pdf.topic.errors && pdf.topic.invalid && pdf.topic.touched">
                    <small class="text-danger" *ngIf="pdf.topic.errors.required">Chief Complaint required</small>
                </div>
            </mat-form-field>

            <div class="form-control-position">
                <i class="feather ft-user"></i>
            </div>
        </div>

        <div class="form-group" *ngIf="patientDetected || customerData == null">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Patient</mat-label>
                <input matInput aria-label="Patient" [matAutocomplete]="auto" formControlName="patient" [ngClass]="{ 'is-invalid': submitted && pdf.patient.errors }">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="myChangeFunc($event.option.value)">
                    <mat-option *ngFor="let patient of filteredPatientsList | async" [value]="patient">
                        <span>{{patient.memberDTO.Name}} , {{patient.memberDTO.Age}} , {{patient.memberDTO.Sex}} , {{patient.memberDTO.Mobile}}</span>
                    </mat-option>
                </mat-autocomplete>
                
                <div *ngIf="pdf.patient.errors && pdf.patient.invalid && pdf.patient.touched">
                    <small class="text-danger" *ngIf="pdf.patient.errors.required">please select a patient from list</small>
                    <small class="text-danger" *ngIf="pdf.patient.errors.incorrect">please select a patient from list</small>
                </div>
            </mat-form-field>
        </div>
        <div class="row">
            <div class="form-group col-md-4">
                <div class="form-group ">
                    <mat-form-field  class="example-full-width" appearance="fill">
                        <mat-label>Date</mat-label>
                        <input matInput class="form-control" type="date" [class.is-invalid]="pdf.startDate.invalid && pdf.startDate.touched" class="form-control" [ngClass]="{ 'is-invalid': submitted && pdf.startDate.errors }" formControlName="startDate"
                        min="{{date | date:'yyyy-MM-dd'}}" (change)="startDateChange($event)" />
        
                        <div *ngIf="pdf.startDate.errors && pdf.startDate.invalid && pdf.startDate.touched">
                            <small class="text-danger" *ngIf="pdf.startDate.errors.required">Date is required</small>
                        </div>
                    </mat-form-field>
                            <!--
                <div class="form-group">
                    <label class=" form-control-label " for="date ">Date</label>
                    <div class="mobileDatePicker">
                        <div class="month ">
                            {{currentMonth | uppercase }}
                            <i class="fa fa-calendar-o monthIcon "></i>
                        </div>
                        <div class="cdk-virtual-scroll-data-source-example ">
                            <cdk-virtual-scroll-viewport orientation="horizontal " itemSize="80 " minBufferPx="400 " maxBufferPx="1400 " class="example-viewport " (scrolledIndexChange)="changeMonth($event) ">
                                <div *cdkVirtualFor="let item of items" class="example-item ">
                                    <div class="item " (click)="select(item) " [ngClass]="{ 'is-active':item===selectedItem} ">
                                        <span class="weekName ">{{returnWeekDay(item)}}</span>
                                        <span> {{item | date:'d'}}</span>
                                    </div>
                                </div>
                            </cdk-virtual-scroll-viewport>
                        </div>
                    </div>
                    <div *ngIf="pdf.date.errors && pdf.date.invalid">
                        <small class="text-danger" *ngIf="pdf.date.errors.required">Date is required</small>
                    </div>
                </div>
                -->
                </div>
             </div>
             <div class="col-md-8 ">
                <div class="form-group ">
                    <label>Time </label><br>
                    <mat-radio-group formControlName="from_time">
                        <mat-radio-button class="example-radio-button " *ngFor="let option of doctorsSlots | async " [value]="option" style="border-width: 1px;border-style: dotted;border-color: black;margin: 2px;padding-top: 10px; padding-left: 2px; ">
                            {{option.startTime | date:'shortTime'}} &nbsp;
                        </mat-radio-button>
                    </mat-radio-group>
                    
                    <div class="text-danger" *ngIf="(doctorsSlots | async)?.length == 0">
                        <p>Sorry , no time slots are available to book</p>
                    </div>
                </div>
             </div>

        </div>
        

       
        <!-- <div class="form-group ">
            <label class="form-control-label " for="from_time ">Start Time</label>
            <input class="form-control " type="time " [class.is-invalid]="pdf.from_time.invalid && pdf.from_time.touched " class="form-control " [ngClass]="{ 'is-invalid': submitted && pdf.from_time.errors } " formControlName="from_time " />

            <div *ngIf="pdf.from_time.errors && pdf.from_time.invalid && pdf.from_time.touched ">
                <small class="text-danger " *ngIf="pdf.from_time.errors.required ">Start time is required</small>
            </div>

            <div class="form-control-position ">
                <i class="feather ft-user "></i>
            </div>
        </div>

        <div class="form-group ">
            <label class="form-control-label " for="to_time ">End Time</label>
            <input class="form-control " type="time " [class.is-invalid]="pdf.to_time.invalid && pdf.to_time.touched " class="form-control " [ngClass]="{ 'is-invalid': submitted && pdf.to_time.errors } " formControlName="to_time " />

            <div *ngIf="pdf.to_time.errors && pdf.to_time.invalid && pdf.to_time.touched ">
                <small class="text-danger " *ngIf="pdf.to_time.errors.required ">End time is required</small>
            </div>

            <div class="form-control-position ">
                <i class="feather ft-user "></i>
            </div>
        </div> -->

        <div style="text-align: center;margin-top: 10px; ">
            <button type="button " class="btn btn-danger " (click)="goBack() " style="margin-right: 20px; ">Cancel</button>
            <button type="submit" class="btn btn-primary " [disabled]="!scheduleForm.valid " (click)="bookAppointment() ">{{title}} Appointment</button>
        </div>

    </form>
</div>
</mat-card-content>