<form [formGroup]="form" (ngSubmit)="onSave()">
    <div>
        <div>
            <label>Cancellation Reason</label>
            <input type="text" id="allergies" (keypress)="keyPressAlpha($event)" placeholder="Enter Cancellation Reason" [(ngModel)]="reason" placement="top" 
            style="width: 100%;height: 40px;"  formControlName="cancellationReason">
            <div class="error" *ngIf="cancellationReason?.errors?.required && (cancellationReason?.dirty || cancellationReason?.touched)">
                Cancellation reason is required 
            </div>
        </div>
    </div>
    <div style="text-align: center;margin: 10px;">
        <button type="button" class="btn btn-danger" (click)="onCancel()">
            Cancel
        </button>
        <button type="submit" style="margin-left: 25px;" class="btn btn-primary" [disabled]="form.invalid">
            Save
        </button>
    </div>
</form>