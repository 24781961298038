import { Component, OnInit, ViewChild } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { TableApiService } from 'src/app/_services/table-api.service';
import { ApiService } from 'src/app/_services/api-service';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap/modal/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { PrescriptionpdfComponent } from 'src/app/prescriptionpdf';

@Component({
  selector: 'my-consultations',
  templateUrl: './consultations.component.html',
  styleUrls: ['./consultations.component.css'],
  providers: [ApiService]
})
export class ConsultationsComponent implements OnInit {

  @BlockUI('zeroConfiguration') blockUIZeroConfiguration: NgBlockUI;
  @BlockUI('defaultOrdering') blockUIDefaultOrdering: NgBlockUI;
  selectedPres: any;
  editModal = null;
  contactFlag: boolean;
  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY: false, wheelPropagation: false };
  public config: PerfectScrollbarConfigInterface = { suppressScrollY: true };
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;

  options = {
    close: false,
    expand: false,
    minimize: false,
    reload: false
  };
  temp = [];
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  constructor(private matDialog: MatDialog, private tableApiservice: TableApiService, private apiService: ApiService, private modal: NgbModal,) {

  }
  data: any;
  rows: any = [];
  defaultrows: any;
  DOMrows: any;
  Scrollrows: any;
  public breadcrumb: any;
  temp2 = this.rows;
  dataTableMessage: any = { emptyMessage: 'Loading...' };
  ngOnInit() {

    var user = JSON.parse(localStorage.getItem('currentUser'));
    this.apiService.getDocterConsultations(user['username']).subscribe((resData: any) => {
      // console.log("res--" + JSON.stringify(resData));
      // this.data = resData;
      resData.forEach(element => {
        if (element.patientName == null)
          element.patientName = ""
        if (element.mobile == null)
          element.mobile = ""
        if (element.chiefComplaint == null)
          element.chiefComplaint = ""
      });
      this.rows = resData;
      this.temp2 = this.rows;
      this.dataTableMessage = { emptyMessage: 'No data to display' };

    }, error => {
      var data = error;
    });

    // this.tableApiservice.getTableInitialisationData().subscribe(Response => {
    // this.data = Response;
    // this.getTabledata();
    // });

  }

  getTabledata() {
    this.rows = this.data.rows;
    this.defaultrows = this.data.defaultrows;
    this.DOMrows = this.data.DOMrows;
    this.Scrollrows = this.data.Scrollrows;
  }


  //    editTableDataModal(editTableDataModalContent, row) {
  //   this.selectedPres = Object.assign({}, row);
  //   this.editModal = this.modal.open(editTableDataModalContent, {
  //     windowClass: 'animated fadeInDown'
  //   });
  //   this.contactFlag = false;
  // }

  editTableDataModal(editTableDataModalContent, row) {
    this.apiService.getConsultationdetails(row.personId, row.prescriptionId, row.meetingId).subscribe((resData: any) => {
      resData.consultation = true;
      resData.time = row.time;
      const dialogRef = this.matDialog.open(PrescriptionpdfComponent, {
        minWidth: '100%',
        disableClose: true,
        hasBackdrop: false,
        height: '100%',
        data: { pageValue: resData }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);

      });

    }, error => {
      var data = error;
    });

  }


  updatedefaultFilter(event) {
    //debugger
    const val = event.target.value.toLowerCase();
    this.rows = [...this.temp2];
    this.temp = [...this.rows];
    const temp = this.rows.filter(function (d) {
      return d.patientName.toLowerCase().indexOf(val) !== -1 || d.mobile.toLowerCase().indexOf(val) !== -1
        || d.chiefComplaint.toLowerCase().indexOf(val) !== -1 || d.publishTime?.indexOf(val) !== -1 || d.meetingId?.toString().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    //this.table.offset = 0;

  }

  reloadZeroConfiguration() {
    this.blockUIZeroConfiguration.start('Loading..');

    setTimeout(() => {
      this.blockUIZeroConfiguration.stop();
    }, 2500);
  }

  reloadDefaultOrdering() {
    this.blockUIDefaultOrdering.start('Loading..');

    setTimeout(() => {
      this.blockUIDefaultOrdering.stop();
    }, 2500);
  }

}
