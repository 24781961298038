import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgxSpinnerService } from 'ngx-spinner';
import { element } from 'protractor';
import { AddPrescriptionDialogComponent } from '../addprescription/addprescription.component';
import { EcgDialogComponent } from '../ecgdilaog/ecgdialog.component';
import { PrescriptionpdfComponent } from '../prescriptionpdf';
import { ReportsUploadDialogComponent } from '../reportsupload/reportsupload.component';
import { ApiService } from '../_services/api-service';
import { ChartApiService } from '../_services/chart.api';
import {
  getSeconds,
  getMinutes,
  getHours,
  getDate,
  getMonth,
  getYear,
  setSeconds,
  setMinutes,
  setHours,
  setDate,
  setMonth,
  setYear,
  addHours
} from 'date-fns';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'app-single-patientview',
  templateUrl: './single-patientview.component.html',
  styleUrls: ['./single-patientview.component.css']
})
export class SinglePatientviewComponent implements OnInit {

  Aadhar: string = "";
  patientName: string;
  patientNumber: string;
  patientMail: string;
  bloodGroup: string;
  bpCapturedTime: string;
  glucoCapturedTime: string;
  ecgList: any = [];

  weightCapturedTime: string;
  tempCapturedTime: string;
  diseases: string;
  allergies: string;
  chiefComplaint: string;
  callTransferReason: string;
  bloodPressure: string;
  temperature: string;
  pulse: string;
  bloodGlucose: string;
  weight: string = "";
  pulseCapturedTime: string;
  height: string = '';
  disease: string;
  age: string;
  bmi:any;
  hdlp:any;
  ldlp:any;
  gender: string;
  diabetic: any;
  thyroid: any;
  opened: boolean;
  isb2b: boolean;
  refresh: string = "no"
  conditions:any=[];
  ecgResult: string = "-";
  cholestrol: string = "--";
  uricacid: string = "--";
  totalcholestrol: string = "--";
  triglyceride: string = "--";
  hdlipoprotein: string = "--";
  ldlipoprotein: string = "--";
  urinanalysis: string = "--";
  himoglobin: string = "--";
  cholestolCapturedTime: string;
  uricacidCapturedTime: string;
  totalcholestrolCapturedTime: string;
  urinanalysisCapturedTime: string;
  himoglobinCapturedTime: string;
  ecgCapturedTime: string;
  hwCapturedTime: string;
  currentUser:any;
  personId: string;
  consultRows: any = [];
  consultTemp = this.consultRows;
  searching = false;
  searchFailed = false;
  spO2: string;
  isDisabled: boolean = false;
  showEditIcon:boolean=false;
  drugslist:any=[];
  instructions: string[] = ['Before Food', 'After Food'];
  durations:string[]=['Select','One Day', 'Two Days','Three Days','Four Days','Five Days','Six Days','Seven Days','Eight Days','Nine Days',
  'Ten Days','Two Weeks','Three Weeks','Four Weeks',"One Month","Two Months","Three Months","Four Months","Five Months","Six Months"];
  frequencies:string[]=["Morning","Afternoon","Night","SOS","Others"];
  dosagelist:any=[];
  rows: any = [];
  healthdata: any;
bloodGroupUpdatedDate: string;
medicalHistoryUpdatedDate: string;
lmpUpdatedDate: string;
thyroidUpdatedDate: string;
diabeticUpdatedDate: string;
allergiesUpdatedDate: string;
bloodPressureUpdatedDate: string;

  parentChannel:string;
  consultationList: any = [];
  allVitalsDTO : any;
  previewPrescription: any;
  published: boolean = false;
  patientVitals:any;
  calldoctor: boolean = false;
  mobileno: string;
  diabeticsince:string;
  thyroidsince:string;
  bpsince:string;
  bp:any;
  patchList:any=[];
  doctorName:any;

  @BlockUI('projectInfo') blockUIProjectInfo: NgBlockUI;
  lineArea4: any;
  data: any;
  graphCardOptions = {
    close: true,
    expand: true,
    minimize: true,
    reload: true
}

agoraCardoptions = {
  close: false,
  expand: true,
  minimize: false,
  reload: true
}
  spO2CapturedTime: string;
  bmiCapturedTime: string;
  prescriptionAddedTime: any;

  constructor(private router:Router,private spinner: NgxSpinnerService,private formBuilder: FormBuilder,private matDialog:MatDialog, private snackbar: MatSnackBar,
     private apiService: ApiService,private chartApiservice: ChartApiService, private modalService: NgbModal) {
   this.mobileno = this.router.getCurrentNavigation().extras.state.mobile;

      // this.mobileno='1234512345';
      this.getReportDetails();
      this.loadPrescriptionhistory()
      this.loadReports();
      this.loadAppointmnets();
   }
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
    var user = JSON.parse(localStorage.getItem('currentUser'));
    this.doctorName=user['username'];
      }

      getReportDetails(){
        this.apiService.getReportdetails('',this.mobileno).subscribe((resData: any) => {

          console.log(resData);
          if (resData != null) {
            this.patientName = resData.name;
            this.patientNumber = resData.mobile;
            this.personId = resData.personId;
    
            //this.bloodGroup=resData.profile.bloodType;
            this.weight = resData.weight != null ? resData.weight+" kg" : "";
            this.height = resData.height != null ? resData.height+" cm" : "";
            this.age = resData.age;
            this.gender = resData.gender;
   

            this.Aadhar=resData.aadhaar;
  
            
            this.tempCapturedTime = resData.tempMeasureTime != null ?  this.getFancyTime(resData.tempMeasureTime.replace("T"," ")) +" ago" : "";
          this.pulseCapturedTime = resData.boMeasureTime != null ? this.getFancyTime(resData.boMeasureTime.replace("T"," ")) +" ago": "";
          this.spO2CapturedTime = resData.boMeasureTime != null ? this.getFancyTime(resData.boMeasureTime.replace("T"," ")) +" ago": "";

          this.glucoCapturedTime = resData.bsMeasureTime != null ? this.getFancyTime(resData.bsMeasureTime.replace("T"," ")) +" ago" : "";
          this.bpCapturedTime = resData.bpMeasureTime != null ? this.getFancyTime(resData.bpMeasureTime.replace("T"," ")) +" ago" : "";
          this.bmiCapturedTime = resData.uhMeasureTime != null ? this.getFancyTime(resData.uhMeasureTime.replace("T"," ")) +" ago" : "";

         this.hwCapturedTime = resData.uhMeasureTime != null ? this.getFancyTime(resData.uhMeasureTime.replace("T"," ")) +" ago" : "";
          this.ecgCapturedTime = resData.ecgMeasureTime != null ? this.getFancyTime(resData.ecgMeasureTime.replace("T"," ")) +" ago" : "";
          this.cholestolCapturedTime = resData.colMeasureTime != null ? this.getFancyTime(resData.colMeasureTime.replace("T"," ")) +" ago" : "";
          this.uricacidCapturedTime = resData.uaMeasureTime != null ? this.getFancyTime(resData.uaMeasureTime.replace("T"," ")) +" ago" : "";
          this.urinanalysisCapturedTime = resData.uraMeasureTime != null ? this.getFancyTime(resData.uraMeasureTime.replace("T"," ")) +" ago" : "";
          this.himoglobinCapturedTime = resData.hbMeasureTime != null ? this.getFancyTime(resData.hbMeasureTime.replace("T"," ")) +" ago" : "";
          this.bloodPressure = resData.highPressure != null ?  resData.highPressure + "/" + resData.lowPressure +" mmHg": "--" ;
           
          this.ecgResult = resData.ecgResult != null ? resData.ecgResult : "-";
            this.cholestrol= resData.chol != null  && resData.chol!="" ? resData.chol : "--";
            this.uricacid= resData.ua != null && resData.ua!=""  ? resData.ua : "--";
            this.totalcholestrol= resData.tchol  && resData.tchol!=""  != null ? resData.tchol : "--";
            
            this.triglyceride= resData.trig != null  && resData.trig!="" ? resData.trig : "--";
            this.ldlipoprotein= resData.tcHdl != null  && resData.tcHdl!="" ? resData.tcHdl : "--";
            this.hdlipoprotein= resData.hdlChol != null  && resData.hdlChol!=""  ? resData.hdlChol : "--";
            this.bmi=resData.bmi != null  && resData.bmi!=""  ? Math.round( resData.bmi * 100 + Number.EPSILON ) / 100 :"--";
            this.temperature = resData.temperature != null && resData.temperature!=""  ? resData.temperature +" \xB0F" : "--";
            this.pulse = resData.bpm != null&& resData.bpm!="" ? resData.bpm+" bpm" : "--";
        
            this.spO2 = resData.oxygen && resData.oxygen!=""  != null ? resData.oxygen+" %" : "--";
            this.bloodGlucose = resData.bloodSugar != null && resData.bloodSugar!="" ? resData.bloodSugar+" mg/dL" : "--";
            this.urinanalysis = resData.uro != null && resData.uro !="" ? resData.uro : "--";
            this.himoglobin = resData.hb != null ? Math.round( resData.hb * 100 + Number.EPSILON )+ " g/dL" : "--";
            if(this.pulse=="--")
            this.pulseCapturedTime=""
            if(this.height=="--")
            this.hwCapturedTime=""
            if(this.spO2=="--")
            this.spO2CapturedTime=""
            if(this.bloodGlucose=="--")
            this.glucoCapturedTime=""
            if(this.urinanalysis=="--")
            this.urinanalysisCapturedTime=""
             if(this.himoglobin=="--")
            this.himoglobinCapturedTime=""
            if(this.temperature=="--")
            this.tempCapturedTime=""
            if(this.bloodPressure=="--")
            this.bpCapturedTime=""
            if(this.bmi=="--")
            this.bmiCapturedTime=""
           
        

            if (resData['userHealthDetailsDTO'] != null) {
           
               let data=resData.userHealthDetailsDTO;
               this.bloodGroupUpdatedDate=data.bloodGroupUpdatedDate!=null?this.getFancyTime(data.bloodGroupUpdatedDate) +" ago":" ";
               this.medicalHistoryUpdatedDate=data.medicalHistoryUpdatedDate!=null?this.getFancyTime(data.medicalHistoryUpdatedDate) +" ago":" ";
               this.lmpUpdatedDate=data.lmpUpdatedDate!=null?this.getFancyTime(data.lmpUpdatedDate) +" ago":" ";
               this.thyroidUpdatedDate=data.thyroidUpdatedDate!=null?this.getFancyTime(data.thyroidUpdatedDate)+" ago":" ";
               this.diabeticUpdatedDate=data.diabeticUpdatedDate!=null?this.getFancyTime(data.diabeticUpdatedDate)+" ago":" ";
               this.allergiesUpdatedDate=data.allergiesUpdatedDate!=null?this.getFancyTime(data.allergiesUpdatedDate)+" ago":" ";
               this.bloodPressureUpdatedDate=data.bloodPressureUpdatedDate!=null?this.getFancyTime(data.bloodPressureUpdatedDate)+" ago":" ";
 
              this.healthdata = resData['userHealthDetailsDTO'];
              console.log("blood--", resData['userHealthDetailsDTO']['bloodGroup']);
              this.bloodGroup = resData['userHealthDetailsDTO']['bloodGroup']+" ve";
              this.diabeticsince = resData['userHealthDetailsDTO']['diabeticSince'] != null && this.healthdata.diabeticSince!="" ? " - "+resData['userHealthDetailsDTO']['diabeticSince']: "";
              this.thyroidsince = resData['userHealthDetailsDTO']['thyroidSince']!= null&& this.healthdata.thyroidSince!="" ? " - "+resData['userHealthDetailsDTO']['thyroidSince']: "";
              this.bpsince = resData['userHealthDetailsDTO']['bloodPressureSince']!= null && this.healthdata.bloodPressureSince!=""? " - "+resData['userHealthDetailsDTO']['bloodPressureSince']: "";
              this.allergies = resData['userHealthDetailsDTO']['allergies'] != null ? resData['userHealthDetailsDTO']['allergies'] : "-";
              if (resData['userHealthDetailsDTO']['diabetic'] != null) {
                this.diabetic = this.stringToBoolean(resData['userHealthDetailsDTO']['diabetic']) ? "Yes" : "No";
              }
              if (resData['userHealthDetailsDTO']['bloodPressure'] != null) {
                this.bp = this.stringToBoolean(resData['userHealthDetailsDTO']['bloodPressure']) ? "Yes" : "No";
              }
              else{
                this.bp= "";
              }
              if (resData['userHealthDetailsDTO']['thyroid'] != null) {
                this.thyroid = this.stringToBoolean(resData['userHealthDetailsDTO']['thyroid']) ? "Yes" : "No";
              }
              this.diseases = resData['userHealthDetailsDTO']['medicalHistory'] != null ? resData['userHealthDetailsDTO']['medicalHistory'] : "-";
    
            }
            localStorage.setItem("userVitals",JSON.stringify(resData));

           
          }
    
      }
        );
    }

  appointmentsList: any = [];
  loadAppointmnets() {
    this.apiService.getAppointments(this.mobileno).subscribe((resData: any) => {
      console.log("appointments--" + JSON.stringify(resData));
      if (resData != null) {
        this.appointmentsList = resData;
      }
    }, error => {
      var data = error;
    });
  }

  uploadReports(){
    const dialogRef = this.matDialog.open(ReportsUploadDialogComponent, {
      width:'70%',
      disableClose:true,
      hasBackdrop: true,
      data: { pageValue: "",mobileno:this.mobileno }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result.event == "refresh")
        this.loadReports();
      
    });
   }

   activateorDeactivatePresccription(row: any) {
    const message = `Are you sure, you want to `+(row.activated ? "deactivate" : "activate")+" this prescription?";
    const dialogData = new ConfirmDialogModel("Please Confirm..", message);

    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      minWidth: "400px",
      disableClose: true,
      hasBackdrop: true,
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      console.log(dialogResult)
      if (dialogResult) {
        this.spinner.show();
        this.apiService.updatePrescriptionStatus(row.id,!row.activated).subscribe((resData: any) => {
          console.log("presres--", resData);
          this.spinner.hide();
          this.loadPrescriptionhistory();
        }, error => {
          this.spinner.hide();
          var data = error;
        });
      }
    });
  }

    getFancyTime(date) : string{
        var date1 = new Date(date).getTime();
        var date2 = new Date().getTime();
        var msec = date2 - date1;
        var mins = Math.floor(msec / 60000);
        var hrs = Math.floor(mins / 60);
        var days = Math.floor(hrs / 24);
        mins = mins % 60;
        hrs = hrs % 24;
        days = days % 365;
        if(days >= 1)
          return days == 1 ? days.toString() + " day" : days.toString()+" days";
        else if(days < 1 && hrs >= 1)
          return hrs == 1 ? hrs.toString() + " hour" : hrs.toString() +" hours";
        else
          return mins == 1 ? mins.toString() + " min" :mins.toString() + " mins";
     }

      private stringToBoolean(stringValue: string): boolean | undefined {  
        try {  
            return JSON.parse(stringValue);  
        }  
        catch (e) {  
            return undefined;  
        }  
    }

    openPrescriptionPreview(data){
      data.prescriptionhistory = true;
      const dialogRef = this.matDialog.open(PrescriptionpdfComponent, {
        minWidth:'100%',
        disableClose:true,
        hasBackdrop:false,
        height:'100%',
        data: {pageValue:  data }
      });
    
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
  
      });
    
    }

    loading : boolean ;
    reportDto: any;
    reportsModel(ReportsModelContent,reportDto) {
     
      this.reportDto = reportDto;
      this.apiService.getReportAttachment(reportDto.recordNo).subscribe(res=>{
        console.log("res--",res);
        this.loading = true;
        this.reportDto.reportAttachment = res!= null ? res : "";
      },error => {
        this.loading = true;
        });
      this.modalService.open(ReportsModelContent, { windowClass: 'animated fadeInDown', size: 'lg' });
    }
  
    vitalType:string;
    lipids: any;
    MediumModel(MediumModelContent,data) {
      this.lipids = data;
      this.modalService.open(MediumModelContent, { windowClass: 'animated fadeInDown', size: 'md' });
    }
  
    hb: any;
    HBModel(HBModelContent,data) {
      this.hb = data;
      this.modalService.open(HBModelContent, { windowClass: 'animated fadeInDown', size: 'md' });
    }
  
    ur: any;
    UAModel(UAModelContent,data) {
      this.ur = data;
      this.modalService.open(UAModelContent, { windowClass: 'animated fadeInDown', size: 'md' });
    }
  
    urica: any;
    UricacidModel(UricacidModelContent,data) {
      this.urica = data;
      this.modalService.open(UricacidModelContent, { windowClass: 'animated fadeInDown', size: 'md' });
    }
  
    chol: any;
    cholModel(CholModelContent,data) {
      this.chol = data;
      this.modalService.open(CholModelContent, { windowClass: 'animated fadeInDown', size: 'md' });
    }

  reportrows:any=[];
  lipidsList : any = [];
  hbList : any = [];
  uaList : any = [];
  patientReportUploadDTOList : any = [];
  loadReports(){
  this.apiService.getPatientVitals(this.mobileno).subscribe(
    response => {

      this.reportrows = [];
      this.patientVitals=response;
      this.ecgList = response['PEEcgDetails'] != null ? response['PEEcgDetails'] : [];
      this.lipidsList = response['BloodFatDetails'] != null ? response['BloodFatDetails'] : [];
      this.hbList = response['HbDetails']!= null ? response['HbDetails'] : [];
      this.uaList = response['UrinalysisDetails'] != null ? response['UrinalysisDetails'] : [];
      let uricacidList = response['UaDetails'] != null ? response['UaDetails'] : [];
      let cholList = response['CholDetails'] != null ? response['CholDetails'] : [];
      this.patientReportUploadDTOList = response['patientReportUploadDTOList'] != null ? response['patientReportUploadDTOList'] : [];
      if(this.ecgList && this.ecgList.length>0){
        this.ecgList.forEach(element => {
          this.reportrows.push(new Report(element.RecordNo,'ECG',element.measureTime,element,'ecg'));

        });
      }
      if(this.lipidsList && this.lipidsList.length>0){
        this.lipidsList.forEach(element => {
          this.reportrows.push(new Report(element.RecordNo,'Lipids Profile',element.measureTime,element,''));
        });
      
      }
      if(this.hbList && this.hbList.length>0){
        this.hbList.forEach(element => {
          this.reportrows.push(new Report(element.RecordNo,'Himoglobin',element.measureTime,element,''));
        });
      
      }
      if(uricacidList && uricacidList.length>0){
        uricacidList.forEach(element => {
          this.reportrows.push(new Report(element.RecordNo,'Uricacid',element.measureTime,element,''));
        });
      
      }
      if(cholList && cholList.length>0){
        cholList.forEach(element => {
          this.reportrows.push(new Report(element.RecordNo,'Cholestrol',element.measureTime,element,''));
        });
      
      }
      if(this.uaList && this.uaList.length>0){
        this.uaList.forEach(element => {
          this.reportrows.push(new Report(element.RecordNo,'Urin Analysis',element.measureTime,element,''));
        });
      
      }
      if(this.patientReportUploadDTOList && this.patientReportUploadDTOList.length>0){
        this.patientReportUploadDTOList.forEach(element => {
          this.reportrows.push(new Report(element.recordNo,element.reportName,element.measureTime,element,'report'));
        });
      
      }
      
    }
  );
}

openReports(row,lipid,hb,ur,uricacid,chol,reports){
  if(row.reportName=="ECG" && row.name=="ecg")
  this.openEcg(row.vital)
  else if(row.reportName=='Lipids Profile')
  this.MediumModel(lipid,row.vital);
  else if(row.reportName=='Himoglobin')
  this.HBModel(hb,row.vital);
  else if(row.reportName == 'Urin Analysis')
    this.UAModel(ur,row.vital)
  else if(row.reportName == 'Uricacid')
    this.UricacidModel(uricacid,row.vital)
  else if(row.reportName == 'Cholestrol')
    this.cholModel(chol,row.vital)
  else{
    this.reportsModel(reports,row.vital);
  }

}

      openEcg(ecg){
        ecg.name = this.patientVitals.Member.Name;
        ecg.gender =  this.patientVitals.Member.Sex;
        ecg.age =  this.patientVitals.Member.Age;
      
        const dialogRef = this.matDialog.open(EcgDialogComponent, {
          width:'100%',
          disableClose:true,
          hasBackdrop: true,
          data: { pageValue: ecg}
        });
      
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', result);
          
        });
      
      }

      loadPrescriptionhistory() {
        this.spinner.show();
        this.apiService.getPatientPrescriptions(this.mobileno).subscribe((resData: any) => {
          console.log("presres--" + JSON.stringify(resData));
          if(resData != null){
            this.consultationList = resData.prescriptionList;
            console.log("consultationList=",this.consultationList)
            this.consultRows=resData;
            this.consultationList=this.consultationList.map(element =>{
              this.showEditIcon =false;
              this.prescriptionAddedTime=element.time;
              if(new Date(this.prescriptionAddedTime).getTime()+172800000>new Date().getTime()){
                console.log("get in");
                this.showEditIcon=true;
              }
              return {
                ...element,showEditIcon:this.showEditIcon
              }
            });
            // console.log("after Res:",this.consultRows)
            this.spinner.hide();
          }
          // this.temp2=this.rows;
        }, error => {
          this.spinner.hide();
          var data = error;
        });
     
      }
      
      openEditPrescription(data){
        const dialogRef = this.matDialog.open(AddPrescriptionDialogComponent, {
          width:'100%',
          disableClose:true,
          hasBackdrop: true,
          data: { pageValue:  data }
        });
      
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', result);
          if(result.event == "refresh")
            this.loadPrescriptionhistory();      
        });
      }
      showEdit(row){
        return row.doctorName==this.doctorName && row.showEditIcon;
      }
}

class Report{
  constructor(
    public recordNo: number,
    public reportName: string,
    public measureTime: string,  
    public vital:any,
    public name:any,
  ) { }
}





