<ngx-spinner bdcolor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" style="color: white" type="ball-spin">Loading...</ngx-spinner>

<mat-card-content>
<div class="container">
    <form [formGroup]="blockForm">
        <div style="text-align: center;">
            <h4>Block Slot</h4>
        </div>
        <div class="row">
            <div class="col-12 col-lg-6 col-sm-12 col-md-12 col-xs-12" >
                <daterangepicker
                [class]="'date-center'"
                [options]="daterangepickerOptions"
                (rangeSelected)="rangeSelected($event)"
                >
                </daterangepicker>
                <div *ngIf="dateerror && !saved" >
                    <small class="text-danger" *ngIf="dateerror">date and time required</small>
                </div> 
                <div *ngIf="dateerror && saved" >
                    <small class="text-danger" *ngIf="dateerror">please select valid date and time</small>
                </div> 
            </div>
            <div class="col-12 col-lg-6 col-sm-12 col-md-12 col-xs-12">
                <div style="margin-top:20px ;">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <input matInput type="text" id="reason"  placeholder="Enter Reason" formControlName="reason">
                    </mat-form-field>
                    <div *ngIf="pdf.reason.errors && pdf.reason.touched">
                        <small class="text-danger" *ngIf="pdf.reason.errors.required">reason field is required</small>
                    </div>
                    <div>
                        <button type="button " class="btn btn-danger " (click)="goBack() " style="margin-right: 20px; ">Cancel</button>
                        <button type="button " class="btn btn-primary "   [disabled]="!blockForm.valid || dateerror" (click)="bookSlot() ">Block slot</button>
                    </div>
            </div>
            </div>
        </div>
    </form>
</div>
</mat-card-content>