import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as constants from './constants'
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class PrescriptionService {
       constructor(private http: HttpClient) {  
    }

    savePrescription(data): Observable<any> {
        return this.http.post(constants.API_REST_URL +'robotapi/editPrescription', data);
      }

      savePrescriptionb2b(data): Observable<any> {
        return this.http.post(constants.API_REST_URL +'api/v1/b2bEditPrescription', data);
      }
    // b2bPublishPrescription
    // b2bEditPrescription
    publishPrescriptionb2b(data): Observable<any> {
      return this.http.post(constants.API_REST_URL +'api/v1/b2bPublishPrescription', data);
    }


      publishPrescription(data): Observable<any> {
        return this.http.post(constants.API_REST_URL +'robotapi/publishPrescription', data);
      }

      getAnalyticsImages(data): Observable<any> {
        return this.http.post(constants.API_REST_URL+"api/v1/getImageBase64String", data);
      }
  

      getPrescriptionHistory(id):Observable<any>{
        return this.http.get(constants.API_REST_URL+"robotapi/getUserPrescriptions/"+id);
      }

      getPrescriptionHistoryB2b(id):Observable<any>{
        return this.http.get(constants.API_REST_URL+"api/v1/b2bGetUserPrescriptions/"+id);
      }
}