import { DatePipe } from '@angular/common';
import { ThrowStmt } from '@angular/compiler';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Optional, Renderer2, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { isThisISOWeek } from 'date-fns';
import { NgxSpinnerService } from 'ngx-spinner';
import { Registration } from '../register';
import { AlertService } from '../_services/alert.service';
import { ApiService } from '../_services/api-service';

export class CodeableConcept {
  coding: string;
  text: string;
}

export class Period {
  start: string;
  end: string;
}

export class Identifier {
  use: string; // usual | official | temp | secondary | old (If known)
  type: CodeableConcept // Description of identifier
  system: string; // The namespace for the identifier value
  value: string; // The value that is unique
  period: Period; // Time period when id is/was valid for use
  assigner: string; // Organization that issued id (may be just text)
}

export class Qualification {
  identifier: Identifier;
  code: CodeableConcept
  period: Period;
  issuer: string;
}

export class HumanName {
  use: string;
  text: string
  family: string;
  given: string;
  prefix: string;
  suffix: string;
  period: Period
}

export class ContactPoint {
  use: string;
  rank: Number
  system: string;
  value: string;
  period: Period;
}

export class Attachment {
  contentType: string; // Mime type of the content, with charset etc.
  language: string; // Human language of the content (BCP-47)
  data: string; // Data inline, base64ed
  url: string; // Uri where the data can be found
  size: Number // Number of bytes of content (if url provided)
  hash: string; // Hash of the data (sha-1, base64ed)
  title: string; // Label to display in place of the data
  creation: string; // Date attachment was first created
}

export class Address {
  use: string; // home | work | temp | old | billing - purpose of this address
  type: string;  // postal | physical | both
  text: string;  // Text representation of the address
  line: string;  // Street name, number, direction & P.O. Box etc.
  city: string;  // Name of city, town etc.
  district: string;  // District name (aka county)
  state: string;  // Sub-unit of country (abbreviations ok)
  postalCode: string;  // Postal code for area
  country: string;  // Country (e.g. can be ISO 3166 2 or 3 letter code)
  period: Period// Time period when address was/is in use

}

export class timevalues {
  startTime:string;
  endTime:string;
}

export class UpdateSlot
{
  startTime:string;
  endTime:string;
  doctorId:number;
  doctorName:string;
  duration:string;
  availableDate:string;
}



declare var require: any;
const dayjs = require("dayjs");
@Component({
  templateUrl: './availableblockstatus.component.html',
  styleUrls: ['./availableblockstatus.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailableBlockStatusComponent implements OnInit {



  public timeList:Array<timevalues>=[];
  public updatedtimelist:Array<UpdateSlot>=[];
  doctorname: any;
  doctorId: any;
  startDate:Date=new Date();
  Day:string;

  personaldetailsForm: FormGroup;
  blockdetailsForm: FormGroup;
  employmentdetailsForm: FormGroup;
  returnUrl: string;
  error = '';
  availableDate:string;
  date:string;
  
 


  showdata:boolean=true;

  @Input() max: any;
  tomorrow = new Date(); 
  duration=[{key:"15",name:"15 Min"},
  {key:"30",name:"30 Min"},
  {key:"45",name:"45 Min"},
  {key:"60",name:"60 Min"}]
  slotDuration;
  public timeSlotList: FormArray;
  public timeSlotList1: FormArray;
  loading: boolean;

  constructor( private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private alertService: AlertService,
    private apiService: ApiService,
    private snackbar: MatSnackBar,
    private datepipe: DatePipe,
    private renderer: Renderer2,
    private dialogRef: MatDialogRef<AvailableBlockStatusComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    , ) {
    dialogRef.disableClose = true;
    this.timeList=data['timeList'];
    const datepipe1: DatePipe = new DatePipe('en-US');
    this.availableDate=datepipe1.transform(data['date'], 'yyyy-MM-dd 00:00:00');
    this.date=datepipe1.transform(data['date'], 'yyyy-MM-dd');
    this.doctorId=data['doctorId'];
    this.slotDuration=data['duration'];
    //this.type = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation()["extras"]["state"] : null;
   

    

  }


  get pdf() {
    return this.personaldetailsForm.controls;
  }

  get pdf1() {
    return this.blockdetailsForm.controls;
  }

  get getTimeSlotFormrGoup() {
    return this.personaldetailsForm.get('timeSlotDTOList') as FormArray;
  }

  get getTimeSlotFormrGoup1() {
    return this.blockdetailsForm.get('timeSlotDTOList1') as FormArray;
  }

  getValidity(i) {
    return (<FormArray>this.personaldetailsForm.get('timeSlotDTOList')).controls[i].invalid;
  }

  getValidity1(i) {
    return (<FormArray>this.blockdetailsForm.get('timeSlotDTOList1')).controls[i].invalid;
  }
 
  addTimeSlots() {
    this.showdata=true;
    this.timeSlotList.push(this.createTimeSlots());
  }

  addTimeSlots1() {
    this.timeSlotList1.push(this.createTimeSlots1());
  }
 
  removeTimeSlots(index) {
    this.timeSlotList.removeAt(index);
  }

  removeTimeSlots1(index) {
    this.timeSlotList1.removeAt(index);
  }

  createEducation(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      courseName: ['', Validators.required],
      university: ['', Validators.required],
      completionyear: ['', Validators.required],
    });
  }
  createAppointment(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      availabilitySlotDuration: ['', Validators.required],
      availabilitySlotDays: [[], Validators.required],
      timeSlotDTOList: this.formBuilder.array([this.createTimeSlots()]),
    });
  }
  createTimeSlots(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
    });
  }
  createTimeSlots1(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
    });
  }
  namePressAlpha(event) {
    var inp = String.fromCharCode(event.nameCode);
    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  multipleSelectArray = [
    {
      "item_id": 1, "item_text": "Monday"
    },
    {
      "item_id": 2, "item_text": "Tuesday"
    },
    {
      "item_id": 3, "item_text": "Wednesday"
    },
    {
      "item_id": 4, "item_text": "Thursday"
    },
    {
      "item_id": 5, "item_text": "Friday"
    },
    {
      "item_id": 6, "item_text": "Saturday"
    },
    {
      "item_id": 0, "item_text": "Sunday"
    }
  ];
  multipleSelectArray1 = [
   "MONDAY","TUESDAY","WEDNESDAY","THURSDAY","FRIDAY","SATURDAY","SUNDAY"
  ];
  timeSlots=[
    { key:"08:00:00",name:"8:00 AM" },
    { key:"08:30:00",name:"8:30 AM" },
    { key:"09:00:00",name:"9:00 AM" },
    { key:"09:30:00",name:"9:30 AM" },
    { key:"10:00:00",name:"10:00 AM" },
    { key:"10:30:00",name:"10:30 AM" },
    { key:"11:00:00",name:"11:00 AM" },
    { key:"11:30:00",name:"11:30 AM" },
    { key:"12:00:00",name:"12:00 PM" },
    { key:"12:30:00",name:"12:30 PM" },
    { key:"13:00:00",name:"1:00 PM" },
    { key:"13:30:00",name:"1:30 PM" },
    { key:"14:00:00",name:"2:00 PM" },
    { key:"14:30:00",name:"2:30 PM" },
    { key:"15:00:00",name:"3:00 PM" },
    { key:"15:30:00",name:"3:30 PM" },
    { key:"16:00:00",name:"4:00 PM" },
    { key:"16:30:00",name:"4:30 PM" },
    { key:"17:00:00",name:"5:00 PM" },
    { key:"17:30:00",name:"5:30 PM" },
    { key:"18:00:00",name:"6:00 PM" },
    { key:"18:30:00",name:"6:30 PM" },
    { key:"19:00:00",name:"7:00 PM" },
    { key:"19:30:00",name:"7:30 PM" },
    { key:"20:00:00",name:"8:00 PM" },
    { key:"20:30:00",name:"8:30 PM" },
    { key:"21:00:00",name:"9:00 PM" },
    { key:"21:30:00",name:"9:30 PM" },
    { key:"22:00:00",name:"10:00 PM" },
    { key:"22:30:00",name:"10:30 PM" },
    { key:"23:00:00",name:"11:00 PM" },
    { key:"23:30:00",name:"11:30 PM" },
]
  multipleMultiSelect = []


  ngOnInit() {
    this.doctorname = JSON.parse(localStorage.getItem('currentUser')).username;
    // this.getDoctors();
    // this.getDoctorDetails();
   
    this.personaldetailsForm = this.formBuilder.group({
      timeSlotDTOList: this.formBuilder.array([this.createTimeSlots()]),

    });

    this.blockdetailsForm=this.formBuilder.group({
      timeSlotDTOList1: this.formBuilder.array([this.createTimeSlots1()]),

    });
    this.timeSlotList = this.personaldetailsForm.get('timeSlotDTOList') as FormArray;
    this.timeSlotList1 = this.blockdetailsForm.get('timeSlotDTOList1') as FormArray;
    //this.loadUserDetails();
    if(this.timeList.length==0)
    {
      this.showdata=false;
    }
    else
    {
      this.showdata=true;
    }
    for(var i=0;i<this.timeList.length-1;i++)
    {
      this.addTimeSlots();
    }
    this.personaldetailsForm.patchValue({
      timeSlotDTOList:this.timeList
    });

 
    
  }

  onSubmit() {
    console.log("output -> ", JSON.stringify(this.personaldetailsForm.value));
    var data=this.personaldetailsForm.get('timeSlotDTOList').value;
    for(var i=0;i<data.length;i++)
    {
      this.updatedtimelist.push({startTime:data[i].startTime,endTime:data[i].endTime,doctorId:this.doctorId,doctorName:this.doctorname,duration:this.slotDuration,availableDate:this.availableDate});
    }
    
    console.log(this.updatedtimelist);
    this.apiService.updateUserSlotDetails(this.updatedtimelist,this.doctorId,this.date).subscribe((resData: any) => {
      this.loading = false;


      var data = JSON.parse(resData['status']);
      if(data==200)
      {
        
        this.dialogRef.close();
       
      }
      
      
      
    }, error => {
      var data = error;
      this.loading = false;
    });

  }

  onSubmit1() {

  }

  getTimeSlots(i){
    var slot=(<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].get('availabilitySlotDuration');
    // var time=new Date(8);
    // if(slot!=''){

    // }
    var arr = [], i, j;
    // for(i=8; i<23; i++) {
    //   for(j=0; j<2; j++) {
    //     var format;
    //     if(i<12){
    //       format=" AM"
    //     }else{
    //       format=" PM"
    //     }
    //     var hr;
    //     if(i<=12){
    //       hr=i;
    //     }else{
    //       hr=i-12;
    //     }
    //     arr.push({name:i + ":" + (j===0 ? "00" : 30*j) +":00",name:hr + ":" + (j===0 ? "00" : 30*j)+format});
    //   }
    // }
    // arr.push({name:"abc",name:"test"})
    return arr;
    // console.log(array);
    
  }

  goBack() {
    this.dialogRef.close('cancel');
  }

  loadUserDetails() {
    var user = JSON.parse(localStorage.getItem('currentUser'));

    this.apiService.getUserDetails(user['username']).subscribe((resData: any) => {
      let userData = resData;
      

    }, error => {
      var data = error;
    });
  }
  // getDoctorDetails()
  // {
  //     this.apiService.getUserDetails(this.doctorname).subscribe((resData: any) => {
  //       var temp=JSON.stringify(resData);
  //     }, error => {
  //       var data = error;
  //     });
  // }
  doctorsList = [];
  // getDoctors() {
  //   this.apiService.getAllDoctors().subscribe((data: any) => {
  //     this.doctorsList = data;
  //     console.log("list--", this.doctorsList);
  //     this.doctorsList.forEach(element => {
  //       if (element.username == this.doctorname)
  //         this.doctorId = element.id;
  //     });
  //   }, error => {
  //     console.log(error);
  //   });
  // }
  


}
