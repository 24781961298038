import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { BookAppointmentComponent } from 'src/app/bookappointment/bookappointment.component';
import { ApiService } from 'src/app/_services/api-service';
import { TableApiService } from 'src/app/_services/table-api.service';

@Component({
  selector: 'app-my-patients',
  templateUrl: './my-patients.component.html',
  styleUrls: ['./my-patients.component.css'],
  providers: [ApiService]
})
export class MyPatientsComponent implements OnInit {
  @BlockUI('zeroConfiguration') blockUIZeroConfiguration: NgBlockUI;
  @BlockUI('defaultOrdering') blockUIDefaultOrdering: NgBlockUI;

  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY: false, wheelPropagation: false };
  public config: PerfectScrollbarConfigInterface = { suppressScrollY: true };
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;

  options = {
    close: false,
    expand: true,
    minimize: false,
    reload: false
  };
  temp = [];
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  constructor(private router: Router, private matDailog: MatDialog, private apiservice: ApiService, private spinner: NgxSpinnerService) {

  }
  data: any;
  rows: any = [];
  defaultrows: any;
  DOMrows: any;
  Scrollrows: any;
  public breadcrumb: any;
  temp2 = this.rows;
  dataTableMessage: any = { emptyMessage: 'Loading...' };

  ngOnInit() {

    // this.tableApiservice.getTableInitialisationData().subscribe(Response => {
    // this.data = Response;
    // this.getTabledata();
    // });
    var user = JSON.parse(localStorage.getItem('currentUser'));
    this.spinner.show();
    this.apiservice.getPatientConsultations(user['username']).subscribe(res => {
      this.rows = res;
      this.temp2 = this.rows;
      this.dataTableMessage = { emptyMessage: 'No data to display' };
      this.spinner.hide();
    })
  }
  getTabledata() {
    this.rows = this.data.rows;
    this.defaultrows = this.data.defaultrows;
    this.DOMrows = this.data.DOMrows;
    this.Scrollrows = this.data.Scrollrows;
  }
  editTableDataModal(editTableDataModalContent, row) {

    var prescriptiondata = row;
    // prescriptiondata['page'] = "prescription";
    this.router.navigate(['/single-patient'], { state: { mobile: row.mobile } });
    // this.router.navigate(['/single-patient']);
  }


  updatedefaultFilter(event) {
    //debugger
    const val = event.target.value.toLowerCase();
    this.rows = [...this.temp2];
    this.temp = [...this.rows];
    const temp = this.rows.filter(function (d) {
      return d.name?.toLowerCase().indexOf(val) !== -1 || d.mobile?.indexOf(val) !== -1
        || d.lastConsultDate?.indexOf(val) !== -1 || d.personId?.toString().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    //this.table.offset = 0;

  }


  bookAppointment(row: any) {
    const dialogRef = this.matDailog.open(BookAppointmentComponent, {
      minWidth: '80%',
      disableClose: true,
      hasBackdrop: true,
      autoFocus: false,
      maxHeight: '90vh',
      data: { pageValue: row }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }


  reloadZeroConfiguration() {
    this.blockUIZeroConfiguration.start('Loading..');

    setTimeout(() => {
      this.blockUIZeroConfiguration.stop();
    }, 2500);
  }

  reloadDefaultOrdering() {
    this.blockUIDefaultOrdering.start('Loading..');

    setTimeout(() => {
      this.blockUIDefaultOrdering.stop();
    }, 2500);
  }

}
