import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ApiService } from 'src/app/_services/api-service';
import { TableApiService } from 'src/app/_services/table-api.service';
import { DatePipe } from '@angular/common'
import { AppointmentConsultComponent } from '../appointment-consultation/appointment-consultation.component';
import { map } from 'rxjs/operators'
import { FormGroup,FormBuilder ,Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  templateUrl: './my-appointments.component.html',
  styleUrls: ['./my-appointments.component.css'],
  providers: [ApiService, DatePipe]
})
export class MyApointmentsComponent implements OnInit {
  @BlockUI('zeroConfiguration') blockUIZeroConfiguration: NgBlockUI;
  @BlockUI('defaultOrdering') blockUIDefaultOrdering: NgBlockUI;

  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY: false, wheelPropagation: false };
  public config: PerfectScrollbarConfigInterface = { suppressScrollY: true };
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;

  options = {
    close: false,
    expand: false,
    minimize: false,
    reload: false
  };
  temp = [];
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  constructor(public datepipe: DatePipe, private router: Router, private matDailog: MatDialog, private apiservice: ApiService,private snackbar: MatSnackBar,
    private spinner: NgxSpinnerService) {

  }
  data: any;
  rows: any = [];
  defaultrows: any;
  DOMrows: any;
  Scrollrows: any;
  public breadcrumb: any;
  temp2 = this.rows;
  dataTableMessage: any = { emptyMessage: 'Loading...' };

  ngOnInit() {
    this.getMyAppointments();
  }

  getMyAppointments() {
    var user = JSON.parse(localStorage.getItem('currentUser'));
    this.apiservice.getDoctorAppointments(user['username']).pipe(map(response => {
      response.map(item => {
        //item.date = this.datepipe.transform(item.startTime, 'yyyy-MM-dd');
        return item;
      });
      return response;
    })).subscribe(res => {
      let row = [];
      res.forEach(element => {
        if(element.cancelledBy != "Doctor")
          row.push(element);
      });
      this.rows = row;
      this.rows=this.rows.map(row=>{
        var toolTip="Meet";
        if((row['consultationtype']=='Paid' && row['paymentStatus']!='Done')){
        toolTip="Payment is not done for the consultation";
        }else if(row.remainingTime>=900){
        toolTip="Meeting can be joined 15 minutes prior to the Scheduled time";
        }
        return {...row ,
        toolTip: toolTip,
        showSuccess: (row.remainingTime<900) && !(row['consultationtype']=='Paid' && row['paymentStatus']!='Done')?true:false,
         }
      })
      // this.rows = res;
      this.temp2 = this.rows;
      this.dataTableMessage = { emptyMessage: 'No data to display' };
    }, error => {

    });
  }

  getTabledata() {
    this.rows = this.data.rows;
    this.defaultrows = this.data.defaultrows;
    this.DOMrows = this.data.DOMrows;
    this.Scrollrows = this.data.Scrollrows;
  }

  startConsultation(row) {
    // this.router.navigate(['/appointment-consult'], { state: row });
    const dialogRef = this.matDailog.open(AppointmentConsultComponent, {
      width: '95%',
      height: '95%',
      maxWidth: '120vw',
      disableClose: true,
      hasBackdrop: true,
      data: { pageValue: row }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

  cancelConsultationReq(row) {
    const dialogRef = this.matDailog.open(CancelAppointmentDialog, {
      width: '50%',
      disableClose: true,
      hasBackdrop: true,
      data: { pageValue: "" }
    });

    dialogRef.afterClosed().subscribe(result => {
      let followupDTO : any = {};
      followupDTO.name = row.customerName;
      followupDTO.contact = row.customerMobile;
      followupDTO.appointmentDate = row.startTime;
      followupDTO.appointmentId = row.id;
      followupDTO.category = "Appointments";
      followupDTO.reason = result.data+" by Dr."+JSON.parse(localStorage.getItem('currentUser')).username;

      console.log('The dialog was closed', result);
      if (result.data != null) {
        this.spinner.show();
        this.apiservice.cancelAppointmentRequest(followupDTO).subscribe((resData: any) => {
          console.log("res--" + JSON.stringify(resData));
          row.cancelledBy = "Doctor";
          delete row.toolTip;
          delete row.showSuccess;
          this.apiservice.rescheduleAppointment(row).subscribe((resData: any) => {
            console.log("res--" + JSON.stringify(resData));
            this.snackbar.open('Your appointment cancellation request has been sent successfully.', "", { duration: 2000 });
            this.spinner.hide();
            this.getMyAppointments();
          }, error => {
            this.spinner.hide();
            var data = error;
          });         
        }, error => {
          this.spinner.hide();
          var data = error;
        });
      }
    });
  }

  cancelConsultation(row) {
    const dialogRef = this.matDailog.open(CancelAppointmentDialog, {
      width: '50%',
      disableClose: true,
      hasBackdrop: true,
      data: { pageValue: "" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result.event=='refresh'){
        this.getMyAppointments();
      }
      if (result.data != null) {
        console.log("result--" + result);

        row['status'] = "CANCELLED";
        row['cancelledBy'] = "DoctorHimSelf";
        row['cancellationReason'] = result.data;
        delete row.showSuccess;
        delete row.toolTip;
        console.log("params--" + JSON.stringify(row));
        this.apiservice.cancelAppointment(row).subscribe((resData: any) => {
          console.log("res--" + JSON.stringify(resData));
          this.getMyAppointments();
        }, error => {
          var data = error;
        });
      }
    });
  }

  updatedefaultFilter(event) {
    const val = event.target.value.toLowerCase();
    this.rows = [...this.temp2];
    this.temp = [...this.rows];
    const temp = this.rows.filter(function (d) {
      return d.customerName.toLowerCase().indexOf(val) !== -1 || d.customerMobile.toLowerCase().indexOf(val) !== -1 ||
        d.topic.toLowerCase().indexOf(val) !== -1 || d.duration.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    //this.table.offset = 0;

  }

  reloadZeroConfiguration() {
    this.blockUIZeroConfiguration.start('Loading..');

    setTimeout(() => {
      this.blockUIZeroConfiguration.stop();
    }, 2500);
  }

  reloadDefaultOrdering() {
    this.blockUIDefaultOrdering.start('Loading..');

    setTimeout(() => {
      this.blockUIDefaultOrdering.stop();
    }, 2500);
  }
  toggleExpandGroup(group) {
    console.log('Toggled Expand Group!', group);
    this.table.groupHeader.toggleExpandGroup(group);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

}

@Component({
  templateUrl: 'cancelappointment.html',
  styleUrls:['my-appointments.component.css']
})
export class CancelAppointmentDialog {
  reason: string;
  constructor(private formBuilder:FormBuilder,
    public dialogRef: MatDialogRef<CancelAppointmentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }
  form:FormGroup = this.formBuilder.group({
    cancellationReason: ['',[Validators.required]],
  });
  get cancellationReason() {
    return this.form.get('cancellationReason') ;
  }
  keyPressAlpha(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  onCancel(): void {
    this.dialogRef.close();
  }
  onSave() {
    this.dialogRef.close({ event: 'refresh', data: this.reason });
  }

}