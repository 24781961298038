import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { User } from 'src/app/_models';
import * as constants from './constants'
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    loggedIn:boolean = false;
    private componentMethodAgoraSource = new Subject<any>();
    componentAgoraCalled=this.componentMethodAgoraSource.asObservable();

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        // return this.http.post<any>('${environment.apiUrl}/users/authenticate', { username, password })
        return this.http.post<any>(constants.API_REST_URL+'login', { username, password }, {observe: 'response' as 'body'})
           .pipe(map((resp: HttpResponse<any>) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                let user = new User();
                console.log('interceptor header keys: ', resp.headers.keys());
                user.token = resp.headers.get('Authorization');
                user.username = username;//resp.headers.get('Username');
                console.log("token--",JSON.stringify(user));
                localStorage.setItem('currentUser', JSON.stringify(user));
                localStorage.setItem("checkin","false");
                this.loggedIn = true;
                this.currentUserSubject.next(user);
                return user;
            },
            error => {
               return error('Username or password is incorrect')
            }
            ));
    }

    logout() {
        // remove user,prescription,notifications from local storage to log user 
        let userDto:User = JSON.parse(localStorage.getItem('currentUser'));
        this.updateStatus("notavailable").subscribe((resData: any) => {
            console.log("logoutres--"+JSON.stringify(resData));
            this.updateCheckInCheckOut(false,userDto.username).subscribe((resData: any) => {
                console.log("checkoutres--"+JSON.stringify(resData));
                localStorage.removeItem('currentUser');
                localStorage.removeItem("localPrescription")
                localStorage.removeItem('notifications')
                this.loggedIn = false;
                localStorage.setItem("checkin","true");
                this.currentUserSubject.next(null);
                this.componentMethodAgoraSource.next();
                
           } , error => {
               var data=error;
               });       
       } , error => {
           var data=error;
           });       

    }
 
    updateCheckInCheckOut(checkin: any,username: any){
        let url = constants.API_REST_URL + "api/v1/updateCheckInCheckOut/"+checkin+"/"+username;
        return this.http.get(url)
    }
    
    updateStatus(status) {
        let user = JSON.parse(localStorage.getItem('currentUser'));
        let userDTO = {"username":user.username,"status":status,"role":"","fcmToken":"","doctorQualification":"","checkIn":"","password":"","id":""}
        let url = constants.API_REST_URL + "api/v1/updateStatus";
        return this.http.post(url,userDTO);
    }
    
}