<div >
    <div class="list-group">
        <label>Drugs Interaction Information</label>
        <div *ngFor="let inte of interactions" class="list-group-item list-group-item-action flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
                <h5 class="text-bold-600">{{inte}}</h5>
                <!-- <small>{{getDaysAgoText(presc)}}</small> -->
            </div>
            <!-- <p>{{presc.prescriptionNotes}}</p> -->
          </div>
    </div>
</div>
<div style="text-align: center;margin: 10px;">
    <button type="button" class="btn btn-danger" (click)="onCancel()">
        Cancel
      </button>
      <button type="submit" style="margin-left: 25px;" class="btn btn-primary" (click)="onSave()">
        {{ source | titlecase}}
      </button>
</div>