<ngx-spinner
bdColor = "rgba(51, 51, 51, 0.8)"
size = "medium"
color = "#fff" style="color: white"
type = "ball-spin">Loading...</ngx-spinner>

<mat-dialog-content>  
  <div *blockUI="'projectInfo'; message: 'Loading'">
    <div class="card-text addScroll" id="style-3">
        <form [formGroup]="consultationForm" autocomplete="off">
          <div style="text-align: center;">
            <h4>Prescription</h4>
          </div>

            <div class="form-body">
                <div class="form-group">
                    <label for="chiefComplaint">Chief complaint</label>
                    <input type="text" id="chiefComplaint" class="form-control" placeholder="Chief complaint" [disabled]="published" [ngClass]="{ 'is-invalid': submitted && pdf.complaints.errors }" (selectItem)="suggestPrescription($event)" [ngbTypeahead]="suggestComplaints" [resultFormatter]="formatter" [inputFormatter]="formatter" ngbTooltip="Chief complaint" placement="top" formControlName="complaints">
                    <div *ngIf="submitted && pdf.complaints.errors" class="invalid-feedback">
                        <div *ngIf="pdf.complaints.errors.required">Chief complaint is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="symptoms">Symptoms</label>
                    <input type="text" id="symptoms" class="form-control" placeholder="Symptoms" [disabled]="published" ngbTooltip="Symptoms" placement="top" formControlName="observation">
                </div>
                <div class="form-group">
                    <label for="examinations">Examinations/Lab Findings</label>
                    <input type="text" id="examinations" class="form-control" placeholder="Examinations/Lab Findings" [disabled]="published" ngbTooltip="Examinations/Lab Findings" placement="top" formControlName="examinations">
                </div>
                <div class="form-group">
                    <label for="relevantPoints">Relevant points From History</label>
                    <input type="text" id="relevantPoints" class="form-control" placeholder="History" [disabled]="published" ngbTooltip="Relevant points from history" placement="top" formControlName="relevantPoints">
                </div>
                <div class="form-group">
                    <label for="diagnosys">Diagnosis/ Provisional Diagnosis</label>
                    <input id="typeahead-http" type="text" class="form-control" placeholder="Diagnosis" [disabled]="published" [ngClass]="{ 'is-invalid': submitted && pdf.diagnosis.errors }" [ngbTypeahead]="searchFromService" ngbTooltip="Diagnosis/ Provisional Diagnosis"
                        placement="top" formControlName="diagnosis" />
                    <!-- <input type="text" id="diagnosys" class="form-control" placeholder="Diagnosys"
          ngbTooltip="Diagnosys/ Provisional Diagnosys" placement="top" formControlName="diagnosys"> -->
                    <!-- <ng-select [items]="conditions"   [multiple]="true" bindLabel="item_text"
           placeholder="Diagnosys"  formControlName="diagnosys" >
          </ng-select> -->
                    <div *ngIf="submitted && pdf.diagnosis.errors" class="invalid-feedback">
                        <div *ngIf="pdf.diagnosis.errors.required">Diagnosis/ Provisional diagnosis is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="investigation">Suggested Investigations</label>
                    <input type="text" id="investigation" class="form-control" placeholder="Suggested Investigations" [disabled]="published" ngbTooltip="Suggested Investigations" placement="top" formControlName="investigation">
                </div>
                <div class="" formArrayName="prescriptionList">
                    <label for="">Medication</label>
                    <div class="" *ngFor="let prescriptionForm of consultationFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">

                            <div class="form-group  col-md-4 pr-1" style="padding-left:20px;">
                                <label for="drug">Drug</label>
                                <input type="text" class="form-control" placeholder="Drug" formControlName="drugName" [disabled]="published" [matAutocomplete]="auto">
                                <!-- <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let drug of drugs[i] | async" [value]="drug.name">
                                        {{drug.name}}
                                    </mat-option>
                                </mat-autocomplete> -->
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)='getDrugAlts(i,$event.option.value)'> 
                                                    <mat-option  *ngFor="let drug of drugs[i] | async"
                                                    [value]="drug">
                                                      {{drug.name}}
                                                    </mat-option>
                                                  </mat-autocomplete>

                            </div>

                            <div class="form-group  col-md-2 prl-1" style="padding-left:20px;display:none;">
                                <label for="strength">Strength</label>
                                <input type="text" id="strength" class="form-control" placeholder="Strength" [disabled]="published"
                                ngbTooltip="Strength" placement="top" formControlName="dosage">
                            </div>
                           
                            <div class="form-group  col-md-2 prl-0" style="padding-left:20px;">
                                <label for="instruction">Instructions</label>
                                <div class="form-control">
                                    <mat-select formControlName="instructions" >
                                        <mat-option *ngFor="let instruction of instructions" [value]="instruction">{{instruction}}</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div class="form-group  col-md-2 prl-0" style="padding-left:20px;">
                                <label for="frequency">Frequency</label>
                                <div class="form-control">
                                    <mat-select multiple formControlName="frequency" >
                                        <mat-option *ngFor="let frequency of frequencies" [value]="frequency">{{frequency}}</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div class="form-group  col-md-2 prl-0" style="padding-left:20px;">
                                <label for="duration">Duration</label>
                                <div class="form-control">
                                    <mat-select formControlName="duration">
                                        <mat-option *ngFor="let duration of durations" [value]="duration">{{duration}}</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                           
                            <div class="form-group col-md-2 text-center " >
                                <button class="btn btn-danger mt-2" type="button" (click)="removePrescritption(i)"><i
                              class="feather ft-x"></i></button>
                            </div>
                        </div>
                        <div class="row" [hidden]="!showAltFlag[i]">
                            <div class="col-md-1">
                            <label for="altDrugs">&nbsp;Alternative&nbsp;Drugs:</label>
                            </div>
                            <div class="form-group col-md-5">
                            <ng-select [items]="drugAlts[i] | async" [multiple]="true" [maxSelectedItems]="3" formControlName="altDrugs" bindLabel="name"
                                placeholder="Alternative Drugs">
                            </ng-select>
                            </div>
                            <div class="form-group col-md-6" role="group"
                            aria-label="Alternative Drugs">
                            <button type="button" style="min-height: 36px;margin-bottom: 5px;margin-right: 5px;" class="js-programmatic-multi-set-val btn btn-sm btn-outline-primary"
                                *ngFor="let item of drugAlts[i] | async | slice:0:4; let in=index" (click)="addAltDrug(i,item)">
                                <i class="feather ft-plus"></i> {{item.name}}
                            </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group ">
                        <div class="col-12 pl-0" >
                            <button data-repeater-create class="btn btn-primary " (click)="addPrescritption()">
                      <i class="feather ft-plus"></i> Add
                    </button>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="clinicalNotes">Clinical Notes / Special Instruction</label>
                    <input type="text" id="clinicalNotes" class="form-control" placeholder="Clinical Notes"  ngbTooltip="Clinical Notes / Special Instruction" placement="top" formControlName="notes">
                </div>
            </div>

            <div class="" formArrayName="lifeStyleModificationList">
                <label for="">Life Style Changes</label>
                <div class="" *ngFor="let tip of lifeStyleModificationFormGroup.controls; let i = index;">
                    <div [formGroupName]="i" class="row">
                        <div class="form-group  col-md-10 prl-1" style="padding-left:20px;">
                            <label for="strength">Changes</label>
                            <input type="text" id="strength" class="form-control" placeholder="Life Style Modifications" [disabled]="published"
                            ngbTooltip="Life Style Changes" placement="top" formControlName="changes">
                        </div>
                       
 
                       
                        <div class="form-group col-md-2 text-center " >
                            <button class="btn btn-danger mt-2" type="button" (click)="removelifeStyleModification(i)"><i
                          class="feather ft-x"></i></button>
                        </div>
                    </div>
                </div>
                <div class="form-group ">
                    <div class="col-12 pl-0" >
                        <button data-repeater-create class="btn btn-primary " (click)="addlifeStyleModification()">
                  <i class="feather ft-plus"></i> Add
                </button>
                    </div>
                </div>
            </div>

            <div class="text-center">
                    <button type="button" class="btn btn-danger mr-1" (click)="onCancel()"> cancel </button>
                    <button type="submit" (click)="publishPrescription()" class="btn btn-primary mr-1"> Update</button>
            </div>
        </form>
    </div>
</div>
</mat-dialog-content>