<ngx-spinner bdcolor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" style="color: white" type="ball-spin">Loading...</ngx-spinner>

<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <!--/ Zero configuration table -->
            <!-- Default ordering table -->
            <section id="ordering">
                <div class="row">
                    <div class="col-12" *blockUI="'defaultOrdering'; message: 'Loading'">
                        <m-card [options]="options" (reloadFunction)="reloadDefaultOrdering($event)">
                            <ng-container mCardHeaderTitle>
                                Appointments
                            </ng-container>
                            <ng-container mCardBody>

                                <ul class="nav tabsborder ">
                                    <ngb-tabset style="width: 100%;">
                                        <ngb-tab>
                                            <ng-template ngbTabTitle><strong>Upcoming</strong></ng-template>
                                            <ng-template ngbTabContent>
                                                <div class="card-dashboard">
                                                    <div class="card-content ">
                                                        <div class="card-body ">
                                                            <div #myTable class="table-bordered default-ordering" style="min-height: 470px;max-height: 100%;">
                                                                <ngx-datatable class="bootstrap" [limit]="7" [rows]="rows" [columnMode]="'force'" [messages]="dataTableMessage" [scrollbarH]="true" [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" fxFlex="auto" [perfectScrollbar]="config">
                                                                    <ngx-datatable-column name="SNo" [flexGrow]="1" [width]="50">
                                                                        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex">
                                                                            {{rowIndex+1}}
                                                                        </ng-template>
                                                                    </ngx-datatable-column>
                                                                      <ngx-datatable-column name="Patient Name" prop="customerName" [flexGrow]="1" [minWidth]="90">
                                                                        <ng-template ngx-datatable-cell-template let-value="value">
                                                                          {{value}}
                                                                        </ng-template>
                                                                      </ngx-datatable-column>
                                                                      <ngx-datatable-column name="Patient Mobile" prop="customerMobile" [flexGrow]="1" [minWidth]="90">
                                                                        <ng-template ngx-datatable-cell-template let-value="value">
                                                                          {{value}}
                                                                        </ng-template>
                                                                      </ngx-datatable-column>
                                                                      <ngx-datatable-column name="Topic" prop="topic" [flexGrow]="1" [minWidth]="90">
                                                                        <ng-template ngx-datatable-cell-template let-value="value">
                                                                          {{value}}
                                                                        </ng-template>
                                                                      </ngx-datatable-column>
                                                                      <ngx-datatable-column name="Appointment Date" prop="startTime" [flexGrow]="1" [minWidth]="90">
                                                                        <ng-template ngx-datatable-cell-template let-value="value">
                                                                          {{ value}} 
                                                                        </ng-template>
                                                                      </ngx-datatable-column>
                                                                      <ngx-datatable-column name="Duration" prop="duration" [flexGrow]="1" [width]="90">
                                                                        <ng-template ngx-datatable-cell-template let-value="value">
                                                                          {{value}}
                                                                        </ng-template>
                                                                      </ngx-datatable-column>
                                                                      <ngx-datatable-column name="Actions" [flexGrow]="1" [width]="200">
                                                                        <ng-template ngx-datatable-cell-template let-row="row">
                                                                            <button type="submit" class="btn-sm btn-primary" [title]="row.toolTip" (click)="startConsultation(row)" [disabled]="!row.showSuccess" >Start</button>
                                                                            <button type="button" class="btn-sm btn-danger" (click)="cancelConsultationReq(row)" style="margin-left: 10px;">Cancel</button>
                                                                        </ng-template>
                                                                    </ngx-datatable-column>   
                                                                </ngx-datatable>
                                                                <!-- <ngx-datatable class="bootstrap" [limit]="7" [rows]="rows" [groupRowsBy]="'date'" [columnMode]="'force'" [messages]="dataTableMessage" [scrollbarH]="true"
                                                                      [headerHeight]="50" [footerHeight]="50"  [groupExpansionDefault]="true" [rowHeight]="50" >
                                                                      <ngx-datatable-group-header [rowHeight]="50" #myGroupHeader (toggle)="onDetailToggle($event)">
                                                                        <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
                                                                            <div>
                                                                                <a href="#" [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Group" (click)="toggleExpandGroup(group)">
                                                                                    <b>date :{{group.value[0].date}}</b>

                                                                                </a>
                                                                            </div>
                                                                        </ng-template>
                                                                      </ngx-datatable-group-header>
                                                                      <ngx-datatable-column name="SNo" [flexGrow]="1" [width]="50">
                                                                        <ng-template ngx-datatable-cell-template let-value="id">
                                                                            {{id}}
                                                                        </ng-template>
                                                                    </ngx-datatable-column>
                                                                      <ngx-datatable-column name="Patient Name" prop="customerName" [flexGrow]="1" [minWidth]="90">
                                                                        <ng-template ngx-datatable-cell-template let-value="value">
                                                                          {{value}}
                                                                        </ng-template>
                                                                      </ngx-datatable-column>
                                                                      <ngx-datatable-column name="Patient Mobile" prop="customerMobile" [flexGrow]="1" [minWidth]="90">
                                                                        <ng-template ngx-datatable-cell-template let-value="value">
                                                                          {{value}}
                                                                        </ng-template>
                                                                      </ngx-datatable-column>
                                                                      <ngx-datatable-column name="Topic" prop="topic" [flexGrow]="1" [minWidth]="90">
                                                                        <ng-template ngx-datatable-cell-template let-value="value">
                                                                          {{value}}
                                                                        </ng-template>
                                                                      </ngx-datatable-column>
                                                                      <ngx-datatable-column name="Appointment Date" prop="startTime" [flexGrow]="1" [minWidth]="90">
                                                                        <ng-template ngx-datatable-cell-template let-value="value">
                                                                          {{ value}} 
                                                                        </ng-template>
                                                                      </ngx-datatable-column>
                                                                      <ngx-datatable-column name="Duration" prop="duration" [flexGrow]="1" [width]="90">
                                                                        <ng-template ngx-datatable-cell-template let-value="value">
                                                                          {{value}}
                                                                        </ng-template>
                                                                      </ngx-datatable-column>
                                                                      <ngx-datatable-column name="Actions" [flexGrow]="1" [width]="200">
                                                                        <ng-template ngx-datatable-cell-template let-row="row">
                                                                            <button type="submit" class="btn-sm btn-primary" (click)="startConsultation(row)" >Start</button>
                                                                            <button type="button" class="btn-sm btn-danger" (click)="cancelConsultation(row)" style="margin-left: 10px;">Cancel</button>
                                                                        </ng-template>
                                                                    </ngx-datatable-column>   
                                                                </ngx-datatable> -->
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngb-tab>
                                        <ngb-tab>
                                            <ng-template ngbTabTitle><strong> Appointmnet History</strong>
                                            </ng-template>
                                            <ng-template ngbTabContent>
                                                <app-appointment-history></app-appointment-history>
                                            </ng-template>

                                        </ngb-tab>

                                        <ngb-tab>
                                          <ng-template ngbTabTitle><strong> Prescriptions History</strong>
                                          </ng-template>
                                          <ng-template ngbTabContent>
                                              <my-consultations></my-consultations>
                                          </ng-template>

                                      </ngb-tab>
                                    </ngb-tabset>
                                </ul>
                            </ng-container>
                        </m-card>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>