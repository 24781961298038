import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { APP_CONSTANTS } from '../_services/constants';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  passwordForm: FormGroup;
  form2:FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isPageLoaded = false;
  screen1:boolean=true;
  ForgotPassword:string="";
  edit:boolean=false;
  type: any;
  passmsg: string;
  PasswordText:string="Forgot Password";
  constructor(private router: Router,private formBuilder: FormBuilder,) { 
    this.type = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation()["extras"]["state"] : null;
    if (this.type != null) {
      console.log("type--", this.type['type']);
      if (this.type['type'] == "changePassword"){
        this.edit = true;
        this.ForgotPassword="Change Password";
        this.PasswordText="Change Password";
        this.screen1=false;
      }
    }
  }
  checkPassSame() {
    let pass = this.form2.value.password;
    let passConf = this.form2.value.cpassword;
    console.log(this.pdf.cpassword);
    if(pass == passConf || !this.pdf.cpassword.touched) {
      this.passmsg = "";
      return false;
    }else {
      this.passmsg = "Password did not match.";
      return true;
    }
  }
  ngOnInit(): void {
    this.passwordForm = this.formBuilder.group({
      username: ['', Validators.required]
    });
    this.form2 = this.formBuilder.group({
      otp: ['', [Validators.required,Validators.pattern(APP_CONSTANTS.REGEX.NUMBERS_ONLY)]],
      password: ['', Validators.required],
      cpassword: ['', Validators.required],
      oldPassword: ['', Validators.required],
    });
  }
  get f() {
    return this.passwordForm.controls;
  }
  get pdf(){
    return this.form2.controls;
  }
  getValidate(){
    this.submitted = true;
    if (this.passwordForm.invalid) {
      return;
    }
    // this.screen1=false;
    this.loading = true;
    this.ForgotPassword="Save";
  }
  newPassword(){
    this.submitted = true;
    if (this.form2.invalid) {
      return;
    }
    // this.screen1=false;
    this.loading = true;
    
  }

}
