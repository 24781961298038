<div class="app-content content">
    <div class="content-wrapper">
      <div class="content-body">
        <section id="ordering">
          <div class="row" style="min-height: 650px;max-height: 100%;">

            <div class="col-md-4">
              <mat-card class="card align-items-center ">
                <h5 class="zpC_head">BPS Report</h5>
                 <div> 
                  <mat-expansion-panel *ngFor="let category of questions;">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{category.categoryName}}
                      </mat-panel-title>
                      <span style="float: right;margin-right: 20px;">Score: {{category.score}}</span>
                    </mat-expansion-panel-header>
          
                    <div class="divrow"  *ngFor="let question of category.socialEngagementQuestionsDTOList;let j = index">
                        <h6 class="divleft">{{question.id}}) {{question.question}}</h6>
                        <h6 *ngIf="question.userAnswerDTO != null" class="divleft">{{question.userAnswerDTO.userAnswer != null ? question.userAnswerDTO.userAnswer : "" }}</h6>
                        <div *ngIf="question.userAnswerDTO != null && question.userAnswerDTO.medicationInfoDTOList != null" class="divleft">
                          <table style="width:100%;margin-top: 5px;" class="t1">
                            <tr >
                              <td>Medicine Name</td>
                              <td>Dosage</td>
                              <td>Frequecy</td>
                            </tr>
                      
                            <tr *ngFor="let medication of question.userAnswerDTO.medicationInfoDTOList">
                              <td> {{medication.medicineName}} </td>
                              <td> {{medication.dose}} </td>
                              <td> {{medication.frequency}} </td>
                            </tr>
                      
                          </table>
                        </div>
          
                        <div class="divrow" *ngFor="let subquestion of question.socialEngagementSubQuestionsDTOList;">
                          <h6 class="divleft">{{subquestion.id}}) {{subquestion.subQuestion}}</h6>
                          <h6 *ngIf="subquestion.userAnswerDTO != null" class="divleft">{{subquestion.userAnswerDTO.userAnswer != null ? subquestion.userAnswerDTO.userAnswer : ""}}</h6>
                          <div *ngIf="subquestion.userAnswerDTO != null && subquestion.userAnswerDTO.userActivityDTOList != null" class="divleft">
                            <table style="width:100%;margin-top: 5px;" class="t1">
                              <tr>
                                <td rowspan="2">Activity</td>
                                <td colspan="2">Morning</td>
                                <td colspan="2">Afternoon</td>
                                <td colspan="2">Evening</td>
                              </tr>
                              <tr>
                                <td>From</td>
                                <td>To</td>
                                <td>From</td>
                                <td>To</td>
                                <td>From</td>
                                <td>To</td>
                              </tr>
                            
                              <tr *ngFor="let activity of subquestion.userAnswerDTO.userActivityDTOList">
                                <td> {{activity.activityName}} </td>
                                <td> {{activity.morning.split("-")[0]}} </td>
                                <td> {{activity.morning.split("-")[1]}} </td>
                                <td> {{activity.afternoon.split("-")[0]}} </td>
                                <td> {{activity.afternoon.split("-")[1]}} </td>
                                <td> {{activity.evening.split("-")[0]}} </td>
                                <td> {{activity.evening.split("-")[1]}} </td>
                              </tr>
                        
                            </table>
                          </div>
            
                      </div>
          
                    </div>
          
                  </mat-expansion-panel>
                 </div>
               </mat-card>
            </div>

            <div class="col-md-8">
                  <iframe #iframe allow="camera; microphone" [src]="meetingUrl" width="100%" height="100%"></iframe>
            </div>
          </div>
        </section>
  
      </div>
    </div>
  </div>
  