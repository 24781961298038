<form [formGroup]="form" (ngSubmit)="onSave()">
<div >
    <div *ngIf="medicaldata.type=='allergies'" >
        <label for="allergies">Allergies</label>
        <input type="text" id="allergies" placeholder="Allergies" (keypress)="keyPressAlpha($event)"
         [(ngModel)] = "allergies" placement="top" style="width: 100%;height: 40px;" formControlName="allergy">
         <div class="errors" *ngIf="allergy?.errors?.required && (allergy?.dirty || allergy?.touched)">
          Allergies are required 
        </div>
      </div>
      <div *ngIf="medicaldata.type=='history'" class="form-group">
        <label>Medical history</label>
        <input type="text" placeholder="Medical history" (keypress)="keyPressAlpha($event)"
        [(ngModel)] = "mhistory" placement="top" style="width: 100%;height: 40px;" formControlName="history">
        <div class="errors" *ngIf="history?.errors?.required && (history?.dirty || history?.touched)">
          Medical history is required 
        </div>
      </div>
</div>
<div style="text-align: center;margin: 10px;">
    <button type="button" class="btn btn-danger" (click)="onCancel()">
        Cancel
      </button>
      <button type="submit" style="margin-left: 25px;" class="btn btn-primary" [disabled]="form.invalid">
        Save
      </button>
</div>
</form>