import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/_services/api-service';
import { TableApiService } from 'src/app/_services/table-api.service';

@Component({
  templateUrl: './firstconsultation.component.html',
  styleUrls: ['./firstconsultation.component.css'],
  providers: [ApiService]
})
export class FirstconsultationComponent implements OnInit {
  meetingUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");
  fcData: any;
  questions: any = [];

  constructor(private router:Router,private tableApiservice: TableApiService,private apiservice:ApiService,private sanitizer : DomSanitizer,private location: Location) {
    let data = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation()["extras"]["state"] : null;
      if(data != null){
        console.log("data--",data);
        this.fcData = data;
        this.meetingUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://deveng.elro.ai/startmeeting/"+this.fcData.meetingNo+"/"+this.fcData.meetingPasscode+"/"+JSON.parse(localStorage.getItem('currentUser')).username);
      }
      else
        this.location.back();
        
  }

  ngOnInit() {
    this.apiservice.validateMobile(this.fcData.customerMobile).subscribe((data: any) =>{
      console.log("data--",data)
      if(data != null){
        this.loadBPSassesment(data);
      }
    }, error=>{
      console.log(error);
    });
  }

  loadBPSassesment(personId){
    this.apiservice.getAllQuestions(personId).subscribe((data: any)=>{
        if(data!=null){
          console.log(data)
          this.questions = data; 
         }
      }, error=>{
        console.log(error);
      });
  }

}
