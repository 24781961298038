import { Inject, Optional } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validator, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../_services/api-service';
import { PrescriptionService } from '../_services/prescription.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TeleConsultComponent } from '../components/tele-consult/tele-consult.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog',
  templateUrl: './ecgdialog.component.html',
  styleUrls: ['./ecgdialog.component.scss'],
})

export class EcgDialogComponent implements OnInit {
  dialogtitle: string = "ECG Report";
  ecgimg;
  patientName : string;
  age : string;
  gender : string;
  ecgResult : string;
  personId : string;
  ecgData: any;
  loading : boolean ;

  constructor(private formBuilder: FormBuilder,public dialogRef: MatDialogRef<TeleConsultComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,private spinner: NgxSpinnerService,
    private prescriptionService: PrescriptionService,private apiService:ApiService,private sanitiser: DomSanitizer
  )

   {
    this.ecgData = data.pageValue;

   }

  ngOnInit() {
    this.loadECGdetails();
  }

  loadECGdetails(){
    this.apiService.getReportDetails(this.ecgData.RecordNo).subscribe((res : any)=>{
      console.log("res--",res);
      this.loading = true;
      this.ecgimg = res.ecgImg != null ? "data:image/png;base64,"+ res.ecgImg  : "";
    },error => {
      this.loading = true;
      });
      //this.ecgimg = this.ecgData.EcgImg != null ? "data:image/png;base64,"+this.ecgData.EcgImg : "";
      this.patientName = this.ecgData.name;
      this.age = this.ecgData.age;
      this.ecgResult = this.ecgData.Result;
      this.gender = this.ecgData.gender!= null? this.ecgData.gender:"";
  }

  onCancel(){
    this.dialogRef.close({ event: 'close'});
  }


  onCancelClick(data:any){
    console.log("cancel"+data);
  }

  sanitize(url:string){
    return this.sanitiser.bypassSecurityTrustUrl(url);
  }
  
}
