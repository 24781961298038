import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BookAppointmentComponent } from 'src/app/bookappointment/bookappointment.component';
import { ApiService } from 'src/app/_services/api-service';
import { TableApiService } from 'src/app/_services/table-api.service';
import { AppointmentConsultComponent } from '../appointment-consultation/appointment-consultation.component';
import { map, mergeMap } from 'rxjs/operators';
import { CancelAppointmentDialog } from '../my-appointments/my-appointments.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, interval } from 'rxjs';
@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [ApiService]
})
export class DashboardComponent implements OnInit, OnDestroy {
  @BlockUI('zeroConfiguration') blockUIZeroConfiguration: NgBlockUI;
  @BlockUI('defaultOrdering') blockUIDefaultOrdering: NgBlockUI;

  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY: false, wheelPropagation: false };
  public config: PerfectScrollbarConfigInterface = { suppressScrollY: true };
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;

  options = {
    close: false,
    expand: false,
    minimize: false,
    reload: false
  };
  temp = [];
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  constructor(public datepipe: DatePipe, private snackbar: MatSnackBar, private router: Router, private matDailog: MatDialog, private apiService: ApiService, private spinner: NgxSpinnerService) {

  }
  data: any;
  rows: any = [];
  rows1: any = [];
  rows2: any = [];
  resRows: any = [];
  defaultrows: any;
  DOMrows: any;
  Scrollrows: any;
  public breadcrumb: any;
  temp2 = this.rows;
  temp3 = this.rows1;
  ongingApnmt: any;
  upcomingApnmt: any;
  showThis: boolean = true;
  showThis1: boolean = true;
  doctorName: any;
  dataTableMessage: any = { emptyMessage: 'Loading...' };
  timer: any;
  ngOnInit() {
    //this.doctorName=JSON.parse(localStorage.getItem('currentUser')).username;
    // this.getMyTodayAppointments();
    this.getMyAppointmentRequests();
    this.getUserDetails();
    this.getMyAppointments();

    this.timer = interval(300000).subscribe(
      (val) => { this.getMyAppointments() }
    );
  }

  getUserDetails() {
    this.spinner.show();
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.apiService.getUserDetails(currentUser['username']).subscribe((resData: any) => {
      console.log("res--" + JSON.stringify(resData));
      let userData = resData;
      if (userData != null)
        this.doctorName = userData.given != null ? "Dr. " + userData.given + " " + userData.family : "";
      else
        this.doctorName = "Dr. " + currentUser.username;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      var data = error;
    });
  }

  getMyTodayAppointments() {
    var user = JSON.parse(localStorage.getItem('currentUser'));
    this.apiService.getTodayDoctorAppointments(user['username']).subscribe(res => {
      this.rows = res;
      if (this.rows != '') {
        this.showThis = false;
      }
      this.temp2 = this.rows;
      if (this.rows[0] && this.rows[0].customerMobile) {
        this.getReportDetails(this.rows[0].customerMobile).subscribe(userD => {
          this.ongingApnmt = Object.assign(this.rows[0], userD);
        }
        );
      }
      if (this.rows[1] && this.rows[1].customerMobile) {
        this.getReportDetails(this.rows[1].customerMobile).subscribe(userD => {
          this.upcomingApnmt = Object.assign(this.rows[1], userD);
        }
        );
      }
      this.dataTableMessage = { emptyMessage: 'No data to display' };

    }, error => {

    });
  }

  getMyAppointments() {
    var user = JSON.parse(localStorage.getItem('currentUser'));
    this.spinner.show();
    this.apiService.getDoctorAppointments(user['username']).subscribe(response => {
        console.log("response", response)
        var res = response.filter((a) => {
          return a['cancelledBy'] != "Doctor"
        })

        this.rows2 = res.map(row => {
          var toolTip = "Meet";
          if ((row['consultationtype'] == 'Paid' && row['paymentStatus'] != 'Done')) {
            toolTip = "Payment is not done for the consultation";
          } else if (row.remainingTime >= 900) {
            toolTip = "Meeting can be joined 15 minutes prior to the Scheduled time";
          }
          return {
            ...row,
            toolTip: toolTip,
            showSuccess: (row.remainingTime < 900) && !(row['consultationtype'] == 'Paid' && row['paymentStatus'] != 'Done') ? true : false,
          }
        });
        this.spinner.hide();
        return response;
      },error => {
        this.spinner.hide();
      }
      );
    //   mergeMap(res => {
    //     var array = [];
    //     for (var i = 0; i < res.length; i++) {
    //       array.push(this.getWaitingTime(res[i]["id"]))
    //     }
    //     return forkJoin(array);
    //   })
    // ).subscribe(res => {

    //   var i = 0;
    //   this.rows2 = this.resRows.map((ele) => {
    //     let totalSeconds = parseInt(JSON.stringify(res[i++]));
    //     let seconds = totalSeconds % 60;
    //     let minutes = ((totalSeconds - seconds) / 60) % 60;
    //     // let hours=(totalSeconds/3600)%3600;
    //     let waitingTime = "";
    //     if (totalSeconds == 0) {
    //       waitingTime = waitingTime.concat("-");
    //     } else {
    //       // if(hours>0){
    //       //   waitingTime=waitingTime.concat(hours+" hours ");
    //       // }
    //       if (minutes > 0) {
    //         waitingTime = waitingTime.concat(minutes + " minutes ");
    //       }
    //       if (seconds > 0) {
    //         waitingTime = waitingTime.concat(seconds + " seconds ");
    //       }
    //     }
    //     return {
    //       ...ele, waitingTime: waitingTime
    //     }
    //   })
    //   // this.rows2=this.rows2.filter((a)=>{
    //   //   return  a['cancelledBy'] != "Doctor"
    //   // })
    //  
  }
  // getWaitingTime(appointmentId) {
  //   return this.apiService.getWaitingTime(appointmentId);
  // }
  getReportDetails(mobileno) {
    return this.apiService.getReportdetails('', mobileno).pipe(
      map(((resData: any) => {
        let user: any = {};
        if (resData != null) {
          user.patientName = resData.name;
          user.patientNumber = resData.mobile;
          user.personId = resData.personId;

          //user.bloodGroup=resData.profile.bloodType;
          user.weight = resData.weight != null ? resData.weight + " kg" : "";
          user.height = resData.height != null ? resData.height + " cm" : "";
          user.age = resData.age;
          user.gender = resData.gender;


          user.Aadhar = resData.aadhaar;


          user.tempCapturedTime = resData.tempMeasureTime != null ? this.getFancyTime(resData.tempMeasureTime.replace("T", " ")) + " ago" : "";
          user.pulseCapturedTime = resData.boMeasureTime != null ? this.getFancyTime(resData.boMeasureTime.replace("T", " ")) + " ago" : "";
          user.spO2CapturedTime = resData.boMeasureTime != null ? this.getFancyTime(resData.boMeasureTime.replace("T", " ")) + " ago" : "";

          user.glucoCapturedTime = resData.bsMeasureTime != null ? this.getFancyTime(resData.bsMeasureTime.replace("T", " ")) + " ago" : "";
          user.bpCapturedTime = resData.bpMeasureTime != null ? this.getFancyTime(resData.bpMeasureTime.replace("T", " ")) + " ago" : "";
          user.bmiCapturedTime = resData.uhMeasureTime != null ? this.getFancyTime(resData.uhMeasureTime.replace("T", " ")) + " ago" : "";

          user.hwCapturedTime = resData.uhMeasureTime != null ? this.getFancyTime(resData.uhMeasureTime.replace("T", " ")) + " ago" : "";
          user.ecgCapturedTime = resData.ecgMeasureTime != null ? this.getFancyTime(resData.ecgMeasureTime.replace("T", " ")) + " ago" : "";
          user.cholestolCapturedTime = resData.colMeasureTime != null ? this.getFancyTime(resData.colMeasureTime.replace("T", " ")) + " ago" : "";
          user.uricacidCapturedTime = resData.uaMeasureTime != null ? this.getFancyTime(resData.uaMeasureTime.replace("T", " ")) + " ago" : "";
          user.urinanalysisCapturedTime = resData.uraMeasureTime != null ? this.getFancyTime(resData.uraMeasureTime.replace("T", " ")) + " ago" : "";
          user.himoglobinCapturedTime = resData.hbMeasureTime != null ? this.getFancyTime(resData.hbMeasureTime.replace("T", " ")) + " ago" : "";
          user.bloodPressure = resData.highPressure != null ? resData.highPressure + "/" + resData.lowPressure + " mmHg" : "--";

          user.ecgResult = resData.ecgResult != null ? resData.ecgResult : "-";
          user.cholestrol = resData.chol != null && resData.chol != "" ? resData.chol : "--";
          user.uricacid = resData.ua != null && resData.ua != "" ? resData.ua : "--";
          user.totalcholestrol = resData.tchol && resData.tchol != "" != null ? resData.tchol : "--";

          user.triglyceride = resData.trig != null && resData.trig != "" ? resData.trig : "--";
          user.ldlipoprotein = resData.tcHdl != null && resData.tcHdl != "" ? resData.tcHdl : "--";
          user.hdlipoprotein = resData.hdlChol != null && resData.hdlChol != "" ? resData.hdlChol : "--";
          user.bmi = resData.bmi != null && resData.bmi != "" ? Math.round(resData.bmi * 100 + Number.EPSILON) / 100 : "--";
          user.temperature = resData.temperature != null && resData.temperature != "" ? resData.temperature + " \xB0F" : "--";
          user.pulse = resData.bpm != null && resData.bpm != "" ? resData.bpm + " bpm" : "--";

          user.spO2 = resData.oxygen && resData.oxygen != "" != null ? resData.oxygen + " %" : "--";
          user.bloodGlucose = resData.bloodSugar != null && resData.bloodSugar != "" ? resData.bloodSugar + " mg/dL" : "--";
          user.urinanalysis = resData.uro != null && resData.uro != "" ? resData.uro : "--";
          user.himoglobin = resData.hb != null ? Math.round(resData.hb * 100 + Number.EPSILON) + " g/dL" : "--";
          if (user.pulse == "--")
            user.pulseCapturedTime = ""
          if (user.height == "--")
            user.hwCapturedTime = ""
          if (user.spO2 == "--")
            user.spO2CapturedTime = ""
          if (user.bloodGlucose == "--")
            user.glucoCapturedTime = ""
          if (user.urinanalysis == "--")
            user.urinanalysisCapturedTime = ""
          if (user.himoglobin == "--")
            user.himoglobinCapturedTime = ""
          if (user.temperature == "--")
            user.tempCapturedTime = ""
          if (user.bloodPressure == "--")
            user.bpCapturedTime = ""
          if (user.bmi == "--")
            user.bmiCapturedTime = ""



          if (resData['userHealthDetailsDTO'] != null) {

            let data = resData.userHealthDetailsDTO;
            user.bloodGroupUpdatedDate = data.bloodGroupUpdatedDate != null ? this.getFancyTime(data.bloodGroupUpdatedDate) + " ago" : " ";
            user.medicalHistoryUpdatedDate = data.medicalHistoryUpdatedDate != null ? this.getFancyTime(data.medicalHistoryUpdatedDate) + " ago" : " ";
            user.lmpUpdatedDate = data.lmpUpdatedDate != null ? this.getFancyTime(data.lmpUpdatedDate) + " ago" : " ";
            user.thyroidUpdatedDate = data.thyroidUpdatedDate != null ? this.getFancyTime(data.thyroidUpdatedDate) + " ago" : " ";
            user.diabeticUpdatedDate = data.diabeticUpdatedDate != null ? this.getFancyTime(data.diabeticUpdatedDate) + " ago" : " ";
            user.allergiesUpdatedDate = data.allergiesUpdatedDate != null ? this.getFancyTime(data.allergiesUpdatedDate) + " ago" : " ";
            user.bloodPressureUpdatedDate = data.bloodPressureUpdatedDate != null ? this.getFancyTime(data.bloodPressureUpdatedDate) + " ago" : " ";

            user.healthdata = resData['userHealthDetailsDTO'];
            console.log("blood--", resData['userHealthDetailsDTO']['bloodGroup']);
            user.bloodGroup = resData['userHealthDetailsDTO']['bloodGroup'] + " ve";
            user.diabeticsince = resData['userHealthDetailsDTO']['diabeticSince'] != null && user.healthdata.diabeticSince != "" ? " - " + resData['userHealthDetailsDTO']['diabeticSince'] : "";
            user.thyroidsince = resData['userHealthDetailsDTO']['thyroidSince'] != null && user.healthdata.thyroidSince != "" ? " - " + resData['userHealthDetailsDTO']['thyroidSince'] : "";
            user.bpsince = resData['userHealthDetailsDTO']['bloodPressureSince'] != null && user.healthdata.bloodPressureSince != "" ? " - " + resData['userHealthDetailsDTO']['bloodPressureSince'] : "";
            user.allergies = resData['userHealthDetailsDTO']['allergies'] != null ? resData['userHealthDetailsDTO']['allergies'] : "-";
            if (resData['userHealthDetailsDTO']['diabetic'] != null) {
              user.diabetic = this.stringToBoolean(resData['userHealthDetailsDTO']['diabetic']) ? "Yes" : "No";
            }
            if (resData['userHealthDetailsDTO']['bloodPressure'] != null) {
              user.bp = this.stringToBoolean(resData['userHealthDetailsDTO']['bloodPressure']) ? "Yes" : "No";
            }
            else {
              user.bp = "";
            }
            if (resData['userHealthDetailsDTO']['thyroid'] != null) {
              user.thyroid = this.stringToBoolean(resData['userHealthDetailsDTO']['thyroid']) ? "Yes" : "No";
            }
            user.diseases = resData['userHealthDetailsDTO']['medicalHistory'] != null ? resData['userHealthDetailsDTO']['medicalHistory'] : "-";

          }

        }
        return user;
      })));
  }

  getMyAppointmentRequests() {
    var user = JSON.parse(localStorage.getItem('currentUser'));
    this.apiService.getMyAppointmentRequests(user['username']).subscribe(res => {
      this.rows1 = res;
      if (this.rows1.length != 0) {
        this.showThis1 = false;
      }
      this.temp3 = this.rows1;
      this.dataTableMessage = { emptyMessage: 'No data to display' };
    }, error => {

    });
  }

  getTabledata() {
    this.rows = this.data.rows;
    this.defaultrows = this.data.defaultrows;
    this.DOMrows = this.data.DOMrows;
    this.Scrollrows = this.data.Scrollrows;
  }

  startConsultation(row) {
    if (!(new Date().getTime() + 900000 > new Date(row.startTime).getTime()) || (row['consultationtype'] == 'Paid' && row['paymentStatus'] != 'Done')) {
      return false;
    }
    clearInterval(this.timer);
    // this.router.navigate(['/appointment-consult'], { state: row });
    const dialogRef = this.matDailog.open(AppointmentConsultComponent, {
      width: '95%',
      height: '95%',
      maxWidth: '120vw',
      disableClose: true,
      hasBackdrop: true,
      data: { pageValue: row }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMyAppointments();
      this.timer = interval(300000).subscribe(
        (val) => { this.getMyAppointments() }
      );
      console.log('The dialog was closed', result);
    });
  }

  singlePatientView(row) {
    this.router.navigate(['/single-patient'], { state: { mobile: row.customerMobile } });
  }

  sendMessageToPatient(row) {
    const dialogRef = this.matDailog.open(MessageToPatientDialog, {
      width: '50%',
      disableClose: true,
      hasBackdrop: true,
      data: { pageValue: "" }
    });
    dialogRef.afterClosed(); {
      console.log('The dialog was closed');
    }
  }

  cancelConsultation(row) {
    const dialogRef = this.matDailog.open(CancelAppointmentDialog, {
      width: '50%',
      disableClose: true,
      hasBackdrop: true,
      data: { pageValue: "" }
    });

    dialogRef.afterClosed().subscribe(result => {
      let followupDTO: any = {};
      followupDTO.name = row.customerName;
      followupDTO.contact = row.customerMobile;
      followupDTO.appointmentDate = row.startTime;
      followupDTO.appointmentId = row.id;
      followupDTO.category = "Appointments";
      followupDTO.reason = result.data + " by Dr." + JSON.parse(localStorage.getItem('currentUser')).username;

      console.log('The dialog was closed', result);
      if (result.data != null) {
        this.spinner.show();
        this.apiService.cancelAppointmentRequest(followupDTO).subscribe((resData: any) => {
          console.log("res--" + JSON.stringify(resData));
          row.cancelledBy = "Doctor";
          delete row.toolTip;
          delete row.showSuccess;
          delete row.waitingTime;
          this.apiService.rescheduleAppointment(row).subscribe((resData: any) => {
            console.log("res--" + JSON.stringify(resData));
            this.snackbar.open('Your appointment cancellation request has been sent successfully.', "", { duration: 2000 });
            this.spinner.hide();
            this.getMyAppointments();
          }, error => {
            this.spinner.hide();
            var data = error;
          });
        }, error => {
          this.spinner.hide();
          var data = error;
        });
      }
    });
  }

  bookAppointment(row: any) {
    const dialogRef = this.matDailog.open(BookAppointmentComponent, {
      width: '70%',
      disableClose: true,
      hasBackdrop: true,
      autoFocus: false,
      maxHeight: '90vh',
      data: { pageValue: null }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result.event == 'refresh') {

        this.getMyTodayAppointments();
        this.getMyAppointmentRequests();
        this.getMyAppointments();
      }

    });
  }


  acceptConsultation(row) {
    row['status'] = "SCHEDULED";
    console.log("params--" + JSON.stringify(row));
    this.apiService.acceptAppointment(row).subscribe((resData: any) => {
      console.log("res--" + JSON.stringify(resData));
      this.getMyTodayAppointments();
      this.getMyAppointmentRequests();
      this.getMyAppointments();
    }, error => {
      var data = error;
    });
  }

  getBGStatus(i) {
    const bgs = ["secondary", "danger", "success", "primary", "info", "warning"];
    return bgs[(i + 10) % 6];
  }

  reloadZeroConfiguration() {
    this.blockUIZeroConfiguration.start('Loading..');

    setTimeout(() => {
      this.blockUIZeroConfiguration.stop();
    }, 2500);
  }

  reloadDefaultOrdering() {
    this.blockUIDefaultOrdering.start('Loading..');

    setTimeout(() => {
      this.blockUIDefaultOrdering.stop();
    }, 2500);
  }

  getFancyTime(date): string {
    var date1 = new Date(date).getTime();
    var date2 = new Date().getTime();
    var msec = date2 - date1;
    var mins = Math.floor(msec / 60000);
    var hrs = Math.floor(mins / 60);
    var days = Math.floor(hrs / 24);
    mins = mins % 60;
    hrs = hrs % 24;
    days = days % 365;
    if (days >= 1)
      return days == 1 ? days.toString() + " day" : days.toString() + " days";
    else if (days < 1 && hrs >= 1)
      return hrs == 1 ? hrs.toString() + " hour" : hrs.toString() + " hours";
    else
      return mins == 1 ? mins.toString() + " min" : mins.toString() + " mins";
  }

  private stringToBoolean(stringValue: string): boolean | undefined {
    try {
      return JSON.parse(stringValue);
    }
    catch (e) {
      return undefined;
    }
  }
  ngOnDestroy() {
    clearInterval(this.timer);
  }

}


@Component({
  templateUrl: 'messagetopatient.html',
  styleUrls: ['dashboard.component.css']
})
export class MessageToPatientDialog {
  message: string;
  constructor(private snackbar: MatSnackBar, private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<MessageToPatientDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }
  form: FormGroup = this.formBuilder.group({
    msg: ['', [Validators.required]],
  });
  get msg() {
    return this.form.get('msg');
  }
  keyPressAlpha(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSend() {
    this.dialogRef.close({ event: 'close', data: this.message });
    this.snackbar.open('Message sent successfully', "", { duration: 2000 });
    console.log("MessageToPatient :", this.message)
  }
}