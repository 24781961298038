<h2 style="text-align: center;" mat-dialog-title>{{dialogtitle }}</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="formgroup" novalidate>

    <div >

        <mat-form-field appearance="fill" >
          <mat-label style="padding-left: 10px;">Doctor</mat-label>
          <mat-select formControlName="doctor" [(ngModel)]="doctor">
            <mat-option *ngFor="let doctor of doctorsList" value="{{doctor.id}}">{{doctor.username}}{{doctor.doctorQualification!= null ? ", "+doctor.doctorQualification : ""}}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- <mat-form-field appearance="fill">
          <mat-label style="padding-left: 10px;">Doctor</mat-label>
                    <input type="text"
                          matInput
                          [matAutocomplete]="auto"
                          formControlName="doctor"
                          [(ngModel)]="doctor"
                          (change)="change(doctor)"
                          (ngModelChange)="ngModelChange($event)">
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option *ngFor="let doctor of doctorsList"
                            (onSelectionChange)="onSelectionChange($event)"
                            [value]="doctor.username">
                        {{doctor.username}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field> -->

        <mat-form-field appearance="fill" >
        
          <mat-label style="padding-left: 10px;">Reason for Transfer</mat-label>
          <textarea matInput placeholder="Reason for Transfer" style="width: 100%;"
            type="text" formControlName="reason" [(ngModel)]="reason" maxlength="500"></textarea>
        </mat-form-field>   

        <div style="text-align: center;"><br/>
          <button class="btn btn-primary" (click)="onSave()" type="submit" [disabled]="!formgroup.valid">
            Submit
          </button>
          <button class="btn btn-danger" style="margin-left: 40px;" (click)="onCancel()">
            Cancel
          </button>
        </div> 
      
        </div>
        </form>
</mat-dialog-content>

