import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VideoSDKMeeting } from '@videosdk.live/rtc-js-prebuilt';
import { environment } from '../../environments/environment';

@Component({
  selector: 'videosdk',
  templateUrl: './videosdkmeeting.component.html',
  styleUrls: ['./videosdkmeeting.component.css']
})
export class VideoSdkMeetingComponent implements OnInit {
  userName: any;
  @Input() meetingId: string = ""

  constructor( @Inject(DOCUMENT) document,private route: ActivatedRoute) {
    this.userName = JSON.parse(localStorage.getItem('currentUser')).username;
  }

  async ngOnInit() {
    const apiKey = environment.VIDEOSDK_API_KEY;
    const appurl = environment.apiUrl;
    const config = {
      name: this.userName,
      meetingId: this.meetingId,
      apiKey: apiKey,

      containerId: "videosdk",
      redirectOnLeave: appurl+"dashboard",

      micEnabled: false,
      webcamEnabled: false,
      participantCanToggleSelfWebcam: true,
      participantCanToggleSelfMic: true,

      chatEnabled: false,
      screenShareEnabled: false,
      pollEnabled: false,
      whiteboardEnabled: false,
      raiseHandEnabled: false,

      recordingEnabled: false,
      recordingEnabledByDefault: false,
      // recordingWebhookUrl: 'https://www.videosdk.live/callback',
      // recordingAWSDirPath: `/meeting-recordings/${this.meetingId}/`, // automatically save recording in this s3 path

      brandingEnabled: false,
      brandLogoURL: 'https://picsum.photos/200',
      brandName: 'ELRO',

      participantCanLeave: true, // if false, leave button won't be visible

      livestream: {
        autoStart: true,
        outputs: [
          // {
          //   url: "rtmp://x.rtmp.youtube.com/live2",
          //   streamKey: "<STREAM KEY FROM YOUTUBE>",
          // },
        ],
      },

      permissions: {
        askToJoin: false, // Ask joined participants for entry in meeting
        toggleParticipantMic: true, // Can toggle other participant's mic
        toggleParticipantWebcam: true, // Can toggle other participant's webcam
        removeParticipant: true, // Remove other participant from meeting
        endMeeting: true, // End meeting for all participant
        drawOnWhiteboard: true, // Can Draw on whiteboard
        toggleWhiteboard: true, // Can toggle whiteboard
        toggleRecording: true, // Can toggle recording
      },

      pin: {
        allowed: true, // participant can pin any participant in meeting
        layout: 'SPOTLIGHT', // meeting layout - GRID | SPOTLIGHT | SIDEBAR
      },

      leftScreen: {
        // visible when redirect on leave not provieded
        actionButton: {
          // optional action button
          label: 'Video SDK Live', // action button label
        },
      },
    };

    const meeting = new VideoSDKMeeting();
    meeting.init(config);
  }
}
