import { NgModule } from '@angular/core';
import {
    BrowserModule,
    HAMMER_GESTURE_CONFIG,
    HammerGestureConfig
} from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {
    NgbModule,
    NgbCarouselConfig,
    NgbModalConfig
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AuthGuard } from './_guards/auth.guard';
import { AlertComponent } from './_helpers/alert.component';
import { AlertService } from './_services/alert.service';
import { AuthService } from './_services/auth.service';
import { ChartApiService } from './_services/chart.api';
import { TableApiService } from './_services/table-api.service';
import { ApplicationApiService } from './_services/application-api.service';
import { QuillInitializeServiceService } from './_services/quill-initialize-service.service';

// Routing
import { routing } from './app.routing';
// Components
import { AppComponent } from './app.component';
import { SettingsModule } from './_layout/settings/settings.module';
import { ThemeSettingsConfig } from './_layout/settings/theme-settings.config';
import { MenuSettingsConfig } from './_layout/settings/menu-settings.config';
import { HeaderComponent } from './_layout/header/header.component';
import { VerticalComponent as HeaderVerticalComponent, VerticalComponent } from './_layout/header/vertical/vertical.component';
import { HorizontalComponent as HeaderHorizontalComponent } from './_layout/header/horizontal/horizontal.component';
import { FullLayoutNavbarComponent } from './_layout/header/full-layout-navbar/full-layout-navbar.component';
import { FooterComponent } from './_layout/footer/footer.component';
import { NavigationComponent as AppNavigationComponent } from './_layout/navigation/navigation.component';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { RegisterComponent } from './register';
import { SocialSigninComponent } from './social-signin/social-signin.component';
import { LoginComponent } from './login';
import { ChangelogComponent } from './changelog/changelog.component';
import { NavbarService } from './_services/navbar.service';
import { VerticalnavComponent } from './_layout/navigation/verticalnav/verticalnav.component';
import { HorizontalnavComponent } from './_layout/navigation/horizontalnav/horizontalnav.component';
// perfect scroll bar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// spinner
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { RouterModule } from '@angular/router';
import { CustomizerComponent } from './_layout/customizer/customizer.component';
import { ChartsModule } from 'ng2-charts';
import { PartialsModule } from './content/partials/partials.module';
import { BreadcrumbModule } from './_layout/breadcrumb/breadcrumb.module';
import { HorizontalCustomizerComponent } from './_layout/customizer/horizontal-customizer/horizontal-customizer.component';
import { BlockTemplateComponent } from './_layout/blockui/block-template.component';
import { BlockUIModule } from 'ng-block-ui';
import { MatchHeightModule } from './content/partials/general/match-height/match-height.module';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';
import { ToastrModule } from 'ngx-toastr';
import { UserService } from './_api/user/user.service';
import { PrivacyPolicyComponent } from './login/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './login/terms-condition/terms-condition.component';
import { JwtService } from './_services/jwt.service';
import { NumberDirective } from './_directives/numbers-only.directive';
import { DemoMaterialModule } from './material-module';
import { AllergiesorMedicalhistoryDialog, PrescriptionNoteDialog, TeleConsultComponent } from './components/tele-consult/tele-consult.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MessagingService } from './_services/messaging.service';
import { NotificationsComponent } from './notifications/notifications.component';
import { NgxAgoraModule } from 'ngx-agora';
import { AgoraComponent } from './agora';
import { MyPatientsComponent } from './components/my-patients/my-patients.component'
    ;
import { ConsultationsComponent } from './components/consultations/consultations.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ConfirmationDialogComponent1 } from './conform-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './conform-dialog/confirmation-dialog.service';
import { CallTransferDialogComponent } from './calltransfer/calltransfer.component';
import { ChartistModule } from 'ng-chartist';
import { PrescriptionpdfComponent } from './prescriptionpdf/prescriptionpdf.component';
import { ApiService } from './_services/api-service';
import { EcgDialogComponent } from './ecgdilaog/ecgdialog.component';;
import { SinglePatientviewComponent } from './single-patientview/single-patientview.component'
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessaging, AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { CallEndComponent } from './callend/callend.component';
import { CancelAppointmentDialog, MyApointmentsComponent } from './components/my-appointments/my-appointments.component';
import { FirstconsultationComponent } from './components/firstconsultation/firstconsultation.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { SchedulerModule } from 'angular-calendar-scheduler';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { MyScheduleComponent } from './components/my-schedule/my-schedule.component';
import { AppointmentConsultComponent, ACPrescriptionNoteDialog, DrugsInteractionDialog } from './components/appointment-consultation/appointment-consultation.component';
import { CommonModule, DatePipe } from '@angular/common';
import { BookAppointmentComponent } from './bookappointment/bookappointment.component';
import { ReportsUploadDialogComponent } from './reportsupload/reportsupload.component';
import { AddPrescriptionDialogComponent } from './addprescription/addprescription.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BlockScheduleComponent } from './blockschedule/blockschedule.component';
import { AngularDayjsModule } from 'angular-dayjs';
import { DatetimerangepickerModule } from "angular-datetimerangepicker";
import { AvailableBlockStatusComponent } from './availableblockstatus/availableblockstatus.component';
import { VideoSdkMeetingComponent } from './videosdkmeeting/videosdkmeeting.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';;
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { DashboardComponent, MessageToPatientDialog } from './components/dashboard/dashboard.component';;
import { AppointmentHistoryComponent } from './components/appointment-history/appointment-history.component'

export function momentAdapterFactory() {
    return adapterFactory(moment);
};

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        PartialsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ChartsModule,
        BrowserAnimationsModule,
        MatchHeightModule,
        BreadcrumbModule,
        NgbModule,
        FormsModule,
        NgSelectModule,
        AngularFireModule.initializeApp(environment.firebase),
        NgxAgoraModule.forRoot({ AppID: environment.agora.appId }),
        AngularFireAuthModule, // imports firebase/auth, only needed for auth features
        AngularFireDatabaseModule,
        AngularFireMessagingModule,
        routing,
        // Settings modules
        SettingsModule.forRoot(ThemeSettingsConfig, MenuSettingsConfig),
        PerfectScrollbarModule,
        ToastrModule.forRoot(),
        NgxSpinnerModule,
        DeviceDetectorModule.forRoot(),
        LoadingBarRouterModule,
        NgxDatatableModule,
        ChartistModule,
        DemoMaterialModule,
        BlockUIModule.forRoot({
            template: BlockTemplateComponent
        }),
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
        SchedulerModule.forRoot({ locale: 'en', headerDateFormat: 'daysRange' }),
        AngularDayjsModule,
        DatetimerangepickerModule


    ],
    declarations: [
        AppComponent,
        PublicLayoutComponent,
        PrivateLayoutComponent,
        HeaderComponent,
        FullLayoutNavbarComponent,
        HeaderHorizontalComponent,
        HeaderVerticalComponent,
        FooterComponent,
        AppNavigationComponent,
        AlertComponent,
        RegisterComponent,
        SocialSigninComponent,
        LoginComponent,
        ChangelogComponent,
        VerticalnavComponent,
        HorizontalnavComponent,
        CustomizerComponent,
        HorizontalCustomizerComponent,
        BlockTemplateComponent,
        FullLayoutComponent,
        PrivacyPolicyComponent,
        TermsConditionComponent,
        NumberDirective,
        TeleConsultComponent,
        AgoraComponent,
        ConfirmationDialogComponent,
        PrescriptionpdfComponent,
        NotificationsComponent,
        MyPatientsComponent,
        ConfirmationDialogComponent1,
        CallTransferDialogComponent,
        EcgDialogComponent,
        ConsultationsComponent,
        AllergiesorMedicalhistoryDialog,
        CallEndComponent,
        MyApointmentsComponent,
        FirstconsultationComponent,
        SinglePatientviewComponent,
        AppointmentConsultComponent,
        CancelAppointmentDialog,
        MessageToPatientDialog,
        MyScheduleComponent,
        DashboardComponent,
        BookAppointmentComponent,
        ReportsUploadDialogComponent,
        AddPrescriptionDialogComponent,
        VerticalComponent,
        BlockScheduleComponent,
        AvailableBlockStatusComponent,
        VideoSdkMeetingComponent,
        ConfirmDialogComponent,
        ForgotPasswordComponent,
        PrescriptionNoteDialog,
        ACPrescriptionNoteDialog,
        DrugsInteractionDialog,
        AppointmentHistoryComponent
    ],
    providers: [
        AuthGuard,
        ChartApiService,
        AlertService,
        NavbarService,
        TableApiService,
        ApplicationApiService,
        QuillInitializeServiceService,
        AuthService,
        MessagingService,
        AngularFireMessaging,
        ConfirmationDialogService,
        ApiService,
        UserService,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfig
        },
        NgbCarouselConfig,
        NgbModalConfig,
        JwtService,
        DatePipe
    ],
    bootstrap: [AppComponent],
    exports: [RouterModule]
})
export class AppModule { }