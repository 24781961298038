import { Component, OnInit, ViewChild, LOCALE_ID, Inject, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ApiService } from 'src/app/_services/api-service';
import { CalendarDateFormatter, CalendarView, DateAdapter } from 'angular-calendar';
import { Subject } from 'rxjs';
import { endOfDay, addMonths, setMinutes, setHours } from 'date-fns';
import { addPeriod, CalendarSchedulerEvent, CalendarSchedulerEventAction, CalendarSchedulerViewComponent, DAYS_IN_WEEK, endOfPeriod, SchedulerDateFormatter, SchedulerEventTimesChangedEvent, SchedulerViewDay, SchedulerViewHour, SchedulerViewHourSegment, startOfPeriod, subPeriod } from 'angular-calendar-scheduler';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CancelAppointmentDialog } from '../my-appointments/my-appointments.component';
import { BlockScheduleComponent } from 'src/app/blockschedule/blockschedule.component';
import { map } from 'rxjs/operators'
import { callbackify } from 'util';
import {  AvailableBlockStatusComponent } from 'src/app/availableblockstatus/availableblockstatus.component';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

export class timevalues {
  startTime:string;
  endTime:string;
}
@Component({
  templateUrl: './my-schedule.component.html',
  styleUrls: ['./my-schedule.component.css'],
  providers: [{
    provide: CalendarDateFormatter,
    useClass: SchedulerDateFormatter
  }]
})
export class MyScheduleComponent implements OnInit {
  @BlockUI('zeroConfiguration') blockUIZeroConfiguration: NgBlockUI;
  @BlockUI('defaultOrdering') blockUIDefaultOrdering: NgBlockUI;

  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY: false, wheelPropagation: false };
  public config: PerfectScrollbarConfigInterface = { suppressScrollY: true };
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;

  options = {
    close: false,
    expand: true,
    minimize: false,
    reload: false
  };
  temp:any;
  rows:any=[];
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;

  CalendarView = CalendarView;

  view: CalendarView = CalendarView.Week;
  viewDate: Date = new Date();
  viewDays: number = DAYS_IN_WEEK;
  refresh: Subject<any> = new Subject();
  locale: string = 'en';
  hourSegments: number = 4;
  weekStartsOn: number = 1;
  startsWithToday: boolean = true;
  activeDayIsOpen: boolean = true;
  excludeDays: number[] = []; // [0];
  weekendDays: number[] = [0, 6];
  doctorsList = [];
  dayStartHour: number = 0;
  dayEndHour: number = 24;
  dataTableMessage: any = { emptyMessage: 'Loading...' };
  minDate: Date = new Date();
  maxDate: Date = endOfDay(addMonths(new Date(), 1));
  editId:any;
  isServiceEnded=false;
  dayModifier: Function;
  hourModifier: Function;
  segmentModifier: Function;
  eventModifier: Function;
  prevBtnDisabled: boolean = false;
  nextBtnDisabled: boolean = false;

  datetimerange:any;
  doctorId:any;
  username:string;
  public timeList:Array<timevalues>=[];

  actions: CalendarSchedulerEventAction[] = [
    {
      when: 'enabled',
      label: '<span class="valign-center"><i class="material-icons md-18 md-red-500">cancel</i></span>',
      title: 'Cancel',
      onClick: (event: CalendarSchedulerEvent): void => {
        console.log('Pressed action \'Delete\' on event ' + event.id);
        this.deleteEvent(event.id);
      }
    }
  ];

  events: CalendarSchedulerEvent[] = [];
  availability = [];
  duration;
  slotAvailability = {
    'MONDAY': {
      night: { start: '', end: '' },
      evening: { start: '', end: '' },
      noon: { start: '', end: '' },
      morning: { start: '', end: '' }
    }, 'TUESDAY': {
      night: { start: '', end: '' },
      evening: { start: '', end: '' },
      noon: { start: '', end: '' },
      morning: { start: '', end: '' }
    }, 'WEDNESDAY': {
      night: { start: '', end: '' },
      evening: { start: '', end: '' },
      noon: { start: '', end: '' },
      morning: { start: '', end: '' }
    }, 'THURSDAY': {
      night: { start: '', end: '' },
      evening: { start: '', end: '' },
      noon: { start: '', end: '' },
      morning: { start: '', end: '' }
    }, 'FRIDAY': {
      night: { start: '', end: '' },
      evening: { start: '', end: '' },
      noon: { start: '', end: '' },
      morning: { start: '', end: '' }
    }, 'SATURDAY': {
      night: { start: '', end: '' },
      evening: { start: '', end: '' },
      noon: { start: '', end: '' },
      morning: { start: '', end: '' }
    }, 'SUNDAY': {
      night: { start: '', end: '' },
      evening: { start: '', end: '' },
      noon: { start: '', end: '' },
      morning: { start: '', end: '' }
    }
  }
  keys = [];
  @ViewChild(CalendarSchedulerViewComponent) calendarScheduler: CalendarSchedulerViewComponent;
  @ViewChild('alertDialog') alertDialog: TemplateRef<any>;
  eventId: any;

  constructor( private apiservice: ApiService,private matDailog: MatDialog,private snackbar: MatSnackBar,private router: Router, private dialog: MatDialog, @Inject(LOCALE_ID) locale: string, private apiService: ApiService, private dateAdapter: DateAdapter,private spinner: NgxSpinnerService) {
    this.locale = locale;
    var user = JSON.parse(localStorage.getItem('currentUser'));
    
    this.getBlockedRecords();
    this.getDoctorDetails();
  }

  ngOnInit() {
    this.loadAppointments();
    // this.loadAvailability();
    // this.getBlockedRecords();
  }
  getDoctorDetails()
  {
    this.spinner.show();
      this.apiService.getUserDetails(JSON.parse(localStorage.getItem('currentUser')).username).subscribe((resData: any) => {
        console.log(resData);
        this.temp=resData;
        this.spinner.hide();
      }, error => {
        var data = error;
      });
  }
  getBlockedRecords(){
   /* var user = JSON.parse(localStorage.getItem('currentUser'));
    this.apiService.getDoctorBlockSlots(user['username']).subscribe(res => {
      console.log("res=",res);
      this.rows = res;
      this.dataTableMessage = { emptyMessage: 'No data to display' }
    }, error => {
    });*/
    var user = JSON.parse(localStorage.getItem('currentUser'));
    this.apiService.getDoctorBlockSlotsPromise(user['username'])
    .then(res=>{
      this.rows=res;
      this.dataTableMessage = { emptyMessage: 'No data to display' }
      this.dayModifier = ((day: SchedulerViewDay): void => {
        if (!this.isDateValid(day.date,this.rows)) {
          day.cssClass = 'cal-disabled';
        }
      }).bind(this);
  
      this.hourModifier = ((hour: SchedulerViewHour): void => {
        if (!this.isDateValid(hour.date,this.rows)) {
          hour.cssClass = 'cal-disabled';
        }
      }).bind(this);
  
      this.segmentModifier = ((segment: SchedulerViewHourSegment): void => {
        if (!this.isDateValid(segment.date,this.rows)) {
          segment.isDisabled = true;
        }
      }).bind(this);
  
      this.eventModifier = ((event: CalendarSchedulerEvent): void => {
        event.isDisabled = !this.isDateValid(event.start,this.rows);
      }).bind(this);
  
      this.dateOrViewChanged();
      this.refresh.next();
    })
  }
  // loadAvailability() {
  //   var user = JSON.parse(localStorage.getItem('currentUser'));
  //   this.apiService.getDoctorAvailabiity(user['username']).subscribe(res => {
  //     this.availability = []
  //     res.forEach(apnmt => {
  //       this.availability.push(apnmt);
  //       this.slotAvailability[apnmt.dayOfWeek][apnmt.session.toLowerCase()]['start'] = apnmt.startTime;
  //       this.slotAvailability[apnmt.dayOfWeek][apnmt.session.toLowerCase()]['end'] = apnmt.endTime;
  //     });
  //     this.keys = Object.keys(this.slotAvailability);
  //     this.duration = this.availability && this.availability.length > 0 ? this.availability[0].duration : 30;
  //   });
  // }

  loadAppointments() {
    var user = JSON.parse(localStorage.getItem('currentUser'));
    this.apiService.getDoctorAppointments(user['username']).subscribe(res => {
      this.events = []
      res.forEach(apnmt => {
        let start = new Date(new Date(apnmt.startTime));
        let end = new Date(new Date(apnmt.startTime).setTime(new Date(apnmt.startTime).getTime() + apnmt.duration * 60 * 1000));
        let isDisabled = moment(start).isBefore(new Date());
        this.events.push(<CalendarSchedulerEvent>{
          id: apnmt.id,
          start: start,
          end: end,
          title: apnmt.customerName,
          content: apnmt.topic,
          color: !isDisabled ? {
            primary: '#1e90ff',
            secondary: '#D1E8FF',
          } : {
            primary: '#34495E',
            secondary: '#ABB2B9',
          },
          actions: !isDisabled ? this.actions : [],
          // status?: CalendarSchedulerEventStatus.;
          // cssClass?: string;
          // isDisabled?: boolean;
          isClickable: true,
          // isCancelled?: boolean;
          draggable: false,
          resizable: {
            beforeStart: false,
            afterEnd: false
          }
        })
      });
      this.refresh.next();
    }, error => {

    });
  }

  saveAvailability() {
    const sessions = ['morning', 'noon', 'evening', 'night'];
    let avialability = [];
    var user = JSON.parse(localStorage.getItem('currentUser'));
    for (const dayOfWeek of this.keys) {
      for (const session of sessions) {
        if (this.slotAvailability[dayOfWeek][session]['start'] != '' && this.slotAvailability[dayOfWeek][session]['end'] != '') {
          avialability.push({ 'doctorId': user['username'], 'duration': this.duration ? this.duration : 30, 'session': session, 'dayOfWeek': dayOfWeek, 'startTime': this.slotAvailability[dayOfWeek][session]['start'], 'endTime': this.slotAvailability[dayOfWeek][session]['end'] });
        }
      }
    }
    this.apiService.saveDoctorAvailabiity(avialability).subscribe((resData: any) => {
      this.snackbar.open('Schedule updated Successfully', "", { duration: 2000 });
      // this.loadAvailability();
    });
  }

  resetAvailability() {
    // this.loadAvailability();
  }

  viewDaysOptionChanged(viewDays: number): void {
    console.log('viewDaysOptionChanged', viewDays);
    this.calendarScheduler.setViewDays(viewDays);
  }

  changeDate(date: Date): void {
    console.log('changeDate', date);
    this.viewDate = date;
    this.dateOrViewChanged();
  }

  changeView(view: CalendarView): void {
    console.log('changeView', view);
    this.view = view;
    this.dateOrViewChanged();
  }

  dateOrViewChanged(): void {
    if (this.startsWithToday) {
      this.prevBtnDisabled = !this.isDateValid(subPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, this.viewDate, 1),null);
      this.nextBtnDisabled = !this.isDateValid(addPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, this.viewDate, 1),null);
    } else {
      this.prevBtnDisabled = !this.isDateValid(endOfPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, subPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, this.viewDate, 1)),null);
      this.nextBtnDisabled = !this.isDateValid(startOfPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, addPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, this.viewDate, 1)),null);
    }

    if (this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    } else if (this.viewDate > this.maxDate) {
      this.changeDate(this.maxDate);
    }
  }

  isDateValid(date: Date,rows:any): boolean {
   if(rows==null){
    return /*isToday(date) ||*/ date >= this.minDate && date <= this.maxDate;
   }else{
     var flag=date >= this.minDate && date <= this.maxDate
    //  console.log(this.rows);
     this.rows.forEach(element => {
      var from = new Date(element.startTime);
      var to   = new Date(element.endTime);
      if((date<to && date>= from)){
        flag= false;
      }
     });
    return flag;
   }
  }

  viewDaysChanged(viewDays: number): void {
    console.log('viewDaysChanged', viewDays);
    this.viewDays = viewDays;
  }

  dayHeaderClicked(day: SchedulerViewDay): void {
    this.loadDoctorSlotDetails(day);
  }

  hourClicked(hour: SchedulerViewHour): void {
    console.log('hourClicked Hour', hour);
  }

  segmentClicked(action: string, segment: SchedulerViewHourSegment): void {
    console.log('segmentClicked Action', action);
    console.log('segmentClicked Segment', segment);
    //this.bookSlotPopup(segment);
  }

  eventClicked(action: string, event: CalendarSchedulerEvent): void {
    console.log('eventClicked Action', action);
    console.log('eventClicked Event', event);
  }

  eventTimesChanged({ event, newStart, newEnd }: SchedulerEventTimesChangedEvent): void {
    console.log('eventTimesChanged Event', event);
    console.log('eventTimesChanged New Times', newStart, newEnd);
    let ev = this.events.find(e => e.id === event.id);
    ev.start = newStart;
    ev.end = newEnd;
    this.refresh.next();
  }

  openOtherDialog() {
    this.dialog.open(this.alertDialog);
  }

  deleteEvent(eventId) {
    this.eventId = eventId;
    this.openOtherDialog();
  }

  delete() {
    this.apiService.deleteAppointment(this.eventId).subscribe((data: any) => {
      console.log(data);
      this.loadAppointments();
    })
    this.dialog.closeAll();
  }

  cancel() {
    this.dialog.closeAll();
  }


  reloadZeroConfiguration() {
    this.blockUIZeroConfiguration.start('Loading..');

    setTimeout(() => {
      this.blockUIZeroConfiguration.stop();
    }, 2500);
  }

  reloadDefaultOrdering() {
    this.blockUIDefaultOrdering.start('Loading..');

    setTimeout(() => {
      this.blockUIDefaultOrdering.stop();
    }, 2500);
  }

   //changes
   bookSlotPopup(row: any) {
    const dialogRef = this.dialog.open(BlockScheduleComponent, {
      width: '90%',
      disableClose: false,
      hasBackdrop: true,
      autoFocus: false,
      minHeight: 'calc(100vh - 90px)',
      maxHeight:90,
      height : 'auto',
      data: { start: row?.date, isEdit:false}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadAppointments();
      this.getBlockedRecords();
      
    });
  }
  editSlot(row: any) {
    console.log("row",row)
    this.editId=row['id'];
    console.log("id=",this.editId);
    const dialogRef = this.dialog.open(BlockScheduleComponent, {
      width: '90%',
      disableClose: true,
      hasBackdrop: true,
      autoFocus: false,
      minHeight: 'calc(100vh - 90px)',
      maxHeight:90,
      height : 'auto',
      data: { start: null, isEdit:true ,editId:this.editId}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadAppointments();
      this.getBlockedRecords();
      
      
    });
  }
  
  deleteSlot(row:any){
    const dialogRef = this.matDailog.open(CancelAppointmentDialog, {
      width: '50%',
      disableClose: true,
      hasBackdrop: true,
      data: { pageValue: "" }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if(result.refresh){
        this.getBlockedRecords();
      }
      if (result.data != null) {
        row['status'] = "CANCELLED";
        row['cancelledBy'] = "DoctorUnblock";
        row['cancellationReason'] = result.data;
        console.log("params--" + JSON.stringify(row));
        this.apiservice.cancelAppointment(row).subscribe((resData: any) => {
          this.loadAppointments();
          this.getBlockedRecords();
          
        }, error => {
          var data = error;
        });
      } 
    });
  }

  AvailBlockPopup(row: any) {
    const dialogRef = this.dialog.open(AvailableBlockStatusComponent, {
      width: '60%',
      disableClose: false,
      hasBackdrop: true,
      autoFocus: false,
      minHeight: 'calc(80vh - 90px)',
      maxHeight:70,
      height : 'auto',
      data: {date:row,timeList:this.timeList,doctorId:this.temp?.id,duration:this.temp?.appointmentsDTOList[0]?.availabilitySlotDuration}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result=='cancel')
      {
  
      }
      else
      {
        let snackBarRef = this.snackbar.open('slots has been updated successfully.', "", { duration: 2000 });
      }
    });
  }

  loadDoctorSlotDetails(day:any) {
    const datepipe1: DatePipe = new DatePipe('en-US');
    var date=datepipe1.transform(day.date, 'yyyy-MM-dd');
    this.timeList=[];
    this.spinner.show();
    this.apiService.getDoctorAvailableSlotDetails(this.temp?.id,date).subscribe((resData: any) => {
      this.timeList= resData;
      this.AvailBlockPopup(date);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      var data = error;
    });
  }




  
  // getDoctors() {
  //   var doctorsList= [];
  //   var user = JSON.parse(localStorage.getItem('currentUser')).username;
  //   this.apiService.getAllDoctors().subscribe((data: any) => {
  //     this.doctorsList = data;
  //     this.doctorsList.forEach(element => {
  //       if (element.username == user)
  //         this.doctorId = element.id;
  //     });
  //   }, error => {
  //     console.log(error);
  //   });
  // }

}
