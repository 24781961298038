<ngx-spinner
bdColor = "rgba(51, 51, 51, 0.8)"
size = "medium"
color = "#fff" style="color: white"
type = "ball-spin">Loading...</ngx-spinner>
<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
        <div class="content-header row mb-1">
        </div>
        <div class="content-body">
            <section class="flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-md-10 col-10 box-shadow-2 p-0">
                        <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                            <div class="card-header border-0 pb-0">
                                <div class="card-title text-center" *ngIf="!edit">
                                    <img src="../assets/images/logo.svg" alt="branding logo" style="width: 30%;">
                                </div>
                                <div class="card-title text-left" *ngIf="edit">
                                   <a [routerLink]="['/dashboard']" data-placement="right" title="home"  class="btn-icon text-primary fonticon-wrap mr-1 "><i class="la la-home " style="font-size: 2.0em;"></i></a>
                                </div>
                                <div class="card-title text-left" *ngIf="!edit">
                                    <a [routerLink]="['/login']" data-placement="right" title="back"  class="btn-icon text-primary fonticon-wrap mr-1 "><i class="la la-arrow-left " style="font-size: 2.0em;"></i></a>
                                </div>
                                <!-- <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                                    <span>Easily Using</span></h6> -->
                            </div>
                            <div class="card-content">
                                <!-- <app-social-signin></app-social-signin> -->
                                <!-- <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
                                    <span>OR Using Email</span></p> -->
                                <div class="card-body">
                                    <form class="form form-horizontal" [formGroup]="personaldetailsForm" (ngSubmit)="onSubmit()">


                                        <div class="row" style="text-align: right;">
                                            <!-- <label class="col-md-2 label-control" >User name</label> -->

                                            <fieldset class="form-group position-relative has-icon-left col-md-4">

                                                <input type="search" [class.is-invalid]="pdf.username.invalid && pdf.username.touched" required formControlName="username" class="form-control" [readonly]="edit" [ngClass]="{ 'is-invalid': submitted && pdf.username.errors }" placeholder="Digi Doctor ID">
                                                <div *ngIf="pdf.username.errors && pdf.username.invalid && pdf.username.touched">
                                                    <small class="text-danger" *ngIf="pdf.username.errors.required">Digi Doctor ID is required</small>
                                                    <small class="text-danger" *ngIf="pdf.username.errors.pattern">Enter valid Doctor ID</small>
                                                    <small class="text-danger" *ngIf="pdf.username.errors.minlength">Doctor ID need to be at least 2 characters long</small>
                                                    <small class="text-danger" *ngIf="pdf.username.errors.maxlength">allows only 20 characters long</small>
                                                </div>
                                                <div class="form-control-position">
                                                    <i class="feather ft-user"></i>
                                                </div>
                                                <!-- <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                            <div *ngIf="f.firstName.errors.required">Username is required</div>
                                        </div> -->
                                            </fieldset>

                                            <fieldset class="form-group position-relative has-icon-left col-md-4">

                                                <input type="search" [class.is-invalid]="pdf.password.invalid && pdf.password.touched" required pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$" formControlName="password" class="form-control pw" [readonly]="edit" [ngClass]="{ 'is-invalid': submitted && pdf.password.errors }"
                                                    placeholder="Password">
                                                <div *ngIf="pdf.password.errors && pdf.password.invalid && pdf.password.touched">
                                                    <small class="text-danger" *ngIf="pdf.password.errors.required">Password is required</small>
                                                </div>
                                                <div *ngIf="pdf.password.errors && (pdf.password.invalid || pdf.password.touched)">
                                                    <small class="text-danger" *ngIf="pdf.password.errors.pattern">password must be 8-16 characters in length with at least 1 upper case, 1 lower case, 1 numeric and 1 special character</small>
                                                </div>
                                                <div class="form-control-position">
                                                    <i class="la la-key"></i>
                                                </div>
                                            </fieldset>

                                            <fieldset class="form-group position-relative has-icon-left col-md-4">

                                                <input type="search" [class.is-invalid]="pdf.confirmPassword.invalid && pdf.confirmPassword.touched" required pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$" formControlName="confirmPassword" class="form-control pw" [readonly]="edit"
                                                    [ngClass]="{ 'is-invalid': submitted && pdf.confirmPassword.errors }" placeholder="Confirm Password">
                                                <div *ngIf="pdf.confirmPassword.errors && pdf.confirmPassword.invalid && pdf.confirmPassword.touched">
                                                    <small class="text-danger" *ngIf="pdf.confirmPassword.errors.required">Confirm Password is required</small>
                                                </div>
                                                <div *ngIf="pdf.confirmPassword.errors && (pdf.confirmPassword.invalid || pdf.confirmPassword.touched)">
                                                    <small class="text-danger" *ngIf="pdf.confirmPassword.errors.pattern">Please provide a valid password</small>
                                                </div>
                                                <div *ngIf="checkPassSame()">
                                                    <small class="text-danger" >{{passmsg}}</small>
                                                </div>
                                                
                                                <div class="form-control-position">
                                                    <i class="la la-key"></i>
                                                </div>
                                            </fieldset>

                                        </div>
                                        <div class="row" style="text-align: right;">
                                            <!-- <label class="col-md-2 label-control" >Name</label> -->


                                            <fieldset class="form-group position-relative has-icon-left col-md-4">
                                                <input type="search" [class.is-invalid]="pdf.given.invalid && pdf.given.touched" required pattern="^[A-Za-z]+[A-Za-z ]*$" formControlName="given" class="form-control" [ngClass]="{ 'is-invalid': submitted && pdf.given.errors }" placeholder="First Name">
                                                <div *ngIf="pdf.given.errors && pdf.given.invalid && pdf.given.touched">
                                                    <small class="text-danger" *ngIf="pdf.given.errors.required">First Name is required</small>
                                                    <small class="text-danger" *ngIf="pdf.given.errors.pattern">Please provide valid firstname</small>
                                                    <small class="text-danger" *ngIf="pdf.given.errors.minlength">firstName need to be atleast 2 characters length </small>
                                                    <small class="text-danger" *ngIf="pdf.given.errors.maxlength">allows only 20 characters long</small>
                                                </div>
                                                <div class="form-control-position">
                                                    <i class="feather ft-user"></i>
                                                </div>
                                            </fieldset>


                                            <fieldset class="form-group position-relative has-icon-left col-md-4">

                                                <input type="search" [class.is-invalid]="pdf.family.invalid && pdf.family.touched" required pattern="^[A-Za-z]+[A-Za-z ]*$" formControlName="family" class="form-control" [ngClass]="{ 'is-invalid': submitted && pdf.family.errors }" placeholder="Last Name">
                                                <div *ngIf="pdf.family.errors && pdf.family.invalid && pdf.family.touched">
                                                    <small class="text-danger" *ngIf="pdf.family.errors.required">Last Name is required</small>
                                                    <small class="text-danger" *ngIf="pdf.family.errors.pattern">Please provide valid lastname</small>
                                                    <small class="text-danger" *ngIf="pdf.family.errors.minlength">lastName need to be at least 2 characters long</small>
                                                    <small class="text-danger" *ngIf="pdf.family.errors.maxlength">allows only 20 characters long</small>
                                                </div>
                                                <div class="form-control-position">
                                                    <i class="feather ft-user"></i>
                                                </div>
                                                <!-- <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                            <div *ngIf="f.firstName.errors.required">Username is required</div>
                                        </div> -->
                                            </fieldset>

                                            <fieldset class="form-group col-md-4">
                                                <div class="input-group">
                                                    <input class="form-control" readonly [class.is-invalid]="pdf.birthDate.invalid && pdf.birthDate.touched" style="border-right: none;text-align: left;" required formControlName="birthDate" ngbTooltip="Date of Birth" placeholder="dd-mm-yyyy (DOB)" matInput [matDatepicker]="picker"
                                                    [min]="minDate" [max]="maxDate" name="dp" [ngClass]="{ 'is-invalid': submitted && pdf.birthDate.errors }" autocomplete="off" (ngModelChange)="dobChange()">
                                                    <mat-datepicker-toggle matSuffix [for]="picker" class="date"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </div>
                                                <div *ngIf="pdf.birthDate.errors && pdf.birthDate.invalid && pdf.birthDate.touched">
                                                    <small class="text-danger" *ngIf="pdf.birthDate.errors.required">Date Of Birth is required</small>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div class="row" style="text-align: right;">
                                            <!-- <label class="col-md-2 label-control" >D.O.B.</label> -->

                                            <fieldset class="form-group position-relative col-md-4">
                                                <select class="form-control" formControlName="gender" [class.is-invalid]="pdf.gender.invalid && pdf.gender.touched" required [ngClass]="{ 'is-invalid': submitted && pdf.gender.errors }">
                                                <option value="" disabled selected>Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </select>
                                                <div *ngIf="pdf.gender.errors && pdf.gender.invalid && pdf.gender.touched">
                                                    <small class="text-danger" *ngIf="pdf.gender.errors.required">select Gender</small>
                                                </div>
                                            </fieldset>
                                            <fieldset class="form-group position-relative has-icon-left col-md-4">

                                                <input type="search" numbersOnly maxlength="10" formControlName="mobile" class="form-control" [class.is-invalid]="pdf.mobile.invalid && pdf.mobile.touched" required pattern="^[6-9](?!0+$)\d{9}$" [ngClass]="{ 'is-invalid': submitted && pdf.mobile.errors }"
                                                    placeholder="Mobile Number">
                                                <div *ngIf="pdf.mobile.errors && pdf.mobile.invalid && pdf.mobile.touched">
                                                    <small class="text-danger" *ngIf="pdf.mobile.errors.required">Mobile Number is required</small>
                                                </div>
                                                <div *ngIf="pdf.mobile.errors && (pdf.mobile.invalid || pdf.mobile.touched)">
                                                    <small class="text-danger" *ngIf="pdf.mobile.errors.pattern">Please provide a valid 10 digit number </small>
                                                </div>
                                                <div class="form-control-position">
                                                    <i class="feather ft-phone"></i>
                                                </div>
                                            </fieldset>
                                            <fieldset class="form-group position-relative has-icon-left col-md-4">

                                                <input type="search" [class.is-invalid]="pdf.email.invalid" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && pdf.email.errors }" placeholder="Email">
                                                <div *ngIf="pdf.email.errors && (pdf.email.invalid || pdf.email.touched)">
                                                    <small class="text-danger" *ngIf="pdf.email.errors.pattern">Please provide a valid Email</small>
                                                </div>
                                                <div class="form-control-position">
                                                    <i class="feather ft-mail"></i>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <label class="label-control">Address</label>
                                        <div class="row" style="text-align: right;">
                                            <fieldset class="form-group position-relative col-md-4">
                                                <input type="search" formControlName="city" class="form-control" [class.is-invalid]="pdf.city.invalid && pdf.city.touched" required pattern="^[a-zA-Z]+(\s[a-zA-Z]+)?$" [ngClass]="{ 'is-invalid': submitted && pdf.city.errors }" placeholder="City">
                                                <div *ngIf="pdf.city.errors && pdf.city.invalid && pdf.city.touched">
                                                    <small class="text-danger" *ngIf="pdf.city.errors.required">City is required</small>
                                                    <small class="text-danger" *ngIf="pdf.city.errors.pattern">please enter valid City </small>
                                                    <small class="text-danger" *ngIf="pdf.city.errors.minlength">City need to be at least 2 characters long</small>
                                                    <small class="text-danger" *ngIf="pdf.city.errors.maxlength">allows only 20 characters long</small>
                                                </div>
                                            </fieldset>
                                            <fieldset class="form-group position-relative col-md-4">
                                                <select class="form-control" formControlName="country" [ngClass]="{ 'is-invalid': submitted && pdf.country.errors }">
                                                <option *ngFor="let obj of countires" [value]="obj">{{obj}}</option>
                                            </select>
                                            </fieldset>
                                            <fieldset class="form-group position-relative col-md-4">
                                                <input type="search" numbersOnly minlength="6" maxlength="6" formControlName="postalcode" class="form-control" pattern="^(?!0+$)\d{6}$" [class.is-invalid]="pdf.postalcode.invalid && pdf.postalcode.touched" required [ngClass]="{ 'is-invalid': submitted && pdf.postalcode.errors }" placeholder="Zip/Pin code">
                                                <div *ngIf="pdf.postalcode.errors && pdf.postalcode.invalid && pdf.postalcode.touched">
                                                    <small class="text-danger" *ngIf="pdf.postalcode.errors.required">Zip/pin is required</small>
                                                    <small class="text-danger" *ngIf="pdf.postalcode.errors.pattern">please enter valid Zip/pin</small>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <label class="label-control">Payment</label>
                                        <div class="row" style="text-align: right;">
                                            <fieldset class="form-group position-relative col-md-4">
                                                <select class="form-control" formControlName="doctorType" [class.is-invalid]="pdf.doctorType.invalid && pdf.doctorType.touched" required [ngClass]="{ 'is-invalid': submitted && pdf.doctorType.errors }" (ngModelChange)="checkDoctorType()">
                                                <option hidden value="" disabled selected>Doctor Type</option>
                                                <option value="Free">Free</option>
                                                <option value="Paid">Paid</option>
                                            </select>
                                                <div *ngIf="pdf.doctorType.errors && pdf.doctorType.invalid && pdf.doctorType.touched">
                                                    <small class="text-danger" *ngIf="pdf.doctorType.errors.required">select Doctor Type</small>
                                                </div>
                                            </fieldset>
                                            <fieldset *ngIf="personaldetailsForm.get('doctorType').value=='Paid'" form class="form-group position-relative col-md-4">
                                                <input type="text" formControlName="feeAmount" class="form-control" placeholder="Amount">
                                                <div *ngIf="pdf.feeAmount.errors && pdf.feeAmount.invalid && pdf.feeAmount.touched">
                                                    <small class="text-danger" *ngIf="pdf.feeAmount.errors.required">Enter fee amount</small>
                                                    <small class="text-danger" *ngIf="pdf.feeAmount.errors.pattern">please enter valid amount upto 5 digits long</small>
                                                </div>
                                            </fieldset>                                           
                                        </div>

                                        <div class="" formArrayName="educationDTOList">
                                            <label class="label-control">Medical Education</label>
                                            <a (click)="addEducation()" style="float: right;"><i class="feather ft-plus"></i> Add more degree</a>
                                            <div class="" *ngFor="let educationForm of personaldetailsFormGroup.controls; let i = index;">
                                                <div [formGroupName]="i" class="row">
                                                    <fieldset class="form-group  col-md-4">
                                                        <input type="search" formControlName="university" minlength="2" maxlength="40" class="form-control" pattern="^[A-Za-z.]+[A-Za-z. ]*$" [ngClass]="{ 'is-invalid': submitted && getValidity(i)}" placeholder="University">
                                                        <div *ngIf="getValidityUniv(i)">
                                                            <small class="text-danger" >Please enter the valid University Name</small>
                                                            </div>
                                                    </fieldset>
                                                    <fieldset class="form-group position-relative col-md-4">
                                                        <input type="search" formControlName="courseName" minlength="2" maxlength="20" class="form-control" pattern="^[A-Za-z.]+[A-Za-z. ]*$" [ngClass]="{ 'is-invalid': submitted && getValidity(i) }" placeholder="Degree">
                                                        <div *ngIf="getValidityCourse(i)">
                                                            <small class="text-danger" >Please enter the valid Degree</small>
                                                            </div>
                                                    </fieldset>
                                                    <fieldset class="form-group position-relative " [ngClass]="{ 'col-md-4' : i==0, 'col-md-3' : i!=0 }">
                                                        <select class="form-control" formControlName="completionyear" [ngClass]="{ 'is-invalid': submitted && getValidity(i) }">
                                                            <option value="">Year</option>
                                                            <option *ngFor="let obj of years" [value]="obj">{{obj}}</option>
                                                        </select>
                                                    </fieldset>
                                                    <fieldset class="col-md-1" *ngIf="i!=0">
                                                        <button class="btn btn-danger" type="button" (click)="removeEducation(i)"><i
                                                            class="feather ft-x"></i></button>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                        <label class="label-control">Area of practice</label>
                                        <div class="row" style="text-align: right;">
                                            <fieldset class="form-group position-relative col-md-8">
                                                <select class="form-control" formControlName="practicing" [ngClass]="{ 'is-invalid': submitted && pdf.practicing.errors }" [class.is-invalid]="pdf.practicing.invalid && pdf.practicing.touched" required>
                                                <option value="">Select</option>
                                                <option *ngFor="let obj of areaOfPractice" [value]="obj">{{obj}}</option>
                                            </select>
                                                <div *ngIf="pdf.practicing.errors && pdf.practicing.invalid && pdf.practicing.touched">
                                                    <small class="text-danger" *ngIf="pdf.practicing.errors.required">select Area Of Practice</small>
                                                </div>
                                            </fieldset>
                                            <fieldset class="form-group position-relative col-md-4">
                                                <select class="form-control" formControlName="practicingSince" [ngClass]="{ 'is-invalid': submitted && pdf.practicingSince.errors }" [class.is-invalid]="pdf.practicingSince.invalid && pdf.practicingSince.touched">
                                                <option value="">Year</option>
                                                <option *ngFor="let obj of years" [value]="obj">{{obj}}</option>
                                            </select>
                                                <div *ngIf="pdf.practicingSince.errors && pdf.practicingSince.invalid && pdf.practicingSince.touched">
                                                    <small class="text-danger" *ngIf="pdf.practicingSince.errors.required">select year of started practicing</small>
                                                </div>
                                            </fieldset>

                                        </div>
                                        <label class="label-control">Professional Licence</label>
                                        <div class="row" style="text-align: right;">
                                            <fieldset class="form-group position-relative col-md-4">
                                                <input type="search" numbersOnly minlength="6" maxlength="6" formControlName="registrationNo" class="form-control" [class.is-invalid]="pdf.registrationNo.invalid && pdf.registrationNo.touched" required pattern="^(?!0+$)\d{6}$" [ngClass]="{ 'is-invalid': submitted && pdf.registrationNo.errors }"
                                                    placeholder="Licence Number">
                                                <div *ngIf="pdf.registrationNo.errors && pdf.registrationNo.invalid && pdf.registrationNo.touched">
                                                    <small class="text-danger" *ngIf="pdf.registrationNo.errors.required">License Number required</small>
                                                    <small class="text-danger" *ngIf="pdf.registrationNo.errors.pattern">Please provide a valid License Number</small>
                                                </div>
                                            </fieldset>
                                            <fieldset class="form-group position-relative col-md-4">
                                                <select class="form-control" formControlName="registeredCountry" [ngClass]="{ 'is-invalid': submitted && pdf.registeredCountry.errors }">
                                                <option *ngFor="let obj of countires" [value]="obj">{{obj}}</option>
                                            </select>
                                            </fieldset>
                                            <fieldset class="form-group position-relative col-md-4">
                                                <input type="search" formControlName="registeredAt" class="form-control" [ngClass]="{ 'is-invalid': submitted && pdf.registeredAt.errors }" [class.is-invalid]="pdf.registeredAt.invalid && pdf.registeredAt.touched" required pattern="^[A-Za-z]+[A-Za-z ]*$" placeholder="State">
                                                <div *ngIf="pdf.registeredAt.errors && pdf.registeredAt.invalid && pdf.registeredAt.touched">
                                                    <small class="text-danger" *ngIf="pdf.registeredAt.errors.required">Registered state is required</small>
                                                    <small class="text-danger" *ngIf="pdf.registeredAt.errors.pattern">please enter the valid state</small>
                                                    <small class="text-danger" *ngIf="pdf.registeredAt.errors.minlength">State need to be at least 2 characters long</small>
                                                    <small class="text-danger" *ngIf="pdf.registeredAt.errors.maxlength">allows only 20 characters long</small>
                                                </div>
                                            </fieldset>

                                        </div>

                                        <div class="" formArrayName="appointmentsDTOList">
                                            <label class="label-control">Schedule for Online Appointments</label>
                                            <a (click)="addAppointment()" style="float: right;"><i class="feather ft-plus"></i> Add more appointments</a>
                                            <div class="" *ngFor="let appointmentsForm of getAppointmentsFormrGoup.controls; let i = index;">
                                                <div [formGroupName]="i" class="row">
                                                    <fieldset class="col-md-1" *ngIf="i!=0">
                                                        <button class="btn btn-danger" type="button" (click)="removeAppointment(i)" ><i
                                                            class="feather ft-x"></i></button>
                                                    </fieldset>
                                                    <!-- <fieldset class="col-md-1" *ngIf="i==0"> -->
                                                        <!-- <button class="btn btn-danger" type="button" ><i
                                                            ></i></button> -->
                                                    <!-- </fieldset> -->
                                                    <fieldset class="form-group  col-md-5">
                                                        <ng-select [items]="multipleSelectArray1" [clearSearchOnAdd]="true" [closeOnSelect]="false" formControlName="availabilitySlotDays"  [multiple]="true" placeholder="Select Days" [ngClass]="{ 'is-invalid': submitted && getValidityAvai(i) }">
                                                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" >
                                                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/>
                                                                    {{item}}
                                                            </ng-template>
                                                        </ng-select>
                                                        <div *ngIf="getValidityDays(i)">
                                                            <small class="text-danger" >Please select the Days</small>
                                                        </div>
                                                        <!-- <input type="search" formControlName="availabilitySlotDays" class="form-control" [ngClass]="{ 'is-invalid': submitted && getValidity(i)}" placeholder="University"> -->
                                                    </fieldset>
                                                    <fieldset class="form-group position-relative" style="display: none;">
                                                        <select class="form-control" formControlName="availabilitySlotDuration" [ngClass]="{ 'is-invalid': submitted && getValidityAvai(i) }">
                                                            <option value="" disabled>Duration</option>
                                                            <option *ngFor="let obj of duration" [value]="obj.key">{{obj.name}}</option>
                                                            <option value="15" selected>15 min</option>
                                                        </select>
                                                    </fieldset>
                                                    <fieldset class="form-group position-relative" [ngClass]="{ 'col-md-7' : i==0, 'col-md-6' : i!=0 }">
                                                        <div class="" formArrayName="timeSlotDTOList">
                                                            <div class="" *ngFor="let appointmentTimeSlotsForm of getAppointmentsFormTimeSlotsGroup(i).controls; let j = index;">
                                                                <div [formGroupName]="j" class="row">
                                                                    <fieldset class="form-group position-relative col-md-2">Time Slots</fieldset>
  
                                                                    <fieldset class="form-group position-relative col-md-4">
                                                                        <select class="form-control" formControlName="startTime" [ngClass]="{ 'is-invalid': submitted && getValidityTimeSlot(i,j) }">
                                                                            <option value="">From</option>
                                                                            <option *ngFor="let obj of timeSlots" [value]="obj.key">{{obj.name}}</option>
                                                                        </select>
                                                                    </fieldset>
                                                                    <fieldset class="form-group position-relative col-md-4">
                                                                        <select class="form-control" formControlName="endTime" [ngClass]="{ 'is-invalid': submitted && getValidityTimeSlot(i,j) }">
                                                                            <option value="">To</option>
                                                                            <option *ngFor="let obj of timeSlots" [value]="obj.key">{{obj.name}}</option>
                                                                        </select>
                                                                    </fieldset>
                                                                    <fieldset class="col-md-1" *ngIf="j==0">
                                                                        <button class="btn btn-primary" type="button" (click)="addAppointmentTimeSlots(i,j)"><i
                                                                            class="feather ft-plus"></i></button>
                                                                    </fieldset>
                                                                    <fieldset class="col-md-1" *ngIf="j!=0">
                                                                        <button class="btn btn-danger" type="button" (click)="removeTimeSlot(i,j)"><i
                                                                            class="feather ft-x"></i></button>
                                                                    </fieldset>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </fieldset>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div style="margin: 5px;">
                                            <label class="label-control" style="margin-right:20px;" for="file">Upload Digital Signature</label>
                                            <input type="file" id="file" accept=".jpg,.jpeg,.png" (change)="handleFileInput($event.target.files)" [ngClass]="show ? 'pad1' : 'pad2'" (click)="newFile()">
                                            <img src="{{signature}}" width="100px" height="100px" *ngIf="signature != ''" />
                                        </div>

                                        <div *ngIf="!edit" class="form-group row">
                                            <div class="col-md-12 col-12 text-center text-sm-left pr-0">
                                                <fieldset>
                                                    <!-- <div class="icheckbox_square-blue" id="icheckbox" (click)="addCheckbox($event)" style="position: relative;">
                                                        <ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins>
                                                    </div> -->
                                                    <input type="checkbox"  formControlName="agreeTerms" name="agree-terms" style="position: relative;">
                                                    I agree to the <a [routerLink]="['/termCondition']" target="_blank">Terms and conditions</a> set out by this site, including our Cookie use.
                                                </fieldset>
                                            </div>
                                        </div>
                                        <button [disabled]="personaldetailsForm.invalid" type="submit" class="btn btn-block" [ngClass]="personaldetailsForm.invalid ? 'btn-outline-secondary' : 'btn-outline-info'">
                                            <i class="fa fa-refresh fa-spin" *ngIf="loading"></i>
                                             {{register}}
                                        </button>
                                    </form>
                                </div>
                                <div class="card-body" *ngIf="edit">
                                    <a [routerLink]="['/dashboard']" class="btn btn-outline-secondary btn-block">Cancel</a>
                                </div>
                                <div style="text-align: center;" *ngIf="!edit">
                                    <span>Already have an account?<a [routerLink]="['/login']"> Login</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div>