<ngx-spinner bdcolor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" style="color: white" type="ball-spin">Loading...</ngx-spinner>

<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <!--/ Zero configuration table -->
            <!-- Default ordering table -->
            <section id="ordering">
                <div class="row">
                    <div class="col-12" *blockUI="'defaultOrdering'; message: 'Loading'">
                        <m-card [options]="options" (reloadFunction)="reloadDefaultOrdering($event)">
                            <!-- <ng-container mCardHeaderTitle>
                                My Schedule
                            </ng-container> -->
                            <ng-container mCardBody>
                                <div class="card-dashboard">
                                    <div class="calendar-container">
                                        <div class=" row " style="border-bottom: 1px solid #e9ebf5;">
                                            <div class="col-12">
                                                <h4 class="form-section"><i class="feather ft-calendar"></i> Schedule</h4>
                                            </div>
                                        </div>
                                        <div class=" row ">
                                            <div class="col-xl-12 d-flex justify-content-center" >
                                                <div class="btn-group m-t-b " role="group ">
                                                    <button type="button " class="btn btn-primary " mwlCalendarPreviousView [view]="view " [(viewDate)]="viewDate " [daysInWeek]="viewDays " [excludeDays]="excludeDays " [disabled]="prevBtnDisabled
                                                " (viewDateChange)="dateOrViewChanged() ">
                                                        <span class="valign-center ">
                                                            <i title="Precedente "
                                                                class="material-icons md-18 ">
                                                                keyboard_arrow_left
                                                            </i>
                                                        </span>
                                                    </button>
                                                    <button type="button " class="btn btn-light " mwlCalendarToday [(viewDate)]="viewDate " (viewDateChange)="dateOrViewChanged() ">
                                                        <span class="valign-center ">
                                                            <i title="Oggi "
                                                                class="material-icons md-18 ">today</i>
                                                        </span>
                                                    </button>
                                                    <button type="button " class="btn btn-primary " mwlCalendarNextView [view]="view " [(viewDate)]="viewDate " [daysInWeek]="viewDays " [excludeDays]="excludeDays " [disabled]="nextBtnDisabled
                                                " (viewDateChange)="dateOrViewChanged() ">
                                                        <span class="valign-center ">
                                                            <i title="Successivo "
                                                                class="material-icons md-18 ">keyboard_arrow_right</i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- <div class="offset-sm-1 offset-md-2 offset-xl-2 col-6 col-md-3 col-sm-4  col-xl-3 valign-center">
                                                <button class="form-control m-t-b btn btn-primary" style="margin: 10px;" (click)="bookSlotPopup()">BLOCK</button>
                                            </div> -->
                                        </div>
                                        <ng-container [ngSwitch]="view ">
                                            <calendar-scheduler-view *ngSwitchCase="CalendarView.Week " [viewDays]="viewDays " [viewDate]="viewDate " [events]="events " [locale]="locale " [responsive]="true " [weekStartsOn]="weekStartsOn
                                                " [excludeDays]="excludeDays " [startsWithToday]="startsWithToday " [hourSegments]="hourSegments " [dayStartHour]="dayStartHour " [dayEndHour]="dayEndHour " [dayModifier]="dayModifier " [hourModifier]="hourModifier "
                                                [segmentModifier]="segmentModifier
                                                " [eventModifier]="eventModifier " [showEventActions]="true " [showSegmentHour]="true " [zoomEventOnHover]="true " (viewDaysChanged)="viewDaysChanged($event) " (dayHeaderClicked)="dayHeaderClicked($event.day) "
                                                (hourClicked)="hourClicked($event.hour) " (segmentClicked)="segmentClicked( 'Clicked', $event.segment) " (eventClicked)="eventClicked( 'Clicked', $event.event) " (eventTimesChanged)="eventTimesChanged($event) "
                                                [refresh]="refresh ">
                                            </calendar-scheduler-view>
                                        </ng-container>
                                        <!--
                                        <div class=" row m-t-b" style="border-bottom: 1px solid #e9ebf5; margin-top:15px;">
                                            <h4 class="form-section"><i class="feather ft-alert-circle"></i> Unavailability</h4>
                                        </div>
                                        <div class="table-bordered default-ordering" style="min-height:400px;">
                                            <ngx-datatable class="bootstrap" [limit]="5" [rows]="rows" [columnMode]="'force'" [scrollbarH]="true" [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" >
                                                <ngx-datatable-column name="SNo" [flexGrow]="1" [width]="50">
                                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex">
                                                        {{rowIndex+1}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Start Date Time" prop="startTime" [flexGrow]="1" [minWidth]="110">
                                                    <ng-template ngx-datatable-cell-template let-value="value">
                                                        {{value}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="End Date Time" prop="endTime" [flexGrow]="1" [minWidth]="110">
                                                    <ng-template ngx-datatable-cell-template let-value="value">
                                                        {{value}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Reason" prop="cancellationReason" [flexGrow]="1" [minWidth]="90">
                                                    <ng-template ngx-datatable-cell-template let-value="value">
                                                        {{value}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Actions" prop="" [flexGrow]="1" [width]="220">
                                                    <ng-template ngx-datatable-cell-template let-row="row">
                                                        <button type="button" (click)="editSlot(row)" class="btn btn-primary">Edit</button>
                                                        <button type="button" (click)="deleteSlot(row)" class="btn btn-danger" style="margin-left: 10px;">Delete</button>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                            </ngx-datatable>
                                        </div>
                                        -->
                                    </div>
                                </div>
                            </ng-container>
                        </m-card>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div>
<ng-template #alertDialog>
    <h2 matDialogTitle>Are you sure?</h2>
    <p matDialogContent>You really want to delete</p>
    <mat-dialog-actions>
        <button class="btn btn-danger " [ngStyle]="{margin: '30px'} " (click)="delete() ">Delete</button>
        <button mat-button matDialogClose (click)="cancel() ">Cancel</button>
    </mat-dialog-actions>
</ng-template>