
export const API_REST_URL = "https://devapksrv.elro.ai:8090/"
//export const API_REST_URL = "https://prod-abgserver.elro.ai:8080/"
//export const API_REST_URL = "https://prodsrv.elro.ai/"
export const EMRI_URL = "https://testgacrm.gaemri.tk/"
export const CDSS_URL = "https://sandbox-healthservice.priaid.ch/";
export const CDSS_Token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6Impvam94Ym90c0BnbWFpbC5jb20iLCJyb2xlIjoiVXNlciIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL3NpZCI6Ijg4NDUiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3ZlcnNpb24iOiIyMDAiLCJodHRwOi8vZXhhbXBsZS5vcmcvY2xhaW1zL2xpbWl0IjoiOTk5OTk5OTk5IiwiaHR0cDovL2V4YW1wbGUub3JnL2NsYWltcy9tZW1iZXJzaGlwIjoiUHJlbWl1bSIsImh0dHA6Ly9leGFtcGxlLm9yZy9jbGFpbXMvbGFuZ3VhZ2UiOiJlbi1nYiIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvZXhwaXJhdGlvbiI6IjIwOTktMTItMzEiLCJodHRwOi8vZXhhbXBsZS5vcmcvY2xhaW1zL21lbWJlcnNoaXBzdGFydCI6IjIwMjEtMDMtMzEiLCJpc3MiOiJodHRwczovL3NhbmRib3gtYXV0aHNlcnZpY2UucHJpYWlkLmNoIiwiYXVkIjoiaHR0cHM6Ly9oZWFsdGhzZXJ2aWNlLnByaWFpZC5jaCIsImV4cCI6MTYxOTc3MDczOSwibmJmIjoxNjE5NzYzNTM5fQ.UfrUTheSUBODa6SOI7ErC2IcMxOiTfTtb5DE6nQqsP8&format=json&language=en-gb";

export const RXNAV_URL = "https://rxnav.nlm.nih.gov/REST/interaction/list.json?rxcuis=";

export const Fire_Emergency_Types = "Fire_Emergency_Types"
export const MCI_Emergency_Types = "MCI_Emergency_Types"
export const Police_Emergency_Types = "Police_Emergency_Types"
export const MedicalComplaints = "MedicalComplaints"
export const ChiefComplaints = "ChiefComplaints"
export const SubChiefComplaints = "SubChiefComplaints"

//export const API_REST_URL = "http://localhost:8080/"

export const APP_CONSTANTS = {
    get REGEX() {
        return {
            NUMBERS_ONLY: "^[0-9]*$"
        }
    }
}