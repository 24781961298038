import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent1 implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  reason: string = "";

  constructor(private activeModal: NgbActiveModal,private snackbar: MatSnackBar) { }

  ngOnInit() {
  }

  public decline() {
    let res = {
      "res": false,
      "reason" : this.reason
    }
    this.activeModal.close(res);
  }

  public accept() {
    if(this.reason != ""){
    let res = {
      "res": true,
      "reason" : this.reason
    }
    this.activeModal.close(res);
  }
  else
    this.snackbar.open("Please fill the reason above to end call","",{duration:2000})
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}
