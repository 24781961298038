import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/_models';
import * as constants from './constants';
@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }
    invokeMyMethod:EventEmitter<string> =  new EventEmitter();
    openTabsPage:EventEmitter<string> =new EventEmitter();
    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    updateFcmToken(fcmToken:string,userName:string):Observable<any> {
        // return this.http.get<User[]>(`${environment.apiUrl}+ "api/v1/" + latitude + "/" + longitude +);
        //let headers = new HttpHeaders().set('Authorization', this.jwtToken.getToken())
        let url = constants.API_REST_URL + "api/v1/updateFcmToken/" + fcmToken + "/" + userName;
        return this.http.get(url);
    }
    
    callHomeComponent() {
        this.componentHomeCallSource.next();
      }
      opentabs(){
        //   this.home.tabChanged();
        //   this.openTabsPage.emit("doctor");
      }

     // Observable string sources
     private componentHomeCallSource = new Subject<any>();
      
  private componentMethodCallSource = new Subject<any>();
  private componentMethodConsultationSource = new Subject<any>();
  private componentCallWindowSource = new Subject<any>();

  // Observable string streams
  componenthomeCalled = this.componentHomeCallSource.asObservable();
  componentMethodCalled = this.componentMethodCallSource.asObservable();
  componentConsultationCalled=this.componentMethodConsultationSource.asObservable();
  componentCallWindowCalled=this.componentCallWindowSource.asObservable();

  // Service message commands
  callComponentMethod() {
    this.componentMethodCallSource.next();
  }
  callWindowMethod() {
    this.componentCallWindowSource.next();
  }
  callConsultationMethod(){
    this.componentMethodConsultationSource.next();

    
  }
}


