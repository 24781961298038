import { Injectable } from "@angular/core";

@Injectable()
export class JwtService {

    constructor() { }
    setToken(token: any) {
        localStorage.setItem("Authorization", token);
    }
    deleteToken() {
        localStorage.removeItem("Authorization");
    }
    getToken(){
        return localStorage.getItem("Authorization");
    }
    setUserName(token:any){
        localStorage.setItem("username",token);
    }
    setLoginData(token:any){
        localStorage.setItem("userDetails",token)
    }

}