<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
        <div class="content-header row">
        </div>
        <div class="content-body">
            <section class="row flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
                        <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                            <div class="card-header border-0">
                                <div class="card-title text-center">
                                        <img src="../assets/images/logo.svg" alt="branding logo" style="width: 40%;">
                                </div>
                                <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2" style="margin: 10px 0 5px;">
                                    <span>{{PasswordText}}</span></h6>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                    <form class="form-horizontal" [formGroup]="passwordForm" (ngSubmit)="getValidate()" *ngIf="screen1">
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="text" formControlName="username" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.username.errors }" id="user-name"
                                                placeholder="Digi Doctor Id" required>
                                            <div class="form-control-position">
                                                <i class="la la-user"></i>
                                            </div>
                                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                                <div *ngIf="f.username.errors.required">Digi Doctor Id is required</div>
                                            </div>
                                        </fieldset>
                                        <button type="submit" class="btn btn-outline-info btn-block">
                                            <i class="fa fa-refresh fa-spin" *ngIf="loading"></i>
                                            Forgot Password
                                        </button>
                                    </form>
                                    <form class="form-horizontal" [formGroup]="form2" (ngSubmit)="newPassword()" *ngIf="!screen1">
                                        <fieldset class="form-group position-relative has-icon-left" *ngIf="!edit">
                                            <input type="text" formControlName="otp" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && pdf.otp.errors }" id="otp"
                                                placeholder="OTP" required minlength="6" maxlength="6">
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                            <div *ngIf="submitted && pdf.otp.errors" class="invalid-feedback">
                                                <div *ngIf="pdf.otp.errors.required">OTP is required</div>
                                                <div *ngIf="pdf.otp.errors.pattern">Please enter valid OTP</div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left" *ngIf="edit">
                                            <input type="password" placeholder="Old Password"
                                                formControlName="oldPassword" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && pdf.oldPassword.errors }" />
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                            <div *ngIf="submitted && pdf.oldPassword.errors" class="invalid-feedback">
                                                <div *ngIf="pdf.oldPassword.errors.required">Old Password is required</div>
                                            </div>

                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="password" placeholder="New Password"
                                                formControlName="password" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && pdf.password.errors }" />
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                            <div *ngIf="submitted && pdf.password.errors" class="invalid-feedback">
                                                <div *ngIf="pdf.password.errors.required">New Password is required</div>
                                            </div>

                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input type="password" placeholder="Confirm Password"
                                                formControlName="cpassword" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && pdf.cpassword.errors }" />
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                            <div *ngIf="submitted && pdf.cpassword.errors" class="invalid-feedback">
                                                <div *ngIf="pdf.cpassword.errors.required">Confirm Password is required</div>
                                            </div>
                                            <div *ngIf="checkPassSame()">
                                                <small class="text-danger" >{{passmsg}}</small>
                                            </div>

                                        </fieldset>
                                        <button type="submit" class="btn btn-outline-info btn-block">
                                            <i class="fa fa-refresh fa-spin" *ngIf="loading"></i>
                                            {{ForgotPassword}}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div>