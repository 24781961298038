import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { environment } from 'src/environments/environment';
import { User } from '../_models';
import { Notifications } from '../_models/notifications';
import { UserService } from '../_services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ApiService } from './api-service';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  notifications = new Notifications();
  timeLeft: number = 20;
  interval;
  timer: boolean;
  notificationMsg:any;

  constructor(private router: Router,
    public apiservice:ApiService,public dialog: MatDialog,private angularFireMessaging: AngularFireMessaging,private userService:UserService,private authService : AuthenticationService) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        // _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
    navigator.serviceWorker.addEventListener('message',this.onRecevingMessage.bind(this))
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log("fcmToken",token);
        let user:User = JSON.parse(localStorage.getItem('currentUser'));
        this.userService.updateFcmToken(token,user.username).subscribe();
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
    
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.notificationMsg=payload;
        console.log("new message received. ", payload);
        this.timeLeft=20;
        this.timer = false;
        this.startTimer();
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: "Incoming Call?"
          });
          dialogRef.afterClosed().subscribe(result => {
            if(result) {
             console.log('Yes clicked');
               console.log("new message received. ", payload);
              this.notifications = Object.assign(new Notifications,payload['data']);
              localStorage.setItem("notifications",JSON.stringify(this.notifications));
                 this.currentMessage.next(payload);
                 this.pauseTimer();
                //  this.userService.callHomeComponent();

              //this.reloadComponent();
              if(this.router.url == "/tele-consult")
                window.location.reload();
              else{
                this.router.navigate(["/tele-consult"])
                .then(() => {
                  window.location.reload();
                });
              }
            }
            else if(result == false)
            {
                this.pauseTimer();
                this.rejectedCall(CallStatusEnum.REJECT);
          }
          });
      
      }
      )
  }

  reloadComponent() {
    let currentUrl = this.router.url; ///tele-consult
    console.log("currenturl--",currentUrl);
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/tele-consult']);
    }
    
  rejectedCall(CallStatusEnum: any){
    this.dialog.closeAll();
    // this.timeLeft = 20;
    let notifications = this.notificationMsg['data'];
    notifications.registrationDTO = {username:JSON.parse(localStorage.getItem('currentUser')).username};
    //notifications.username=JSON.parse(localStorage.getItem('currentUser')).username; 
    notifications.callingFromDoctor=true;
    notifications.callStatusEnum=CallStatusEnum;
    var json=JSON.stringify(notifications);
    console.log("noti--",json);
    this.apiservice.sendNotificationBackToServer(notifications).subscribe(
      response => {
        console.log("sendNotificationBackToServer",response);

        // this.authService.updateStatus("available").subscribe((resData: any) => {
        //   console.log("res--"+JSON.stringify(resData));
      
        //   } , error => {
        //  var data=error;
        //  });
         
      },
      error => {
        console.log("sendNotificationBackToServer",error);
      });

  }
  
  startTimer() {
    setTimeout(()=>{  
      if(!this.timer)
        this.rejectedCall(CallStatusEnum.NOT_ANSWER);                    
      this.timer = true;
    }, 20000); 
 }

  pauseTimer() {
    //this.timeLeft=-1;
    //clearInterval(this.interval);
    this.timer = true;
  }

  onRecevingMessage(event){
    if(event.data != null){
      var type = event.data.firebaseMessagingType;
      if(type == "push-msg-received" || type=="notification-clicked"){
        var payload = event.data.firebaseMessagingData,
        from = payload.from;
        // payload = payloadDdata.notification
        if(from == environment.firebase.messagingSenderId){
          console.log("new message received. ", payload);
        }
        this.notificationMsg=payload;
        this.timeLeft=20;
        this.timer = false;
        this.startTimer();
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: "Incoming Call?"
          });
          dialogRef.afterClosed().subscribe(result => {
            if(result) {
             console.log('Yes clicked');
               console.log("new message received. ", payload);
              this.notifications = Object.assign(new Notifications,payload['data']);
              localStorage.setItem("notifications",JSON.stringify(this.notifications));
                 this.currentMessage.next(payload);
                 this.pauseTimer();
                //  this.userService.callHomeComponent();
 
                console.log("currenturl--",this.router.url);
                if(this.router.url == "/tele-consult")
                  window.location.reload();
                else{
                  this.router.navigate(["/tele-consult"])
                  .then(() => {
                    window.location.reload();
                  });
                }
              //this.reloadComponent();
            }
            else if(result == false)
            {
               console.log("closed",result);
               this.pauseTimer();
               this.rejectedCall(CallStatusEnum.REJECT);
             }
          });
 
      }
    }
  }
}

 enum CallStatusEnum {
	ACCEPT, REJECT,NOT_ANSWER
}