import { Inject, Optional } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validator, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../_services/api-service';
import { PrescriptionService } from '../_services/prescription.service';
import { TeleConsultComponent } from '../components/tele-consult/tele-consult.component';

@Component({
  selector: 'app-dialog',
  templateUrl: './calltransfer.component.html',
  styleUrls: ['./calltransfer.component.scss'],
//   providers : [
//     User
//  ]
  providers: [ApiService]
})
export class CallTransferDialogComponent implements OnInit {
  formgroup: FormGroup;
  type: string;
  doctor: string = "";
  reason: string ="";
  dialogtitle : string = "Call Transfer";
  healthdata: any;
  doctorsList: any = [];

  constructor( private formBuilder: FormBuilder,public dialogRef: MatDialogRef<TeleConsultComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private prescriptionService: PrescriptionService,private apiService:ApiService
  )

   {
    dialogRef.disableClose = true;

  }

  ngOnInit() {
    this.formgroup = this.formBuilder.group({     
      doctor: new FormControl('', [Validators.required]),
      reason:new FormControl('', [Validators.required]),
    });
    this.loadDoctors();
}

loadDoctors(){
  this.apiService.getDoctorsList().subscribe((resData: any) => {
    this.doctorsList = resData;
    } , error => {
     var data=error;
     });
}

private stringToBoolean(stringValue: string): boolean | undefined {  
  try {  
      return JSON.parse(stringValue);  
  }  
  catch (e) {  
      return undefined;  
  }  
}  

  onCancel(){
    this.dialogRef.close({ event: 'close'});
  }

  onSave(){
  var data1 = {
  "doctor": this.doctor,
  "reason": this.reason,
}

console.log("doctor",this.doctor);
    this.dialogRef.close({ event: 'close', data:data1 });
    
  }

  onCancelClick(data:any){
    //this.prescriptionList.splice(data, 1);
    console.log("calltransfer"+data);
  }

  
}
