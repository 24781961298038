<ngx-spinner
bdColor = "rgba(51, 51, 51, 0.8)"
size = "medium"
color = "#fff" style="color: white"
type = "ball-spin">Loading...</ngx-spinner>

<mat-dialog-content>  
  <div>
      <section>
        <div *blockUI="'projectInfo'; message: 'Loading'">

          <div class="row">
            <div class="col-xl-12 col-md-12 col-12">
              <div class="card">
                <div class="card-header text-center">
                  <h4 class="card-title">Upload Reports</h4>
                </div>
                <div class="card-body">
                  <div class="card-block">

                    <form [formGroup]="reportsForm">

                      <div class="form-group">
                        <label class="form-control-label"
                        for="reportName">Report Name:</label>
                        <input class="form-control" type="text" maxlength="255"
                            [class.is-invalid]="pdf.reportName.invalid && pdf.reportName.touched" required
                            formControlName="reportName" />
                        <div *ngIf="pdf.reportName.errors && pdf.reportName.invalid && pdf.reportName.touched">
                            <small class="text-danger" *ngIf="pdf.reportName.errors.required">Report Name is required</small>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                        for="measureTime">Report Date:</label>
                        <div class="input-group">
                          <input class="form-control"
                          [class.is-invalid]="pdf.measureTime.errors && pdf.measureTime.invalid && pdf.measureTime.touched" required
                              formControlName="measureTime" type="date" max="{{tomorrow | date:'yyyy-MM-dd'}}" ngbTooltip="Report Date" placeholder="DD-MM-YYYY">
                              <!-- <mat-datepicker-toggle matSuffix [for]="picker" class="date"></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>   -->
                        </div>
                      <div *ngIf="pdf.measureTime.errors && pdf.measureTime.invalid && pdf.measureTime.touched">
                          <small class="text-danger" *ngIf="pdf.measureTime.errors.required">Report Date is required</small>
                      </div>
                    </div>

                      <div style="margin: 5px;">
                        <label class="label-control" style="margin-right:20px;" for="file">Upload Report</label>
                        <input type="file" id="file" accept="image/*" (change)="handleFileInput($event.target.files)">
                        <img src="{{report}}" width="100px" height="100px" *ngIf="report != ''" />    
                    </div>

                    </form>
                    
                    <div class="card-body" style="text-align: center;margin: 10px;">
                      <button
                        class="btn btn-danger" (click)="onCancel()">
                        Cancel
                      </button>

                      <button style="margin-left: 25px;"
                        class="btn btn-primary" (click)="onSubmit()" [disabled]="reportsForm.invalid">
                        Submit
                      </button>

                  </div>

                  </div>
                </div>
              </div>
            </div>
            </div>

          </div>
          </section>
  </div>

</mat-dialog-content>