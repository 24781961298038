<ngx-spinner
bdColor = "rgba(51, 51, 51, 0.8)"
size = "medium"
color = "#fff" style="color: white"
type = "ball-spin">Loading...</ngx-spinner>
<div class="row">
    <!-- <div class="col-12" style="z-index:9999999">
        <div style="float: right;" *ngIf="showCancel">
            <mat-icon (click)="onCancel()" style="font-size: 25px;cursor: pointer;">
                close
            </mat-icon>
        </div>
    </div> -->
    <div class="col-12">
      <div style="float: left;" data-toggle="tooltip" data-placement="right" title="back">
          <mat-icon (click)="onCancel()" style="font-size: 25px;cursor: pointer;">
            keyboard_backspace
          </mat-icon>
      </div>
  </div>
</div>
<div class="app-content content">
  <div class="content-wrapper" style="padding: 0px 2rem; margin-left:-20px;" >
      <div class="content-body" style="margin: -10px;">
          <!-- Simple User Cards -->
          <!-- <div style="padding-left: 20px;">
            <notifications></notifications>
          </div> -->

          <div class="row">
            
            <section class="col-xl-5 col-md-6 col-12">
              <div *blockUI="'projectInfo'; message: 'Loading';" style="min-height: 500px;">
                  <m-card [options]="vitaloptions" (reloadFunction)="refreshVitals()" style="height: 764.5px;" (click)="showCancelButton()">
                      <ng-container mCardHeaderTitle >
                        <div class="card-body" style="text-align: center;">
                          <img src="../../../assets/images/avatar.png" class="rounded-circle  height-50" alt="Card image">
                        </div>
                      <div class="card-body" style="margin-top:-30px;text-align: center;"> 
                          <h4 class="card-title">{{patientName}}{{age != null ? "," : ""}} {{age}} {{gender}}</h4><br>
                          <h6 class="card-subtitle text-muted">
                              <span >{{patientNumber}}</span><br>
                              <span *ngIf="Aadhar">Aadhaar: {{Aadhar}}</span>
                          </h6>
                      </div>
                      </ng-container>
                     
                      <!-- <ng-container mCardHeaderTitle>
                          Vitals
                      </ng-container>
                      <ng-container mCardBody>
                          <div class="card-text">
                              <p class="card-text">With supporting text below as a natural lead-in to
                                  additional content.</p>
                          </div>
                      </ng-container> -->
                      <ng-container mCardBody>
                          <ul class="nav nav-tabs _bottom nav-underline no-hover-bg tabsborder" style="margin-top:-60px;">
                              <ngb-tabset style="width: 100%;">
                                  <ngb-tab title="Health Info">
                                      <ng-template ngbTabContent>
                                        <div class="row addScroll">
                                          <div class="col-md-12 col-12">
                                              <div class="card text-center">
                                                  
                                                  <div class="table-responsive card-body" style="text-align: left;">
                                                      <table class="table table-bordered mb-0">
                                                          <tbody>
                                                              <!-- <tr>
                                                    <td class="width-300">Blood group:{{bloodGroup}}</td>
                                                  </tr>
                                                  <tr>
                                                    <td class="width-300">Blood group:{{bloodGroup}}</td>
                                                </tr> -->
                                                              <tr>
                                                                  <td class="width-300 ho-1">Blood group
                                                                      <!-- <span class="float-right" style="font-style: italic;font-size: 12px;padding-top: 5px;">{{bloodGroupUpdatedDate}}</span> -->
                                                                      <div class="btn btn-float float-right vr-lbl box-shadow-0 btn-outline-info"><span class="ladda-label"><span>{{bloodGroup}}</span></span>
                                                                      </div>
                                                                  </td>
                                                              </tr>
                                                              <!-- <tr>
                                                  <td class="width-300">Height
                                                    <span class="float-right"style="font-style: italic;">{{hwCapturedTime}}</span>
                                                    <div class="btn btn-float vr-lbl box-shadow-0 btn-outline-info"><span class="ladda-label"><span>{{height}}</span></span></div>
                                                   </td>
                                              </tr>
                                              <tr>
                                                  <td class="width-300">Weight
                                                    <span class="float-right"  style="font-style: italic;">{{hwCapturedTime}}</span>
                                                    <div class="btn btn-float vr-lbl box-shadow-0 btn-outline-info"><span class="ladda-label"><span>{{weight}}</span></span></div>
                                                   </td>
                                              </tr> -->
                                                              <tr [hidden]="lmp == null">
                                                                  <td class="width-300 ho-1">LMP
                                                                      <!-- <span class="float-right" style="font-style: italic;font-size: 12px;padding-top: 5px;">{{lmpUpdatedDate}}</span> -->
                                                                      <div class="btn btn-float float-right vr-lbl box-shadow-0 btn-outline-info"><span class="ladda-label"><span>{{lmp | date:'dd-MM-yyyy'}}</span></span>
                                                                      </div>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td class="width-300 ho-1">Diabetic 
                                                                    
                                                                      <!-- <span class="float-right" style="font-style: italic;font-size: 12px;padding-top: 5px;padding-left: 5px;">{{diabeticUpdatedDate}}</span> -->
                                                                      <div class="btn btn-float float-right  vr-lbl box-shadow-0 btn-outline-info"><span class="ladda-label"><span>{{diabetic}}</span></span>
                                                                      </div>
                                                                      <span *ngIf="diabetic == 'Yes' " style="float: right;">(since{{diabeticsince}})</span>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td class="width-300 ho-1">Thyroid
                                                                    
                                                                      <!-- <span class="float-right" style="font-style: italic;font-size: 12px;padding-top: 5px;padding-left: 5px;">{{thyroidUpdatedDate}}</span> -->
                                                                      <div class="btn btn-float float-right vr-lbl box-shadow-0 btn-outline-info"><span class="ladda-label"><span>{{thyroid}}</span></span>
                                                                      </div> 
                                                                      <span *ngIf="thyroid == 'Yes' " style="float: right;">(since{{thyroidsince}})</span>
                                                                      <!-- <span *ngIf="thyroid == 'Yes' " style="float:right;">{{thyroidsince}}</span> -->
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td class="width-300 ho-1">Blood pressure
                                                                    
                                                                      <!-- <span class="float-right" style="font-style: italic;font-size: 12px;padding-top: 5px;padding-left: 5px;">{{bloodPressureUpdatedDate}}</span> -->
                                                                      <div class="btn btn-float float-right vr-lbl box-shadow-0 btn-outline-info" ><span class="ladda-label" style="text-align: right;"><span>{{bp}}</span></span>
                                                                      </div>
                                                                      <span *ngIf="bp == 'Yes' " style="float: right;">(since{{bpsince}})</span>
                                                                  </td>
                                                              </tr>
                                                              <tr>
                                                                  <td class="width-300 ho-1">
                                                                      Allergies:
                                                                      <!-- <span class="float-right" style="font-style: italic;font-size: 12px;padding-top: 5px;">{{allergiesUpdatedDate}}</span> -->
                                                                      <p class="card-text">{{allergies}}
                                                                          <a *ngIf="allergies != null" class="primary edit mr-1 mrless" (click)="updateHealthdetails('allergies')"><i class="la la-pencil"></i>
                                                  </a>
                                                                      </p>
                                                                  </td>
                                                                  
                                                              </tr>
                                                              <tr>
                                                                  <td class="width-300 ho-1">
                                                                      Medical history:
                                                                      <!-- <span class="float-right" style="font-style: italic;font-size: 12px;padding-top: 5px;">{{medicalHistoryUpdatedDate}}</span> -->
                                                                      <p class="card-text">{{disease}}
                                                                          <a *ngIf="disease != null" class="primary edit mr-1 mrless" (click)="updateHealthdetails('history')"><i class="la la-pencil"></i>
                                                  </a>
                                                                      </p>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      </ng-template>
                                  </ngb-tab>
                                  <ngb-tab title="Vitals">
                                      <ng-template ngbTabContent>
                                          <div class="table-responsive" >
                                              <table class="table table-bordered mb-0 vitals-r">
                                                <tbody>
                                                  <tr>
                                                    <td class="width-300 ho-1 " >
                                                     
                                                      <h6 class="list-group-item-text text-muted" >Height</h6>
                                                      <h3 class="list-group-item-heading primary">{{height}}</h3>
                                                      <span class="float-right" style="float:right ;font-style: italic;">{{hwCapturedTime}}</span>
                                                        
                                                    </td>

                                                    <td class="width-300 ho-1 " >
                                                      
                                                      <h6 class="list-group-item-text text-muted" >Weight</h6>
                                                      <h3 class="list-group-item-heading primary" >{{weight}}</h3>
                                                      <span class="float-right" style="float:right ; font-style: italic;">{{hwCapturedTime}}</span>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td class="width-300 ho-1 "   (click)="LargeModel(LargeModelContent, 'BMI')">
                                                      
                                                      <h6 class="list-group-item-text text-muted" >BMI</h6>
                                                      <h3 class="list-group-item-heading primary" >{{bmi}}</h3>
                                                      <span class="float-right" style="float:right ; font-style: italic;">{{hwCapturedTime}}</span>
                                                        
                                                    </td>
                                                    <td class="width-300 ho-1 "    (click)="LargeModel(LargeModelContent,'Pulse')">
                                                      
                                                      <h6 class="list-group-item-text text-muted" >Pulse</h6>
                                                        <h3 class="list-group-item-heading primary" >{{pulse}}</h3>
                                                        <span class="float-right" style="float:right ; font-style: italic;">{{pulseCapturedTime}}</span>
                                                        
                                                    </td>

                                                    
                                                  </tr>
                                                  
                                                  <tr>
                                                    <td class="width-300 ho-1 "   (click)="LargeModel(LargeModelContent,'Blood pressure')" >
                                                     
                                                      <h6 class="list-group-item-text text-muted" >Blood pressure</h6>
                                                      <h3 class="list-group-item-heading primary" >{{bloodPressure}}</h3>
                                                      <span class="float-right" style="float:right ; font-style: italic;">{{bpCapturedTime}}</span>
                                                        
                                                    </td>
                                                    <td class="width-300 ho-1 "   (click)="LargeModel(LargeModelContent,'Blood glucose')">
                                                
                                                      <h6 class="list-group-item-text text-muted" >Blood glucose</h6>
                                                        <h3 class="list-group-item-heading primary" >{{bloodGlucose}}</h3>
                                                        <span class="float-right" style="float:right ; font-style: italic;">{{glucoCapturedTime}}</span>
                                                        
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td class="width-300 ho-1 "  (click)="LargeModel(LargeModelContent,'Body temperature')">
                                                     
                                                      <h6 class="list-group-item-text text-muted" >Body temperature</h6>
                                                        <h3 class="list-group-item-heading primary" >{{temperature}}</h3>
                                                        <span class="float-right" style="float:right ; font-style: italic;">{{tempCapturedTime}}</span>
                                                        
                                                    </td>
                                                    
                                                    <td class="width-300 ho-1 "   (click)="LargeModel(LargeModelContent,'Blood saturation')">
                                                      
                                                      <h6 class="list-group-item-text text-muted" >Blood saturation</h6>
                                                        <h3 class="list-group-item-heading primary" >{{spO2}}</h3>
                                                        <span class="float-right" style="float:right ; font-style: italic;">{{pulseCapturedTime}}</span>
                                                        
                                                    </td>
                                                  </tr>
                                                  <!--     <tr>
                                                    <td class="width-300 ho-1 " >
                                                       (click)="LargeModel(LargeModelContent,'High Density Lipoprotein')" 
                                                      <span class="float-right" style="font-style: italic;">{{cholestolCapturedTime}}</span>
                                                        <h3 class="list-group-item-heading primary">{{hdlipoprotein}}</h3>
                                                        <h6 class="list-group-item-text text-muted">High Density Lipoprotein</h6>
                                                    </td>
                                                    <td class="width-300 ho-1 " >
                                                       (click)="LargeModel(LargeModelContent,'Low density lipoprotein')" 
                                                      <span class="float-right" style="font-style: italic;">{{cholestolCapturedTime}}</span>
                                                        <h3 class="list-group-item-heading primary">{{ldlipoprotein}}</h3>
                                                        <h6 class="list-group-item-text text-muted">Low density lipoprotein</h6>
                                                    </td>
                                                  </tr>
                                                     <tr>
                                                      <td class="width-300 ho-1 "> 
                                                       (click)="LargeModel(LargeModelContent,'Hemoglobin')" 
                                                      <span class="float-right" style="font-style: italic;">{{himoglobinCapturedTime}}</span>
                                                        <h3 class="list-group-item-heading primary">{{himoglobin}}</h3>
                                                        <h6 class="list-group-item-text text-muted">Hemoglobin</h6>
                                                    </td>   
                                                  
                                                  <td class="width-300 ho-1 " >
                                                      < (click)="LargeModel(LargeModelContent,'Urin analysis')" 
                                                      <span class="float-right" style="font-style: italic;">{{urinanalysisCapturedTime}}</span>
                                                        <h3 class="list-group-item-heading primary">{{urinanalysis}}</h3>
                                                        <h6 class="list-group-item-text text-muted">Urin analysis</h6>
                                                    </td>
                                                  </tr>  
                                                  <tr>
                                                    <td class="width-300 ho-1 ">
                                                       (click)="LargeModel(LargeModelContent,'Total cholestrol')" 
                                                      <span class="float-right" style="font-style: italic;">{{cholestolCapturedTime}}</span>
                                                        <h3 class="list-group-item-heading primary">{{totalcholestrol}}</h3>
                                                        <h6 class="list-group-item-text text-muted">Total cholestrol</h6>
                                                    </td>
                                                    <td class="width-300 ho-1 " >
                                                       (click)="LargeModel(LargeModelContent,'Triglyceride')" 
                                                      <span class="float-right" style="font-style: italic;">{{cholestolCapturedTime}}</span>
                                                        <h3 class="list-group-item-heading primary">{{triglyceride}}</h3>
                                                        <h6 class="list-group-item-text text-muted">Triglyceride</h6>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td class="width-300 ho-1 " >
                                                       (click)="LargeModel(LargeModelContent,'Uric acid')" 
                                                      <span class="float-right" style="font-style: italic;">{{uricacidCapturedTime}}</span>
                                                        <h3 class="list-group-item-heading primary">{{uricacid}}</h3>
                                                        <h6 class="list-group-item-text text-muted">Uric acid</h6>
                                                    </td>  

                                                    <td class="width-300 ho-1 " >
                                                      (click)="LargeModel(LargeModelContent,'ECG')" 
                                                      <span class="float-right" style="font-style: italic;">{{ecgCapturedTime}}</span>
                                                        <p class="list-group-item-heading primary">{{ecgResult}}</p>
                                                        <h6 class="list-group-item-text text-muted">ECG</h6>
                                                    </td>
                                                  
                                                  </tr>  -->                                                            
                                                </tbody>
                                              </table>
                                          </div>
                                      </ng-template>
                                  </ngb-tab>
                                                              
                                  <ngb-tab>
                                    <ng-template ngbTabTitle>Reports</ng-template>
                                    <ng-template ngbTabContent>
                                      <div class="table-bordered default-ordering ttable">
                                        <ngx-datatable class="bootstrap" [limit]="7" [rows]="reportrows" [columnMode]="'force'" [scrollbarH]="true" [messages]="reportsDataTableMessage"
                                          [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" fxFlex="auto" >
                    
                                          <ngx-datatable-column name="Report ID"  prop="recordNo" [flexGrow]="1" [minWidth]="90">
                                            <ng-template ngx-datatable-cell-template let-value="value">
                                              {{value}}
                                            </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Report Name" prop="reportName" [flexGrow]="1" [minWidth]="90">
                                            <ng-template ngx-datatable-cell-template let-value="value">
                                              {{value}}
                                            </ng-template>
                                          </ngx-datatable-column>

                                          <ngx-datatable-column name="Report Date" prop="measureTime" [flexGrow]="1" [minWidth]="90">
                                            <ng-template ngx-datatable-cell-template let-value="value">
                                              {{value | date:'dd-MM-yyyy'}}
                                            </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Actions" [flexGrow]="1" [minWidth]="30">
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                              <a [routerLink]="" class="primary edit mr-1 mrless"
                                                (click)="openReports(row,MediumModelContent,HBModelContent,UAModelContent,UricacidModelContent,CholModelContent,ReportsModelContent)"><i class="la la-eye"></i>
                                              </a>
                                            </ng-template>

                                          </ngx-datatable-column>
                                        </ngx-datatable>
                                           </div>
                                    </ng-template>
                                </ngb-tab>
                                  <ngb-tab>
                                      <ng-template ngbTabTitle>Prescriptions</ng-template>
                                      <ng-template ngbTabContent >
                                        <div class="table-bordered default-ordering ttable">
                                          <ngx-datatable class="bootstrap" [limit]="7" [rows]="consultRows" [columnMode]="'force'" [scrollbarH]="true" [messages]="prescriptionsDataTableMessage"
                                            [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" fxFlex="auto" >
                      
                                            <ngx-datatable-column name="Consult. Date"  prop="time" [flexGrow]="1" [minWidth]="90">
                                              <ng-template ngx-datatable-cell-template let-value="value">
                                                {{value | date:'dd-MM-yyyy HH:mm:ss'}}
                                              </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Chief Complaint" prop="clinicalNotes.complaints" [flexGrow]="1" [minWidth]="90">
                                              <ng-template ngx-datatable-cell-template let-value="value">
                                                {{value}}
                                              </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Doctor Name" [flexGrow]="1" [minWidth]="50">
                                              <ng-template ngx-datatable-cell-template let-row="row">
                                                {{row.registrationDTO != null ? "Dr.": ""}} {{row.registrationDTO != null ? row.registrationDTO.given :""}} {{row.registrationDTO != null ? row.registrationDTO.family:""}}{{row.registrationDTO != null ? ",": ""}} {{row.registrationDTO != null ? row.registrationDTO.practicing:""}}
                                              </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Contact" prop="registrationDTO.mobile" [flexGrow]="1" [minWidth]="90">
                                              <ng-template ngx-datatable-cell-template let-value="value">
                                                {{value}}
                                              </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Actions" [flexGrow]="1" [minWidth]="30">
                                              <ng-template ngx-datatable-cell-template let-row="row">
                                                <a [routerLink]="" class="primary edit mr-1 mrless"
                                                  (click)="openPrescriptionPreview(row)"><i class="la la-eye"></i>
                                                </a>
                                              </ng-template>
                                            </ngx-datatable-column>
                                          </ngx-datatable>
                                             </div>
                                      </ng-template>
                                  </ngb-tab>

                              </ngb-tabset>
                          </ul>
                      </ng-container>
                  </m-card>
              </div>
          </section>
          <section class="col-xl-7 col-md-6 col-12">
            <div class="row">
                <section class="col-md-12 col-12">
                    <div *blockUI="'projectInfo'; message: 'Loading';">
                        <m-card [options]="agoraCardoptions" style="height: 764.5px;" class="video-p" (click)="showCancelButton()" >
                            <ng-container mCardHeaderTitle>
                               Source: {{callTransferReason != null ? callTransferReason == "" ? "Patient" : "Transfer" : ""}}

                               <!-- <div [hidden]="patientNumber == null" style="float: right;margin-right: 20px;">
                               <button (click)="calltransfer()"  _ngcontent-vyc-c137="" type="button" class="btn btn-icon btn-secondary mr-1 btn-sm" ngbTooltip="Transfer Call" placement="top" >
                                  <i _ngcontent-bfs-c169="" class="feather ft-phone-forwarded"></i>
                              </button>
                              </div> -->
                            </ng-container>
                            <ng-container mCardBody>
                              <!-- <agora [parentData]="parentChannel"></agora> -->

                              <div [hidden]="connecting" class="text-center" style="margin: 5px;">
                                <div class="loader-wrapper">
                                  <div class="loader-container">
                                    <div class="fading-circle loader-blue-grey">
                                      <div class="circle1 circle"></div>
                                      <div class="circle2 circle"></div>
                                      <div class="circle3 circle"></div>
                                      <div class="circle4 circle"></div>
                                      <div class="circle5 circle"></div>
                                      <div class="circle6 circle"></div>
                                      <div class="circle7 circle"></div>
                                      <div class="circle8 circle"></div>
                                      <div class="circle9 circle"></div>
                                      <div class="circle10 circle"></div>
                                      <div class="circle11 circle"></div>
                                      <div class="circle12 circle"></div>
                                    </div>
                                  </div>
                                </div>
                                Connecting...
                              </div>

                              <div [hidden]="patientNumber != null">
                                <img src="../assets/images/default-video.png" width="100%" height="280px">
                              </div>
                              <div style="height: 550px;" [hidden]="patientNumber == null">
                                <videosdk [meetingId]="meetingNumber"></videosdk>
                              </div>
                              <!-- <iframe #iframe [hidden]="patientNumber == null" (load)="iframeUrl(iframe)" allow="camera; microphone" [src]="meetingUrl" width="100%" [ngClass]="applyStyles ? 'height1' : 'height2'"></iframe> -->
                            </ng-container>
                        </m-card>
                    </div>
                </section>
                </div>
            </section>
          <section class="col-xl-12 col-md-12 col-12">
            <div *blockUI="'projectInfo'; message: 'Loading'">
                <m-card [options]="agoraCardoptions" style="height: 764.5px;">
                    <ng-container mCardHeaderTitle>
                        Prescription
                        <p style="color: red;margin: 5px 0;">Patient Notes: {{chiefComplaint}}</p>                        
                  </ng-container>
                
                  <ng-container mCardBody >
                        <div class="card-text addScroll" id="style-3" style="margin-top:-30px;">
                            <form [formGroup]="consultationForm" autocomplete="off">
                                <div class="form-body">
                                  <div class="row">
                                        <div class="col-sm form-group">
                                            <label for="chiefComplaint">Chief complaint</label>
                                            <input type="text" id="chiefComplaint" class="form-control" placeholder="Chief complaint" [disabled]="published"
                                            [ngClass]="{ 'is-invalid': submitted && pdf.complaints.errors }"
                                            (selectItem)="suggestPrescription($event)" [ngbTypeahead]="suggestComplaints" [resultFormatter]="formatter" [inputFormatter]="formatter"
                                            ngbTooltip="Chief complaint" placement="bottom" formControlName="complaints">
                                            <div *ngIf="submitted && pdf.complaints.errors" class="invalid-feedback">
                                            <div *ngIf="pdf.complaints.errors.required">Chief complaint is required</div>
                                            </div>
                                        </div>
                                        <div class="col-sm form-group">
                                            <label for="symptoms">Symptoms</label>
                                            <input type="text" id="symptoms" class="form-control" placeholder="Symptoms" [disabled]="published"
                                            ngbTooltip="Symptoms" placement="top" formControlName="observation">
                                        </div>
                                        <div class="col-sm form-group">
                                            <label for="examinations">Examinations/Lab Findings</label>
                                            <input type="text" id="examinations" class="form-control" placeholder="Examinations/Lab Findings" [disabled]="published"
                                            ngbTooltip="Examinations/Lab Findings" placement="top" formControlName="examinations">
                                        </div>
                                  </div>
                                  <div class="row">
                                        <div class="col-sm form-group">
                                            <label for="relevantPoints">Relevant points From History</label>
                                            <input type="text" id="relevantPoints" class="form-control" placeholder="History" [disabled]="published"
                                            ngbTooltip="Relevant points from history" placement="top" formControlName="relevantPoints">
                                        </div>
                                        <div class="col-sm form-group">
                                            <label for="diagnosys">Diagnosis/ Provisional Diagnosis</label>
                                            <input id="typeahead-http" type="text" class="form-control" placeholder="Diagnosis" [disabled]="published"
                                            [ngClass]="{ 'is-invalid': submitted && pdf.diagnosis.errors }"
                                            [ngbTypeahead]="searchFromService"  ngbTooltip="Diagnosis/ Provisional Diagnosis" placement="bottom" formControlName="diagnosis" />
                                            <!-- <input type="text" id="diagnosys" class="form-control" placeholder="Diagnosys"
                                            ngbTooltip="Diagnosys/ Provisional Diagnosys" placement="top" formControlName="diagnosys"> -->
                                            <!-- <ng-select [items]="conditions"   [multiple]="true" bindLabel="item_text"
                                            placeholder="Diagnosys"  formControlName="diagnosys" >
                                            </ng-select> -->
                                            <div *ngIf="submitted && pdf.diagnosis.errors" class="invalid-feedback">
                                            <div *ngIf="pdf.diagnosis.errors.required">Diagnosis/ Provisional diagnosis is required</div>
                                        </div>
                                        </div>
                                        <div class="col-sm form-group">
                                            <label for="investigation">Suggested Investigations</label>
                                            <input type="text" id="investigation" class="form-control" placeholder="Suggested Investigations" [disabled]="published"
                                            ngbTooltip="Suggested Investigations" placement="top" formControlName="investigation">
                                        </div>
                                  </div>
                                  <div class="form-group">
                                    <label for="clinicalNotes">Clinical Notes / Special Instruction</label>
                                    <input type="text" id="clinicalNotes" class="form-control" placeholder="Clinical Notes" [disabled]="published"
                                    ngbTooltip="Clinical Notes / Special Instruction" placement="top" formControlName="notes">
                                  </div>
                                  <div class=""  formArrayName="prescriptionList">
                                        <label for="">Medication</label>
                                        <div class="form-group ">
                                          <div class="col-12 pl-0" *ngIf="!published">
                                            <button data-repeater-create  class="btn btn-primary " (click)="addPrescritption()">
                                              <i class="feather ft-plus"></i> Add
                                            </button>
                                          </div>
                                        </div>
                                        <div class="med-row" *ngFor="let prescriptionForm of consultationFormGroup.controls; let i = index;" [formGroupName]="i">
                                            <div class="row">
                                                <div class="form-group  col-md-4 pr-1" style="padding-left:20px;">
                                                   <label for="drug">Drug</label>
                                                 <!-- <input id="typeahead-http" type="text" class="form-control" placeholder="Drug"
                                                 [ngbTypeahead]="searchDrug"  ngbTooltip="Drug" placement="top" formControlName="drug" (onSelectionChange)="onDrugSelect(i,drug)"/> -->
                                                 <input type="text" class="form-control"
                                                 placeholder="Drug"
                                                 formControlName="drugName"
                                                 [disabled]="published"
                                                 [matAutocomplete]="auto">

                                                 <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)='getDrugAlts(i,$event.option.value)'> 
                                                  <mat-option  *ngFor="let drug of drugs[i] | async"
                                                  [value]="drug">
                                                    {{drug.name}}
                                                  </mat-option>
                                                </mat-autocomplete>
     
                                                </div>                                                       
                                                
                                                <div class="form-group  col-md-2 prl-1" style="padding-left:20px;display:none;">
                                                    <label for="strength">Strength</label>
                                                    <input type="text" id="strength" class="form-control" placeholder="Strength" [disabled]="published"
                                                    ngbTooltip="Strength" placement="top" formControlName="dosage">
                                                </div>
                                                <!-- <div class="form-group  col-md-2">
                                                  <label for="strength">Strength</label>
                                                  <div class="form-control">
                                                    <mat-select  formControlName="dosage">
                                                      <mat-option *ngFor="let dosage of dosagelist[i]" [value]="dosage">{{dosage}}</mat-option>
                                                    </mat-select>
                                                  </div>
                                              </div> -->
                                                <!-- <div class="form-group  col-md-2">
                                                    <label for="instruction">Instruction</label>
                                                    <input type="text" id="instruction" class="form-control" placeholder="Instruction"
                                                    ngbTooltip="Instruction" placement="top" formControlName="instruction">
                                                </div> -->
                                                <div class="form-group  col-md-2 prl-0" style="padding-left:20px;">
                                                  <label for="instruction">Instructions</label>
                                                  <div class="form-control">
                                                    <mat-select  formControlName="instructions" [disabled]="published">
                                                      <mat-option *ngFor="let instruction of instructions" [value]="instruction">{{instruction}}</mat-option>
                                                    </mat-select>
                                                  </div>
                                              </div>
                                                <div class="form-group  col-md-2 prl-0" style="padding-left:20px;">
                                                    <label for="frequency">Frequency</label>
                                                    <div class="form-control">
                                                      <mat-select   multiple formControlName="frequency" [disabled]="published">
                                                        <mat-option *ngFor="let frequency of frequencies" [value]="frequency">{{frequency}}</mat-option>
                                                      </mat-select>
                                                    </div>
                                                </div>
                                                <div class="form-group  col-md-2 prl-0" style="padding-left:20px;">
                                                  <label for="duration">Duration</label>
                                                  <div class="form-control">
                                                    <mat-select  formControlName="duration" [disabled]="published">
                                                      <mat-option *ngFor="let duration of durations" [value]="duration">{{duration}}</mat-option>
                                                    </mat-select>
                                                  </div>
                                              </div>
                                                <!-- <div class="form-group  col-md-2">
                                                    <label for="duration">Duration</label>
                                                    <input type="text" id="duration" class="form-control" placeholder="Duration"
                                                    ngbTooltip="Duration" placement="top" formControlName="duration">
                                                </div> -->
                                                <div class="form-group col-md-2 text-center " *ngIf="!published">
                                                    <button class="btn btn-danger mt-2" type="button" (click)="removePrescritption(i)"><i
                                                        class="feather ft-x"></i></button>
                                                </div>
                                            </div>
                                            <div class="row" [hidden]="!showAltFlag[i]">
                                              <div class="col-md-1">
                                                <label for="altDrugs">&nbsp;Alternative&nbsp;Drugs:</label>
                                              </div>
                                              <div class="form-group col-md-5">
                                                <ng-select [items]="drugAlts[i] | async" [multiple]="true" [maxSelectedItems]="3" formControlName="altDrugs" bindLabel="name"
                                                  placeholder="Alternative Drugs">
                                                </ng-select>
                                              </div>
                                              <div class="form-group col-md-6" role="group"
                                                aria-label="Alternative Drugs">
                                                <button type="button" style="min-height: 36px;margin-bottom: 5px;margin-right: 5px;" class="js-programmatic-multi-set-val btn btn-sm btn-outline-primary"
                                                  *ngFor="let item of drugAlts[i] | async | slice:0:4; let in=index" (click)="addAltDrug(i,item)">
                                                  <i class="feather ft-plus"></i> {{item.name}}
                                                </button>
                                              </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                <div class=""  formArrayName="lifestylemodficationList">
                                  <label for="">Life Style Changes</label>
                                  <div class="form-group ">
                                    <div class="col-12 pl-0" *ngIf="!published">
                                      <button data-repeater-create  class="btn btn-primary " (click)="addLifeStyleModfication()">
                                        <i class="feather ft-plus"></i> Add 

                                      </button>

                                      <button type="button" style="min-height: 36px;margin-bottom: 5px;margin-left:1px;margin-right: 5px;" class="js-programmatic-multi-set-val btn btn-sm btn-outline-primary"
                                                  *ngFor="let item of modificationSuggestions | slice:0:3; let in=index" (click)="addLifeStyleSuggestion(item)">
                                                  <i class="feather ft-plus"></i> {{item.lifeStyleChanges}}
                                      </button>
                                    </div>
                                  </div>
                                  <div class="" *ngFor="let prescriptionForm of lifeStyleModficationFormGroup.controls; let i = index;">
                                      <div [formGroupName]="i" class="row">                
                                          <div class="form-group  col-md-10 prl-1" style="padding-left:20px;">
                                              <label for="Changes">Tips</label>
                                              <input type="text" id="changes" class="form-control" placeholder="Life Style modifications" [disabled]="published"
                                              ngbTooltip="life style changes" placement="top" formControlName="changes">
                                          </div>


                                          <div class="form-group col-md-2 text-center " *ngIf="!published">
                                              <button class="btn btn-danger mt-2" type="button" (click)="removeLifeStyleModfication(i)"><i
                                                  class="feather ft-x"></i></button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                                <div *ngIf="isDisabled"> 
                                  
                                <div class="form-actions" *ngIf="!published" style="text-align: center;">
                                  <button type="submit" (click)="savePrescription()" class="btn btn-primary mr-1" >
                                    Save
                                 </button>
                                  <button type="submit" class="btn btn-primary" (click)="review()" style="margin-left:7px;">
                                    Review & Publish
                                 </button>
                                  <button type="button" class="btn btn-warning mr-1"  (click)="reset()"  style="margin-left:20px;">
                                     Reset
                                  </button>

                                </div>
                              </div>
                              </form>
                        </div>
                        <!-- Content here -->
                    </ng-container>
                </m-card>
            </div>
        </section>
            </div>
                  

                  <!-- Modal -->
                  <ng-template class="modal text-left" #LargeModelContent let-c="close" let-d="dismiss">
                    <div class="modal-lg">
                      <!-- <div class="modal-header">
                        <h6 id="myModalLabel23">Basic Modal</h6>
                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div> -->
                      <div class="modal-body">
                        <section>
                          <div *blockUI="'projectInfo'; message: 'Loading'">
                              <!-- <m-card [options]="graphCardOptions" >
                                  <ng-container mCardHeaderTitle>
                                      <span >Vital Analytics</span>
                                  </ng-container>
                                  <ng-container mCardBody> -->
                                    <!-- <div class="card-text"> -->
                                    <div class="row">
                                      <div class="col-xl-4 col-md-12 col-12">
                                        <div class="row">
                                          <div class="col-xl-12 col-md-12 col-12">
                                            <div class="card ">
                                              <div class="card-content">
                                                  <div class="card-body">
                                                    <!-- <span class="float-right" style="font-style: italic;">3 hours ago</span> -->
                                                      <h4 class="card-title danger">{{vitalType}}</h4>
                                                      <h5 class="card-title">{{patientName}}</h5>
                                                      <!-- <p class="card-text">24.5</p> -->
                                                  </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-xl-12 col-md-12 col-12">
                                            <div class="card table-bottom-space">
                                              <div class="card-content collapse show" style="margin: -5px 0px -5px -5px;">
                                                <!-- <div class="table">
                                                  <div class="table-responsive">
                                                    <table class="table table-bordered table-striped">
                                                     <thead>
                                                     <tr>
                                                       <th scope="col">#</th>
                                                       <th scope="col">Firstname</th>
                                                       <th scope="col">Lastname</th>
                                                       <th scope="col">Username</th>
                                                     </tr>
                                                     </thead>
                                                     <tbody>
                                                  
                                                     </tbody>
                                                   </table>
                                                   </div>
                                    
                                                </div> -->
                                                <div class="table-bordered default-ordering">
                                                  <ngx-datatable class="bootstrap" [limit]="7" [rows]="rows" [columnMode]="'force'"
                                                    [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" fxFlex="auto" [perfectScrollbar]="config">
                              
                                                    <!-- <ngx-datatable-column name="Name"  prop="name" [flexGrow]="1" [minWidth]="90">
                                                      <ng-template ngx-datatable-cell-template let-value="value">
                                                        {{value}}
                                                      </ng-template>
                                                    </ngx-datatable-column> -->
                                                    <ngx-datatable-column name="Vital Value" prop="vital" [flexGrow]="1" [minWidth]="90">
                                                      <ng-template ngx-datatable-cell-template let-value="value">
                                                        {{value}}
                                                      </ng-template>
                                                    </ngx-datatable-column>
                                                    <ngx-datatable-column name="Time" prop="time" [flexGrow]="1" [minWidth]="90">
                                                      <ng-template ngx-datatable-cell-template let-value="value">
                                                        {{value}}
                                                      </ng-template>
                                                    </ngx-datatable-column>
                                    
                                                  </ngx-datatable>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                          
                                      </div>
                                      <div class="col-xl-8 col-md-12 col-12">
                                        <div class="row">
                                          <div class="col-xl-12 col-md-12 col-12">
                                            <div class="card">
                                              <div class="card-header">
                                                <h4 class="card-title">{{vitalType}} Chart</h4>
                                              </div>
                                              <div class="card-body">
                                                <div class="card-block">
                                                  <ngb-tabset>
                                                    <ngb-tab title="Week">
                                                        <ng-template ngbTabContent>
                                                          <div id="line-area4" class="height-400" style="margin-top: 10px;">
                                                            <!-- <x-chartist *ngIf="lineArea4" [data]="lineArea4.data" [type]="lineArea4.type" [options]="lineArea4.options"
                                                              [responsiveOptions]="lineArea4.responsiveOptions" [events]="lineArea4.events">
                                                            </x-chartist> -->
                                                            <canvas baseChart class="chart" *ngIf="dataLoaded" [datasets]="chartsData" [labels]="chartsLabels"
                                                              [options]="chartsOptions"  [legend]="line"
                                                              [chartType]="lineChartsType"></canvas>
                                                          </div>
                                                        </ng-template>
                                                    </ngb-tab>

                                                    <ngb-tab title="Month">
                                                      <ng-template ngbTabContent>
                                                        <div id="line-area4" class="height-400" style="margin-top: 10px;">
                                                          <canvas baseChart class="chart" *ngIf="dataLoaded" [datasets]="monthChartsData" [labels]="monthChartsLabels"
                                                            [options]="chartsOptions"  [legend]="line"
                                                            [chartType]="lineChartsType"></canvas>
                                                        </div>
                                                      </ng-template>
                                                  </ngb-tab>

                                                  <ngb-tab title="Year">
                                                    <ng-template ngbTabContent>
                                                      <div id="line-area4" class="height-400" style="margin-top: 10px;">
                                                        <canvas baseChart class="chart" *ngIf="dataLoaded" [datasets]="yearChartsData" [labels]="yearChartsLabels"
                                                          [options]="chartsOptions"  [legend]="line"
                                                          [chartType]="lineChartsType"></canvas>
                                                      </div>
                                                    </ng-template>
                                                </ngb-tab>
                                              </ngb-tabset>
                                                  
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <!-- <div class="col-xl-12 col-md-12 col-12">
                                            <div class="card text-center">
                                              <div class="card-body">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                    <label>Overal Status</label>
                                                  </div>
                                                  <div class="col-md-3">
                                                    <span>32.0</span>
                                                    <p>Highest</p>
                                                  </div>
                                                  <div class="col-md-3">
                                                    <span>26.0</span>
                                                    <p>Average</p>
                                                  </div>
                                                  <div class="col-md-3">
                                                    <span>20.0</span>
                                                    <p>Lowest</p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div> -->
                                        </div>
                                      </div>
                                    </div>
                                    <!-- </div> -->
                                  <!-- </ng-container>
                              </m-card> -->
                          </div>
                        </section>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn grey btn-outline-secondary"
                          (click)="d('Close modal')">Close</button>
                      </div>
                    </div>
                  </ng-template>

                   <!-- Lp Modal -->
                   <ng-template class="modal text-left" #MediumModelContent let-c="close" let-d="dismiss">
                    <div class="modal-md">

                      <div class="modal-body">
                        <section>
                          <div *blockUI="'projectInfo'; message: 'Loading'">

                            <div class="row">
                              <div class="col-xl-12 col-md-12 col-12">
                                <div class="card">
                                  <div class="card-header">
                                    <h4 class="card-title">Lipids Profile Report</h4>
                                  </div>
                                  <div class="card-body">
                                    <div class="card-block">

                                      <div class="table-responsive" >
                                        <table class="table table-bordered mb-0">
                                             <thead>
                                             <tr>
                                               <th scope="col">Parameter</th>
                                               <th scope="col">Value</th>
                                             </tr>
                                             </thead>
                                             <tbody>
                                             <tr>
                                              <td>Total cholestrol</td>
                                              <td>{{lipids.TChol}} mg/dL</td>
                                             </tr>
                                             <tr>
                                              <td>High density lipoprotein</td>
                                              <td>{{lipids.HdlChol}} mg/dL</td>
                                             </tr>
                                             <tr>
                                              <td >Low density lipoprotein</td>
                                              <td>{{lipids.CalcLdl}} mg/dL</td>
                                             </tr>
                                             <tr>
                                              <td>Tryglyceride</td>
                                              <td >{{lipids.Trig}} mg/dL</td>
                                             </tr>
                                             </tbody>
                                        </table>
                                        </div>
                                      
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </div>

                            </div>
                            </section>
                            </div>

                            <div class="modal-footer">
                              <button type="button" class="btn grey btn-outline-secondary"
                                (click)="d('Close modal')">Close</button>
                            </div>

                     </div>
                  </ng-template>

                  <!-- Hb Modal -->
                  <ng-template class="modal text-left" #HBModelContent let-c="close" let-d="dismiss">
                    <div class="modal-md">

                      <div class="modal-body">
                        <section>
                          <div *blockUI="'projectInfo'; message: 'Loading'">

                            <div class="row">
                              <div class="col-xl-12 col-md-12 col-12">
                                <div class="card">
                                  <div class="card-header">
                                    <h4 class="card-title">Hemoglobin Report</h4>
                                  </div>
                                  <div class="card-body">
                                    <div class="card-block">

                                      <div class="table-responsive" >
                                        <table class="table table-bordered mb-0">
                                             <thead>
                                             <tr>
                                               <th scope="col">Parameter</th>
                                               <th scope="col">Value</th>
                                             </tr>
                                             </thead>
                                             <tbody>
                                             <tr>
                                              <td>Hemoglobin</td>
                                              <td>{{hb.Hb}} g/dL</td>
                                             </tr>
                                             <tr>
                                              <td>Hematocrit</td>
                                              <td>{{hb.Hct}} %</td>
                                             </tr>
                                             </tbody>
                                        </table>
                                        </div>
                                      
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </div>

                            </div>
                            </section>
                            </div>

                            <div class="modal-footer">
                              <button type="button" class="btn grey btn-outline-secondary"
                                (click)="d('Close modal')">Close</button>
                            </div>

                     </div>
                  </ng-template>

                  <!-- Chol Modal -->
                  <ng-template class="modal text-left" #CholModelContent let-c="close" let-d="dismiss">
                    <div class="modal-md">

                      <div class="modal-body">
                        <section>
                          <div *blockUI="'projectInfo'; message: 'Loading'">

                            <div class="row">
                              <div class="col-xl-12 col-md-12 col-12">
                                <div class="card">
                                  <div class="card-header">
                                    <h4 class="card-title">Cholestrol Report</h4>
                                  </div>
                                  <div class="card-body">
                                    <div class="card-block">

                                      <div class="table-responsive" >
                                        <table class="table table-bordered mb-0">
                                             <thead>
                                             <tr>
                                               <th scope="col">Parameter</th>
                                               <th scope="col">Value</th>
                                             </tr>
                                             </thead>
                                             <tbody>
                                             <tr>
                                              <td>Cholesterol</td>
                                              <td>{{chol.Chol}} mg/dL</td>
                                             </tr>
                                             
                                             </tbody>
                                        </table>
                                        </div>
                                      
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </div>

                            </div>
                            </section>
                            </div>

                            <div class="modal-footer">
                              <button type="button" class="btn grey btn-outline-secondary"
                                (click)="d('Close modal')">Close</button>
                            </div>

                     </div>
                  </ng-template>

                  <!-- Uric acid Modal -->
                  <ng-template class="modal text-left" #UricacidModelContent let-c="close" let-d="dismiss">
                    <div class="modal-md">

                      <div class="modal-body">
                        <section>
                          <div *blockUI="'projectInfo'; message: 'Loading'">

                            <div class="row">
                              <div class="col-xl-12 col-md-12 col-12">
                                <div class="card">
                                  <div class="card-header">
                                    <h4 class="card-title">Uric Acid Report</h4>
                                  </div>
                                  <div class="card-body">
                                    <div class="card-block">

                                      <div class="table-responsive" >
                                        <table class="table table-bordered mb-0">
                                             <thead>
                                             <tr>
                                               <th scope="col">Parameter</th>
                                               <th scope="col">Value</th>
                                             </tr>
                                             </thead>
                                             <tbody>
                                             <tr>
                                              <td>Uric Acid</td>
                                              <td>{{urica.Ua}} mg/dL</td>
                                             </tr>
                                             </tbody>
                                        </table>
                                        </div>
                                      
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </div>

                            </div>
                            </section>
                            </div>

                            <div class="modal-footer">
                              <button type="button" class="btn grey btn-outline-secondary"
                                (click)="d('Close modal')">Close</button>
                            </div>

                     </div>
                  </ng-template>

                   <!-- UA Modal -->
                   <ng-template class="modal text-left" #UAModelContent let-c="close" let-d="dismiss">
                    <div class="modal-md">

                      <div class="modal-body">
                        <section>
                          <div *blockUI="'projectInfo'; message: 'Loading'">

                            <div class="row">
                              <div class="col-xl-12 col-md-12 col-12">
                                <div class="card">
                                  <div class="card-header">
                                    <h4 class="card-title">Urine Analysis Report</h4>
                                  </div>
                                  <div class="card-body">
                                    <div class="card-block">

                                      <div class="table-responsive" >
                                        <table class="table table-bordered mb-0">
                                             <thead>
                                             <tr>
                                               <th scope="col">Parameter</th>
                                               <th scope="col">Value</th>
                                             </tr>
                                             </thead>
                                             <tbody>
                                              <tr >
                                                <td >LEU</td>
                                                <td >{{ur.LEU}}</td>
                                              </tr>
              
                                              <tr>
                                                <td >URO</td>
                                                <td >{{ur.URO}}</td>
                                              </tr>
              
                                              <tr >
                                                <td >BIL</td>
                                                <td >{{ur.BIL}}</td>
                                              </tr>
              
                                              <tr >
                                                <td >KET</td>
                                                <td >{{ur.KET}}</td>
                                              </tr>
              
                                              <tr >
                                                <td >GLU</td>
                                                <td >{{ur.GLU}}</td>
                                              </tr>
              
                                              <tr >
                                                <td >PRO</td>
                                                <td >{{ur.PRO}}</td>
                                              </tr>
              
                                              <tr >
                                                <td >BLD</td>
                                                <td >{{ur.BLD}}</td>
                                              </tr>
              
                                              <tr >
                                                <td >NIT</td>
                                                <td >{{ur.NIT}}</td>
                                              </tr>
              
                                              <tr >
                                                <td >pH</td>
                                                <td >{{ur.PH}}</td>
                                              </tr>
              
                                              <tr >
                                                <td >SG</td>
                                                <td >{{ur.SG}}</td>
                                              </tr>

                                              <tr >
                                                <td >VC</td>
                                                <td >{{ur.VC}}</td>
                                              </tr>

                                             </tbody>
                                        </table>
                                        </div>
                                      
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </div>

                            </div>
                            </section>
                            </div>

                            <div class="modal-footer">
                              <button type="button" class="btn grey btn-outline-secondary"
                                (click)="d('Close modal')">Close</button>
                            </div>

                     </div>
                  </ng-template>

                  <!-- reports Modal -->
         <ng-template class="modal text-left" #ReportsModelContent let-c="close" let-d="dismiss">
          <div class="modal-lg">

            <div class="modal-body">
              <section>
                <div *blockUI="'projectInfo'; message: 'Loading'">

                  <div [hidden]="loading" class="text-center">
                    <div class="loader-wrapper">
                      <div class="loader-container">
                        <div class="fading-circle loader-blue-grey">
                          <div class="circle1 circle"></div>
                          <div class="circle2 circle"></div>
                          <div class="circle3 circle"></div>
                          <div class="circle4 circle"></div>
                          <div class="circle5 circle"></div>
                          <div class="circle6 circle"></div>
                          <div class="circle7 circle"></div>
                          <div class="circle8 circle"></div>
                          <div class="circle9 circle"></div>
                          <div class="circle10 circle"></div>
                          <div class="circle11 circle"></div>
                          <div class="circle12 circle"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div [hidden]="!loading" class="row">
                    <div class="col-xl-12 col-md-12 col-12">
                      <div class="card">
                        <div class="card-header">
                          <h4 class="card-title">{{reportDto.reportName}} Report</h4>
                        </div>
                        <div class="">
                          <div class="" style="width: 100%;overflow: auto;">

                            <img src="{{reportDto.reportAttachment}}" />
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>

                  </div>
                  </section>
                  </div>

                  <div class="modal-footer">
                    <button type="button" class="btn grey btn-outline-secondary"
                      (click)="d('Close modal')">Close</button>
                  </div>

           </div>
        </ng-template>

      </div>
  </div>
</div>