<form [formGroup]="form" (ngSubmit)="onSend()">
    <div>
        <div>
            <label>Message To Patient</label>
            <input type="text" id="allergies" (keypress)="keyPressAlpha($event)" placeholder="Enter Message To Patient" [(ngModel)]="message" placement="top" 
            style="width: 100%;height: 40px;"  formControlName="msg">
            <div class="errors" *ngIf="msg?.errors?.required && (msg?.dirty || msg?.touched)">
                Message is required 
            </div>
        </div>
    </div>
    <div style="text-align: center;margin: 10px;">
        <button type="button" class="btn btn-danger" (click)="onCancel()">
            Cancel
        </button>
        <button type="submit" style="margin-left: 25px;" class="btn btn-success" [disabled]="form.invalid">
            Send
        </button>
    </div>
</form>