import { Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ApiService } from 'src/app/_services/api-service';
import { Notifications } from 'src/app/_models/notifications';
import { UserService } from 'src/app/_services/user.service';
import { Observable, of } from 'rxjs';
import { NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { catchError, debounceTime, distinctUntilChanged, filter, map, tap, switchMap, startWith } from 'rxjs/operators';
import { ApiDrug } from 'src/app/_models/apidrug';
import { Prescription } from 'src/app/_models/prescription';
import { PrescriptionpdfComponent } from 'src/app/prescriptionpdf';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ChartApiService } from 'src/app/_services/chart.api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { EcgDialogComponent } from 'src/app/ecgdilaog/ecgdialog.component';
import 'chartist-plugin-tooltips';
import { DatePipe } from '@angular/common';
import { CallTransferDialogComponent } from 'src/app/calltransfer/calltransfer.component';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/_models';
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import localForage from "localforage";
import { NgxSpinnerService } from 'ngx-spinner';

import { distanceInWordsToNow } from 'date-fns'
import { DrugsInteractionDialog } from '../appointment-consultation/appointment-consultation.component';

@Component({
  selector: 'app-tele-consult',
  templateUrl: './tele-consult.component.html',
  styleUrls: ['./tele-consult.component.css'],
  providers: [ApiService, DatePipe],
  encapsulation: ViewEncapsulation.None
})
export class TeleConsultComponent implements OnInit {
  Aadhar: string = "";
  patientName: string;
  patientNumber: string;
  patientMail: string;
  bloodGroup: string;
  bpCapturedTime: string;
  glucoCapturedTime: string;
  prescription = new Prescription();
  weightCapturedTime: string;
  tempCapturedTime: string;
  diseases: string;
  allergies: string;
  chiefComplaint: string;
  callTransferReason: string;
  bloodPressure: string = "--";
  temperature: string = "--";
  pulse: string = "--";
  bloodGlucose: string = "--";
  weight: string = "--";
  pulseCapturedTime: string;
  height: string = '--';
  disease: string;
  age: string;
  lmp: string;
  bmi: any = "--"
  hdlp: any;
  ldlp: any;
  gender: string;
  diabetic: any;
  thyroid: any;
  opened: boolean;
  isb2b: boolean;
  refresh: string = "no"
  conditions: any = [];
  ecgResult: string = "-";
  cholestrol: string = "--";
  uricacid: string = "--";
  totalcholestrol: string = "--";
  triglyceride: string = "--";
  hdlipoprotein: string = "--";
  ldlipoprotein: string = "--";
  urinanalysis: string = "--";
  himoglobin: string = "--";
  cholestolCapturedTime: string;
  uricacidCapturedTime: string;
  totalcholestrolCapturedTime: string;
  urinanalysisCapturedTime: string;
  himoglobinCapturedTime: string;
  ecgCapturedTime: string;
  hwCapturedTime: string;
  currentUser: any;
  searching = false;
  searchFailed = false;
  spO2: string = "--";
  isDisabled: boolean = true;
  submitted = false;
  drugslist: any = [];
  instructions: string[] = ['Before Food', 'After Food'];
  durations: string[] = ['Select', 'One Day', 'Two Days', 'Three Days', 'Four Days', 'Five Days', 'Six Days', 'Seven Days', 'Eight Days', 'Nine Days',
    'Ten Days', 'Two Weeks', 'Three Weeks', 'Four Weeks', "One Month", "Two Months", "Three Months", "Four Months", "Five Months", "Six Months"];
  frequencies: string[] = ["Morning", "Afternoon", "Night", "SOS", "Others"];
  dosagelist: any = [];
  rows: any = [];
  reportrows: any = [];

  notification = new Notifications();
  parentChannel: string;
  consultationList: any = [];
  allVitalsDTO: any;
  previewPrescription: any;
  published: boolean = false;
  drugs: Observable<any[]>[] = [];
  drugAlts: Observable<any[]>[] = [];
  prescSuggestions: any = [];
  prescSuggestionsFlag: boolean = false;
  patientVitals: any;
  ecgList: any = [];

  bloodGroupUpdatedDate: string;
  medicalHistoryUpdatedDate: string;
  lmpUpdatedDate: string;
  thyroidUpdatedDate: string;
  diabeticUpdatedDate: string;
  allergiesUpdatedDate: string;
  bloodPressureUpdatedDate: string;
  diabeticsince: string;
  thyroidsince: string;
  bp: string;
  bpsince: string;

  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY: false, wheelPropagation: false };
  public config: PerfectScrollbarConfigInterface = { suppressScrollY: true };
  consultRows: any = [];
  consultTemp = this.consultRows;
  connecting: boolean = true;

  reportsDataTableMessage: any = { emptyMessage: 'No data to display' };
  prescriptionsDataTableMessage: any = { emptyMessage: 'No data to display' };
  @BlockUI('projectInfo') blockUIProjectInfo: NgBlockUI;
  consultationForm: FormGroup;
  public prescriptionList: FormArray;
  lineArea4: any;
  data: any;
  graphCardOptions = {
    close: true,
    expand: true,
    minimize: true,
    reload: true
  }

  agoraCardoptions = {
    close: false,
    expand: true,
    minimize: false,
    reload: false
  }

  vitaloptions = {
    close: false,
    expand: true,
    minimize: false,
    reload: true
  }
  showAltFlag: Array<boolean> = [];
  prescriptionNotes: any;

  getlineArea(data, label, type) {

    if (label == "Blood pressure") {
      if (type == "week") {
        this.chartsData = [
          { data: data.series[0], label: 'Systolic' },
          { data: data.series[1], label: 'Diastolic' },
        ];
        this.chartsLabels = data.labels;
      }
      if (type == "month") {
        this.monthChartsData = [
          { data: data.series[0], label: 'Systolic' },
          { data: data.series[1], label: 'Diastolic' },
        ];
        this.monthChartsLabels = data.labels;
      }
      if (type == "year") {
        this.yearChartsData = [
          { data: data.series[0], label: 'Systolic' },
          { data: data.series[1], label: 'Diastolic' },
        ];
        this.yearChartsLabels = data.labels;
      }
      this.chartsOptions = this.lineChartsOptions;
    }
    else {
      if (type == "week") {
        this.chartsData = [
          { data: data.series, label: label },
        ];
        this.chartsLabels = data.labels;
      }
      if (type == "month") {
        this.monthChartsData = [
          { data: data.series, label: label },
        ];
        this.monthChartsLabels = data.labels;
      }
      if (type == "year") {
        this.yearChartsData = [
          { data: data.series, label: label },
        ];
        this.yearChartsLabels = data.labels;
      }
      this.chartsOptions = this.lineChartsOptions;
    }
  }
  lineChartsType = "line";

  constructor(private formBuilder: FormBuilder, private matDialog: MatDialog, private snackbar: MatSnackBar, private datepipe: DatePipe, private router: Router, private spinner: NgxSpinnerService,
    private apiService: ApiService, private userService: UserService, private authService: AuthenticationService, private modalService: NgbModal, private sanitizer: DomSanitizer) {

    this.currentUser = localStorage.getItem('currentUser');
    localStorage.setItem("presstatus", "nondraft");
  }

  get consultationFormGroup() {
    return this.consultationForm.get('prescriptionList') as FormArray;
  }


  onDrugSelect(index, drug: ApiDrug) {
    var arrayControl = this.consultationForm.get('prescriptionList') as FormArray;
    var prescription = arrayControl.at(index);
    // prescription.get('dosage').patchValue=drug.strength;
    // this.dosagelist[index]=this.dosagelist[index].push(drug.strength);
    prescription.patchValue({ 'dosage': drug.strength });
    //  this.dosagelist[index].push(drug.strength);

  }

  suggestPrescription(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.spinner.show();
    this.apiService.suggestPrescription(e.item.prescriptionIds.slice(-3)).subscribe((res: any) => {
      this.spinner.hide();
      if (res && res.length > 0) {
        this.showPrescriptionNoteDialog(res);
      }
    });
  }

  loadPrescritption(presc: any) {
    // this.prescSuggestionsFlag = presc && presc.prescriptionDetails && presc.prescriptionDetails.length > 0;
    let prescSuggestions = [];
    for (var i = 0; i < this.prescriptionList.length; i++) {
      this.removePrescritption(i);
    }
    for (var i = 0; i < presc.prescriptionDetails.length; i++) {
      this.addPrescritption();
      let p: any = presc.prescriptionDetails[i];
      p.drugName = (p.drug && p.drug.name) ? p.drug : { "name": p.drugName };
      if (p.drugAlts && p.drugAlts.length > 0) {
        let ad = [];
        if (p.altDrugs) {
          p.altDrugs.split(", ").forEach((d) => { const dAlt = p.drugAlts.find((alt) => alt.name == d); dAlt ? ad.unshift(dAlt) : ad.unshift({ "name": d }) });
        }
        p.altDrugs = ad;
        this.drugAlts[i] = of(p.drugAlts);
        this.showAltFlag[i] = true;
      }
      if (p.frequency) {
        let fq = [];
        p.frequency.split(", ").forEach((f) => fq.push(f));
        p.frequency = fq;
      }
      prescSuggestions.push(p);
    }
    const clinicalNotes = presc.clinicalNotes;
    const lifeStyleModifications = presc.lifeStyleModifications;
    clinicalNotes['prescriptionList'] = prescSuggestions;
    clinicalNotes.complaints = { "complaints": clinicalNotes.complaints }
    this.consultationForm.patchValue(clinicalNotes);
  }

  loadDrugAlts(index, medicine) {
    this.apiService.getDrugsbyName(medicine).subscribe((res: any[]) => {
      if (res.length > 0) {
        this.getDrugAlts(index, res[0]);
      }
    });
  }

  getDrugAlts(index, medicine) {
    const controls = <FormArray>this.consultationForm.controls['prescriptionList'];
    controls.at(index).get('altDrugs').patchValue([]);
    this.showAltFlag[index] = false;
    this.drugAlts[index] = of([]);
    if (medicine.generic && medicine.generic.trim().length > 0) {
      this.drugAlts[index] = this.apiService.getDrugsbyGenericName(medicine.generic).pipe(
        tap((resdata: any) => {
          this.showAltFlag[index] = resdata && resdata.length > 0;
          let ind = resdata.findIndex(x => x.id === medicine.id);
          resdata.splice(ind, 1);
          return resdata;
        }),
        catchError(() => {
          return of([]);
        }));
    }
  }

  addAltDrug(index: number, item: any) {
    var arrayControl = this.consultationForm.get('prescriptionList') as FormArray;
    const drugSel = arrayControl.at(index).get('drugName').value;
    let altDrugs = arrayControl.at(index).get('altDrugs').value;
    if (!altDrugs.find(x => x.id === item.id || x.name === item.name)) {
      altDrugs.push(item);
      arrayControl.at(index).get('altDrugs').patchValue(altDrugs);
    }
  }

  get pdf() {
    return this.consultationForm.controls;
  }

  addPrescritption() {
    this.prescriptionList.push(this.createPrescription());
    const controls = <FormArray>this.consultationForm.controls['prescriptionList'];
    this.manageNameControl(controls.length - 1);
  }

  removePrescritption(index) {
    // this.prescriptionList.removeAt(index);
    const controls = <FormArray>this.consultationForm.controls['prescriptionList'];
    controls.removeAt(index);
    // remove from filteredOptions too.
    this.drugs.splice(index, 1);
    this.drugAlts[index] = of([]);
    this.showAltFlag[index] = false;
  }

  createPrescription(): FormGroup {

    return this.formBuilder.group({
      drugName: [''],
      dosage: [''],
      instructions: [''],
      frequency: [''],
      duration: [''],
      altDrugs: [[]]
    });
  }

  meetingNumber = ''
  passWord = ''
  meetingUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");

  iframeurl: string = "";
  iframeUrl(iframe) {
    let url = environment.apiUrl;
    console.log("ifraurl--", iframe.src);
    if (this.iframeurl == "")
      this.iframeurl = iframe.src;
    if (this.iframeurl == url + "callend")
      this.clearData();
    else if (this.iframeurl == "" || this.iframeurl == url)
      this.iframeurl = "";
    else
      this.iframeurl = url + "callend";
  }

  private browserefresh: boolean;

  showPrescriptionNoteDialog(rows) {
    const dialogRef = this.matDialog.open(PrescriptionNoteDialog, {
      width: '50%',
      disableClose: true,
      hasBackdrop: true,
      data: { pageValue: rows }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result.event == 'not-select') {
        this.prescriptionNotes = result.data;
      } else {
        if (result.data != null) {
          console.log("result--" + result);
          this.loadPrescritption(result.data);

        }
      }
    });
  }

  ngOnInit(): void {
    // this.patientNumber='9059368414';
    // this.loadPatientVitals();
    // this.loadPrescriptionhistory();

    // let notifi = {
    //   "callTransferMeetingId" : "1843",
    //   "personId" : "6",
    //   "agoraMeetingId" : "12345",
    //   "zoomMeetingPassword" : "reer3",
    //   "b2bCall" : "true"
    // }
    // localStorage.setItem('notifications',JSON.stringify(notifi));

    this.consultationForm = this.formBuilder.group({
      complaints: ['', Validators.required],
      observation: [''],
      examinations: [''],
      diagnosis: ['', Validators.required],
      relevantPoints: [''],
      investigation: [''],
      notes: [''],
      prescriptionList: this.formBuilder.array([this.createPrescription()])

    });
    this.manageNameControl(0);

    // this.consultationForm.get('diagnosis').valueChanges.subscribe(value => {
    //   this.apiService.getCD10(value).subscribe(respone => {
    //     this.conditions = respone[3];

    //   }),
    //     error => {
    //       console.log(error);
    //     }

    // })

    this.prescriptionList = this.consultationForm.get('prescriptionList') as FormArray;

    this.notification = JSON.parse(localStorage.getItem("notifications"));
    var username = JSON.parse(localStorage.getItem('currentUser')).username;

    if (this.notification != null) {
      console.log("before");
      this.connecting = false;
      // setTimeout(() => {
      this.meetingNumber = this.notification.agoraMeetingId;
      this.passWord = this.notification.zoomMeetingPassword;
      //this.meetingUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://prod-abgadmin.elro.ai/joinmeeting/" + this.meetingNumber + "/" + this.passWord + "/" + JSON.parse(localStorage.getItem('currentUser')).username);
      this.connecting = true;
      this.loadPatientDetails(this.notification, username);
      console.log("after");
      // }, 5000);
    }
    else {
      localForage.setDriver([
        localForage.INDEXEDDB,
        localForage.WEBSQL,
        localForage.LOCALSTORAGE
      ]).then(function () {
        localForage.getItem("notificationData").then(function (readValue: string) {
          console.log('notdata--: ', readValue);
          if (readValue != null) {
            localStorage.setItem("notifications", readValue);
            localForage.removeItem("notificationData").then(() => {
              window.location.reload();
            });
          }
        });
      });
    }

    this.browserefresh = true;
    let context = this;
    window.addEventListener("beforeunload", function (e) {
      let userDto = JSON.parse(localStorage.getItem('currentUser'));
      // if(!context.browserefresh){
      //   context.authService.updateCheckInCheckOut("0",userDto.username).subscribe();
      //   localStorage.setItem("checkin","false"); 
      // }
      // context.browserefresh = false;
    });

    this.userService.componentMethodCalled.subscribe(
      () => {
        var scrollingElement = (document.scrollingElement || document.body)
        scrollingElement.scrollTop = scrollingElement.scrollHeight;
        // this.tabChanged()
        // alert(document.body.scrollHeight);
        // window.scrollTo(0,document.body.scrollHeight);
        // window.scrollBy(0,200);


      }
    );
    this.userService.componenthomeCalled.subscribe(
      () => {
        let notifications = JSON.parse(localStorage.getItem('notifications'));
        var username = JSON.parse(localStorage.getItem('currentUser')).username;
        this.loadPatientDetails(notifications, username);


      }
    );
    localStorage.setItem("prescriptionId", "0");

    this.consultationForm.valueChanges.subscribe(data => {
      console.log('Form changes', data)
      localStorage.setItem("presstatus", "nondraft");
    });

  }

  loading: boolean;
  reportDto: any;
  reportsModel(ReportsModelContent, reportDto) {

    this.reportDto = reportDto;
    this.apiService.getReportAttachment(reportDto.recordNo).subscribe(res => {
      console.log("res--", res);
      this.loading = true;
      this.reportDto.reportAttachment = res != null ? res : "";
    }, error => {
      this.loading = true;
    });
    this.modalService.open(ReportsModelContent, { windowClass: 'animated fadeInDown', size: 'lg' });
  }

  vitalType: string;
  lipids: any;
  MediumModel(MediumModelContent, data) {
    this.lipids = data;
    this.modalService.open(MediumModelContent, { windowClass: 'animated fadeInDown', size: 'md' });
  }

  hb: any;
  HBModel(HBModelContent, data) {
    this.hb = data;
    this.modalService.open(HBModelContent, { windowClass: 'animated fadeInDown', size: 'md' });
  }

  ur: any;
  UAModel(UAModelContent, data) {
    this.ur = data;
    this.modalService.open(UAModelContent, { windowClass: 'animated fadeInDown', size: 'md' });
  }

  urica: any;
  UricacidModel(UricacidModelContent, data) {
    this.urica = data;
    this.modalService.open(UricacidModelContent, { windowClass: 'animated fadeInDown', size: 'md' });
  }

  chol: any;
  cholModel(CholModelContent, data) {
    this.chol = data;
    this.modalService.open(CholModelContent, { windowClass: 'animated fadeInDown', size: 'md' });
  }

  dataLoaded: boolean = false;
  public chartsData: Array<any> = [];
  public monthChartsData: Array<any> = [];
  public yearChartsData: Array<any> = [];
  public chartsLabels;
  public monthChartsLabels;
  public yearChartsLabels;
  public chartsOptions;
  lineChartsOptions: any = {
    animation: {
      duration: 1000, // general animation time
      easing: 'easeOutBack'
    },
    hover: {
      animationDuration: 1000, // duration of animations when hovering an item
      mode: 'label'
    },
    responsiveAnimationDuration: 1000, // animation duration after a resize
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
    },
    scales: {
      xAxes: [{

        ticks: {
          padding: 4
        },
        scaleLabel: {
          display: true,
          labelString: 'Date'
        }
      }],
      yAxes: [{
        ticks: {
          padding: 4
        },
        scaleLabel: {
          display: true,
          labelString: 'Value'
        }
      }]
    },
  };

  LargeModel(LargeModelContent, type: string) {
    this.vitalType = type;
    let data: any;
    let mothData: any;
    let yearData: any;
    let currentdate = new Date();
    let weekdate = new Date(currentdate.getFullYear(), currentdate.getMonth(), currentdate.getDate() - 7);
    let monthdate = new Date(currentdate.getFullYear(), currentdate.getMonth() - 1, currentdate.getDate());
    let yeardate = new Date(currentdate.getFullYear() - 1, currentdate.getMonth(), currentdate.getDate() - 7);

    if (this.patientVitals != null) {
      this.rows = [];
      if (type == 'BMI' && this.patientVitals.HeightDetails != null && this.patientVitals.HeightDetails.length > 0) {
        let dates: any[] = [];
        let BMI: any = [];
        let monthdates: any[] = [];
        let monthBMI: any = [];
        let yeardates: any[] = [];
        let yearBMI: any = [];
        this.patientVitals.HeightDetails.forEach(element => {
          if (element.BMI != null)
            this.rows.push(new Vital(this.patientVitals.Member.Name, element.BMI, this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss")));
          if (element.BMI) {
            let vitaldate = new Date(element.measureTime);
            if (weekdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
              dates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
              BMI.push(+element.BMI);
            }
            if (monthdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
              monthdates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
              monthBMI.push(+element.BMI);
            }
            if (yeardate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
              yeardates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
              yearBMI.push(+element.BMI);
            }
          }
        });

        data = {
          "labels": dates,
          "series": BMI
        }
        mothData = {
          "labels": monthdates,
          "series": monthBMI,
        }
        yearData = {
          "labels": yeardates,
          "series": yearBMI,
        }
        console.log("final", data);
      }
      else if (type == "Body temperature") {
        let dates: any[] = [];
        let temp: any = [];
        let monthdates: any[] = [];
        let monthtemp: any = [];
        let yeardates: any[] = [];
        let yeartemp: any = [];
        if (this.patientVitals.TemperatureDetails != null) {
          this.patientVitals.TemperatureDetails.forEach(element => {
            if (element.Temperature != null)
              this.rows.push(new Vital(this.patientVitals.Member.Name, element.Temperature + " \xB0F", this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss")));
            if (element.Temperature) {
              let vitaldate = new Date(element.measureTime);
              if (weekdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                dates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                temp.push(+element.Temperature);
              }
              if (monthdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                monthdates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                monthtemp.push(+element.Temperature);
              }
              if (yeardate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                yeardates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                yeartemp.push(+element.Temperature);
              }
            }
          });
          data = {
            "labels": dates,
            "series": temp
          }
          mothData = {
            "labels": monthdates,
            "series": monthtemp,
          }
          yearData = {
            "labels": yeardates,
            "series": yeartemp,
          }
        }
        console.log("final", data);
      }
      else if (type == "Blood pressure") {
        let dates: any[] = [];
        let BPhigh: any = [];
        let BpLow: any = [];
        let monthdates: any[] = [];
        let monthBPhigh: any = [];
        let monthBpLow: any = [];
        let yeardates: any[] = [];
        let yearBPhigh: any = [];
        let yearBpLow: any = [];
        if (this.patientVitals.BloodPressureDetails != null) {
          this.patientVitals.BloodPressureDetails.forEach(element => {
            if (element.HighPressure != null) {
              var bp = element.HighPressure + "/" + element.LowPressure + " mmHg";
              this.rows.push(new Vital(this.patientVitals.Member.Name, bp, this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss")));
            }
            if (element.HighPressure) {
              let vitaldate = new Date(element.measureTime);
              if (weekdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                dates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                BPhigh.push(+element.HighPressure);
                BpLow.push(+element.LowPressure)
              }
              if (monthdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                monthdates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                monthBPhigh.push(+element.HighPressure);
                monthBpLow.push(+element.LowPressure)
              }
              if (yeardate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                yeardates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                yearBPhigh.push(+element.HighPressure);
                yearBpLow.push(+element.LowPressure)
              }
            }
          });
          data = {
            "labels": dates,
            "series": [BPhigh, BpLow],
          }
          mothData = {
            "labels": monthdates,
            "series": [monthBPhigh, monthBpLow],
          }
          yearData = {
            "labels": yeardates,
            "series": [yearBPhigh, yearBpLow],
          }
          console.log("final", data);
        }
      }
      else if (type == "Pulse") {
        let dates: any[] = [];
        let pulse: any = [];
        let monthdates: any[] = [];
        let monthpulse: any = [];
        let yeardates: any[] = [];
        let yearpulse: any = [];
        if (this.patientVitals.BoDetails != null) {
          this.patientVitals.BoDetails.forEach(element => {
            if (element.Bpm != null)
              this.rows.push(new Vital(this.patientVitals.Member.Name, element.Bpm + " bpm", this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss")));
            if (element.Bpm) {
              let vitaldate = new Date(element.measureTime);
              if (weekdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                dates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                pulse.push(+element.Bpm);
              }
              if (monthdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                monthdates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                monthpulse.push(+element.Bpm);
              }
              if (yeardate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                yeardates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                yearpulse.push(+element.Bpm);
              }
            }
          });
          data = {
            "labels": dates,
            "series": pulse
          }
          mothData = {
            "labels": monthdates,
            "series": monthpulse,
          }
          yearData = {
            "labels": yeardates,
            "series": yearpulse,
          }
        }
      }
      else if (type == "Blood glucose") {
        let dates: any[] = [];
        let BG: any = [];
        let monthdates: any[] = [];
        let monthBG: any = [];
        let yeardates: any[] = [];
        let yearBG: any = [];
        if (this.patientVitals.BloodSugarDetails != null && this.patientVitals.BloodSugarDetails.length > 0) {
          this.patientVitals.BloodSugarDetails.forEach(element => {
            if (element.BloodSugar != null)
              this.rows.push(new Vital(this.patientVitals.Member.Name, element.BloodSugar + " mg/dL", this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss")));
            if (element.BloodSugar) {
              let vitaldate = new Date(element.measureTime);
              if (weekdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                dates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                BG.push(+element.BloodSugar);
              }
              if (monthdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                monthdates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                monthBG.push(+element.BloodSugar);
              }
              if (yeardate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                yeardates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                yearBG.push(+element.BloodSugar);
              }
            }
          });
          data = {
            "labels": dates,
            "series": BG
          }
          mothData = {
            "labels": monthdates,
            "series": monthBG,
          }
          yearData = {
            "labels": yeardates,
            "series": yearBG,
          }
        }
      }
      else if (type == "Blood saturation") {
        let dates: any[] = [];
        let BS: any = [];
        let monthdates: any[] = [];
        let monthBS: any = [];
        let yeardates: any[] = [];
        let yearBS: any = [];
        if (this.patientVitals.BoDetails != null && this.patientVitals.BoDetails.length > 0) {
          this.patientVitals.BoDetails.forEach(element => {
            if (element.Oxygen != null)
              this.rows.push(new Vital(this.patientVitals.Member.Name, element.Oxygen + " %", this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss")));
            if (element.Oxygen) {
              let vitaldate = new Date(element.measureTime);
              if (weekdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                dates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                BS.push(+element.Oxygen);
              }
              if (monthdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                monthdates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                monthBS.push(+element.Oxygen);
              }
              if (yeardate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                yeardates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                yearBS.push(+element.Oxygen);
              }
            }
          });
          data = {
            "labels": dates,
            "series": BS
          }
          mothData = {
            "labels": monthdates,
            "series": monthBS,
          }
          yearData = {
            "labels": yeardates,
            "series": yearBS,
          }
        }
      } else {
        return;
      }

    }
    if (data != null) {
      this.getlineArea(data, type, "week");
    }
    if (mothData != null) {
      this.getlineArea(mothData, type, "month");
    }
    if (yearData != null) {
      this.getlineArea(yearData, type, "year");
    }
    this.dataLoaded = true;
    // this.chartApiservice.getChartistData().subscribe(Response => {
    //   this.data = Response;
    // });
    if (this.rows != null && this.rows.length > 0)
      this.modalService.open(LargeModelContent, { windowClass: 'ttable', size: 'lg' });
    else {
      this.snackbar.open('No Data to Display', '', { duration: 2000 });
    }
  }

  calltransfer() {
    const dialogRef = this.matDialog.open(CallTransferDialogComponent, {
      width: '70%',
      disableClose: true,
      hasBackdrop: true,
      data: { pageValue: "" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result != null && result.data != null) {
        var data = result.data;

        localStorage.setItem("transfer", "true");
        let notifications = JSON.parse(localStorage.getItem('notifications'));
        let userVitals = localStorage.getItem("userVitals");
        var meetingDTO = {
          "deviceId": "",
          "agoraMeetingId": this.meetingNumber,
          "zoomMeetingPassword": this.passWord,
          "chiefCompliant": localStorage.getItem("chiefComplaint"),
          "personId": notifications.personId,
          "androidId": "",
          "id": notifications.meetingId == "0" ? notifications.callTransferMeetingId : notifications.meetingId,
          "callTransferMeetingId": notifications.callTransferMeetingId,
          "callTransferReason": data['reason'],
          "doctorId": data['doctor'],
          "vitalData": userVitals,
          "callTransferDoctor": JSON.parse(localStorage.getItem('currentUser')).username,
          "prescriptionId": localStorage.getItem("prescriptionId")
        };

        console.log("params--" + JSON.stringify(meetingDTO));
        this.apiService.saveMeetingId(meetingDTO).subscribe((resData: any) => {
          var data = JSON.parse(resData['body']);
          console.log("res--" + JSON.stringify(data));
          this.clearData();
        }, error => {
          var data = error;
        });

      }
    });

  }

  clearData() {
    this.authService.updateStatus("available").subscribe((resData: any) => {
      console.log("res--" + JSON.stringify(resData));
      localStorage.removeItem("localPrescription")
      localStorage.removeItem('notifications')
      window.location.reload();
      localStorage.setItem("transfer", "false");

    }, error => {
      var data = error;
    });
  }

  loadPrescriptionhistory() {
    this.prescriptionsDataTableMessage = { emptyMessage: 'Loading..' };
    this.apiService.getPatientPrescriptions(this.patientNumber).subscribe((resData: any) => {
      console.log("presres--" + JSON.stringify(resData));
      if (resData != null) {
        this.consultationList = resData.prescriptionList;
        this.consultRows = resData.prescriptionList;
      }
      this.prescriptionsDataTableMessage = { emptyMessage: 'No data to display' };
      // this.temp2=this.rows;
    }, error => {
      var data = error;
    });

  }

  openPrescriptionPreview(data) {
    data.prescriptionhistory = true;
    const dialogRef = this.matDialog.open(PrescriptionpdfComponent, {
      minWidth: '100%',
      disableClose: true,
      hasBackdrop: false,
      height: '100%',
      data: { pageValue: data }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

    });

  }

  refreshVitals() {
    let notifications = JSON.parse(localStorage.getItem('notifications'));
    if (notifications != null) {
      var username = JSON.parse(localStorage.getItem('currentUser')).username;
      this.loadPatientDetails(notifications, username);
    }
  }

  formatter = (result: any) => result.complaints;

  displayFn(drug: any): string {
    return drug && drug.name ? drug.name : '';
  }

  manageNameControl(index: number) {
    console.log("called mana");
    this.showAltFlag[index] = false;
    var arrayControl = this.consultationForm.get('prescriptionList') as FormArray;
    //this.drugs[index] = arrayControl.at(index).get('drug').valueChanges
    this.drugs[index] = arrayControl.at(index).get('drugName').valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(name => name.length > 2 ? this.apiService.getDrugsbyName(name).pipe(
          tap((resdata: any) => {
            this.showAltFlag[index] = false;
            return resdata[3];
          }),
          catchError(() => {
            return of([]);
          })) : []
        )
      );
  }

  // old medicine api start
  private _filter(name: string) {
    const filterValue = name.toLowerCase();
    return this.drugslist.filter(option =>
      option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  loadDrugList() {
    if (localStorage.getItem("drugs") == null) {
      this.apiService.getDrugsList().subscribe((resData: any) => {

        localStorage.setItem('drugs', JSON.stringify(resData));
      }, error => {

      }
      );
    }
  }

  searchDrug = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.filterDrugs(term)
      ),
      tap(() => this.searching = false)
    )

  filterDrugs(value) {

    var filteredDrugs = [];
    this.drugs = [];
    if (value != null && value != "") {
      this.drugslist.forEach(element => {

        if (element.composition.toLowerCase().includes(value.toLowerCase())) {
          filteredDrugs.push(element);
        }
      });
      return filteredDrugs;
    }
  }

  // end 
  searchFromService = (text$: Observable<any>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.apiService.getICD11(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

  suggestComlaints = (text$: Observable<any>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.apiService.suggestComlaints({
          "doctorId": JSON.parse(this.currentUser).username,
          "clinicalNotes": { "complaints": term }
        }).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

  getData(term) {
    var res;
    this.apiService.getCD10(term).subscribe((resData: any) => {
      res = resData[3];
    }, error => {
      var data = error;
      res = [{}]
    })
    return res;
  }

  loadPatientDetails(notifications, username) {
    // notifications.meetingId
    this.apiService.getPatientDetails(notifications.meetingId == "0" ? notifications.callTransferMeetingId : notifications.meetingId, username).subscribe((resData: any) => {
      if (resData.chiefComplaint != null)
        this.chiefComplaint = resData.chiefComplaint;
      localStorage.setItem("chiefComplaint", this.chiefComplaint);
      this.callTransferReason = resData.callTransferReason != null ? resData.callTransferReason : "";
      if (resData.agoraMeetingId != null) {
        this.parentChannel = resData.agoraMeetingId;
      }

      if (resData.personId != null) {
        this.notification.personId = resData.personId;
        localStorage.setItem("notifications", JSON.stringify(this.notification));
      }
      if (resData.allVitalsDTO != null) {
        this.allVitalsDTO = resData.allVitalsDTO;
        localStorage.setItem("userVitals", JSON.stringify(this.allVitalsDTO));
        this.patientName = resData.allVitalsDTO.name;
        this.patientNumber = resData.allVitalsDTO.mobile;
        this.Aadhar = resData.allVitalsDTO.aadhaar != null ? resData.allVitalsDTO.aadhaar : "";
        this.height = resData.allVitalsDTO.height != null && resData.allVitalsDTO.height != "undefined" ? resData.allVitalsDTO.height + " cm." : "";
        this.weight = resData.allVitalsDTO.weight != null && resData.allVitalsDTO.weight != "undefined" ? resData.allVitalsDTO.weight + " kg" : "";
        this.age = resData.allVitalsDTO.age;
        this.bmi = resData.allVitalsDTO.bmi != null ? resData.allVitalsDTO.bmi : "--";
        this.hdlp = resData.allVitalsDTO.hd;
        this.gender = resData.allVitalsDTO.gender != null ? resData.allVitalsDTO.gender : "";
        if (resData.allVitalsDTO.highPressure != null) {
          this.bloodPressure = resData.allVitalsDTO.highPressure + "/" + resData.allVitalsDTO.lowPressure + " mmHg";
          // this.bpCapturedTime=resData.bpReadings.capturedTime;
        }
        if (resData.allVitalsDTO.bloodSugar != null) {
          this.bloodGlucose = resData.allVitalsDTO.bloodSugar + " mg/dL";
          // this.glucoCapturedTime=resData.glucoReadings.capturedTime;
        }
        else
          this.bloodGlucose = "-";

        if (resData.allVitalsDTO.temperature != null) {
          // this.tempCapturedTime=resData.tempReadings.capturedTime;
          this.temperature = resData.allVitalsDTO.temperature + " \xB0F";
        }
        if (resData.allVitalsDTO.oxygen != null) {
          // this.pulse=resData.hrreadings.hrMax;
          // this.pulseCapturedTime=resData.hrreadings.capturedTime;
          this.spO2 = resData.allVitalsDTO.oxygen + "%";
        }
        if (resData.allVitalsDTO.bpm != null) {
          this.pulse = resData.allVitalsDTO.bpm + " bpm";
        }
        this.ecgResult = resData.allVitalsDTO.ecgResult != null ? resData.allVitalsDTO.ecgResult : "-";
        this.cholestrol = resData.allVitalsDTO.chol != null ? resData.allVitalsDTO.chol + " mg/dL" : "--";
        this.uricacid = resData.allVitalsDTO.ua != null ? resData.allVitalsDTO.ua + " mg/dL" : "--";
        this.himoglobin = resData.allVitalsDTO.hb != null ? resData.allVitalsDTO.hb + " g/dL" : "--";
        this.totalcholestrol = resData.allVitalsDTO.tchol != null ? resData.allVitalsDTO.tchol + " mg/dL" : "--";
        this.triglyceride = resData.allVitalsDTO.trig != null ? resData.allVitalsDTO.trig + " mg/dL" : "--";
        this.ldlipoprotein = resData.allVitalsDTO.tcHdl != null ? resData.allVitalsDTO.tcHdl + " mg/dL" : "--";
        this.hdlipoprotein = resData.allVitalsDTO.hdlChol != null ? resData.allVitalsDTO.hdlChol + " mg/dL" : "--";
        this.himoglobin = resData.allVitalsDTO.hb != null ? resData.allVitalsDTO.hb : "--";
        this.urinanalysis = resData.allVitalsDTO.uro != null ? resData.allVitalsDTO.uro : "--";
        this.tempCapturedTime = resData.allVitalsDTO.tempMeasureTime != null ? this.getFancyTime(resData.allVitalsDTO.tempMeasureTime.replace("T", " ")) + " ago" : "";
        this.pulseCapturedTime = resData.allVitalsDTO.boMeasureTime != null ? this.getFancyTime(resData.allVitalsDTO.boMeasureTime.replace("T", " ")) + " ago" : "";
        this.glucoCapturedTime = resData.allVitalsDTO.bsMeasureTime != null ? this.getFancyTime(resData.allVitalsDTO.bsMeasureTime.replace("T", " ")) + " ago" : "";
        this.bpCapturedTime = resData.allVitalsDTO.bpMeasureTime != null ? this.getFancyTime(resData.allVitalsDTO.bpMeasureTime.replace("T", " ")) + " ago" : "";
        this.hwCapturedTime = resData.allVitalsDTO.uhMeasureTime != null ? this.getFancyTime(resData.allVitalsDTO.uhMeasureTime.replace("T", " ")) + " ago" : "";
        this.ecgCapturedTime = resData.allVitalsDTO.ecgMeasureTime != null ? this.getFancyTime(resData.allVitalsDTO.ecgMeasureTime.replace("T", " ")) + " ago" : "";
        this.cholestolCapturedTime = resData.allVitalsDTO.colMeasureTime != null ? this.getFancyTime(resData.allVitalsDTO.colMeasureTime.replace("T", " ")) + " ago" : "";
        this.uricacidCapturedTime = resData.allVitalsDTO.uaMeasureTime != null ? this.getFancyTime(resData.allVitalsDTO.uaMeasureTime.replace("T", " ")) + " ago" : "";
        this.urinanalysisCapturedTime = resData.allVitalsDTO.uraMeasureTime != null ? this.getFancyTime(resData.allVitalsDTO.uraMeasureTime.replace("T", " ")) + " ago" : "";
        this.himoglobinCapturedTime = resData.allVitalsDTO.hbMeasureTime != null ? this.getFancyTime(resData.allVitalsDTO.hbMeasureTime.replace("T", " ")) + " ago" : "";

      }
      if (resData.allVitalsDTO != null && resData.allVitalsDTO.userHealthDetailsDTO != null) {
        var profile = resData.allVitalsDTO.userHealthDetailsDTO;
        // this.isDisabled = true;
        this.bloodGroup = profile.bloodGroup + " ve";
        this.allergies = profile.allergies;
        if (profile.lmp != null) {
          let x = profile.lmp.split(" ");
          this.lmp = x[0];
        }
        this.diabetic = this.stringToBoolean(profile.diabetic) ? "Yes" : "No";
        if (profile.thyroid != null) {
          this.thyroid = this.stringToBoolean(profile.thyroid) ? "Yes" : "No";
        }
        if (profile.bloodPressure != null)
          this.bp = this.stringToBoolean(profile.bloodPressure) ? "Yes" : "No";
        this.disease = profile.medicalHistory;
        this.diabeticsince = profile['diabeticSince'] != null ? profile['diabeticSince'] != "" ? " - " + profile['diabeticSince'] : "" : "";
        this.thyroidsince = profile['thyroidSince'] != null ? profile['thyroidSince'] != "" ? " - " + profile['thyroidSince'] : "" : "";
        this.bpsince = profile['bloodPressureSince'] != null ? profile['bloodPressureSince'] != "" ? " - " + profile['bloodPressureSince'] : "" : "";

        this.bloodGroupUpdatedDate = profile.bloodGroupUpdatedDate != null ? this.getFancyTime(profile.bloodGroupUpdatedDate) + " ago" : " ";
        this.medicalHistoryUpdatedDate = profile.medicalHistoryUpdatedDate != null ? this.getFancyTime(profile.medicalHistoryUpdatedDate) + " ago" : " ";
        this.lmpUpdatedDate = profile.lmpUpdatedDate != null ? this.getFancyTime(profile.lmpUpdatedDate) + " ago" : " ";
        this.thyroidUpdatedDate = profile.thyroidUpdatedDate != null ? this.getFancyTime(profile.thyroidUpdatedDate) + " ago" : " ";
        this.diabeticUpdatedDate = profile.diabeticUpdatedDate != null ? this.getFancyTime(profile.diabeticUpdatedDate) + " ago" : " ";
        this.allergiesUpdatedDate = profile.allergiesUpdatedDate != null ? this.getFancyTime(profile.allergiesUpdatedDate) + " ago" : " ";
        this.bloodPressureUpdatedDate = profile.bloodPressureUpdatedDate != null ? this.getFancyTime(profile.bloodPressureUpdatedDate) + " ago" : " ";

      }

      this.loadPatientVitals();
      this.loadPrescriptionhistory();

      console.log("channelName", this.parentChannel);
    }, error => {
      var data = error;
    });

  }

  private stringToBoolean(stringValue: string): boolean | undefined {
    try {
      return JSON.parse(stringValue);
    }
    catch (e) {
      return undefined;
    }
  }

  updateHealthdetails(type) {
    let notifications = JSON.parse(localStorage.getItem('notifications'));
    var username = JSON.parse(localStorage.getItem('currentUser')).username;

    var data = {
      "type": type,
      "allergies": this.allergies,
      "diseases": this.disease
    }
    const dialogRef = this.matDialog.open(AllergiesorMedicalhistoryDialog, {
      width: '50%',
      disableClose: true,
      hasBackdrop: true,
      data: { pageValue: data }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result != null) {
        console.log("result--" + result);

        var meetingDTO = {
          "personId": notifications.personId,
          "medicalHistory": type == "history" ? result.data : this.disease,
          "allergies": type == "allergies" ? result.data : this.allergies,
        };

        console.log("params--" + JSON.stringify(meetingDTO));
        this.apiService.updateAllergisandHistory(meetingDTO).subscribe((resData: any) => {
          console.log("res--" + JSON.stringify(resData));

          this.loadPatientDetails(this.notification, username);

        }, error => {
          var data = error;
        });
      }
    });
  }

  openEcg(ecg) {
    ecg.name = this.patientVitals.Member.Name;
    ecg.gender = this.patientVitals.Member.Sex;
    ecg.age = this.patientVitals.Member.Age;

    const dialogRef = this.matDialog.open(EcgDialogComponent, {
      width: '90%',
      disableClose: true,
      hasBackdrop: true,
      data: { pageValue: ecg }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

    });

  }

  lipidsList: any = [];
  hbList: any = [];
  uaList: any = [];
  patientReportUploadDTOList: any = [];
  loadPatientVitals() {
    this.reportsDataTableMessage = { emptyMessage: 'Loading..' };
    this.apiService.getPatientVitals(this.patientNumber).subscribe(
      response => {

        this.reportrows = [];
        this.patientVitals = response;
        this.ecgList = response['PEEcgDetails'] != null ? response['PEEcgDetails'] : [];
        this.lipidsList = response['BloodFatDetails'] != null ? response['BloodFatDetails'] : [];
        this.hbList = response['HbDetails'] != null ? response['HbDetails'] : [];
        this.uaList = response['UrinalysisDetails'] != null ? response['UrinalysisDetails'] : [];
        let uricacidList = response['UaDetails'] != null ? response['UaDetails'] : [];
        let cholList = response['CholDetails'] != null ? response['CholDetails'] : [];
        this.patientReportUploadDTOList = response['patientReportUploadDTOList'] != null ? response['patientReportUploadDTOList'] : [];
        if (this.ecgList && this.ecgList.length > 0) {
          this.ecgList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'ECG', element.measureTime, element, 'ecg'));

          });
        }
        if (this.lipidsList && this.lipidsList.length > 0) {
          this.lipidsList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'Lipids Profile', element.measureTime, element, ''));
          });

        }
        if (this.hbList && this.hbList.length > 0) {
          this.hbList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'Himoglobin', element.measureTime, element, ''));
          });

        }
        if (uricacidList && uricacidList.length > 0) {
          uricacidList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'Uricacid', element.measureTime, element, ''));
          });

        }
        if (cholList && cholList.length > 0) {
          cholList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'Cholestrol', element.measureTime, element, ''));
          });

        }
        if (this.uaList && this.uaList.length > 0) {
          this.uaList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'Urin Analysis', element.measureTime, element, ''));
          });

        }
        if (this.patientReportUploadDTOList && this.patientReportUploadDTOList.length > 0) {
          this.patientReportUploadDTOList.forEach(element => {
            this.reportrows.push(new Report(element.recordNo, element.reportName, element.measureTime, element, 'report'));
          });

        }
        this.reportsDataTableMessage = { emptyMessage: 'No data to display' };
      }
    );
  }

  openReports(row, lipid, hb, ur, uricacid, chol, reports) {
    if (row.reportName == "ECG" && row.name == "ecg")
      this.openEcg(row.vital)
    else if (row.reportName == 'Lipids Profile')
      this.MediumModel(lipid, row.vital);
    else if (row.reportName == 'Himoglobin')
      this.HBModel(hb, row.vital);
    else if (row.reportName == 'Urin Analysis')
      this.UAModel(ur, row.vital)
    else if (row.reportName == 'Uricacid')
      this.UricacidModel(uricacid, row.vital)
    else if (row.reportName == 'Cholestrol')
      this.cholModel(chol, row.vital)
    else {
      this.reportsModel(reports, row.vital);
    }

  }

  reset() {
    this.consultationForm.reset();
  }

  showDrugsInteractionDialog(rows, source) {
    const dialogRef = this.matDialog.open(DrugsInteractionDialog, {
      width: '50%',
      disableClose: true,
      hasBackdrop: true,
      data: { pageValue: rows, source: source }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result.event == 'save') {
        this.savePrescriptionFinal();
      }
      if (result.event == 'publish') {
        this.publishPrescriptionFinal();
      }
    });
  }

  savePrescription() {
    let rxcuis = [];
    this.submitted = true;
    if (this.consultationForm.invalid) {
      this.snackbar.open('Please enter all the mandatory fields', '', { duration: 2000 });
      return;
    }
    if (this.notification != null) {
      this.prescription.personId = this.notification.personId;
      this.prescription.meetingId = this.notification.meetingId;
      this.prescription.doctorId = JSON.parse(this.currentUser).username;
      this.prescription.prescriptionNotes = this.prescriptionNotes;
      let diagnosis = this.consultationForm.get('diagnosis').value;
      if (diagnosis != null && typeof diagnosis != 'string')
        this.consultationForm.patchValue({ 'diagnosis': (diagnosis[0]) })

      let clinicalNotes = Object.assign({}, this.consultationForm.value);
      clinicalNotes.complaints = clinicalNotes.complaints.complaints
      this.prescription.clinicalNotes = clinicalNotes;
      let prescriptionDetails = [];
      clinicalNotes.prescriptionList.slice(0).forEach(function (val) {
        let value = Object.assign({}, val);
        if (value.drugName != null && typeof (value.drugName) != 'string') {
          console.log(value);
          if (value.drugName.rxcui) {
            rxcuis = rxcuis.concat(value.drugName.rxcui.split(', '));
          }
          value.drugName = value.drugName.name;
        }
        if (value.frequency != null && typeof (value.frequency) != 'string') {
          console.log(value);
          value.frequency = value.frequency.join(", ");
        }
        if (value.altDrugs != null && typeof (value.altDrugs) != 'string') {
          console.log(value);
          value.altDrugs = value.altDrugs.map(function (elem) {
            return elem.name;
          }).join(", ");
        }
        prescriptionDetails.push(value);
      });
      delete clinicalNotes.prescriptionList;
      this.prescription.prescriptionDetails = prescriptionDetails;
      console.log("consultationform" + this.consultationForm.value)
      if (this.prescriptionList.length > 1 && rxcuis.length > 1) {
        this.spinner.show();
        this.apiService.getDrugsInteractions(rxcuis.join('+')).subscribe((res) => {
          this.spinner.hide();
          let interactions = [];
          res['fullInteractionTypeGroup']?.forEach((intGrp) => {
            intGrp['fullInteractionType']?.forEach((intType) => {
              intType['interactionPair']?.forEach((intPair) => {
                interactions.push(intPair['description']);
              })
            })
          });
          if (interactions.length > 0) {
            this.showDrugsInteractionDialog(interactions, "save");
          } else {
            this.savePrescriptionFinal();
          }
        })
      } else {
        this.savePrescriptionFinal();
      }
    }
  }

  savePrescriptionFinal() {
    this.apiService.savePrescription(this.prescription)
      .subscribe(
        response => {
          console.log(response);
          this.previewPrescription = response;
          this.prescription.id = response.id;
          localStorage.setItem("prescriptionId", response.id);
          localStorage.setItem("presstatus", "draft");
          this.previewPrescription['allVitalsDTO'] = this.allVitalsDTO;
          localStorage.setItem('localPrescription', JSON.stringify(this.previewPrescription));
          this.snackbar.open('Prescription saved successfully.', '', { duration: 2000 });
        },
        error => {
          console.log(error);
        });
  }

  review() {
    if (localStorage.getItem("presstatus") == "nondraft") {
      this.snackbar.open('Please save the prescription to review.', '', { duration: 2000 });
    } else {
      if (this.previewPrescription != null && this.previewPrescription != undefined) {
        const dialogRef = this.matDialog.open(PrescriptionpdfComponent, {
          minWidth: '100%',
          disableClose: true,
          hasBackdrop: false,
          height: '100%',
          data: { pageValue: this.previewPrescription }
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', result);
          if (result != null && result.data == "publish")
            this.publishPrescription();
        });
      }
    }

  }

  publishPrescription(): void {
    let rxcuis = [];
    if (this.notification != null) {
      this.prescription.personId = this.notification.personId;
      this.prescription.meetingId = this.notification.meetingId;
      this.prescription.doctorId = JSON.parse(this.currentUser).username;

      this.prescription.editPrescription = true;
      this.prescription.isPublished = true;
      let diagnosis = this.consultationForm.get('diagnosis').value;
      if (diagnosis != null && typeof diagnosis != 'string')
        this.consultationForm.patchValue({ 'diagnosis': (diagnosis[0]) })
      let clinicalNotes = Object.assign({}, this.consultationForm.value);
      clinicalNotes.complaints = clinicalNotes.complaints.complaints
      this.prescription.clinicalNotes = clinicalNotes;
      let prescriptionDetails = [];
      clinicalNotes.prescriptionList.slice(0).forEach(function (val) {
        let value = Object.assign({}, val);
        if (value.drugName != null && typeof (value.drugName) != 'string') {
          console.log(value);
          if (value.drugName.rxcui) {
            rxcuis = rxcuis.concat(value.drugName.rxcui.split(', '));
          }
          value.drugName = value.drugName.name;
        }
        if (value.frequency != null && typeof (value.frequency) != 'string') {
          console.log(value);
          value.frequency = value.frequency.join(", ");
        }
        if (value.altDrugs != null && typeof (value.altDrugs) != 'string') {
          console.log(value);
          value.altDrugs = value.altDrugs.map(function (elem) {
            return elem.name;
          }).join(", ");
        }
        prescriptionDetails.push(value);
      });
      delete clinicalNotes.prescriptionList;
      this.prescription.prescriptionDetails = prescriptionDetails;
      if (this.prescriptionList.length > 1 && rxcuis.length > 1) {
        this.spinner.show();
        this.apiService.getDrugsInteractions(rxcuis.join('+')).subscribe((res) => {
          this.spinner.hide();
          let interactions = [];
          res['fullInteractionTypeGroup']?.forEach((intGrp) => {
            intGrp['fullInteractionType']?.forEach((intType) => {
              intType['interactionPair']?.forEach((intPair) => {
                interactions.push(intPair['description']);
              })
            })
          });
          if (interactions.length > 0) {
            this.showDrugsInteractionDialog(interactions, "publish");
          } else {
            this.publishPrescriptionFinal();
          }
        })
      } else {
        this.publishPrescriptionFinal();
      }
    }
  }

  publishPrescriptionFinal() {
    //changed to b2n for demo
    this.spinner.show();
    this.apiService.publishPrescription(this.prescription)
      .subscribe(
        response => {
          this.spinner.hide();
          console.log("pubres---" + response);
          localStorage.setItem("presstatus", "published");
          this.published = true;
          this.snackbar.open('Prescription is published successfully.', '', { duration: 2000 });
        },
        error => {
          this.spinner.hide();
          console.log(error);
        });
  }


  getFancyTime(date): string {
    var date1 = new Date(date).getTime();
    var date2 = new Date().getTime();
    var msec = date2 - date1;
    var mins = Math.floor(msec / 60000);
    var hrs = Math.floor(mins / 60);
    var days = Math.floor(hrs / 24);
    mins = mins % 60;
    hrs = hrs % 24;
    days = days % 365;
    if (days >= 1)
      return days == 1 ? days.toString() + " day" : days.toString() + " days";
    else if (days < 1 && hrs >= 1)
      return hrs == 1 ? hrs.toString() + " hour" : hrs.toString() + " hours";
    else
      return mins == 1 ? mins.toString() + " min" : mins.toString() + " mins";
  }
}

class Vital {
  constructor(
    public name: number,
    public vital: string,
    public time: string,
  ) { }
}
class Report {
  constructor(
    public recordNo: number,
    public reportName: string,
    public measureTime: string,
    public vital: any,
    public name: any,
  ) { }
}




@Component({
  selector: 'dialog-allergies',
  templateUrl: 'allergiesorhistory.html',
  styleUrls: ['tele-consult.component.css']
})
export class AllergiesorMedicalhistoryDialog {
  medicaldata: any;
  allergies: string;
  mhistory: string;
  type: string;
  form: FormGroup;
  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AllergiesorMedicalhistoryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.medicaldata = data.pageValue;
    this.allergies = this.medicaldata.allergies;
    this.mhistory = this.medicaldata.diseases;
    this.type = this.medicaldata.type;
    if (this.type == 'allergies') {
      this.form = this.formBuilder.group({
        allergy: ['', [Validators.required]],
      });
    } else {
      this.form = this.formBuilder.group({
        history: ['', [Validators.required]]
      });
    }

  }


  get allergy() {
    return this.form.get('allergy');
  }
  get history() {
    return this.form.get('history');
  }
  keyPressAlpha(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  onCancel(): void {
    this.dialogRef.close();
  }

  onSave() {
    this.dialogRef.close({ event: 'close', data: this.medicaldata['type'] == "allergies" ? this.allergies : this.mhistory });
  }

}

@Component({
  templateUrl: 'prescriptionnote.html',
  styleUrls: ['tele-consult.component.css']
})
export class PrescriptionNoteDialog {
  reason: string;
  prescriptions: [];
  showNoteInput: boolean = false;
  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PrescriptionNoteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.prescriptions = data.pageValue;
    this.showNoteInput = false;
  }
  form: FormGroup = this.formBuilder.group({
    prescriptionNote: ['', [Validators.required]],
  });
  get prescriptionNote() {
    return this.form.get('prescriptionNote');
  }
  keyPressAlpha(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  onCancel(): void {
    this.showNoteInput = true;
  }
  onBack(): void {
    this.showNoteInput = false;
  }
  onSelect(prescription: any): void {
    this.dialogRef.close({ event: "select", data: prescription });
  }
  onPrescriptionNoteSave(): void {
    this.dialogRef.close({ event: "not-select", data: this.reason });
  }
  getDaysAgoText(prescription: any) {
    return distanceInWordsToNow(new Date(prescription.time), { addSuffix: true })
  }
}