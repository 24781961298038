<div [hidden]="loading" class="text-center">
  <div class="loader-wrapper">
    <div class="loader-container">
      <div class="fading-circle loader-blue-grey">
        <div class="circle1 circle"></div>
        <div class="circle2 circle"></div>
        <div class="circle3 circle"></div>
        <div class="circle4 circle"></div>
        <div class="circle5 circle"></div>
        <div class="circle6 circle"></div>
        <div class="circle7 circle"></div>
        <div class="circle8 circle"></div>
        <div class="circle9 circle"></div>
        <div class="circle10 circle"></div>
        <div class="circle11 circle"></div>
        <div class="circle12 circle"></div>
      </div>
    </div>
  </div>
</div>

  <div style="float: right;">
    <mat-icon (click)="onCancel()" style="width: 50px; height: 50px; font-size: 25px;cursor: pointer;">
      close
    </mat-icon>
  </div>
  
  <div [hidden]="!loading" style="background-color: #fff !important; border-radius: 20px !important;" >
    <h4 style="border-bottom:1px solid #000; padding:20px 0; color: #000; font-weight: 700; text-align: center;" class="card-title">
      {{dialogtitle }}</h4>

  <mat-dialog-content class="mat-typography">
        <div>

          <div >
            <p>Name: {{patientName}}, {{age}} {{gender}}</p>
            <div class="row">
              <div class="pro2 col-md-4">
                <p >HR: {{ecgData.Hr != null ? ecgData.Hr+" bpm" : "" }}</p>
                <p >PAxis: {{ecgData.PAxis != null ? ecgData.PAxis+" °" : "" }}</p>
                <p >QRSAxis: {{ecgData.Hr != null ? ecgData.QRSAxis+" °" : "" }}</p>
              </div>

              <div class="pro2 col-md-4">
                <p >QRS: {{ecgData.QRS != null ? ecgData.QRS +" ms": "" }}</p>
                <p >TAxis: {{ecgData.TAxis != null ? ecgData.TAxis+" °" : "" }}</p>
                <p >RV5: {{ecgData.RV5 != null ? ecgData.RV5 +" mV": "" }}</p>
              </div>

              <div class="pro2 col-md-4">
                <p >QT: {{ecgData.QT != null ? ecgData.QT+" ms" : "" }}</p>
                <p >QTC: {{ecgData.QTc != null ? ecgData.QTc+" ms" : "" }}</p>
                <p >SV1: {{ecgData.SV1 != null ? ecgData.SV1+" mV" : "" }}</p>
              </div>

              </div>
           
              <div class="row pro2">
                <p >ECG Result: {{ecgResult}}</p>
              </div>
          </div>

          <div class="col-md-12" style="width:100% ; height:500px; overflow:auto">
            <img style="margin: 10px;width:100%;" [src]="sanitize(ecgimg)" />
          </div>
          
          <!-- <div class="model-footer text-center">
            <button class="btn btn-danger" (click)="onCancel()">
              Cancel
            </button>
          </div> -->

        </div>

  </mat-dialog-content>
  </div>
