<div *ngIf="channelName != '' ">
    <div [id]="localCallId" class="local"></div>
    
    <div *ngFor="let callId of remoteCalls" [id]="callId" class="remote" ></div>

        <!-- <div *ngIf="remoteCalls.length>0"> -->
           
    </div>

    <div *ngIf="channelName != '' ">
        <div id="videos" >
            <div id="remote-video"></div>
            <div id="toolbar" class=flex-container>
               
                <button _ngcontent-bfs-c179="" type="button" class="btn btn-secondary round mr-1 mb-1" (click)="unmuteVideo()"  ngbTooltip="Switch On/Switch Off" placement="top">
                    <i _ngcontent-bfs-c169="" [hidden]="!videoMute" class="feather ft-video-off"></i>
                    <i _ngcontent-bfs-c169="" [hidden]="videoMute" class="feather ft-video"></i>
                </button>
                <button _ngcontent-bfs-c179="" type="button" class="btn btn-secondary round mr-1 mb-1" (click)="unmuteAudio()" ngbTooltip="Mute/UnMute Audio" placement="top">
                    <i _ngcontent-bfs-c169="" [hidden]="!audioMute" class="feather ft-mic-off"></i>
                    <i _ngcontent-bfs-c169="" [hidden]="audioMute" class="feather ft-mic"></i>
                </button>
                <button _ngcontent-bfs-c179="" type="button" class="btn btn-danger round mr-1 mb-1"  (click)="openConfirmationDialog()" ngbTooltip="End Call" placement="top"> 
                    <i _ngcontent-bfs-c169="" class="feather ft-phone-off"></i>
                </button>
                <button _ngcontent-bfs-c179="" type="button" class="btn btn-secondary round mr-1 mb-1" (click)="calltransfer()" ngbTooltip="Transfer Call" placement="top" >
                    <i _ngcontent-bfs-c169="" class="feather ft-phone-forwarded"></i>
                </button>
                <button _ngcontent-bfs-c179="" type="button" class="btn btn-danger round mr-1 mb-1" ngbTooltip={{recording}} placement="top" (click)="startOrStop()">
                    <i _ngcontent-bfs-c169="" class="feather ft-disc"></i>
                </button>
            </div>
            <div id="local-video"></div>
        </div>
    </div>

    <div *ngIf="channelName == '' ">
        <img src="../assets/images/default-video.png" width="100%" height="280px">
    </div>