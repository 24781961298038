<ngx-spinner bdcolor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" style="color: white" type="ball-spin">Loading...</ngx-spinner>

<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <!--/ Zero configuration table -->
            <!-- Default ordering table -->
            <section id="ordering">
                <div class="row">
                    <div class="col-12" *blockUI="'defaultOrdering'; message: 'Loading'">
                        <m-card [options]="options" (reloadFunction)="reloadDefaultOrdering($event)">
                            <ng-container mCardHeaderTitle >
                                <span class="font-weight-bold">Dashboard</span>
                                <!-- <div class="heading-elements">
                                    <ul class="list-inline mb-0">
                                        <li><a [routerLink]="" class="btn btn-sm btn-success box-shadow-2 round btn-min-width pull-right" (click)="bookAppointment()">Create Appointment</a></li>
                                    </ul>
                                </div> -->
                            </ng-container>
                            <ng-container mCardBody >
                                <div class="card-dashboard " style="margin-top:-15px;">
                                    <div class="row">
                                        <div class="col-xl-8 col-md-8 col-sm-12">
                                            <div class="p-2 pt-0 pb-0 text-white rounded" style="background-color: #9e8cda;">
                                                <div class="row">
                                                       <div class="col-2">
                                                        <img src="../../../assets/images/backgrounds/doctor.png "  class="image-fluid"  height="100" width="100"/>
                                                       </div> 

                                                       <div class="offset-2 offset-sm-1 col-8 col-sm-9 offset-md-1 col-md-9 offset-xl-0 col-xl-10">
                                                        <h1 class="text-white pr-0">Hi, <span class=" display-4" style="color:#c45952;font-family:Verdana, Geneva, Tahoma, sans-serif;">{{doctorName}}</span></h1>
                                                        <h4 class="pr-0 text-white">Medicines cure diseases, but only doctors can cure patients</h4>
                                                    </div> 
                                                </div>
                                              </div>
                                        </div>
                                        <div class="col-xl-4 col-md-4 col-sm-12">
                                            <div class="card ">
                                                <div class="p-1 pb-0 pt-0 bg-primary text-white rounded" style="margin-top: 2px;min-height: 140px;">
                                                    <h2 class="text-white">Scheduled Appointments</h2>
                                                    <h4 class="display-4 text-white">{{rows2.length}}</h4>
                                                  </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-xl-12 col-md-12 col-sm-12">
                                            <div class="card border-success">
                                                <div class="card-header">
                                                    <h4 class="card-title">  <span class="font-weight-bold">Appointments</span></h4>
                                                </div>
                                                <!-- <div class="d-flex justify-content-center" *ngIf="showThis" >No Data Found</div> -->
                                                <div class="card-content justify-content-center ">
                                                    <div class="table-bordered default-ordering" style="min-height:200px;">
                                                        <ngx-datatable class="bootstrap" [limit]="4" [rows]="rows2" [columnMode]="'force'" [scrollbarH]="true" [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" >
                                                            <ngx-datatable-column name="SNo" [flexGrow]="1" [width]="50">
                                                                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex">
                                                                    {{rowIndex+1}}
                                                                </ng-template>
                                                            </ngx-datatable-column>
                                                            <ngx-datatable-column name="Name"  prop="customerName" [flexGrow]="1" [width]="150">
                                                                <ng-template ngx-datatable-cell-template let-value="value">
                                                                    {{value}}
                                                                </ng-template>
                                                            </ngx-datatable-column>
                                                            <ngx-datatable-column name="Chief Complaint" prop="topic" [flexGrow]="1" [minWidth]="50">
                                                                <ng-template ngx-datatable-cell-template let-value="value">
                                                                    {{value}}
                                                                </ng-template>
                                                            </ngx-datatable-column>
                                                            <ngx-datatable-column name="Date and Time" prop="startTime" [flexGrow]="1" [minWidth]="110">
                                                                <ng-template ngx-datatable-cell-template let-value="value">
                                                                    {{value | date:'mediumDate'}} {{value | date:'shortTime'}}
                                                                </ng-template>
                                                            </ngx-datatable-column>
    
                                                            <ngx-datatable-column name="Actions" prop="" [flexGrow]="1" [width]="100">
                                                                <ng-template ngx-datatable-cell-template let-row="row">
                                                                    <a data-toggle="tooltip" data-placement="right" [title]="row.toolTip" (click)="startConsultation(row) " class="btn-icon fonticon-wrap mr-1 " [ngClass]="row.showSuccess ? 'text-success' : 'text-secondary'"><i class="la la-video-camera "></i></a>
                                                                    <a data-toggle="tooltip" data-placement="right" title="view" (click)="singlePatientView(row) " class="btn-icon text-primary fonticon-wrap mr-1 "><i class="la la-eye "></i></a>
                                                                    <a data-toggle="tooltip" data-placement="right" title="cancel" (click)="cancelConsultation(row) " class="btn-icon text-danger fonticon-wrap mr-1 "><i class="la la-close "></i></a>
                                                                </ng-template>
                                                            </ngx-datatable-column>
                                                            <ngx-datatable-column name="Type"  prop="multiple" [flexGrow]="1" [width]="50">
                                                                <ng-template ngx-datatable-cell-template let-value="value">
                                                                    {{value?'Group':'Single'}}
                                                                </ng-template>
                                                            </ngx-datatable-column>
                                                            <ngx-datatable-column name="waiting Time"  prop="waitingTime" [flexGrow]="1" [width]="150">
                                                                <ng-template ngx-datatable-cell-template let-value="value">
                                                                    {{value}}
                                                                </ng-template>
                                                            </ngx-datatable-column>
                                                            <ngx-datatable-column name="" prop="" [flexGrow]="1" [minWidth]="50">
                                                                <ng-template ngx-datatable-cell-template let-row="row">
                                                                   <!-- {{row.multiple?"Group":"Single"}} -->
                                                                   </ng-template>
                                                            </ngx-datatable-column>
                                                        </ngx-datatable>
                                                    </div>
                                                </div>
                                                <div class="card-body ">
                                                    <a [routerLink]="['/appointments']" class="card-link info ">Show all</a>
                                                    <a [routerLink]="['/schedule']" class="card-link info ">My Schedule</a>
                                                </div>
                                            </div>
                                        </div>
                                        <!--
                                        <div class="col-xl-4 col-md-6 col-sm-12" >
                                            <div class="card border-blue " [ngClass]="showThis ? 'height1' : 'height2'">
                                                    <div class="card-header">
                                                        <h4 class="card-title">Next Appointment</h4>
                                                    </div>
                                                <div class="d-flex justify-content-center" *ngIf="showThis" >No Data Found</div>
                                                <div class="card-content bg-hexagons ">
                                                    <div class="card-body" style="margin-top:-40px;">
                                                         <div id="carousel-example-generic " class="carousel slide " data-ride="carousel "> 
                                                        <ngb-carousel *ngIf="rows " [interval]="false " [wrap]="true " [pauseOnHover]="false " [showNavigationArrows]="false ">
                                                           <ng-template *ngIf="ongingApnmt" ngbSlide>
                                                                <div class="row">
                                                                    <div class="col-xl-10 card-body">
                                                                        <h4 class="card-title"><b> {{ongingApnmt.patientName}}{{ongingApnmt.age != null ? "," : ""}} {{ongingApnmt.age}} {{ongingApnmt.gender}} </b> At {{ongingApnmt.startTime | date:'shortTime'}}</h4>
                                                                        <h6 class="card-subtitle text-muted">
                                                                            <span>{{ongingApnmt.patientNumber}}</span><br>
                                                                            <span>Aadhaar: {{ongingApnmt.Aadhar}}</span>
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="row ">
                                                                    <div class="col-xl-12 ">
                                                                        <h6 class="mb-1 ">Chief complaint </h6>
                                                                        <h4 class="mb-1 ">{{ongingApnmt.topic}}</h4>
                                                                    </div>
                                                                </div>
                                                                <div class="row ">
                                                                    <div class="col-xl-3 ">
                                                                        <h6 class="mb-1 ">Height </h6>
                                                                        <h4 class="mb-1 ">{{ongingApnmt.height}}</h4>
                                                                    </div>
                                                                    <div class="col-xl-3 ">
                                                                        <h6 class="mb-1 ">Weight </h6>
                                                                        <h4 class="mb-1 ">{{ongingApnmt.weight}}</h4>
                                                                    </div>
                                                                    <div class="col-xl-3 ">
                                                                        <h6 class="mb-1 ">Allergies </h6>
                                                                        <h4 class="mb-1 ">{{ongingApnmt.allergies}}</h4>
                                                                    </div>
                                                                    <div class="col-xl-3 ">
                                                                        <h6 class="mb-1 ">Blood group ({{ongingApnmt.bloodGroup}})</h6>
                                                                    </div>
                                                                </div>
                                                                <div class="row ">
                                                                    
                                                                    <div class="col-xl-3 ">
                                                                        <h6 class="mb-1 ">Diabetic </h6>
                                                                        <h4 class="mb-1 ">
                                                                            <div class="badge badge-success" *ngIf="ongingApnmt.diabetic == 'No' "><span>{{ongingApnmt.diabetic}}</span></div>
                                                                                <div class="badge badge-warning" *ngIf="ongingApnmt.diabetic == 'Yes' "><span>{{ongingApnmt.diabetic}}</span>
                                                                            </div> <span *ngIf="ongingApnmt.diabetic == 'Yes' ">{{ongingApnmt.diabeticsince}}</span></h4>
                                                                    </div>
                                                                    
                                                                    <div class="col-xl-3 ">
                                                                        <h6 class="mb-1 "> Medical History ({{ongingApnmt.diseases}})</h6>
                                                                         <h4 class="mb-1 ">
                                                                            {{ongingApnmt.diseases}}</h4> 
                                                                    </div>
                                                                    <div class="col-xl-3 ">
                                                                        <h6 class="mb-1 ">Blood pressure 
                                                                            <div class="badge badge-success" *ngIf="ongingApnmt.bp == 'No' "><span>{{ongingApnmt.bp}}</span></div>
                                                                            <div class="badge badge-warning" *ngIf="ongingApnmt.bp == 'Yes' "><span>{{ongingApnmt.bp}}</span>
                                                                            </div> <span *ngIf="ongingApnmt.bp == 'Yes' ">{{ongingApnmt.bpsince}}</span></h6>
                                                                    </div>
                                                                    <div class="col-xl-3 ">
                                                                        <h6 class="mb-1 ">Thyroid </h6>
                                                                        <h4 class="mb-1 ">
                                                                            <div class="badge badge-success" *ngIf="ongingApnmt.thyroid == 'No' "><span>{{ongingApnmt.thyroid}}</span></div>
                                                                            <div class="badge badge-warning" *ngIf="ongingApnmt.thyroid == 'Yes' "><span>{{ongingApnmt.thyroid}}</span>
                                                                            </div> <span *ngIf="ongingApnmt.thyroid == 'Yes' ">{{ongingApnmt.thyroidsince}}</span></h4>
                                                                    </div>
                                                                    
                                                                    
                                                                </div>
                                                                <br />
                                                                <div class="row ">
                                                                    <div class="col-xl-6 ">
                                                                        <div class="fonticon-container ">
                                                                            <a data-toggle="tooltip" data-placement="right" title="view" (click)="singlePatientView(ongingApnmt) " class="btn-icon text-primary fonticon-wrap mr-1 "><i class="la la-street-view "></i></a>
                                                                        </div>
                                                                        <div class="fonticon-container ">
                                                                            <a data-toggle="tooltip" data-placement="right" title="message" (click)="sendMessageToPatient(ongingApnmt)" class="btn-icon text-success fonticon-wrap mr-1 "><i class="la la-envelope "></i></a>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-6 ">
                                                                        <button type="button " (click)="startConsultation(ongingApnmt) " class="btn btn-primary ">Start</button>
                                                                        <button type="button " (click)="cancelConsultation(ongingApnmt) " class="btn btn-danger " style="margin-left: 10px; ">Cancel</button>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <br />
                                                            </ng-template>
                                                            <ng-template *ngIf="upcomingApnmt" ngbSlide>

                                                                <div class="row ">
                                                                    <div class="col-xl-10 card-body">
                                                                        <h4 class="card-title"><b> {{upcomingApnmt.patientName}}{{upcomingApnmt.age != null ? "," : ""}} {{upcomingApnmt.age}} {{upcomingApnmt.gender}} </b> At {{upcomingApnmt.startTime | date:'shortTime'}}</h4>
                                                                        <h6 class="card-subtitle text-muted">
                                                                            <span>{{upcomingApnmt.patientNumber}}</span><br>
                                                                            <span>Aadhaar: {{upcomingApnmt.Aadhar}}</span>
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                <div class="row ">
                                                                    <div class="col-xl-12 ">
                                                                        <h6 class="mb-1 ">Chief complaint </h6>
                                                                        <h4 class="mb-1 ">{{upcomingApnmt.topic}}</h4>
                                                                    </div>
                                                                </div>
                                                                <div class="row ">
                                                                    <div class="col-xl-3 ">
                                                                        <h6 class="mb-1 ">Height </h6>
                                                                        <h4 class="mb-1 ">{{upcomingApnmt.height}}</h4>
                                                                    </div>
                                                                    <div class="col-xl-3 ">
                                                                        <h6 class="mb-1 ">Weight </h6>
                                                                        <h4 class="mb-1 ">{{upcomingApnmt.weight}}</h4>
                                                                    </div>
                                                                    <div class="col-xl-3 ">
                                                                        <h6 class="mb-1 ">Allergies </h6>
                                                                        <h4 class="mb-1 ">{{upcomingApnmt.allergies}}</h4>
                                                                    </div>
                                                                    <div class="col-xl-3 ">
                                                                        <h6 class="mb-1 ">Blood group ({{upcomingApnmt.bloodGroup}})</h6>
                                                                    </div>
                                                                </div>
                                                                <div class="row ">
                                                                    <div class="col-xl-3 ">
                                                                        <h6 class="mb-1 ">Diabetic </h6>
                                                                        <h4 class="mb-1 ">
                                                                            <div class="badge badge-success" *ngIf="upcomingApnmt.diabetic == 'No' "><span>{{upcomingApnmt.diabetic}}</span></div>
                                                                            <div class="badge badge-warning" *ngIf="upcomingApnmt.diabetic == 'Yes' "><span>{{upcomingApnmt.diabetic}}</span>
                                                                            </div> <span *ngIf="upcomingApnmt.diabetic == 'Yes' ">{{upcomingApnmt.diabeticsince}}</span></h4>
                                                                    </div>
                                                                    
                                                                    <div class="col-xl-3 ">
                                                                        <h6 class="mb-1 "> Medical History ({{upcomingApnmt.diseases}})</h6>
                                                                         <h4 class="mb-1 ">
                                                                            {{upcomingApnmt.diseases}}</h4> 
                                                                    </div>
                                                                    <div class="col-xl-3 ">
                                                                        <h6 class="mb-1 ">Blood pressure 
                                                                            <div class="badge badge-success" *ngIf="upcomingApnmt.bp == 'No' "><span>{{upcomingApnmt.bp}}</span></div>
                                                                            <div class="badge badge-warning" *ngIf="upcomingApnmt.bp == 'Yes' "><span>{{upcomingApnmt.bp}}</span>
                                                                            </div> <span *ngIf="upcomingApnmt.bp == 'Yes' ">{{upcomingApnmt.bpsince}}</span></h6>
                                                                    </div>
                                                                    <div class="col-xl-3 ">
                                                                        <h6 class="mb-1 ">Thyroid </h6>
                                                                        <h4 class="mb-1 ">
                                                                            <div class="badge badge-success" *ngIf="upcomingApnmt.thyroid == 'No' " ><span>{{upcomingApnmt.thyroid}}</span></div>
                                                                            <div class="badge badge-warning" *ngIf="upcomingApnmt.thyroid == 'Yes' " ><span>{{upcomingApnmt.thyroid}}</span>
                                                                            </div> <span *ngIf="upcomingApnmt.thyroid == 'Yes' ">{{upcomingApnmt.thyroidsince}}</span></h4>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <div class="row ">
                                                                    <div class="col-xl-6 ">
                                                                        <div class="fonticon-container ">
                                                                            <a data-toggle="tooltip" data-placement="right" title="view" (click)="singlePatientView(upcomingApnmt) " class="btn-icon text-primary fonticon-wrap mr-1 "><i class="la la-street-view "></i></a>
                                                                        </div>
                                                                        <div class="fonticon-container ">
                                                                            <a data-toggle="tooltip" data-placement="right" title="message" (click)="sendMessageToPatient(upcomingApnmt)" class="btn-icon text-success fonticon-wrap mr-1 "><i class="la la-envelope "></i></a>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-6 ">
                                                                        <button type="button " (click)="startConsultation(upcomingApnmt) " class="btn btn-primary ">Start</button>
                                                                        <button type="button " (click)="cancelConsultation(upcomingApnmt) " class="btn btn-danger " style="margin-left: 10px; ">Cancel</button>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <br />
                                                            </ng-template>
                                                        </ngb-carousel>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        -->
                                        <!-- <div class="col-xl-4 col-md-4 col-sm-12 ">
                                            <div class="card border-warning " [ngClass]="showThis ? 'height1' : 'height2'">
                                                <div class="card-header">
                                                    <h4 class="card-title"><span class="font-weight-bold">Appointment Requests</span></h4>
                                                </div>
                                                <hr style="width: 100%; ">
                                                <div class="d-flex justify-content-left" style="margin: 5px 15px 15px 15px;" *ngIf="showThis1" >No Data Found</div>
                                                <div class="card-content">
                                                    <ul class="list-group list-group-flush">
                                                        <li class="list-group-item list-group-item-action list-group-item-secondary" *ngFor="let app of rows1; let i=index">
                                                            <div class="row">
                                                                <div class="col-xl-7 col-md-7 col-sm-7">
                                                                    <div class="media align-items-stretch">
                                                                        <div class="avatar-circle bg-secondary">
                                                                            <span class="initials">{{app.customerName.charAt(0) | uppercase}}</span>
                                                                        </div>
                                                                        <div class="media-body">
                                                                            <h4>{{app.customerName}}</h4>
                                                                            <span>{{app.topic}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-5 col-md-5 col-sm-5">
                                                                    <div class="align-self-center">
                                                                        <h4 class="bg-gradient-directional-secondary round white pl-1"><b>{{app.startTime | date:'short'}}</b></h4>
                                                                        <div class="fonticon-container pull-right ">
                                                                            <a (click)="acceptConsultation(app) " class="btn-icon text-success fonticon-wrap mr-1 "><i class="la la-check "></i></a>
                                                                        </div>
                                                                        <div class="fonticon-container pull-right ">
                                                                            <a (click)="cancelConsultation(app) " class="btn-icon text-danger fonticon-wrap mr-1 "><i class="la la-close "></i></a>
                                                                        </div>
                                                                        <div class="fonticon-container pull-right">
                                                                            <a (click)="singlePatientView(app) " class="btn-icon text-primary fonticon-wrap mr-1 "><i class="la la-street-view "></i></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                 <div class="card-body ">
                                                        <a [routerLink]=" " class="card-link info ">Show all</a>
                                                        <a [routerLink]=" " class="card-link info ">My Schedule</a> 
                                                    </div> 
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </ng-container>
                        </m-card>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div>