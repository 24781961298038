<ngx-spinner
bdColor = "rgba(51, 51, 51, 0.8)"
size = "medium"
color = "#fff" style="color: white"
type = "ball-spin">Loading...</ngx-spinner>

<div class="app-content content">
  <div class="content-wrapper">
      <div class="content-body">
          <!-- Simple User Cards -->
          
          <div class="row">
              <section id="simple-user-cards" class="col-xl-4 col-md-6 col-12">

                  <div class="row">
                      <div class="col-md-12 col-12">
                          <div class="card text-center">
                              <div class="card-body">
                                  <img src="../../../assets/images/avatar.png"
                                      class="rounded-circle  height-150" alt="Card image">
                              </div>
                              <div class="card-body">
                                <h4 class="card-title">{{patientName}}{{age != null ? "," : ""}} {{age}} {{gender}}</h4>                            
                                      <h6 class="card-subtitle text-muted">
                                      <span>{{patientNumber}}</span><br>
                                      <!-- <span>Aadhaar: {{Aadhar}}</span> -->
                                  </h6>
                              </div>
                              <div class="table-responsive card-body" style="text-align: left;">
                                <table class="table table-bordered mb-0">
                                  <tbody>
                                    <!-- <tr>
                                      <td class="width-300">Blood group:{{bloodGroup}}</td>
                                    </tr>
                                    <tr>
                                      <td class="width-300">Blood group:{{bloodGroup}}</td>
                                  </tr> -->
                                  <tr>
                                    <td class="width-300">Blood group
                                      <div class="btn btn-float float-right vr-lbl box-shadow-0 btn-outline-info"><span class="ladda-label"><span>{{bloodGroup}}</span></span></div>
                                      </td>
                                </tr>
                                <!-- <tr>
                                    <td class="width-300">Height
                                      <div class="btn btn-float vr-lbl box-shadow-0 btn-outline-info"><span class="ladda-label"><span>{{height}}</span></span></div>
                                     </td>
                                </tr>
                                <tr>
                                    <td class="width-300">Weight
                                      <div class="btn btn-float vr-lbl box-shadow-0 btn-outline-info"><span class="ladda-label"><span>{{weight}}</span></span></div>
                                     </td>
                                </tr> -->
                                <!-- <tr>
                                    <td class="width-300">LMP</td>
                                </tr> -->
                                <tr>
                                  <td class="width-300">Diabetic   
                                    
                                    <div class="btn btn-float float-right vr-lbl box-shadow-0 btn-outline-info"><span class="ladda-label"><span>{{diabetic}}</span></span></div>
                                    <span *ngIf="diabetic == 'Yes' " style="float: right;">(since{{diabeticsince}})</span> 
                                    </td>
                              </tr>
                              <tr>
                                  <td class="width-300">Thyroid 
                                    <div class="btn btn-float float-right vr-lbl box-shadow-0 btn-outline-info"><span class="ladda-label"><span>{{thyroid}}</span></span></div>
                                    <span *ngIf="thyroid == 'Yes' " style="float: right;">(since{{thyroidsince}})</span>  
                                   </td>
                              </tr>
                              <tr>
                                <td class="width-300">Blood pressure
                                  <div class="btn btn-float float-right vr-lbl box-shadow-0 btn-outline-info"><span class="ladda-label"><span>{{bp}}</span></span></div>
                                  <span *ngIf="bp == 'Yes' " style="float: right;">(since{{bpsince}})</span>
                                 </td>
                            </tr>
                                          <tr>
                                            <td class="width-300">
                                              <span class="float-right" style="font-style: italic;"></span>
                                              Allergies:
                                              <p class="card-text">{{allergies}}</p></td>
                                        </tr>
                                        <tr>
                                          <td class="width-300">
                                            <span class="float-right" style="font-style: italic;"></span>
                                            Medical History:
                                            <p class="card-text">{{diseases}}</p>
                                          </td>
                                      </tr> 
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <section class="col-xl-8 col-md-12 col-12">
                    <div *blockUI="'projectInfo'; message: 'Loading';">
                        <m-card [options]="agoraCardoptions" (reloadFunction)="getReportDetails()" style="height: 764.5px;">
                            <ng-container mCardHeaderTitle>
                                Vitals
                                <!-- <mat-icon (click)="refreshVitals()"  style="width: px; height: 25px; font-size: 25px;margin-right: 15px;vertical-align:middle;cursor: pointer;">
                                  autorenew
                                </mat-icon> -->
                            </ng-container>
                            <!-- <ng-container mCardHeaderTitle>
                                Vitals
                            </ng-container>
                            <ng-container mCardBody>
                                <div class="card-text">
                                    <p class="card-text">With supporting text below as a natural lead-in to
                                        additional content.</p>
                                </div>
                            </ng-container> -->
                            <ng-container mCardBody>
                              <div class="table-responsive" >
                                <table class="table table-bordered mb-0 vitals-r">
                                  <tbody>
                                    <tr>
                                      <td class="width-300 ho-1 " >
                                        <span class="float-right" style="font-style: italic;">{{hwCapturedTime}}</span>
                                          <h3 class="list-group-item-heading primary">{{height}}</h3>
                                          <h6 class="list-group-item-text text-muted">Height</h6>
                                      </td>

                                      <td class="width-300 ho-1 " >
                                        <span class="float-right" style="font-style: italic;">{{hwCapturedTime}}</span>
                                          <h3 class="list-group-item-heading primary">{{weight}}</h3>
                                          <h6 class="list-group-item-text text-muted">Weight</h6>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="width-300 ho-1 " (click)="LargeModel(LargeModelContent, 'BMI')">
                                        <span class="float-right" style="font-style: italic;">{{bmiCapturedTime}}</span>
                                          <h3 class="list-group-item-heading primary">{{bmi}}</h3>
                                          <h6 class="list-group-item-text text-muted">BMI</h6>
                                      </td>

                                      <td class="width-300 ho-1 " (click)="LargeModel(LargeModelContent,'Body temperature')">
                                        <span class="float-right" style="font-style: italic;">{{tempCapturedTime}}</span>
                                          <h3 class="list-group-item-heading primary">{{temperature}}</h3>
                                          <h6 class="list-group-item-text text-muted">Body temperature</h6>
                                      </td>
                                    </tr>
                                    
                                    <tr>
                                      <td class="width-300 ho-1 " (click)="LargeModel(LargeModelContent,'Blood pressure')" >
                                        <span class="float-right" style="font-style: italic;">{{bpCapturedTime}}</span>
                                          <h3 class="list-group-item-heading primary">{{bloodPressure}}</h3>
                                          <h6 class="list-group-item-text text-muted">Blood pressure</h6>
                                      </td>
                                      <td class="width-300 ho-1 " (click)="LargeModel(LargeModelContent,'Blood glucose')">
                                        <span class="float-right" style="font-style: italic;">{{glucoCapturedTime}}</span>
                                          <h3 class="list-group-item-heading primary">{{bloodGlucose}}</h3>
                                          <h6 class="list-group-item-text text-muted">Blood glucose</h6>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="width-300 ho-1 " (click)="LargeModel(LargeModelContent,'Pulse')">
                                        <span class="float-right" style="font-style: italic;">{{pulseCapturedTime}}</span>
                                          <h3 class="list-group-item-heading primary">{{pulse}}</h3>
                                          <h6 class="list-group-item-text text-muted">Pulse</h6>
                                      </td>
                                      <td class="width-300 ho-1 " (click)="LargeModel(LargeModelContent,'Blood saturation')">
                                        <span class="float-right" style="font-style: italic;">{{spO2CapturedTime}}</span>
                                          <h3 class="list-group-item-heading primary">{{spO2}}</h3>
                                          <h6 class="list-group-item-text text-muted">Blood saturation</h6>
                                      </td>
                                    </tr>

                                  </tbody>
                                </table>
                            </div>
                            </ng-container>
                        </m-card>
                      
                        <ng-container mCardBody>
                          <ul class="nav nav-tabs _bottom nav-underline no-hover-bg tabsborder">
                              <ngb-tabset style="width: 100%;">
                                  <ngb-tab title="Appointments">
                                      <ng-template ngbTabContent>

                                        <div class="table-bordered default-ordering">
                                          <ngx-datatable class="bootstrap" [rows]="appointmentsList" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [scrollbarH]="true">
                      
                                              <ngx-datatable-column name="S No" [minWidth]="50">
                                                  <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex">
                                                      {{rowIndex+1}}
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="Health Problem" prop="topic" [minWidth]="90">
                                                  <ng-template ngx-datatable-cell-template let-value="value">
                                                      {{value}}
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="Doctor" prop="doctorName" [minWidth]="90">
                                                  <ng-template ngx-datatable-cell-template let-value="value">
                                                      {{value}}
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="Appointment Date" prop="startTime" [flexGrow]="1">
                                                  <ng-template ngx-datatable-cell-template let-value="value">
                                                      {{value | date:'dd-MM-yyyy HH:mm:ss'}}
                                                  </ng-template>
                                              </ngx-datatable-column>
                      
                                          </ngx-datatable>
                                      </div>

                                      </ng-template>
                                  </ngb-tab>
                                            
                                  <ngb-tab>
                                    <ng-template ngbTabTitle>Prescriptions</ng-template>
                                    <ng-template ngbTabContent>
                                      
                                      <!-- <div class="row">
                                        <div class=" col-sm-12 col-md-12">
                                          
                                          <div style="float: right;margin-right: 20px;">
                                            <button style="margin: 5px;" class="btn btn-primary" (click)="addPrescription()">
                                              Add Prescription
                                            </button>
                          
                                          </div>
                                        </div>
                                      </div> -->

                                      <div class="table-bordered default-ordering">
                                        <ngx-datatable class="bootstrap" [limit]="10" [rows]="consultationList" [columnMode]="'force'" [scrollbarH]="true"
                                          [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" fxFlex="auto" >
                    
                                          <ngx-datatable-column name="S No"  [flexGrow]="1" [width]="50">
                                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex">
                                              {{rowIndex+1}}
                                            </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Chief Complaint" prop="clinicalNotes.complaints" [flexGrow]="1" [minWidth]="90">
                                            <ng-template ngx-datatable-cell-template let-value="value">
                                              {{value}}
                                            </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Prescription Date" prop="time" [flexGrow]="1" [minWidth]="90">
                                            <ng-template ngx-datatable-cell-template let-value="value">
                                              {{value | date:'dd-MM-yyyy HH:mm:ss'}}
                                            </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Doctor" prop="doctorName" [flexGrow]="1" [minWidth]="90">
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                              {{row.registrationDTO != null ? "Dr.": ""}} {{row.registrationDTO != null ? row.registrationDTO.given :""}} {{row.registrationDTO != null ? row.registrationDTO.family:""}}{{row.registrationDTO != null ? ",": ""}} {{row.registrationDTO != null ? row.registrationDTO.practicing:""}}
                                            </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Dr. Contact" prop="registrationDTO.mobile" [flexGrow]="1" [minWidth]="90">
                                            <ng-template ngx-datatable-cell-template let-value="value">
                                              {{value}}
                                            </ng-template>
                                          </ngx-datatable-column>
                                         
                                          <ngx-datatable-column name="Actions" [flexGrow]="1" [minWidth]="30" >
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                              <a data-toggle="tooltip" data-placement="right" title="View" [routerLink]="" class="primary edit mr-1 mrless" 
                                                (click)="openPrescriptionPreview(row)"><i class="la la-eye"></i>
                                              </a>
                                              <a data-toggle="tooltip" data-placement="right" title="Edit" [routerLink]="" class="primary edit mr-1 mrless" *ngIf="showEdit(row)"
                                                (click)="openEditPrescription(row)"><i class="la la-edit"></i>
                                              </a>
                                              <a data-toggle="tooltip" data-placement="right" title="Deactivate" [routerLink]="" class="primary edit mr-1 mrless" *ngIf="row.activated"
                                                (click)="activateorDeactivatePresccription(row)"><i class="fa fa-toggle-off"></i>
                                              </a>
                                              <a data-toggle="tooltip" data-placement="right" title="Activate" [routerLink]="" class="primary edit mr-1 mrless" *ngIf="!row.activated"
                                                (click)="activateorDeactivatePresccription(row)"><i class="fa fa-toggle-on"></i>
                                              </a>
                                            </ng-template>
                                          </ngx-datatable-column>

                                        </ngx-datatable>
                                      </div>

                                    </ng-template>
                                  </ngb-tab>

                                  <ngb-tab>
                                    <ng-template ngbTabTitle>Medical Records</ng-template>
                                    <ng-template ngbTabContent>

                                      <div>
                                        <div class="row">
                                          <div class=" col-sm-12 col-md-12">
                                            
                                            <div style="float: right;margin-right: 20px;">
                                              <button style="margin: 5px;" class="btn btn-primary" (click)="uploadReports()">
                                                Add Medical Record
                                              </button>
                            
                                            </div>
                                          </div>
                                        </div>

                                      <ng-container>
                                      <div class="table-bordered default-ordering">
                                        <ngx-datatable class="bootstrap" [limit]="10" [rows]="reportrows" [columnMode]="'force'" [scrollbarH]="true"
                                          [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" fxFlex="auto">
                    
                                          <ngx-datatable-column name="Record No"  prop="recordNo" [flexGrow]="1" [minWidth]="50">
                                            <ng-template ngx-datatable-cell-template let-value="value">
                                              {{value}}
                                            </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Report Name" prop="reportName" [flexGrow]="1" [minWidth]="90">
                                            <ng-template ngx-datatable-cell-template let-value="value">
                                              {{value}}
                                            </ng-template>
                                          </ngx-datatable-column>

                                          <ngx-datatable-column name="Report Date" prop="measureTime" [flexGrow]="1" [minWidth]="90">
                                            <ng-template ngx-datatable-cell-template let-value="value">
                                              {{value | date:'dd-MM-yyyy'}}
                                            </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Actions" [flexGrow]="1" [minWidth]="30">
                                            <ng-template ngx-datatable-cell-template let-row="row">
                                              <a data-toggle="tooltip" data-placement="right" title="View" [routerLink]="" class="primary edit mr-1 mrless"
                                                (click)="openReports(row,MediumModelContent,HBModelContent,UAModelContent,UricacidModelContent,CholModelContent,ReportsModelContent)"><i class="la la-eye"></i>
                                              </a>
                                            </ng-template>

                                          </ngx-datatable-column>
                                        </ngx-datatable>
                                      </div>
                                      </ng-container>
                                      </div>
                                      
                                    </ng-template>
                                </ngb-tab>

                              </ngb-tabset>
                          </ul>
                      </ng-container>


                    </div>
              </section>
            
            </div>
                  
                               <!-- Lp Modal -->
                               <ng-template class="modal text-left" #MediumModelContent let-c="close" let-d="dismiss">
                                <div class="modal-md">
            
                                  <div class="modal-body">
                                    <section>
                                      <div *blockUI="'projectInfo'; message: 'Loading'">
            
                                        <div class="row">
                                          <div class="col-xl-12 col-md-12 col-12">
                                            <div class="card">
                                              <div class="card-header">
                                                <h4 class="card-title">Lipids Profile Report</h4>
                                              </div>
                                              <div class="card-body">
                                                <div class="card-block">
            
                                                  <div class="table-responsive" >
                                                    <table class="table table-bordered mb-0">
                                                         <thead>
                                                         <tr>
                                                           <th scope="col">Parameter</th>
                                                           <th scope="col">Value</th>
                                                         </tr>
                                                         </thead>
                                                         <tbody>
                                                         <tr>
                                                          <td>Total cholestrol</td>
                                                          <td>{{lipids.TChol}} mg/dL</td>
                                                         </tr>
                                                         <tr>
                                                          <td>High density lipoprotein</td>
                                                          <td>{{lipids.HdlChol}} mg/dL</td>
                                                         </tr>
                                                         <tr>
                                                          <td >Low density lipoprotein</td>
                                                          <td>{{lipids.CalcLdl}} mg/dL</td>
                                                         </tr>
                                                         <tr>
                                                          <td>Tryglyceride</td>
                                                          <td >{{lipids.Trig}} mg/dL</td>
                                                         </tr>
                                                         </tbody>
                                                    </table>
                                                    </div>
                                                  
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          </div>
            
                                        </div>
                                        </section>
                                        </div>
            
                                        <div class="modal-footer">
                                          <button type="button" class="btn grey btn-outline-secondary"
                                            (click)="d('Close modal')">Close</button>
                                        </div>
            
                                 </div>
                              </ng-template>
            
                              <!-- Hb Modal -->
                              <ng-template class="modal text-left" #HBModelContent let-c="close" let-d="dismiss">
                                <div class="modal-md">
            
                                  <div class="modal-body">
                                    <section>
                                      <div *blockUI="'projectInfo'; message: 'Loading'">
            
                                        <div class="row">
                                          <div class="col-xl-12 col-md-12 col-12">
                                            <div class="card">
                                              <div class="card-header">
                                                <h4 class="card-title">Hemoglobin Report</h4>
                                              </div>
                                              <div class="card-body">
                                                <div class="card-block">
            
                                                  <div class="table-responsive" >
                                                    <table class="table table-bordered mb-0">
                                                         <thead>
                                                         <tr>
                                                           <th scope="col">Parameter</th>
                                                           <th scope="col">Value</th>
                                                         </tr>
                                                         </thead>
                                                         <tbody>
                                                         <tr>
                                                          <td>Hemoglobin</td>
                                                          <td>{{hb.Hb}} g/dL</td>
                                                         </tr>
                                                         <tr>
                                                          <td>Hematocrit</td>
                                                          <td>{{hb.Hct}} %</td>
                                                         </tr>
                                                         </tbody>
                                                    </table>
                                                    </div>
                                                  
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          </div>
            
                                        </div>
                                        </section>
                                        </div>
            
                                        <div class="modal-footer">
                                          <button type="button" class="btn grey btn-outline-secondary"
                                            (click)="d('Close modal')">Close</button>
                                        </div>
            
                                 </div>
                              </ng-template>
            
                              <!-- Chol Modal -->
                              <ng-template class="modal text-left" #CholModelContent let-c="close" let-d="dismiss">
                                <div class="modal-md">
            
                                  <div class="modal-body">
                                    <section>
                                      <div *blockUI="'projectInfo'; message: 'Loading'">
            
                                        <div class="row">
                                          <div class="col-xl-12 col-md-12 col-12">
                                            <div class="card">
                                              <div class="card-header">
                                                <h4 class="card-title">Cholestrol Report</h4>
                                              </div>
                                              <div class="card-body">
                                                <div class="card-block">
            
                                                  <div class="table-responsive" >
                                                    <table class="table table-bordered mb-0">
                                                         <thead>
                                                         <tr>
                                                           <th scope="col">Parameter</th>
                                                           <th scope="col">Value</th>
                                                         </tr>
                                                         </thead>
                                                         <tbody>
                                                         <tr>
                                                          <td>Cholestrol</td>
                                                          <td>{{chol.Chol}} mg/dL</td>
                                                         </tr>
                                                         
                                                         </tbody>
                                                    </table>
                                                    </div>
                                                  
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          </div>
            
                                        </div>
                                        </section>
                                        </div>
            
                                        <div class="modal-footer">
                                          <button type="button" class="btn grey btn-outline-secondary"
                                            (click)="d('Close modal')">Close</button>
                                        </div>
            
                                 </div>
                              </ng-template>
            
                              <!-- Uric acid Modal -->
                              <ng-template class="modal text-left" #UricacidModelContent let-c="close" let-d="dismiss">
                                <div class="modal-md">
            
                                  <div class="modal-body">
                                    <section>
                                      <div *blockUI="'projectInfo'; message: 'Loading'">
            
                                        <div class="row">
                                          <div class="col-xl-12 col-md-12 col-12">
                                            <div class="card">
                                              <div class="card-header">
                                                <h4 class="card-title">Uric Acid Report</h4>
                                              </div>
                                              <div class="card-body">
                                                <div class="card-block">
            
                                                  <div class="table-responsive" >
                                                    <table class="table table-bordered mb-0">
                                                         <thead>
                                                         <tr>
                                                           <th scope="col">Parameter</th>
                                                           <th scope="col">Value</th>
                                                         </tr>
                                                         </thead>
                                                         <tbody>
                                                         <tr>
                                                          <td>Uric Acid</td>
                                                          <td>{{urica.Ua}} mg/dL</td>
                                                         </tr>
                                                         </tbody>
                                                    </table>
                                                    </div>
                                                  
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          </div>
            
                                        </div>
                                        </section>
                                        </div>
            
                                        <div class="modal-footer">
                                          <button type="button" class="btn grey btn-outline-secondary"
                                            (click)="d('Close modal')">Close</button>
                                        </div>
            
                                 </div>
                              </ng-template>
            
                               <!-- UA Modal -->
                               <ng-template class="modal text-left" #UAModelContent let-c="close" let-d="dismiss">
                                <div class="modal-md">
            
                                  <div class="modal-body">
                                    <section>
                                      <div *blockUI="'projectInfo'; message: 'Loading'">
            
                                        <div class="row">
                                          <div class="col-xl-12 col-md-12 col-12">
                                            <div class="card">
                                              <div class="card-header">
                                                <h4 class="card-title">Urine Analysis Report</h4>
                                              </div>
                                              <div class="card-body">
                                                <div class="card-block">
            
                                                  <div class="table-responsive" >
                                                    <table class="table table-bordered mb-0">
                                                         <thead>
                                                         <tr>
                                                           <th scope="col">Parameter</th>
                                                           <th scope="col">Value</th>
                                                         </tr>
                                                         </thead>
                                                         <tbody>
                                                          <tr >
                                                            <td >LEU</td>
                                                            <td >{{ur.LEU}}</td>
                                                          </tr>
                          
                                                          <tr>
                                                            <td >URO</td>
                                                            <td >{{ur.URO}}</td>
                                                          </tr>
                          
                                                          <tr >
                                                            <td >BIL</td>
                                                            <td >{{ur.BIL}}</td>
                                                          </tr>
                          
                                                          <tr >
                                                            <td >KET</td>
                                                            <td >{{ur.KET}}</td>
                                                          </tr>
                          
                                                          <tr >
                                                            <td >GLU</td>
                                                            <td >{{ur.GLU}}</td>
                                                          </tr>
                          
                                                          <tr >
                                                            <td >PRO</td>
                                                            <td >{{ur.PRO}}</td>
                                                          </tr>
                          
                                                          <tr >
                                                            <td >BLD</td>
                                                            <td >{{ur.BLD}}</td>
                                                          </tr>
                          
                                                          <tr >
                                                            <td >NIT</td>
                                                            <td >{{ur.NIT}}</td>
                                                          </tr>
                          
                                                          <tr >
                                                            <td >pH</td>
                                                            <td >{{ur.PH}}</td>
                                                          </tr>
                          
                                                          <tr >
                                                            <td >SG</td>
                                                            <td >{{ur.SG}}</td>
                                                          </tr>
            
                                                          <tr >
                                                            <td >VC</td>
                                                            <td >{{ur.VC}}</td>
                                                          </tr>
            
                                                         </tbody>
                                                    </table>
                                                    </div>
                                                  
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          </div>
            
                                        </div>
                                        </section>
                                        </div>
            
                                        <div class="modal-footer">
                                          <button type="button" class="btn grey btn-outline-secondary"
                                            (click)="d('Close modal')">Close</button>
                                        </div>
            
                                 </div>
                              </ng-template>
            
                              <!-- reports Modal -->
                     <ng-template class="modal text-left" #ReportsModelContent let-c="close" let-d="dismiss">
                      <div class="modal-lg">
            
                        <div class="modal-body">
                          <section>
                            <div *blockUI="'projectInfo'; message: 'Loading'">
            
                              <div [hidden]="loading" class="text-center">
                                <div class="loader-wrapper">
                                  <div class="loader-container">
                                    <div class="fading-circle loader-blue-grey">
                                      <div class="circle1 circle"></div>
                                      <div class="circle2 circle"></div>
                                      <div class="circle3 circle"></div>
                                      <div class="circle4 circle"></div>
                                      <div class="circle5 circle"></div>
                                      <div class="circle6 circle"></div>
                                      <div class="circle7 circle"></div>
                                      <div class="circle8 circle"></div>
                                      <div class="circle9 circle"></div>
                                      <div class="circle10 circle"></div>
                                      <div class="circle11 circle"></div>
                                      <div class="circle12 circle"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div [hidden]="!loading" class="row">
                                <div class="col-xl-12 col-md-12 col-12">
                                  <div class="card">
                                    <div class="card-header">
                                      <h4 class="card-title">{{reportDto.reportName}} Report</h4>
                                    </div>
                                    <div class="">
                                      <div class="" style="width: 100%;overflow: auto;">
            
                                        <img src="{{reportDto.reportAttachment}}"  style="width:100%;"/>
                                        
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </div>
            
                              </div>
                              </section>
                              </div>
            
                              <div class="modal-footer">
                                <button type="button" class="btn grey btn-outline-secondary"
                                  (click)="d('Close modal')">Close</button>
                              </div>
            
                       </div>
                    </ng-template>

      </div>
  </div>
</div>