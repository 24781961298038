import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { AuthenticationService } from '../_services/authentication.service';
import { first } from 'rxjs/operators';
import localForage from "localforage";

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isPageLoaded = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    public authService: AuthService,
    private authenticationService: AuthenticationService,
    private renderer: Renderer2
  ) {
    // this.route.queryParams.subscribe(params => {
    //   this.returnUrl = params['returnUrl'];
    // });
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: false
    });
    // Remember Me
    if (localStorage.getItem('remember')) {
      this.renderer.removeClass(document.body, 'bg-full-screen-image');
      localStorage.removeItem('currentLayoutStyle');
      this.router.navigate(['/dashboard/sales']);
    } else if (localStorage.getItem('currentUser')) {
      // Force logout on login if not remember me
      this.authService.doLogout();
      this.isPageLoaded = true;
    } else {
      this.isPageLoaded = true;
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  tryLogin() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    const value = {
      username: this.f.username.value,
      password: this.f.password.value
    };

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          if (this.loginForm.controls['rememberMe'] && this.loginForm.controls['rememberMe'].value) {
            localStorage.setItem('remember', 'true');
          } else {
            localStorage.removeItem('remember');
          }
          localStorage.removeItem('currentLayoutStyle');
          this.setUserInStorage(data);
          this.router.navigate(['/dashboard']);
          //window.location.reload();
        },
        error => {
          this.alertService.error(error.error.error);
          this.loading = false;
        });

  }

  addCheckbox(event) {
    const toggle = document.getElementById('icheckbox');
    if (event.currentTarget.className === 'icheckbox_square-blue') {
      this.renderer.addClass(toggle, 'checked');
    } else if (event.currentTarget.className === 'icheckbox_square-blue checked') {
      this.renderer.removeClass(toggle, 'checked');
    }
  }

  setUserInStorage(res) {
    if (res != null) {
      localStorage.setItem('currentUser', JSON.stringify(res));
    } else {
      localStorage.setItem('currentUser', JSON.stringify(res));
    }
    localForage.setDriver([
      localForage.INDEXEDDB,
      localForage.WEBSQL,
      localForage.LOCALSTORAGE
    ]).then(function () {
      localForage.setItem("doctorname", res.username);
    });

  }

  onRegister() {
    this.router.navigate(["/register"])
  }
}
