<ngx-spinner bdcolor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" style="color: white" type="ball-spin">Loading...</ngx-spinner>

<div class="app-content content">
    <div class="content-wrapper">
      <div class="content-body">
        <!--/ Zero configuration table -->
        <!-- Default ordering table -->
        <section id="ordering">
          <div class="row">
            <div class="col-12" *blockUI="'defaultOrdering'; message: 'Loading'">
              <m-card [options]="options" (reloadFunction)="reloadDefaultOrdering($event)">
                <ng-container mCardHeaderTitle>
                  My Patients
                </ng-container>
                <ng-container mCardBody>
                  <div class="card-dashboard">
                    <!-- <p class="card-text">Lets say you want to sort column descending and
                          ascending order. You want to search by the name column. </p> -->
                    <div class="row">
                      <div class=" col-sm-12 col-md-12">
                        <div class="dataTables_filter">
                          <h5>Search:<input type='text'
                              style='padding:5px;margin:0px auto; margin-left: 8px; width:300px;border-radius: .22rem; border: 1px solid #BABFC7;'
                              placeholder='Type to filter by all columns...' (keyup)='updatedefaultFilter($event)' />
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div class="table-bordered default-ordering" style="min-height: 470px;max-height: 100%;"> 
                      <ngx-datatable class="bootstrap" [limit]="7" [rows]="rows" [columnMode]="'force'" [messages]="dataTableMessage" [scrollbarH]="true"
                        [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" fxFlex="auto" [perfectScrollbar]="config">
  
                        <ngx-datatable-column name="Patient ID"  prop="personId" [flexGrow]="1" [minWidth]="90">
                          <ng-template ngx-datatable-cell-template let-value="value">
                            {{value}}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Patient Name" prop="name" [flexGrow]="1" [minWidth]="90">
                          <ng-template ngx-datatable-cell-template let-value="value">
                            {{value}}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Contact" prop="mobile" [flexGrow]="1" [minWidth]="90">
                          <ng-template ngx-datatable-cell-template let-value="value">
                            {{value}}
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Last visited" prop="lastConsultDate" [flexGrow]="1" [minWidth]="90">
                          <ng-template ngx-datatable-cell-template let-value="value">
                            {{ value | date:'dd-MM-yyyy HH:mm:ss'}} 
                          </ng-template>
                        </ngx-datatable-column>
                        <!-- <ngx-datatable-column name="Location" prop="address" [flexGrow]="1" [minWidth]="90">
                          <ng-template ngx-datatable-cell-template let-value="value">
                            {{value}}
                          </ng-template>
                        </ngx-datatable-column> -->
                        <ngx-datatable-column name="Actions" [flexGrow]="1" [minWidth]="100">
                          <ng-template ngx-datatable-cell-template let-row="row">
                            <a [routerLink]="" title="View Details" class="primary edit mr-1 mrless" (click)="editTableDataModal(editTableDataModalContent, row)"
                              ><i class="la la-eye"></i>
                            </a>
                            <a [routerLink]="" title="Book Appointment" class="primary edit mr-1 mrless" (click)="bookAppointment(row)"
                              ><i class="la la-calendar"></i>
                            </a>
                          </ng-template>
                        </ngx-datatable-column>   
                      </ngx-datatable>
                    </div>
                  </div>
                </ng-container>
              </m-card>
            </div>
          </div>
        </section>
  
        <!-- Scroll - horizontal and vertical table -->
      </div>
    </div>
  </div>
  <!-- ////////////////////////////////////////////////////////////////////////////-->
  