<section id="ordering">
    <div class="row">
        <div class="col-12" *blockUI="'defaultOrdering'; message: 'Loading'">
            <m-card [options]="options" (reloadFunction)="reloadDefaultOrdering($event)">
                <ng-container mCardBody>
                    <div class="card-dashboard">
                        <!-- <p class="card-text">Lets say you want to sort column descending and
                          ascending order. You want to search by the name column. </p> -->
                        <div class="row">
                            <div class=" col-sm-12 col-md-12">
                                <div class="dataTables_filter">
                                    <h5>Search:<input type='text' style='padding:5px;margin:0px auto; margin-left: 8px; width:300px;border-radius: .22rem; border: 1px solid #BABFC7;' placeholder='Type to filter by all columns...' 
                                        />
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div #myTable class="table-bordered default-ordering" style="min-height: 470px;max-height: 100%;">
                            <ngx-datatable class="bootstrap" [limit]="7" [rows]="rows" [columnMode]="'force'" [messages]="dataTableMessage" [scrollbarH]="true" [headerHeight]="50" [footerHeight]="50" [rowHeight]="50" fxFlex="auto" [perfectScrollbar]="config">
                                <ngx-datatable-column name="SNo" [flexGrow]="1" [width]="50">
                                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex">
                                        {{rowIndex+1}}
                                    </ng-template>
                                </ngx-datatable-column>
                                  <ngx-datatable-column name="Patient Name" prop="customerName" [flexGrow]="1" [minWidth]="90">
                                    <ng-template ngx-datatable-cell-template let-value="value">
                                      {{value}}
                                    </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column name="Patient Mobile" prop="customerMobile" [flexGrow]="1" [minWidth]="90">
                                    <ng-template ngx-datatable-cell-template let-value="value">
                                      {{value}}
                                    </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column name="Topic" prop="topic" [flexGrow]="1" [minWidth]="90">
                                    <ng-template ngx-datatable-cell-template let-value="value">
                                      {{value}}
                                    </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column name="Appointment Date" prop="startTime" [flexGrow]="1" [minWidth]="90">
                                    <ng-template ngx-datatable-cell-template let-value="value">
                                      {{ value}} 
                                    </ng-template>
                                  </ngx-datatable-column>
                                  <ngx-datatable-column name="Duration" prop="duration" [flexGrow]="1" [width]="90">
                                    <ng-template ngx-datatable-cell-template let-value="value">
                                      {{value}}
                                    </ng-template>
                                </ngx-datatable-column> 
                                <ngx-datatable-column name="Actions" [flexGrow]="1" [width]="200">
                                    <ng-template ngx-datatable-cell-template let-row="row">
                                        <button type="submit" class="btn-sm btn-primary" [title]="row.toolTip" (click)="startConsultation(row)" [disabled]="!row.showSuccess" >Join</button>
                                    </ng-template>
                                </ngx-datatable-column> 
                            </ngx-datatable>
                        </div>
                        
                    </div>
                </ng-container>
            </m-card>
        </div>
    </div>
</section>