import { Inject, Input, Optional } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validator, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../_services/api-service';
import { PrescriptionService } from '../_services/prescription.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { report } from 'process';
import { SinglePatientviewComponent } from '../single-patientview/single-patientview.component';
import { ApiDrug } from '../_models/apidrug';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Prescription } from '../_models/prescription';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ACPrescriptionNoteDialog, DrugsInteractionDialog } from '../components/appointment-consultation/appointment-consultation.component';

@Component({
  templateUrl: './addprescription.component.html',
  styleUrls: ['./addprescription.component.scss'],
  providers: [ApiService,
    DatePipe]
})

export class AddPrescriptionDialogComponent implements OnInit {
  currentUser: any;
  personId: any;
  drugslist: any = [];
  instructions: string[] = ['Before Food', 'After Food'];
  durations: string[] = ['Select', 'One Day', 'Two Days', 'Three Days', 'Four Days', 'Five Days', 'Six Days', 'Seven Days', 'Eight Days', 'Nine Days',
    'Ten Days', 'Two Weeks', 'Three Weeks', 'Four Weeks', "One Month", "Two Months", "Three Months", "Four Months", "Five Months", "Six Months"];
  frequencies: string[] = ["Morning", "Afternoon", "Night", "SOS", "Others"];
  dosagelist: any = [];
  drugs: Observable<any[]>[] = [];
  consultationForm: FormGroup;
  public prescriptionList: FormArray;
  public lifeStyleModificationList: FormArray;
  searching = false;
  searchFailed = false;
  conditions: any = [];
  complaints: any;
  observation: any;
  examinations: any;
  relevantPoints: any;
  diagnosis: any;
  investigation: any;
  drugName: any;
  dosage: any;
  frequency: any;
  duration: any;
  instruction: any;
  notes: any;
  prescription = new Prescription();
  id: any;
  @BlockUI('projectInfo') blockUIProjectInfo: NgBlockUI;
  lifeStyleModfication: any[];

  constructor(private formBuilder: FormBuilder, private matDialog: MatDialog, public dialogRef: MatDialogRef<SinglePatientviewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private spinner: NgxSpinnerService, private snackbr: MatSnackBar,
    private apiService: ApiService, private prescriptionService: PrescriptionService, private datepipe: DatePipe,
  ) {
    this.personId = data.pageValue.personId;
    this.currentUser = localStorage.getItem('currentUser');
    this.drugslist = JSON.parse(localStorage.getItem("drugs"));
    console.log("data", data)
    this.drugs[0] = this.drugslist;
    this.complaints = data.pageValue.clinicalNotes.complaints;
    this.observation = data.pageValue.clinicalNotes.observation;
    this.relevantPoints = data.pageValue.clinicalNotes.relevantPoints;
    this.investigation = data.pageValue.clinicalNotes.investigation;
    this.notes = data.pageValue.clinicalNotes.notes;
    this.diagnosis = data.pageValue.clinicalNotes.diagnosis;
    this.examinations = data.pageValue.clinicalNotes.examinations;
    this.id = data.pageValue.id;
    // this.dosage = data.pageValue.prescriptionDetails['0'].dosage;
    // this.drugName = data.pageValue.prescriptionDetails['0'].drugName;
  }

  onDrugSelect(index, drug: ApiDrug) {
    var arrayControl = this.consultationForm.get('prescriptionList') as FormArray;
    var prescription = arrayControl.at(index);
    prescription.patchValue({ 'dosage': drug.strength });
  }

  drugAlts: Observable<any[]>[] = [];
  prescSuggestions: any = [];
  prescSuggestionsFlag: boolean = false;
  showAltFlag: Array<boolean> = [];
  prescriptionNotes: any;

  displayFn(drug: any): string {
    return drug && drug.name ? drug.name : '';
  }

  suggestPrescription(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.spinner.show();
    this.apiService.suggestPrescription(e.item.prescriptionIds.slice(-3)).subscribe((res: any) => {
      this.spinner.hide();
      if (res && res.length > 0) {
        this.showPrescriptionNoteDialog(res);
      }
    });
  }

  loadPrescritption(presc: any) {
    // this.prescSuggestionsFlag = presc && presc.prescriptionDetails && presc.prescriptionDetails.length > 0;
    let prescSuggestions = [];
    for (var i = 0; i < this.prescriptionList.length; i++) {
      this.removePrescritption(i);
    }
    for (var i = 0; i < presc.prescriptionDetails.length; i++) {
      this.addPrescritption();
      let p: any = presc.prescriptionDetails[i];
      p.drugName = (p.drug && p.drug.name) ? p.drug : { "name": p.drugName };
      if (p.drugAlts && p.drugAlts.length > 0) {
        let ad = [];
        if (p.altDrugs) {
          p.altDrugs.split(", ").forEach((d) => { const dAlt = p.drugAlts.find((alt) => alt.name == d); dAlt ? ad.unshift(dAlt) : ad.unshift({ "name": d }) });
        }
        p.altDrugs = ad;
        this.drugAlts[i] = of(p.drugAlts);
        this.showAltFlag[i] = true;
      }
      if (p.frequency) {
        let fq = [];
        p.frequency.split(", ").forEach((f) => fq.push(f));
        p.frequency = fq;
      }
      prescSuggestions.push(p);
    }
    const clinicalNotes = presc.clinicalNotes;
    const lifeStyleModifications = presc.lifeStyleModifications;
    const controls1 = <FormArray>this.consultationForm.controls['lifestylemodficationList'];
    controls1.clear();
    for (var i = 0; i < lifeStyleModifications.length; i++) {
      this.addlifeStyleModification();
    }
    clinicalNotes['lifestylemodficationList'] = lifeStyleModifications.map(lsc => { return { 'changes': lsc.lifeStyleChanges } });
    clinicalNotes['prescriptionList'] = prescSuggestions;
    clinicalNotes.complaints = { "complaints": clinicalNotes.complaints }
    this.consultationForm.patchValue(clinicalNotes);
  }

  loadDrugAlts(index, medicine) {
    this.apiService.getDrugsbyName(medicine).subscribe((res: any[]) => {
      if (res.length > 0) {
        this.getDrugAlts(index, res[0]);
      }
    });
  }

  getDrugAlts(index, medicine) {
    const controls = <FormArray>this.consultationForm.controls['prescriptionList'];
    controls.at(index).get('altDrugs').patchValue([]);
    this.showAltFlag[index] = false;
    this.drugAlts[index] = of([]);
    if (medicine.generic && medicine.generic.trim().length > 0) {
      this.drugAlts[index] = this.apiService.getDrugsbyGenericName(medicine.generic).pipe(
        tap((resdata: any) => {
          this.showAltFlag[index] = resdata && resdata.length > 0;
          let ind = resdata.findIndex(x => x.id === medicine.id);
          resdata.splice(ind, 1);
          return resdata;
        }),
        catchError(() => {
          return of([]);
        }));
    }
  }

  addAltDrug(index: number, item: any) {
    var arrayControl = this.consultationForm.get('prescriptionList') as FormArray;
    const drugSel = arrayControl.at(index).get('drugName').value;
    let altDrugs = arrayControl.at(index).get('altDrugs').value;
    if (!altDrugs.find(x => x.id === item.id || x.name === item.name)) {
      altDrugs.push(item);
      arrayControl.at(index).get('altDrugs').patchValue(altDrugs);
    }
  }

  showPrescriptionNoteDialog(rows) {
    const dialogRef = this.matDialog.open(ACPrescriptionNoteDialog, {
      width: '50%',
      disableClose: true,
      hasBackdrop: true,
      data: { pageValue: rows }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result.event == 'not-select') {
        this.prescriptionNotes = result.data;
      } else {
        if (result.data != null) {
          console.log("result--" + result);
          this.loadPrescritption(result.data);

        }
      }
    });
  }

  suggestComplaints = (text$: Observable<any>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.apiService.suggestComlaints({
          "doctorId": JSON.parse(this.currentUser).username,
          "clinicalNotes": { "complaints": term }
        }).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

  formatter = (result: any) => result.complaints;

  get pdf() {
    return this.consultationForm.controls;
  }

  addPrescritption() {
    this.prescriptionList.push(this.createPrescription());
    const controls = <FormArray>this.consultationForm.controls['prescriptionList'];
    this.manageNameControl(controls.length - 1);
  }

  removePrescritption(index) {
    // this.prescriptionList.removeAt(index);
    const controls = <FormArray>this.consultationForm.controls['prescriptionList'];
    controls.removeAt(index);
    // remove from filteredOptions too.
    this.drugs.splice(index, 1);
    this.drugAlts[index] = of([]);
    this.showAltFlag[index] = false;
  }

  addlifeStyleModification() {
    this.lifeStyleModificationList.push(this.createLifeStyleModfication());
    const controls = <FormArray>this.consultationForm.controls['lifeStyleModificationList'];

  }

  removelifeStyleModification(index) {

    const controls = <FormArray>this.consultationForm.controls['lifeStyleModificationList'];
    controls.removeAt(index);

  }

  createPrescription(): FormGroup {

    return this.formBuilder.group({
      drugName: [''],
      dosage: [''],
      instructions: [''],
      frequency: [''],
      duration: ['']
    });
  }

  createLifeStyleModfication(): FormGroup {
    return this.formBuilder.group({
      changes: [''],
    });
  }

  get consultationFormGroup() {
    return this.consultationForm.get('prescriptionList') as FormArray;
  }

  get lifeStyleModificationFormGroup() {
    return this.consultationForm.get('lifeStyleModificationList') as FormArray;
  }

  ngOnInit() {

    // this.data.prescriptionDetails.map(element =>{
    //   this.drugName=element.drugName;
    //   this.dosage=element.dosage;
    //   this.instruction=element.instructions;
    //   this.frequency=element.frequency;
    //   this.duration=element.duration;
    // })

    this.consultationForm = this.formBuilder.group({
      complaints: ['', Validators.required],
      observation: [''],
      examinations: [''],
      diagnosis: ['', Validators.required],
      relevantPoints: [''],
      investigation: [''],
      notes: [''],
      prescriptionList: this.formBuilder.array([this.createPrescription()]),
      lifeStyleModificationList: this.formBuilder.array([this.createLifeStyleModfication()])


    });
    this.manageNameControl(0);
    this.prescriptionList = this.consultationForm.get('prescriptionList') as FormArray;
    this.lifeStyleModificationList = this.consultationForm.get('lifeStyleModificationList') as FormArray;

    // this.data.pageValue.prescriptionDetails.map(element =>{
    // let i=0;
    // var arrayControl = this.consultationForm.get('prescriptionList') as FormArray;
    // var prescription=arrayControl.at(i);
    // prescription.patchValue({'drugName':element.drugName,'dosage':element.dosage,'frequency':element.frequency.replaceAll(" ","").split(','),'duration':element.duration,'instructions':element.instructions});

    // i++;
    // })
    var array = [];
    var array1 = [];
    var ind = 0;

    this.data.pageValue.prescriptionDetails.forEach(element => {
      if (ind == 0) {

      } else {
        this.addPrescritption();
      }
      ind++;
      array.push({ 'drugName': element.drugName, 'dosage': element.dosage, 'frequency': element.frequency.replaceAll(" ", "").split(','), 'duration': element.duration, 'instructions': element.instructions });
    });

    this.data.pageValue.lifeStyleModifications.forEach(element => {
      if (ind == 0) {

      } else {
        this.addlifeStyleModification();
      }
      ind++;
      array1.push({ 'changes': element.lifeStyleChanges });
    });
    console.log(array1);
    this.consultationForm.patchValue({
      'complaints': this.complaints, 'observation': this.observation, 'examinations': this.examinations,
      'diagnosis': this.diagnosis, 'relevantPoints': this.relevantPoints, 'notes': this.notes, 'investigation': this.investigation, 'prescriptionList': array, 'lifeStyleModificationList': array1
    });

    // this.prescriptionList.at[i].patchValue({'drugName':this.drugName,'dosage':this.dosage,'frequency':this.frequency,'duration':this.duration,'instructions':this.instruction});

    // this.consultationForm.get('diagnosis').valueChanges.subscribe(value => {
    //   this.apiService.getCD10(value).subscribe(respone => {
    //     this.conditions = respone[3];

    //   }),
    //     error => {
    //       console.log(error);
    //     }

    // })
  }

  manageNameControl(index: number) {
    var arrayControl = this.consultationForm.get('prescriptionList') as FormArray;
    this.drugs[index] = arrayControl.at(index).get('drugName').valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(name => name.length > 2 ? this.apiService.getDrugsbyName(name).pipe(
          tap((resdata: any) => {
            return resdata[3];

          }),
          catchError(() => {
            return of([]);
          })) : []
        )
      );
  }

  // old medicine api start
  private _filter(name: string) {
    const filterValue = name.toLowerCase();
    return this.drugslist.filter(option =>
      //  option.name.toLowerCase().indexOf(filterValue) === 0);
      option.composition.toLowerCase().indexOf(filterValue) === 0);
  }

  loadDrugList() {
    if (localStorage.getItem("drugs") == null) {
      this.apiService.getDrugsList().subscribe((resData: any) => {
        localStorage.setItem('drugs', JSON.stringify(resData));
      }, error => {

      });
    }
  }

  searchDrug = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.filterDrugs(term)
      ),
      tap(() => this.searching = false)
    )

  filterDrugs(value) {

    var filteredDrugs = [];
    this.drugs = [];
    if (value != null && value != "") {
      this.drugslist.forEach(element => {

        if (element.composition.toLowerCase().includes(value.toLowerCase())) {
          filteredDrugs.push(element);
        }
      });
      return filteredDrugs;
    }
  }

  // end 
  searchFromService = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.apiService.getICD11(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )


  getData(term) {
    var res;
    this.apiService.getCD10(term).subscribe((resData: any) => {
      res = resData[3];
    }, error => {
      var data = error;
      res = [{}]
    })
    return res;
  }

  publishPrescription(): void {
    let rxcuis = [];
    if (this.consultationForm.invalid) {
      this.snackbr.open('Please enter all the mandatory fields', '', { duration: 2000 });
      return;
    }
    this.prescription.personId = this.personId;
    var username = JSON.parse(localStorage.getItem('currentUser')).username;
    this.prescription.doctorId = username;

    let diagnosis = this.consultationForm.get('diagnosis').value;
    if (diagnosis != null && typeof diagnosis != 'string')
      this.consultationForm.patchValue({ 'diagnosis': (diagnosis[0]) })

    let clinicalNotes = Object.assign({}, this.consultationForm.value);
    clinicalNotes.complaints = clinicalNotes.complaints.complaints
    this.prescription.clinicalNotes = clinicalNotes;
    let prescriptionDetails = [];
    clinicalNotes.prescriptionList.slice(0).forEach(function (val) {
      let value = Object.assign({}, val);
      if (value.drugName != null && typeof (value.drugName) != 'string') {
        console.log(value);
        if (value.drugName.rxcui) {
          rxcuis = rxcuis.concat(value.drugName.rxcui.split(', '));
        }
        value.drugName = value.drugName.name;
      }
      if (value.frequency != null && typeof (value.frequency) != 'string') {
        console.log(value);
        value.frequency = value.frequency.join(", ");
      }
      if (value.altDrugs != null && typeof (value.altDrugs) != 'string') {
        console.log(value);
        value.altDrugs = value.altDrugs.map(function (elem) {
          return elem.name;
        }).join(", ");
      }
      prescriptionDetails.push(value);
    });
    delete clinicalNotes.prescriptionList;
    delete clinicalNotes.lifestylemodficationList;
    this.prescription.prescriptionDetails = prescriptionDetails;
    this.prescription.editPrescription = true;
    var username = JSON.parse(localStorage.getItem('currentUser')).username;
    this.prescription.id = this.id;
    this.lifeStyleModfication = [];
    for (var i = 0; i < this.lifeStyleModificationList.value.length; i++) {
      this.lifeStyleModfication.push({ "prescriptionId": this.data.pageValue.prescriptionId, "lifeStyleChanges": this.lifeStyleModificationList.value[i].changes });
    }
    this.prescription.lifeStyleModifications = this.lifeStyleModfication;
    if (this.prescriptionList.length > 1 && rxcuis.length > 1) {
      this.spinner.show();
      this.apiService.getDrugsInteractions(rxcuis.join('+')).subscribe((res) => {
        this.spinner.hide();
        let interactions = [];
        res['fullInteractionTypeGroup']?.forEach((intGrp) => {
          intGrp['fullInteractionType']?.forEach((intType) => {
            intType['interactionPair']?.forEach((intPair) => {
              interactions.push(intPair['description']);
            })
          })
        });
        if (interactions.length > 0) {
          this.showDrugsInteractionDialog(interactions, "publish");
        } else {
          this.publishPrescriptionFinal();
        }
      })
    } else {
      this.publishPrescriptionFinal();
    }
  }

  showDrugsInteractionDialog(rows, source) {
    const dialogRef = this.matDialog.open(DrugsInteractionDialog, {
      width: '50%',
      disableClose: true,
      hasBackdrop: true,
      data: { pageValue: rows, source: source }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result.event == 'publish') {
        this.publishPrescriptionFinal();
      }
    });
  }

  publishPrescriptionFinal() {
    this.spinner.show();
    this.apiService.savePrescription(this.prescription).subscribe(response => {
      this.spinner.hide();
      console.log("pubres---" + response);
      this.snackbr.open('Prescription is updated successfully.', '', { duration: 2000 });
      this.dialogRef.close({ event: 'refresh' });
    },
      error => {
        this.spinner.hide();
        console.log(error);
      });
  }

  onCancel() {
    this.dialogRef.close({ event: 'close' });
  }

  onCancelClick(data: any) {
    console.log("cancel" + data);
  }

}