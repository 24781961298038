// Default menu settings configurations

export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  isStarterkitExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  horizontal_menu: {
    items: Partial<MenuItem>[]
  };
  vertical_menu: {
    items: Partial<MenuItem>[]
  };
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {
    items: [
      // {
      //   title: 'Dashboard',
      //   icon: 'la-home',
      //   page: 'null',
      //   submenu: {
      //     items: [
      //       {
      //         title: 'Sales',
      //         icon: 'la-dollar',
      //         page: '/dashboard/sales'
      //       },
      //       {
      //         title: 'Ecommerce',
      //         icon: 'la-cart-plus',
      //         page: '/dashboard/ecommerce'
      //       },
      //     ]
      //   }
      // },
      {
        title: 'Schedule',
        icon: 'la-calendar',
        page: '/schedule'
      },
      // {
      //   title: 'Tele Consult',
      //   icon: 'la-television',
      //   page: '/tele-consult'
      // },
      {
        title: 'Patients',
        icon: 'la-th-list',
        page: '/my-patients'
      },
      {
        title: 'Consultation List',
        icon: 'la-table',
        page: '/consultations'
      },
      {
        title: 'Appointments',
        icon: 'la-stethoscope',
        page: '/appointments'
      },
      // {
      //   title: 'Templates',
      //   icon: 'la-television',
      //   page: 'null',
      //   submenu: {
      //     items: [
      //       {
      //         title: 'Horizontal',
      //         page: 'null'
      //       },
      //       {
      //         title: 'Vertical',
      //         page: 'null'
      //       },
      //     ]
      //   }
      // }

    ]
  },
  vertical_menu: {
    items: [
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: '/dashboard',
        //   submenu: {
        //     items: [
        //       {
        //         title: 'Sales',
        //         icon: 'la-dollar',
        //         page: '/dashboard/sales'
        //       },
        //       {
        //         title: 'Ecommerce',
        //         icon: 'la-cart-plus',
        //         page: '/dashboard/ecommerce'
        //       },
        //     ]
        //   }
      },
      {
        title: 'Schedule',
        icon: 'la-calendar',
        page: '/schedule'
      },
      // {
      //   title: 'Tele Consult',
      //   icon: 'la-television',
      //   page: '/tele-consult'
      // },
      {
        title: 'Patients',
        icon: 'la-group',
        page: '/my-patients'
      },
      {
        title: 'Appointments',
        icon: 'la-stethoscope',
        page: '/appointments'
      },
      // {
      //   title: 'Templates',
      //   icon: 'la-television',
      //   page: 'null',
      //   submenu: {
      //     items: [
      //       {
      //         title: 'Horizontal',
      //         page: 'null'
      //       },
      //       {
      //         title: 'Vertical',
      //         page: 'null'
      //       },
      //     ]
      //   }
      // }

    ]
  }

};





