<form [formGroup]="form" (ngSubmit)="onPrescriptionNoteSave()">
    <div>
        <div [hidden]="showNoteInput" class="list-group">
            <label>Select One from below list of previous prescption(s)</label>
            <a (click)="onSelect(presc)" *ngFor="let presc of prescriptions" class="list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="text-bold-600">{{presc.clinicalNotes.complaints}} - {{presc.clinicalNotes.observation}} (Diagnosis: {{presc.clinicalNotes.diagnosis}})</h5>
                    <small>{{getDaysAgoText(presc)}}</small>
                </div>
                <p>{{presc.prescriptionNotes}}</p>
            </a>
        </div>
        <div [hidden]="!showNoteInput" >
            <label>Prescription Note</label>
            <input type="text" id="allergies" (keypress)="keyPressAlpha($event)" placeholder="Enter Cancellation Reason" [(ngModel)]="reason" placement="top" 
            style="width: 100%;height: 40px;"  formControlName="prescriptionNote">
            <div class="error" *ngIf="prescriptionNote?.errors?.required && (prescriptionNote?.dirty || prescriptionNote?.touched)">
                Prescription Note is required 
            </div>
        </div>
    </div>
    <div style="text-align: center;margin: 10px;">
        <button [hidden]="showNoteInput" type="button" class="btn btn-danger" (click)="onCancel()">
            Cancel
        </button>
        <button [hidden]="!showNoteInput" type="button" class="btn btn-danger" (click)="onBack()">
            Back
        </button>
        <button type="submit" [hidden]="!showNoteInput" style="margin-left: 25px;" class="btn btn-primary" [disabled]="form.invalid">
            Okay
        </button>
    </div>
</form>