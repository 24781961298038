import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { UserService } from '../_api/user/user.service';
import { ToastrService } from 'ngx-toastr';
import * as firebase from 'firebase/app';
import { ApiService } from '../_services/api-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _moment from 'moment';
import { Moment } from 'moment';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export class Registration {
  id: Number;
  family: string;
  given: string;
  username: string;
  mobile: string;
  email: string;
  password: string;
  confirmPassword: string;
  gender: string;
  birthDate: string;
  line: string;
  systemOfMedicine: string;
  practicing: string;
  visibletoPublic: string;
  registeredState: string;
  registeredCountry: string;
  registrationNo: string;
  registrationDate: string;
  validTill: string;
  courseName: string;
  university: string;
  college: string;
  completionYear: string;
  areaOfPractice: string;
  practicingSince: string;
  city: string;
  district: string;
  state: string;
  postalCode: string;
  show:true;
  //     identifier :Identifier;
  //   active : boolean;
  //   name : HumanName; // The name(s) associated with the practitioner
  //   telecom : ContactPoint; // A contact detail for the practitioner (that apply to all roles)
  //   address : Address; // Address(es) of the practitioner that are not role specific (typically home address)
  //   gender : string; // male | female | other | unknown
  //   birthDate : string; // The date  on which the practitioner was born
  //   photo : Attachment; // Image of the person
  //   qualification : Qualification;
  //   communication : CodeableConcept; 
}

export class CodeableConcept {
  coding: string;
  text: string;
}

export class Period {
  start: string;
  end: string;
}

export class Identifier {
  use: string; // usual | official | temp | secondary | old (If known)
  type: CodeableConcept // Description of identifier
  system: string; // The namespace for the identifier value
  value: string; // The value that is unique
  period: Period; // Time period when id is/was valid for use
  assigner: string; // Organization that issued id (may be just text)
}

export class Qualification {
  identifier: Identifier;
  code: CodeableConcept
  period: Period;
  issuer: string;
}

export class HumanName {
  use: string;
  text: string
  family: string;
  given: string;
  prefix: string;
  suffix: string;
  period: Period
}

export class ContactPoint {
  use: string;
  rank: Number
  system: string;
  value: string;
  period: Period;
}

export class Attachment {
  contentType: string; // Mime type of the content, with charset etc.
  language: string; // Human language of the content (BCP-47)
  data: string; // Data inline, base64ed
  url: string; // Uri where the data can be found
  size: Number // Number of bytes of content (if url provided)
  hash: string; // Hash of the data (sha-1, base64ed)
  title: string; // Label to display in place of the data
  creation: string; // Date attachment was first created
}

export class Address {
  use: string; // home | work | temp | old | billing - purpose of this address
  type: string;  // postal | physical | both
  text: string;  // Text representation of the address
  line: string;  // Street name, number, direction & P.O. Box etc.
  city: string;  // Name of city, town etc.
  district: string;  // District name (aka county)
  state: string;  // Sub-unit of country (abbreviations ok)
  postalCode: string;  // Postal code for area
  country: string;  // Country (e.g. can be ISO 3166 2 or 3 letter code)
  period: Period// Time period when address was/is in use

}

@Component({
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.css'],
  providers: [ApiService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe
  ]
})
export class RegisterComponent implements OnInit {
  passmsg;any;
  personaldetailsForm: FormGroup;
  registrationdetailsForm: FormGroup;
  employmentdetailsForm: FormGroup;
  returnUrl: string;
  error = '';
  today = new Date();
  registration = new Registration();
  type: any;
  edit: boolean = false;
  register: string = "Register";
  show:boolean=false;
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  errorMessage = '';
  successMessage = '';
  user = {};
  users = [];
  years: number[] = [];
  areaOfPractice: any[];
  countires: string[];
  invalidPassword: boolean = false;
  public educationList: FormArray;
  public appointmentList: FormArray;
  defaultImage = '../assets/images/portrait/small/default.png';
  fileToUpload: File = null;
  signature: string = "";
  @Input() max: any;
  tomorrow = new Date(); 
  duration=[{key:"15",name:"15 Min"},
  {key:"30",name:"30 Min"},
  {key:"45",name:"45 Min"},
  {key:"60",name:"60 Min"}]
  newFileData: string | ArrayBuffer;
  minDate: Moment;
  maxDate:Moment;
  showError: boolean;
  constructor( private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private apiService: ApiService,
    private snackbar: MatSnackBar,
    private datepipe: DatePipe,
    private renderer: Renderer2) {
    // for (let i = 2021; i > 1905; i--) {
    //   this.years.push(i);
    // }
    this.type = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation()["extras"]["state"] : null;
    if (this.type != null) {
      console.log("type--", this.type['type']);
      if (this.type['type'] == "edit") {
        this.edit = true;
        this.register = "Update"
      }
    }
    // this.tomorrow.setFullYear(this.tomorrow.getFullYear() - 24);
  }
  checkPassSame() {
    let pass = this.personaldetailsForm.value.password;
    let passConf = this.personaldetailsForm.value.confirmPassword;
    console.log(this.pdf.confirmPassword);
    if(pass == passConf || !this.pdf.confirmPassword.touched) {
      this.passmsg = "";
      return false;
    }else {
      this.passmsg = "Password did not match.";
      return true;
    }
  }
  

  get pdf() {
    return this.personaldetailsForm.controls;
  }
  get personaldetailsFormGroup() {
    return this.personaldetailsForm.get('educationDTOList') as FormArray;
  }
  get getAppointmentsFormrGoup() {
    return this.personaldetailsForm.get('appointmentsDTOList') as FormArray;
  }
  getAppointmentsFormTimeSlotsGroup(i) {
    return (<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].get('timeSlotDTOList') as FormArray;
  }
  
  getValidity(i) {
    return (<FormArray>this.personaldetailsForm.get('educationDTOList')).controls[i].invalid;
  }
  getValidityUniv(i) {
    return (<FormArray>this.personaldetailsForm.get('educationDTOList')).controls[i].get('university').errors
    && (<FormArray>this.personaldetailsForm.get('educationDTOList')).controls[i].get('university').invalid
    && (<FormArray>this.personaldetailsForm.get('educationDTOList')).controls[i].get('university').touched;
  }
  getValidityCourse(i) {
    return (<FormArray>this.personaldetailsForm.get('educationDTOList')).controls[i].get('courseName').errors
    && (<FormArray>this.personaldetailsForm.get('educationDTOList')).controls[i].get('courseName').invalid
    && (<FormArray>this.personaldetailsForm.get('educationDTOList')).controls[i].get('courseName').touched;
  }
  getValidityAvai(i) {
    console.log(<FormArray>this.personaldetailsForm.get('appointmentsDTOList'));
    return (<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].invalid;
  }
  getValidityDays(i){
    return (<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].get('availabilitySlotDays').errors
    && (<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].get('availabilitySlotDays').invalid
    && (<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].get('availabilitySlotDays').touched;
  }
  getValidityTimeSlot(i,j) {
    return (<FormArray>(<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].get('timeSlotDTOList')).controls[j].invalid;
  }
  addEducation() {
    this.educationList.push(this.createEducation());
  }
  addAppointment() {
    this.appointmentList.push(this.createAppointment());
  }
  addAppointmentTimeSlots(i) {
    (<FormArray>(<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].get('timeSlotDTOList')).push(this.createTimeSlots())
    // this.appointmentList[i]["timeSlotDTOList"].push(this.createTimeSlots());
  }
  removeEducation(index) {
    this.educationList.removeAt(index);
  }
  removeAppointment(index) {
    this.appointmentList.removeAt(index);
  }
  removeTimeSlot(i,index) {
    (<FormArray>(<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].get('timeSlotDTOList')).removeAt(index)
    // this.appointmentList[i]["timeSlotDTOList"].removeAt(index);
  }
  createEducation(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      courseName: ['', Validators.required],
      university: ['', Validators.required],
      completionyear: ['', Validators.required],
    });
  }
  createAppointment(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      availabilitySlotDuration: ['15', Validators.required],
      availabilitySlotDays: ['', Validators.required],
      timeSlotDTOList: this.formBuilder.array([this.createTimeSlots()]),
    });
  }
  createTimeSlots(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
    });
  }
  namePressAlpha(event) {
    var inp = String.fromCharCode(event.nameCode);
    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  multipleSelectArray = [
    {
      "item_id": 1, "item_text": "Monday"
    },
    {
      "item_id": 2, "item_text": "Tuesday"
    },
    {
      "item_id": 3, "item_text": "Wednesday"
    },
    {
      "item_id": 4, "item_text": "Thursday"
    },
    {
      "item_id": 5, "item_text": "Friday"
    },
    {
      "item_id": 6, "item_text": "Saturday"
    },
    {
      "item_id": 0, "item_text": "Sunday"
    }
  ];
  multipleSelectArray1 = [
   "MONDAY","TUESDAY","WEDNESDAY","THURSDAY","FRIDAY","SATURDAY","SUNDAY"
  ];
  timeSlots=[
    { key:"08:00:00",name:"8:00 AM" },
    { key:"08:30:00",name:"8:30 AM" },
    { key:"09:00:00",name:"9:00 AM" },
    { key:"09:30:00",name:"9:30 AM" },
    { key:"10:00:00",name:"10:00 AM" },
    { key:"10:30:00",name:"10:30 AM" },
    { key:"11:00:00",name:"11:00 AM" },
    { key:"11:30:00",name:"11:30 AM" },
    { key:"12:00:00",name:"12:00 PM" },
    { key:"12:30:00",name:"12:30 PM" },
    { key:"13:00:00",name:"1:00 PM" },
    { key:"13:30:00",name:"1:30 PM" },
    { key:"14:00:00",name:"2:00 PM" },
    { key:"14:30:00",name:"2:30 PM" },
    { key:"15:00:00",name:"3:00 PM" },
    { key:"15:30:00",name:"3:30 PM" },
    { key:"16:00:00",name:"4:00 PM" },
    { key:"16:30:00",name:"4:30 PM" },
    { key:"17:00:00",name:"5:00 PM" },
    { key:"17:30:00",name:"5:30 PM" },
    { key:"18:00:00",name:"6:00 PM" },
    { key:"18:30:00",name:"6:30 PM" },
    { key:"19:00:00",name:"7:00 PM" },
    { key:"19:30:00",name:"7:30 PM" },
    { key:"20:00:00",name:"8:00 PM" },
    { key:"20:30:00",name:"8:30 PM" },
    { key:"21:00:00",name:"9:00 PM" },
    { key:"21:30:00",name:"9:30 PM" },
    { key:"22:00:00",name:"10:00 PM" },
    { key:"22:30:00",name:"10:30 PM" },
    { key:"23:00:00",name:"11:00 PM" },
    { key:"23:30:00",name:"11:30 PM" },
]
  multipleMultiSelect = []

  slotAvailability = {
    night: { start: '', end: '' },
    evening: { start: '', end: '' },
    noon: { start: '', end: '' },
    morning: { start: '', end: '' }
  }

  ngOnInit() {
    this.areaOfPractice = this.apiService.getAllAreaOfPractice();
    this.countires = this.apiService.getCountries();
    this.personaldetailsForm = this.formBuilder.group({
      id: [''],
      given: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(20)]],
      family: ['',[Validators.required,Validators.minLength(2),Validators.maxLength(20)]],
      // middlename: ['', Validators.required],
      username: ['', [Validators.required,Validators.pattern("^[A-Za-z0-9 ]+$"),Validators.minLength(2),Validators.maxLength(20)]],
      mobile: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      birthDate: ['', Validators.required],
      gender: ['', Validators.required],
      email: [''],
      city: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(20)]],
      country: ['India', Validators.required],
      doctorType:['',Validators.required],
      postalcode: '',
      feeAmount:'',
      registeredCountry: [{ value: 'India', disabled: true }, Validators.required],
      registeredAt: ['',[Validators.required,Validators.minLength(2),Validators.maxLength(20)]],
      registrationNo: ['', Validators.required],
      // regdate: ['', Validators.required],
      // validtill: ['', Validators.required],
      // systemofmedicine: ['', Validators.required],
      // practicing: ['', Validators.required],
      // visibletoPublic: ['', Validators.required],
      educationDTOList: this.formBuilder.array([this.createEducation()]),
      // coursename: ['', Validators.required],
      // university: ['', Validators.required],
      // // college: ['', Validators.required],
      // completionyear: ['', Validators.required],
      practicing: ['', Validators.required],
      practicingSince:'',
      agreeTerms:false,
      // availabilitySlotDuration: ['', Validators.required],
      // availabilitySlotDays: ['', Validators.required],
      appointmentsDTOList: this.formBuilder.array([this.createAppointment()]),

    });
    if(!this.edit){
      this.personaldetailsForm.get('agreeTerms').setValidators(Validators.requiredTrue);
    }
    this.educationList = this.personaldetailsForm.get('educationDTOList') as FormArray;
    this.appointmentList = this.personaldetailsForm.get('appointmentsDTOList') as FormArray;
    // this.timeSlotList = this.personaldetailsForm.get('appointmentsDTOList').get('timeSlotDTOList') as FormArray;

    this.registrationdetailsForm = this.formBuilder.group({

    });
    this.employmentdetailsForm = this.formBuilder.group({
      speciality: ['', Validators.required],
      subspeciality: ['', Validators.required],
      state_h: ['', Validators.required],
      district_h: ['', Validators.required],
      hositalname: ['', Validators.required],
      designation: ['', Validators.required],

    });
    const currentYear = moment().year();
    this.minDate = moment([currentYear - 100, 0, 1]);
    this.maxDate= moment([currentYear - 18, 0, 1])
    // this.addEducation();
    // var details = {"given":"muneeswar","family":"reddy","username":"muni","mobile":"9998939994","password":"123","confirmPassword":"123","birthDate":"2021-02-09","gender":"Male","city":"Hyderabad","country":"India","postalCode":"500082","registeredCountry":"India","registeredState":"Telengana","registrationNo":"ggg11","educationList":[{"coursename":"gg","university":"gg","completionyear":"2004"},{"coursename":"ggg","university":"ww","completionyear":"2003"}],"areaofpractice":"Anesthesiology - Pediatric Anesthesiology","practicingsince":"2003"};
    // this.personaldetailsForm.patchValue(details);
    if (this.edit)
      this.loadUserDetails();
  }
  addCheckbox(event) {
    const toggle = document.getElementById('icheckbox');
    if (event.currentTarget.className === 'icheckbox_square-blue') {
      this.renderer.addClass(toggle, 'checked');
    } else if (event.currentTarget.className === 'icheckbox_square-blue checked') {
      this.renderer.removeClass(toggle, 'checked');
    }
  }
  handleFileInput(files: FileList) {
    this.newFile();
    this.fileToUpload = files.item(0);
    console.log("filedata=",this.fileToUpload)
    console.log(files.item(0).name)
    const reader = new FileReader();
    reader.readAsDataURL(this.fileToUpload);
    reader.onload = () => {
      // console.log("signaturedata--" + reader.result);
      // this.newFileData=reader.result;
      // console.log("new=",this.newFileData)
      this.signature = reader.result.toString();
    
    };
    
  }
  loadUserDetails() {
    var user = JSON.parse(localStorage.getItem('currentUser'));
    this.loading = true;
    this.spinner.show();
    this.apiService.getUserDetails(user['username']).subscribe((resData: any) => {
      this.loading = false;
      let userData = resData;
      userData.birthDate = userData.birthDate?.split(" ")[0];
      userData.password = "Achala@123";
      userData.confirmPassword = "Achala@123";
      this.signature = resData.signature != null ? resData.signature : "";
      if(this.signature!=''){
        this.show=true;
      }
      this.years = [];
      let dobyear = parseInt(userData.birthDate?.split("-")[0]);
      for (let i = dobyear + 18; i <= this.tomorrow.getFullYear(); i++) {
        this.years.push(i);
      }
      for (var i = 0; i < userData.educationDTOList.length - 1; i++) {
        this.addEducation();
      }
      for (var i = 0; i < userData.appointmentsDTOList.length - 1; i++) {
        this.addAppointment();
      }
      for (var i = 0; i < userData.appointmentsDTOList.length; i++) {
      var appointmentData=userData.appointmentsDTOList[i];
        for(var j = 0; j < appointmentData.timeSlotDTOList.length-1; j++){
          this.addAppointmentTimeSlots(i);
        }
      }
      var array=[];
      userData.appointmentsDTOList=userData.appointmentsDTOList.map(element => {
          return {...element,availabilitySlotDuration:15}
      });
      this.personaldetailsForm.patchValue(userData);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      var data = error;
      this.loading = false;
    });
  }
  newFile(){
    this.show=false;
  }
  checkDoctorType(){
    if(this.personaldetailsForm.value.doctorType=='Paid'){
      this.personaldetailsForm.get('feeAmount').setValidators([Validators.required,Validators.pattern("^[0-9]\\d{0,4}(\\.\\d{1,5})?$")]);
    }
    else{
      this.personaldetailsForm.get('feeAmount').setValidators(null);
      this.personaldetailsForm.get('feeAmount').setErrors(null);
    }
  }
  dobChange() {
    let date = new Date(this.personaldetailsForm.value.birthDate);
    console.log("dobyesr--", date.getFullYear())
    if (!isNaN(date.getFullYear())) {
      this.years = [];
      for (let i = date.getFullYear() + 18; i <= this.tomorrow.getFullYear(); i++) {
        this.years.push(i);
      }
    }
  }
  checkRange(timeSlots){
    var flag=true;
      if(timeSlots.length>0){
          for(var i=0;i<timeSlots.length;i++){
            for(var j=0;j<=i;j++){
              if(i!=j){
                var startTimeArr1=timeSlots[i].startTime.split(":");
                var startTimeArr2=timeSlots[j].startTime.split(":");
                var endTimeArr1=timeSlots[i].endTime.split(":");
                var endTimeArr2=timeSlots[j].endTime.split(":");
                var date=new Date();
                var StartDate1=date.setHours(startTimeArr1[0],startTimeArr1[1],startTimeArr1[2]);
                var StartDate2=date.setHours(startTimeArr2[0],startTimeArr2[1],startTimeArr2[2]);
                var EndDate1=date.setHours(endTimeArr1[0],endTimeArr1[1],endTimeArr1[2]);
                var EndDate2=date.setHours(endTimeArr2[0],endTimeArr2[1],endTimeArr2[2]);
                if((StartDate1 < EndDate2) && (StartDate2 < EndDate1)) {
                  //overlapping dates
                  this.snackbar.open('Time Slots Overlapping. Please Check', "", { duration: 2000 });
                  flag=false;
                }
                             
              console.log(i +":"+j);
              }
            }
          }
      }
      return flag;
  }
  onSubmit() {
    // console.log("res--", JSON.stringify(this.personaldetailsForm.value));
    // console.log("date--", this.datepipe.transform(this.personaldetailsForm.value.birthDate, "yyyy-MM-dd"))
    this.submitted = true;
    if (this.personaldetailsForm.invalid) {
      return;
    }
    if (this.personaldetailsForm.value.password != this.personaldetailsForm.value.confirmPassword) {
      //this.alertService.error('Please enter confirm password same as password');
      this.snackbar.open('Please enter confirm password same as password.', "", { duration: 2000 });
      return;
    }
    var appList=this.personaldetailsForm.value.appointmentsDTOList;
    var flag=true;
    this.multipleSelectArray1.forEach(element => {
      var timeSlots=[];
      if(flag){
      appList.forEach(appArray => {
        if(appArray.availabilitySlotDays.includes(element)){
          appArray.timeSlotDTOList.forEach(timeSlot => {
            timeSlots.push(timeSlot);
            let date=new Date();
            var startTimeArr1=timeSlot.startTime.split(":");
            var StartDate1=date.setHours(startTimeArr1[0],startTimeArr1[1],startTimeArr1[2]);
            var endTimeArr1=timeSlot.endTime.split(":");
            var EndDate1=date.setHours(endTimeArr1[0],endTimeArr1[1],endTimeArr1[2]);
            if((StartDate1 >= EndDate1 )) {
              this.snackbar.open('Invalid time slots. Please Check', "", { duration: 2000 });
              flag=false;
            }
          });
        }
      });
      if(!this.checkRange(timeSlots)){
        flag=false;
      }
    }
      console.log(flag);
    });
    if(!flag){
      return;
    }
    // if(!this.edit && !this.personaldetailsForm.get('agreeTerms').value){
    //   return;
    // }
    this.personaldetailsForm.value.educationDTOList = this.uniqueData(this.personaldetailsForm.value.educationDTOList, "courseName");
    this.personaldetailsForm.value.birthDate = this.datepipe.transform(this.personaldetailsForm.value.birthDate, "yyyy-MM-dd") + " 00:00:00";
    this.personaldetailsForm.value.signature = this.signature;

    console.log("res--", JSON.stringify(this.personaldetailsForm.value));
    if (this.edit) {
      this.personaldetailsForm.value.password = null;
      this.personaldetailsForm.value.confirmPassword = null;

      if(this.personaldetailsForm.value.doctorType=='Free')
      {
        this.personaldetailsForm.value.feeAmount=0;
      }
      this.loading = true;
      this.apiService.updateUserDetails(this.personaldetailsForm.value).subscribe((resData: any) => {
        this.loading = false;
        console.log("res--" + JSON.stringify(resData));
        // this.alertService.success('Profile details has been updated successfully.');
        // this.router.navigate(['/']);
        var data = JSON.parse(resData['body']);
        if (!data.emailExist && !data.mobileExist && !data.registrationNoExist) {
          let snackBarRef = this.snackbar.open('Profile details has been updated successfully.', "", { duration: 2000 });
          snackBarRef.afterDismissed().subscribe(() => {
            console.log('The snack-bar was dismissed');
            this.router.navigate(['/dashboard']);
          });
        }
        else {
          let msg = "";
          if (data.emailExist)
            msg = "This email"
          if (data.mobileExist)
            msg = "This mobile number"
          if (data.registrationNoExist)
            msg = "This licence number"
          if (data.emailExist && data.mobileExist)
            msg = "This email and mobile number"
          if (data.emailExist && data.registrationNoExist)
            msg = "This email and licence number"
          if (data.mobileExist && data.registrationNoExist)
            msg = "This mobile number and licence number"
          if (data.emailExist && data.mobileExist && data.registrationNoExist)
            msg = "This email, mobile number and licence number"
          this.snackbar.open(msg + " already exists, please use other values.", "", { duration: 3000 });

        }
      }, error => {
        var data = error;
        this.loading = false;
      });
    }
    else {
      this.loading = true;
      this.apiService.getUserDetails(this.personaldetailsForm.value.username).subscribe((resData: any) => {
        this.loading = false;
        console.log("res--" + JSON.stringify(resData));
        this.loading = true;
        if (resData == null) {
          // this.registration.registrationDate = this.registration.registrationDate + " 00:00:00";
          // this.registration.validTill = this.registration.validTill + " 00:00:00";
          let reqBody = this.personaldetailsForm.value;
          // if (reqBody.availabilitySlotDays) {
          //   let avialability = [];
          //   for (let slot in reqBody.availabilitySlotDays) {
          //     avialability.push({ 'duration': reqBody.availabilitySlotDuration, 'session': 'Morning', 'dayOfWeek': reqBody.availabilitySlotDays[slot]['item_id'], 'startTime': this.slotAvailability.morning.start, 'endTime': this.slotAvailability.morning.end });
          //     avialability.push({ 'duration': reqBody.availabilitySlotDuration, 'session': 'Noon', 'dayOfWeek': reqBody.availabilitySlotDays[slot]['item_id'], 'startTime': this.slotAvailability.noon.start, 'endTime': this.slotAvailability.noon.end });
          //     avialability.push({ 'duration': reqBody.availabilitySlotDuration, 'session': 'Evening', 'dayOfWeek': reqBody.availabilitySlotDays[slot]['item_id'], 'startTime': this.slotAvailability.evening.start, 'endTime': this.slotAvailability.evening.end });
          //     avialability.push({ 'duration': reqBody.availabilitySlotDuration, 'session': 'Night', 'dayOfWeek': reqBody.availabilitySlotDays[slot]['item_id'], 'startTime': this.slotAvailability.night.start, 'endTime': this.slotAvailability.night.end });
          //   }
          //   reqBody['availability'] = avialability;
          //   delete reqBody.availabilitySlotDays;
          //   delete reqBody.availabilitySlotDuration;
          // }
          this.apiService.registration(reqBody).subscribe((resData: any) => {
            this.loading = false;
            console.log("res--" + JSON.stringify(resData));
            // this.alertService.success('This doctor has been registered successfully with us, Please proceed to login.');
            // this.router.navigate(['/']);
            var data = JSON.parse(resData['body']);
            if (!data.emailExist && !data.mobileExist && !data.registrationNoExist) {
              let snackBarRef = this.snackbar.open('This doctor has been registered successfully with us, Please proceed to login.', "", { duration: 2000 });
              this.router.navigate(['/login']);
              snackBarRef.afterDismissed().subscribe(() => {
                console.log('The snack-bar was dismissed');
              });
            }
            else {
              let msg = "";
              if (data.emailExist)
                msg = "This email"
              if (data.mobileExist)
                msg = "This mobile number"
              if (data.registrationNoExist)
                msg = "This licence number"
              if (data.emailExist && data.mobileExist)
                msg = "This email and mobile number"
              if (data.emailExist && data.registrationNoExist)
                msg = "This email and licence number"
              if (data.mobileExist && data.registrationNoExist)
                msg = "This mobile number and licence number"
              if (data.emailExist && data.mobileExist && data.registrationNoExist)
                msg = "This email, mobile number and licence number"
              this.snackbar.open(msg + " already exists, please use other values.", "", { duration: 3000 });

            }
          }, error => {
            var data = error;
            this.loading = false;
          });
        }
        else {
          this.snackbar.open('This username has been already taken,please try with other username.', "", { duration: 2000 });
          //this.alertService.error("This username has been already taken,please try with other username.")
        }
      }, error => {
        var data = error;
        this.loading = false;
      });
    }

  }

  uniqueData(array, name) {
    // create new objects for use
    var uniqueArray = [];
    var map = new Map();

    // loop throught array
    array.forEach((user, index) => {
      // first item is always unique add to unique whithout check
      if (index == 0) {
        // using map set first item in map name and value is dynamic which we can set
        map.set(array[index].courseName, array[index].courseName);
        uniqueArray.push(array[index]);
      }

      //check if the name already exists if exists do not push else push
      if (!map.get(user[name])) {
        map.set(user[name], user[name]);
        uniqueArray.push(user);
      }
    });
    return uniqueArray;
  }
  getTimeSlots(i){
    var slot=(<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].get('availabilitySlotDuration');
    // var time=new Date(8);
    // if(slot!=''){

    // }
    var arr = [], i, j;
    // for(i=8; i<23; i++) {
    //   for(j=0; j<2; j++) {
    //     var format;
    //     if(i<12){
    //       format=" AM"
    //     }else{
    //       format=" PM"
    //     }
    //     var hr;
    //     if(i<=12){
    //       hr=i;
    //     }else{
    //       hr=i-12;
    //     }
    //     arr.push({name:i + ":" + (j===0 ? "00" : 30*j) +":00",name:hr + ":" + (j===0 ? "00" : 30*j)+format});
    //   }
    // }
    // arr.push({name:"abc",name:"test"})
    return arr;
    // console.log(array);
    
  }
}
