import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { AbstractControl } from '@angular/forms';
import { MyApointmentsComponent } from '../components/my-appointments/my-appointments.component';
import { ApiService } from '../_services/api-service';
import { RequestMeeting } from '../_models/RequestMeetingObject';
import { Settings } from '../_models/SettingsObject';
import { map, startWith } from 'rxjs/operators';
export function RequireMatch(control: AbstractControl) {
  const selection: any = control.value;
  if (typeof selection === 'string') {
    return { incorrect: true };
  }
  return null;
}

@Component({
  templateUrl: './bookappointment.component.html',
  styleUrls: ['./bookappointment.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookAppointmentComponent implements OnInit {

  scheduleForm: FormGroup;
  meetingObject: RequestMeeting;
  scheduleBtnClicked: boolean = false;
  showScheduleAnotherMeeting: boolean = false;
  date: Date = new Date();
  customerData: any;
  submitted: boolean = false;
  reschedule: boolean;
  title = "Schedule";
  doctorname: any;
  patientDetected:boolean=false;
  // doctorId: any;
  selectedDate: Date;
  doctorType:any;
  feeAmount:any;
  temp:any;
  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private router: Router,
    public dialogRef: MatDialogRef<BookAppointmentComponent>, private apiService: ApiService,
    private snackbar: MatSnackBar, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
    this.customerData = data.pageValue;
  }

  ngOnInit(): void {
    this.calInit();
    this.meetingObject = new RequestMeeting();
    this.getDoctorDetails();
    this.scheduleForm = this.formBuilder.group({
      topic: ["", [Validators.required]],
      startDate:["",Validators.required],
      from_time: ["", [Validators.required]],
      // to_time: ["", [Validators.required]],
      patient: ["", [Validators.required]],
      time_zone: ["GMT+5:30, India Standard Time"],
    })
    // this.getDoctors();
    if (this.customerData == null) {
      this.getCustomers();
    } else {
      if (this.customerData.name)
        this.scheduleForm.controls["patient"].setValue({ memberDTO: { Name: this.customerData.name , Age:this.customerData.age , Sex:this.customerData.sex , Mobile:this.customerData} });
    }
    this.filteredPatientsList = this.pdf['patient'].valueChanges.pipe(
      startWith(''),
      map(patient => (patient && typeof (patient) === 'string' ? this._filterpatients(patient, 0) : this.patients.slice())),
    );
    this.pdf['startDate'].valueChanges.subscribe((dateVal) => {
      this.getDoctorSlots(dateVal);
    });

  }

  filteredPatientsList: Observable<any[]>;
  patients: any[] = [];
  displayFn(patient: any): string {
    return patient && patient.memberDTO.Name ? patient.memberDTO.Name : '' ;
  }
  getCustomers() {
    this.apiService.getAllCustomers().subscribe((data: any) => {
      if (data != null) {
        console.log(data)
        this.patients = data;
      }
    }, error => {
      console.log(error);
    });
  }

  myChangeFunc(data: any) {
    this.patientDetected=true;
    this.scheduleForm.controls["patient"].setValue(data);
    this.customerData = this.customerData != null ? this.customerData : {};
    this.customerData.mobile = data.memberDTO.Mobile;
    this.customerData.name = data.memberDTO.Name;
  }

  private _filterpatients(value: string, from: number): any[] {
    if (!value) {
      return;
    }
    const filterValue = value.toLowerCase();
    if (from == 0) {
      return this.patients.filter(patient => patient.memberDTO.Name.toLowerCase().includes(filterValue));
    }
  }

  doctorsList = [];

  doctorsSlots: Observable<any[]>;
  getDoctorSlots(dateVal) {
    if (this.temp?.id) {
      this.doctorsSlots = this.apiService.getDoctorFreeSlots(this.temp?.id, dateVal);
    }
  }

  get pdf() {
    return this.scheduleForm.controls;
  }
  getDoctorDetails()
  {
    this.doctorname = JSON.parse(localStorage.getItem('currentUser')).username;
    this.spinner.show();
      this.apiService.getUserDetails(this.doctorname).subscribe((resData: any) => {
        console.log(resData);
        this.temp=resData;
        this.spinner.hide();
      }, error => {
        var data = error;
      });
  }
  bookAppointment() {
    // this.getDoctorDetails();
    this.submitted = true;
    this.meetingObject.topic = this.scheduleForm.get('topic').value;
    this.meetingObject.agenda = "";
    this.meetingObject.type = 2;
    this.meetingObject.start_time = this.getStartTime();
    this.meetingObject.duration = this.scheduleForm.get('from_time').value.duration;
    this.meetingObject.schedule_for = "";
    this.meetingObject.timezone = this.scheduleForm.get('time_zone').value;
    this.meetingObject.recurrence = null;
    this.meetingObject.settings = new Settings();
    this.meetingObject.settings.host_video = true;
    this.meetingObject.settings.participant_video = false;
    this.meetingObject.settings.cn_meeting = false;
    this.meetingObject.settings.in_meeting = true;
    this.meetingObject.settings.join_before_host = false;
    this.meetingObject.settings.mute_upon_entry = false;
    this.meetingObject.settings.watermark = true;
    this.meetingObject.settings.use_pmi = false;
    this.meetingObject.settings.registration_type = 1;
    this.meetingObject.settings.audio = "both";
    this.meetingObject.settings.approval_type = 1;
    this.meetingObject.settings.auto_recording = "none";

    this.spinner.show();
    this.apiService.scheduleMeeting(this.meetingObject).subscribe((data: any) => {
      if (data != null) {
        this.spinner.hide();
        console.log(JSON.stringify(data))
        let resdata = JSON.parse(data);
        this.scheduleAppointment(resdata);
      }
    }, error => {
      this.spinner.hide();
    });
  }

  scheduleAppointment(meetingData: any) {
    const datepipe: DatePipe = new DatePipe('en-US')
    let formattedDate = datepipe.transform(meetingData.start_time, 'YYYY-MM-dd HH:mm:ss')
    let appointmentDTO = {
      "customerMobile": this.customerData != null ? this.customerData.mobile : this.scheduleForm.get('patient').value.memberDTO.Mobile,
      "customerName": this.customerData != null ? this.customerData.name : this.scheduleForm.get('patient').value.memberDTO.Name,
      "doctorId": this.temp?.id,
      "doctorName": this.doctorname,
      "topic": meetingData.topic,
      "meetingNo": meetingData.id,
      "meetingPasscode": meetingData.password,
      "startTime": formattedDate,
      "duration": meetingData.duration,
      "consultationtype":this.temp?.doctorType,
      "consultationfee":this.temp?.feeAmount
    };
    this.spinner.show();
    this.apiService.bookDoctorAppointment(appointmentDTO).subscribe((data: any) =>{
      this.spinner.hide();
      console.log("appres--" + JSON.stringify(data))
      if(data.status==200){
      this.snackbar.open('Your appointment has been scheduled successfully.', "", { duration: 2000 });
      this.dialogRef.close({ event: 'refresh' });
      }else if(data.status==226){
        this.snackbar.open('Selected slot is already booked. Please select other slot', "", { duration: 2000 });
      }else if(data.status==208){
        this.snackbar.open('Patient has already scheduled appointment.', "", { duration: 2000 });
      }
      // window.location.reload();
    }, error => {
      this.spinner.hide();
    });
  }

  updateFormDate(value: any) {
    const datepipe: DatePipe = new DatePipe('en-US')
    this.scheduleForm.controls['date'].setValue(datepipe.transform(value, 'yyyy-MM-dd'));
  }

  getStartTime() {
    const datepipe: DatePipe = new DatePipe('en-US')
    let formattedDate = datepipe.transform(this.scheduleForm.get('from_time').value.startTime, 'YYYY-MM-ddTHH:mm:ssZ')
    return formattedDate;
  }

  //getDuration(): number {
  //  let from = new Date(this.scheduleForm.get('date').value + " " + this.scheduleForm.get('from_time').value);
  //  let to = new Date(this.scheduleForm.get('date').value + " " + this.scheduleForm.get('to_time').value);
  //  return Math.abs(to.getTime() - from.getTime()) / (1000 * 60);
  //}

  goBack() {
    this.dialogRef.close({ event: 'close' });
  }

  items: any[] = [];
  currentDate = new Date();
  currentMonth = "";
  stopDate = new Date();
  selectedItem = null;

  calInit() {
    // Creating an array with specified date range
    this.items = this.getDates(
      new Date(),
      moment(new Date()).add(1, 'months')
    );
  }

  // Common method to create an array of dates
  getDates(startDate: any, stopDate: any) {
    let dateArray = [];
    let currentDate = moment(startDate);
    stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }
    return dateArray;
  }

  // Get the selected Date
  select(item: any) {
    this.selectedItem = item;
    const datepipe: DatePipe = new DatePipe('en-US')
    this.scheduleForm.get('date').setValue(datepipe.transform(item, 'yyyy-MM-dd'));
  }

  // Method for changing Month
  changeMonth(e: any) {
    this.currentDate = this.items[e];
    this.currentMonth = new Date(this.currentDate).toLocaleString("default",
      {
        month: "short"
      });
  }

  // Method to get the current weekday of the date showon
  returnWeekDay(item: any) {
    return new Date(item).toLocaleDateString("default", { weekday: "short" });
  }

  startDateChange(event) {
    let ddate = new Date(event.target.value);
    this.selectedItem = ddate;
    const datepipe: DatePipe = new DatePipe('en-US')
    this.scheduleForm.get('startDate').setValue(datepipe.transform(ddate, 'yyyy-MM-dd'));
 
  }

}
