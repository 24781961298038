<h5 mat-dialog-title>
  {{title}}
</h5>

<div mat-dialog-content>
  <p>{{message}}</p>
</div>

<div mat-dialog-actions style="float: right;">
  <button type="button" class="btn btn-danger" (click)="onDismiss()">No</button>
  <button type="button" class="btn btn-primary" style="margin-left: 20px;" (click)="onConfirm()">Yes</button>
</div>